import { useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../../models/Dilution";
import { useCallback } from "react";

type UpdateSourceType =
  | `dilutionDetails.groups.${number}.reagents.${number}.preDilution.inputMaterial.stockConcentration`
  | `dilutionDetails.groups.${number}.reagents.${number}.preDilution.inputMaterial.stockName`
  | `dilutionDetails.groups.${number}.reagents.${number}.preDilution.inputMaterial.number`
  | `dilutionDetails.groups.${number}.reagents.${number}.preDilution.inputMaterial.lotNumber`
  | `dilutionDetails.groups.${number}.reagents.${number}.preDilution.inputMaterial.shortName`
  | `dilutionDetails.groups.${number}.reagents.${number}.preDilution.inputMaterial.targetUnit`
  | `dilutionDetails.groups.${number}.reagents.${number}.preDilution.inputMaterial.stockUnit`;

export const useUpdatePredulutionInputMaterial = ({
  groupIndex,
  reagentIndex,
  updateSource,
}: {
  groupIndex: number;
  reagentIndex: number;
  updateSource: UpdateSourceType;
}) => {
  const { setValue, getValues } = useFormContext<MastermixDilution>();
  return useCallback(
    (value: string) => {
      const preDilution = getValues(
        `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution`
      );
      if (!preDilution) {
        return;
      }
      setValue(updateSource, value);
    },
    [getValues, setValue, groupIndex, reagentIndex, updateSource]
  );
};
