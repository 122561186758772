import ApolloClient from "apollo-client";
import { createContext, useContext } from "react";
import { ApolloConsumer } from "react-apollo";

export const ApolloClientContext = createContext<ApolloClient<unknown> | null>(
  null
);

export const ApolloClientWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ApolloConsumer>
      {(client: ApolloClient<unknown>) => (
        <ApolloClientContext.Provider value={client}>
          {children}
        </ApolloClientContext.Provider>
      )}
    </ApolloConsumer>
  );
};

export const useApolloClient = () => {
  const context = useContext(ApolloClientContext);
  if (!context) {
    throw new Error(
      "You cannot use useApolloClient outside ApolloClientWrapper!"
    );
  }
  return context;
};
