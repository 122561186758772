import {
  OwcIcon,
  OwcIconButton,
  OwcTableCell,
  OwcTableRow,
  OwcTypography,
} from "@one/react";
import { useQueryContext } from "../../components/query-context/useQueryContext";
import TransparentLink from "../../components/TransparentLink/TransparentLink";
import { StatusDilutionTypeLabels } from "../../models/Dilution";
import { DilutionShortInfo } from "../../views/main-view/MainView";
import { getDilutionTypeText } from "../common-dilution/utils/getDilutionTypeText";
import { useCopyDilution } from "../copy-dilution/useCopyDilution";
import { useDeleteDilution } from "../delete-dilution/useDeleteDilution";
import { useDetailsLink } from "../hooks/useDetailsLink";
import { useNavigateToEditPage } from "../hooks/useNavigateToEditPage";
import { useDilutionListFilter } from "./context/dilution-list-filter/useDilutionListFilter";
import { DateAndTime } from "./TableListView";

export const DilutionTableRow = ({ info }: { info: DilutionShortInfo }) => {
  const detailsLink = useDetailsLink(info.type, info.id);

  const handleGoToEditPage = useNavigateToEditPage();
  const { deleteDilution, loading: pendingDelete } = useDeleteDilution({
    id: info.id,
    elnId: info.elnId,
    name: info.name,
    createdBy: info.createdBy,
  });
  const { copyDilution, loading: pendingCopy } = useCopyDilution({
    id: info.id,
    elnId: info.elnId,
    name: info.name,
  });
  const { refetch } = useQueryContext();
  const { clearFilter } = useDilutionListFilter();

  const pendingAction = pendingDelete || pendingCopy;

  return (
    <TransparentLink to={detailsLink}>
      <OwcTableRow
        data-testid={`dilution-list-table-body-row-for-${info.id}`}
        key={info.id}
        selectable={false}
        expandable={false}
      >
        <OwcTableCell valign="middle">
          <OwcTypography
            variant="body2"
            data-testid={`dilution-list-table-body-cell-for-${info.id}-name`}
          >
            {info.name}
          </OwcTypography>
        </OwcTableCell>
        <OwcTableCell valign="middle">
          <OwcTypography
            variant="body2"
            data-testid={`dilution-list-table-body-cell-for-${info.id}-status`}
          >
            {StatusDilutionTypeLabels[info.status!]}
          </OwcTypography>
        </OwcTableCell>
        <OwcTableCell valign="middle">
          <OwcTypography
            variant="body2"
            data-testid={`dilution-list-table-body-cell-for-${info.id}-userId`}
          >
            {info.updatedBy}
          </OwcTypography>
        </OwcTableCell>
        <OwcTableCell valign="middle">
          <OwcTypography
            variant="body2"
            data-testid={`dilution-list-table-body-cell-for-${info.id}-elnId`}
          >
            {info.elnId}
          </OwcTypography>
        </OwcTableCell>
        <OwcTableCell valign="middle">
          <OwcTypography variant="body2">
            <DateAndTime
              testId={`dilution-list-table-body-cell-for-${info.id}-createdAt`}
              date={info.createdAt}
            />
          </OwcTypography>
        </OwcTableCell>
        <OwcTableCell valign="middle">
          <OwcTypography variant="body2">
            <DateAndTime
              testId={`dilution-list-table-body-cell-for-${info.id}-updatedAt`}
              date={info.updatedAt}
            />
          </OwcTypography>
        </OwcTableCell>
        <OwcTableCell valign="middle">
          <OwcTypography
            data-testid={`dilution-list-table-body-cell-for-${info.id}-type`}
            variant="body2"
          >
            {getDilutionTypeText(info.type)}
          </OwcTypography>
        </OwcTableCell>
        <OwcTableCell
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          valign="middle"
          align="right"
        >
          <OwcIconButton
            data-testid={`dilution-list-table-body-cell-for-${info.id}-edit-btn`}
            flat
            disableRipple
            onClick={() => handleGoToEditPage(info.id, info.type)}
            disabled={pendingAction || info.status === "VERIFIED"}
          >
            <OwcIcon name="edit" />
          </OwcIconButton>
          <OwcIconButton
            data-testid={`dilution-list-table-body-cell-for-${info.id}-copy-btn`}
            flat
            disableRipple
            onClick={async () => {
              if (await copyDilution()) {
                clearFilter();
              }
              refetch();
            }}
            disabled={pendingAction}
          >
            <OwcIcon name="copy" />
          </OwcIconButton>
          <OwcIconButton
            data-testid={`dilution-list-table-body-cell-for-${info.id}-delete-btn`}
            flat
            disableRipple
            onClick={async () => {
              await deleteDilution();
              refetch();
            }}
            disabled={pendingAction}
          >
            <OwcIcon name="delete" />
          </OwcIconButton>
        </OwcTableCell>
      </OwcTableRow>
    </TransparentLink>
  );
};
