/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { limitComma } from "../../utils/helpers/limitComma";
import { nullableNumber } from "../../utils/helpers/nullableNumber";
import { numberKeepStringValidator } from "../../utils/helpers/numberKeepStringValidator";
import { materialSchema } from "../common-dilution/serial-and-co-formulated-dilution/forms/MaterialFormContent/schema/materialSchema";

const VOLUME_OF_REPLICATE_LIMIT_COMMA = 3;

const DEAD_VOLUME_LIMIT_COMMA = 3;

const volumeOfReplicateMessage = "Volume of Replicate must be a valid number";
const replicatesPerPanelMemberMessage =
  "Number of replicates per panel member must be a valid number";

export const checkIfEmpty = (v?: string | null) =>
  v === null || v === undefined || v === "";

export const highLevelInfoSchema = yup.object({
  name: yup
    .string()
    .required("Name of dilution cannot be empty")
    .typeError("Name of dilution cannot be empty"),
  replicatesPerPanelMember: yup
    .number()
    .when("dilutionDetails.customLeftOver", {
      is: checkIfEmpty,
      otherwise: (schema) =>
        schema
          .nullable(true)
          .transform((_, val) => (val === Number(val) ? val : null)),
      then: (schema) =>
        schema
          .required(replicatesPerPanelMemberMessage)
          .typeError(replicatesPerPanelMemberMessage)
          .min(
            yup.ref("$validation.replicatesPerPanelMemberMin"),
            "Number of replicates per panel member must be greater than or equal to ${min}"
          )
          .max(
            yup.ref("$validation.replicatesPerPanelMemberMax"),
            "Number of replicates per panel member must be less than or equal to ${max}"
          )
          .integer("Number of replicates per panel member must be an integer"),
    }),
  volumeOfReplicate: yup.string().when("dilutionDetails.customLeftOver", {
    is: checkIfEmpty,
    otherwise: (schema) => schema.nullable(),
    then: (schema) =>
      schema
        .typeError(volumeOfReplicateMessage)
        .test(
          numberKeepStringValidator(
            yup
              .number()
              .required(volumeOfReplicateMessage)
              .typeError(volumeOfReplicateMessage)
              .min(
                yup.ref("$validation.volumeOfReplicateMin"),
                "Volume of Replicate must be a number greater than or equal to ${min}"
              )
              .max(
                yup.ref("$validation.volumeOfReplicateMax"),
                "Volume of Replicate must be a number less than or equal to ${max}"
              )
          )
        )
        .test(
          limitComma(
            VOLUME_OF_REPLICATE_LIMIT_COMMA,
            "Volume of Replicate must have at most 3 digits after comma"
          )
        ),
  }),
  panelMembers: yup
    .number()
    .required("Number of panel members cannot be empty")
    .typeError("Number of panel members must be a valid number")
    .min(
      yup.ref("$validation.numberOfPanelMembersMin"),
      "Number of panel members must be greater than or equal to ${min}"
    )
    .max(
      yup.ref("$validation.numberOfPanelMembersMax"),
      "Number of panel members must be less than or equal to ${max}"
    )
    .integer("Number of panel members must be an integer"),
  deadVolume: yup
    .string()
    .typeError("Dead volume must be a valid number")
    .test(
      numberKeepStringValidator(
        yup
          .number()
          .typeError("Dead volume must be a valid number")
          .min(
            yup.ref("$validation.highLevelInfoDeadVolumeMin"),
            "Dead volume must be greater than or equal to ${min}"
          )
          .max(
            yup.ref("$validation.highLevelInfoDeadVolumeMax"),
            "Dead volume must be less than or equal to ${max}"
          )
          .transform(nullableNumber)
          .nullable()
      )
    )
    .test(
      limitComma(
        DEAD_VOLUME_LIMIT_COMMA,
        "Dead volume must have at most 3 digits after comma"
      )
    )
    .nullable(),
  dilutionDetails: yup.object({
    inputMaterials: yup
      .array(materialSchema)
      .min(1, "At least one input material must be specified"),
    diluents: yup
      .array(
        yup.object({
          stockName: yup
            .string()
            .required("Diluent name cannot be empty")
            .typeError("Diluent name cannot be empty"),
          number: yup
            .string()
            .required("Diluent material number cannot be empty")
            .typeError("Diluent material number cannot be empty"),
        })
      )
      .nullable(),
  }),
});
