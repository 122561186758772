import { useNavigate } from "react-router-dom";
import Container from "../../components/Container/Container";
import WelcomePic from "../../components/pic/WelcomePic";
import Hero from "../../components/shared/Hero/Hero";

const WelcomeView = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Hero
        testId="welcomePage"
        actionName="Create first dilution"
        pic={<WelcomePic />}
        title="Welcome to Dilution Workflow Manager"
        subtitle="Create your first dilution and share it with others"
        action={() => navigate("/experiment-type")}
      />
    </Container>
  );
};

export default WelcomeView;
