import { OwcInput } from "@one/react";
import { useFormContext } from "react-hook-form";
import OwcInputSuffix from "../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { MastermixDilution } from "../../../../models/Dilution";
import {
  reactionVolumeMax,
  reactionVolumeMin,
} from "../mastermixSchema/maxtermixSchemaMinMaxValue";

const ReactionVolume = () => {
  const { watch, getFieldState, formState } =
    useFormContext<MastermixDilution>();
  const value = watch(`dilutionDetails.reactionVolume.displayedValue`);
  const { error } = getFieldState("dilutionDetails.reactionVolume", formState);

  return (
    <OwcInput
      disabled={true}
      data-testid={`dilutionDetails.reactionVolume-input`}
      label="*Reaction volume"
      value={value}
      type="number"
      min={reactionVolumeMin}
      max={reactionVolumeMax}
      required
      error={Boolean(error)}
    >
      <span
        slot="error-text"
        data-testid={`dilutionDetails.reactionVolume-error`}
      >
        {error?.message}
      </span>
      <OwcInputSuffix>μL</OwcInputSuffix>
    </OwcInput>
  );
};

export default ReactionVolume;
