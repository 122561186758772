export const stockConcentrationMin = 0.001;
export const stockConcentrationMax = 9999999.999;

export const r2ConcentrationMin = 0.001;
export const r2ConcentrationMax = 9999999.999;

export const pcrConcentrationMin = 0.001;
export const pcrConcentrationMax = 9999999.999;

export const r1VolumeMin = 1;
export const r1VolumeMax = 999;

export const r2VolumeMin = 1;
export const r2VolumeMax = 999;

export const eluateVolumeMin = 1;
export const eluateVolumeMax = 999;

export const numberOfReactionsMin = 1;
export const numberOfReactionsMax = 99999;

export const reactionVolumeMin = 1;
export const reactionVolumeMax = 999;
