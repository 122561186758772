import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { MastermixDilution } from "../../../../../../models/Dilution";
import { getNumberFieldInputProps } from "../../../../../../utils/helpers/getNumberFieldInputProps";
import {
  stockConcentrationMax,
  stockConcentrationMin,
} from "../../../mastermixSchema/maxtermixSchemaMinMaxValue";
import { useUpdatePredulutionInputMaterial } from "../../../pre-dilution-step-content/hooks/useUpdatePredulutionInputMaterial";

interface MaterialStockConcentrationProps {
  groupIndex: number;
  reagentIndex: number;
}

const MaterialStockConcentration = ({
  groupIndex,
  reagentIndex,
}: MaterialStockConcentrationProps) => {
  const updateFirstPredilution = useUpdatePredulutionInputMaterial({
    groupIndex,
    reagentIndex,
    updateSource: `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.inputMaterial.stockConcentration`,
  });
  return (
    <Controller<
      MastermixDilution,
      `dilutionDetails.groups.${typeof groupIndex}.reagents.${typeof reagentIndex}.material.stockConcentration`
    >
      name={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.material.stockConcentration`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.material.stockConcentration-input`}
          label="*Stock concentration"
          {...getNumberFieldInputProps(field)}
          type="number"
          required
          error={Boolean(error)}
          min={stockConcentrationMin}
          max={stockConcentrationMax}
          onInputChange={(event) => {
            field.onChange(event.detail);
            updateFirstPredilution(event.detail);
          }}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.material.stockConcentration-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default MaterialStockConcentration;
