import { OwcProgressSpinner } from "@one/react";
import styles from "./ButtonLoading.module.scss";

interface ButtonLoadingProps {
  size?: number;
  testId: string;
}

const DEFAULT_BUTTON_SIZE = 32;

const ButtonLoading = ({
  testId,
  size = DEFAULT_BUTTON_SIZE,
}: ButtonLoadingProps) => {
  return (
    <div className={styles.buttonLoading}>
      <OwcProgressSpinner
        data-testid={testId}
        diameter={size}
        style={{ width: size, height: size }}
      />
    </div>
  );
};

export default ButtonLoading;
