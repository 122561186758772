/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { numberKeepStringValidator } from "../../../../../../utils/helpers/numberKeepStringValidator";
import { targetSchema } from "./targetSchema";

const customLeftOverMessage = "Leftover must be a valid number";
const numberOfReplicatesMessage = "Number of replicates must be a valid number";
export const PANEL_MEMBER_DILUTION_FACTOR_ERROR =
  "PANEL_MEMBER_DILUTION_FACTOR_ERROR";

export const panelMemberSchema = yup.object({
  stepName: yup
    .string()
    .required("Panel member name cannot be empty")
    .typeError("Panel member name cannot be empty"),

  targets: yup
    .array(targetSchema)
    .typeError("Targets is required because panel is not negative")
    .when("isNegative", {
      is: true,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema,
    }),
});

export const panelMemberFormSchemaLeftOver = panelMemberSchema.shape({
  customLeftOver: yup
    .string()
    .typeError(customLeftOverMessage)
    .required("Leftover cannot be empty")
    .test(
      numberKeepStringValidator(
        yup
          .number()
          .required(customLeftOverMessage)
          .typeError(customLeftOverMessage)
          .min(
            yup.ref("$validation.customLeftOverMin"),
            "Leftover must greater or equal ${min}"
          )
          .max(
            yup.ref("$validation.customLeftOverMax"),
            "Leftover must lesser or equal ${max}"
          )
      )
    ),
});

export const panelMemberFormSchemaReplicates = panelMemberSchema.shape({
  numberOfReplicates: yup
    .number()
    .integer("Number of replicates must be integer")
    .required(numberOfReplicatesMessage)
    .typeError(numberOfReplicatesMessage)
    .min(
      yup.ref("$validation.numberOfReplicatesMin"),
      "Number of replicates must be greater than or equal to ${min}"
    )
    .max(
      yup.ref("$validation.numberOfReplicatesMax"),
      "Number of replicates must be less than or equal to ${max}"
    ),
});
