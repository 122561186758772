import { MastermixDilution } from "../../../../models/Dilution";
import { GroupType } from "../../../../models/mastermix-models/GroupType";
import { getReactionVolume } from "../main-fields/utils/getReactionVolume";
import { getTotalVolume } from "../main-fields/utils/getTotalVolume";
import { getEmptyReagentGroup } from "./getEmptyReagentGroup";
import { getEmptyReagentGroupGenerics } from "./getEmptyReagentGroupGenerics";

const probeDefaultCountReagents = 2;

export const REAGENT_GROUP_TYPE_TO_NAME: Record<
  Exclude<GroupType, "CUSTOM">,
  string
> = {
  BACKBONE: "Backbone",
  GENERIC: "Generic",
  PRIMER: "Primer",
  PROBE: "Probe",
};

const defaultR1Value = "10";
const defaultR2Value = "15";
const defaultEluateVolumeValue = "25";
const defaultNumberOfReaction = null;
const defaultReactionVolumeRawVersion = getReactionVolume(
  defaultR1Value,
  defaultR2Value,
  defaultEluateVolumeValue
);
const defaultTotalVolume = getTotalVolume(
  defaultR2Value,
  defaultNumberOfReaction
);
export const getEmptyMastermix = (): MastermixDilution => {
  return {
    id: "",
    elnId: "",
    name: "",

    type: "MASTERMIX",
    dilutionDetails: {
      diluent: {
        id: "",
        lotNumber: null,
        number: null,
        shortName: "",
        stockName: "",
        neededVolume: null,
      },
      preDiluents: [],
      eluateVolume: defaultEluateVolumeValue,
      numberOfReactions: defaultNumberOfReaction,
      r1Volume: defaultR1Value,
      r2Volume: defaultR2Value,
      reactionVolume: defaultReactionVolumeRawVersion,
      totalVolume: defaultTotalVolume,
      groups: [
        getEmptyReagentGroup(
          REAGENT_GROUP_TYPE_TO_NAME["BACKBONE"],
          "BACKBONE"
        ),
        getEmptyReagentGroupGenerics(),
        getEmptyReagentGroup(REAGENT_GROUP_TYPE_TO_NAME["PRIMER"], "PRIMER"),
        getEmptyReagentGroup(
          REAGENT_GROUP_TYPE_TO_NAME["PROBE"],
          "PROBE",
          probeDefaultCountReagents
        ),
      ],
    },
  };
};
