import {
  CellData,
  generateSingleValueCellTable,
  generateSingleValueCellWithUnitTable,
  generateTableCellContent,
} from "../../utils/generate-cell-table";
import { parseToBreakableStrings } from "../../utils/parse-to-breakable-strings";
import { calculateCellWidth, layout } from "./common";

export interface PreDilutionTableRow {
  stepName: CellData;
  predecessorName: CellData;
  concentrationPredecessor: CellData;
  volumeOfPredecessor: CellData;
  volumeOfDiluent: CellData;
  dilutionFactor: CellData;
  finalConcentration: CellData;
  totalVolume: CellData;
  deadVolume: CellData;
}

export interface PreDilutionTablInput {
  rows: PreDilutionTableRow[];
}

const predilutionStepsTable = (resultsData: PreDilutionTablInput) => {
  const tableHeaderNames = [
    "Pre-dilution name",
    "Name of Predecessor",
    "Concentration Predecessor",
    "Volume of Predecessor [μL]*****",
    "Volume of Diluent [μL] ******",
    "Dilution Factor*******",
    "Final Concentration",
    "Total Volume [μL]********",
    "Dead volume [μL]",
  ];

  const columnsAlignedToLeft = [0, 1];
  const headers = tableHeaderNames.map((tableHeaderName, index) => {
    const style = ["bold"];

    if (columnsAlignedToLeft.includes(index)) {
      style.push("alignedToLeft");
    }

    return generateSingleValueCellTable({ value: tableHeaderName, style });
  });

  const rows = resultsData.rows.map(
    (row: PreDilutionTableRow, rowIndex: number) =>
      Object.values(row).map(({ value, unit, noBorder, styles }, index) => {
        const style = [];
        let valueToAssign = parseToBreakableStrings(value);

        // Align first two columns to the left
        if (columnsAlignedToLeft.includes(index)) {
          style.push("alignedToLeft");
        }

        // Display one element inner table as regular value
        if (Array.isArray(valueToAssign) && valueToAssign.length === 1) {
          valueToAssign = valueToAssign[0];
        }

        // Return value with unit for concentration values
        if (unit) {
          return generateSingleValueCellWithUnitTable({ value, unit });
        }

        return generateTableCellContent(rowIndex, {
          value: valueToAssign,
          style,
          noBorder,
          styles,
        });
      })
  );
  const columnWidth = calculateCellWidth(tableHeaderNames.length);
  return {
    style: ["table", "marginBottomLarge"],
    table: {
      widths: [...Array(tableHeaderNames.length)].map(() => columnWidth),
      dontBreakRows: true,
      body: [headers, ...rows],
    },
    layout,
  };
};

export default predilutionStepsTable;
