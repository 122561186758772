import { useCallback, useState } from "react";
import { DilutionSampleLabelsForm } from "../DilutionSampleLabelsForm";
import { generateBarcodes } from "./helpers";
import { downloadCSV } from "./downloadCSV";

export const useSampleLables = () => {
  const [open, setOpen] = useState(false);
  const generateCSV = useCallback(
    (values: DilutionSampleLabelsForm["labels"]) => {
      const barcodes = generateBarcodes(values);
      downloadCSV(barcodes);
    },
    []
  );
  const openPrintLabelsDialog = useCallback(() => setOpen(true), []);
  const closePrintLabelsDialog = useCallback(() => setOpen(false), []);
  return {
    open,
    closePrintLabelsDialog,
    openPrintLabelsDialog,
    generateCSV,
  };
};
