const BASE = 10;
const DEFAULT_ROUND_TO = 2;
const FRACTION_DIGITS = 2;

interface ToFixedOptions {
  roundTo: number | null;
  exponential: boolean;
}

export function toFixed(
  factor: number,
  options?: Partial<ToFixedOptions>
): string;
export function toFixed(factor: null, options?: Partial<ToFixedOptions>): null;
export function toFixed(
  factor: undefined,
  options?: Partial<ToFixedOptions>
): undefined;
export function toFixed(
  factor: number | null,
  options?: Partial<ToFixedOptions>
): string | null;
export function toFixed(
  factor: number | undefined,
  options?: Partial<ToFixedOptions>
): string | undefined;
export function toFixed(
  theNumber: number | null | undefined,
  {
    roundTo = DEFAULT_ROUND_TO,
    exponential = false,
  }: Partial<ToFixedOptions> = {}
): string | null | undefined {
  if (theNumber === null) {
    return theNumber;
  }
  if (theNumber === undefined) {
    return theNumber;
  }
  const divideBy = Math.pow(BASE, roundTo ?? 1);
  const rounded =
    roundTo === null ? theNumber : Math.round(theNumber * divideBy) / divideBy;
  if (exponential) {
    return roundTo
      ? rounded.toExponential(FRACTION_DIGITS)
      : rounded.toExponential();
  }

  return roundTo === null
    ? rounded.toString()
    : parseFloat(rounded.toString()).toString();
}
