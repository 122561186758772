import { Navigate } from "react-router-dom";
import SerialDilutionWorkflow from "../features/serial-dilution/SerialDilutionWorkflow/SerialDilutionWorkflow";
import { CoFormDilution } from "../models/Dilution";
import { getHighLevelMaterialDilution } from "../utils/helpers/getHighLevelMaterialDilution";
import DilutionPageTemplate from "./DilutionPageTemplate";
import { getCoFormDilutionQueryGql } from "./serial-print-page/queries/getCoFormDilutionQueryGql";
import { useStepForDraftDilution } from "../features/common-dilution/serial-and-co-formulated-dilution/utils/useValidateStepForDraftDilution";

const SerialEditPageContent = ({ dilution }: { dilution: CoFormDilution }) => {
  const initialStep = useStepForDraftDilution();
  if (dilution.status === "VERIFIED") {
    return <Navigate to="/" />;
  }

  return (
    <SerialDilutionWorkflow
      initialDilution={getHighLevelMaterialDilution(dilution)}
      initialStep={initialStep}
    />
  );
};

const SerialEditPage = () => (
  <DilutionPageTemplate
    component={SerialEditPageContent}
    titleForError={`Loading edit page for dilution`}
    query={getCoFormDilutionQueryGql}
  />
);

export default SerialEditPage;
