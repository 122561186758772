import { useMemo } from "react";
import { useApolloClient } from "../../components/apollo-client-context/ApolloClientWrapper";
import { useDilutionListFilter } from "../../features/dilutions-list/context/dilution-list-filter/useDilutionListFilter";
import { useDrillData } from "../../utils/hooks/useDrillData";
import { DilutionListQueryType, DilutionShortInfo } from "./MainView";
import { getDilutionsListQueryGql } from "./queries/getDilutionsListQueryGql";

export const useDillutionData = () => {
  const { searchText, type, hasFilter } = useDilutionListFilter();
  const client = useApolloClient();
  const params = useMemo(
    () => ({
      filter: hasFilter
        ? {
            and: {
              ...(type !== "ALL" ? { type: { eq: type } } : {}),
              ...(searchText
                ? {
                    or: [
                      {
                        name: {
                          contains: searchText,
                        },
                      },
                      {
                        elnId: {
                          contains: searchText,
                        },
                      },
                      {
                        updatedBy: {
                          contains: searchText,
                        },
                      },
                    ],
                  }
                : {}),
            },
          }
        : undefined,
    }),
    [searchText, type, hasFilter]
  );
  return useDrillData<DilutionListQueryType, DilutionShortInfo>({
    params,
    fetchLimit: 30,
    handler: {
      gqlQuery: getDilutionsListQueryGql,
      mapResponse: (res) => {
        const items = res.listSortedDilutions?.items ?? [];
        const nextToken = res.listSortedDilutions?.nextToken ?? null;
        return {
          items,
          hasMoreData: !!nextToken,
          nextToken,
        };
      },
      client,
    },
  });
};
