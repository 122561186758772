import { OwcInput } from "@one/react";
import { Controller, useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../../../models/Dilution";
import { getStringFieldInputProps } from "../../../../../../utils/helpers/getStringFieldInputProps";
import { useUpdatePredulutionInputMaterial } from "../../../pre-dilution-step-content/hooks/useUpdatePredulutionInputMaterial";

interface MaterialStockUnitProps {
  groupIndex: number;
  reagentIndex: number;
}

const MaterialStockUnit = ({
  groupIndex,
  reagentIndex,
}: MaterialStockUnitProps) => {
  const { getValues, setValue } = useFormContext<MastermixDilution>();
  const updateFirstPredilutionTargetUnit = useUpdatePredulutionInputMaterial({
    groupIndex,
    reagentIndex,
    updateSource: `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.inputMaterial.targetUnit`,
  });
  const updateFirstPredilutionStockUnit = useUpdatePredulutionInputMaterial({
    groupIndex,
    reagentIndex,
    updateSource: `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.inputMaterial.stockUnit`,
  });
  return (
    <Controller<
      MastermixDilution,
      `dilutionDetails.groups.${typeof groupIndex}.reagents.${typeof reagentIndex}.material.stockUnit`
    >
      name={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.material.stockUnit`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.material.stockUnit-input`}
          label="*Unit"
          {...getStringFieldInputProps(field)}
          required
          error={Boolean(error)}
          onInputChange={(event) => {
            const theValue = getValues(
              `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.material.stockUnit`
            );
            /* istanbul ignore next */
            if (theValue === event.detail) {
              // It's already set to that value; skip on change
              return;
            }
            field.onChange(event.detail);
            setValue(
              `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.material.targetUnit`,
              event.detail
            );
            updateFirstPredilutionTargetUnit(event.detail);
            updateFirstPredilutionStockUnit(event.detail);
          }}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.material.stockUnit-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default MaterialStockUnit;
