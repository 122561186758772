import { PanelMember } from "../../../../../../models/coform-models/PanelMember";
import { PreDilution } from "../../../../../../models/coform-models/PreDilution";
import { HighLevelMaterialDilution } from "../models/HighLevelMaterialDilution";
import { getPanelMemberTargets } from "./getPanelMemberTargets";

const MIN_STEP_NAME_NUMBERS = 2;
const NEGATIVE_PM_DEFAULT_NAME = `Negative PM`;

const getNegativePanelMember = (
  dilution: HighLevelMaterialDilution,
  panelMembers: PanelMember[]
) => {
  const existingNegativePanelMember =
    dilution.dilutionDetails?.panelMembers.find((p) => p.isNegative === true);

  const numberOfReplicates = existingNegativePanelMember
    ? existingNegativePanelMember.numberOfReplicates
    : dilution.replicatesPerPanelMember ?? 0;

  const negativePanelMember = {
    stepNumber: panelMembers.length + 1,
    diluentVolume: null,
    inputs: null,
    leftOver: null,
    totalVolume: null,
    ...(existingNegativePanelMember ?? {}),
    customLeftOver: existingNegativePanelMember?.customLeftOver
      ? existingNegativePanelMember?.customLeftOver
      : dilution.dilutionDetails?.customLeftOver ?? null,
    numberOfReplicates,
    replicateVolume: `${dilution.volumeOfReplicate}`,
    deadVolume: `${dilution.deadVolume ?? ""}`,
    diluent: dilution.dilutionDetails?.diluents![0] ?? null,
    isNegative: true,
    stepName: existingNegativePanelMember
      ? existingNegativePanelMember.stepName
      : NEGATIVE_PM_DEFAULT_NAME,
    targets: null,
  };
  return negativePanelMember;
};

const getPanelMember = (
  existingPanelMembersWithoutNegative: PanelMember[] | undefined,
  index: number,
  dilution: HighLevelMaterialDilution,
  preDilutions: PreDilution[],
  panelMembers: PanelMember[]
) => {
  const existingPanelMember = existingPanelMembersWithoutNegative?.[index];
  const stepNamePrettyNumber = `${index + 1}`.padStart(
    MIN_STEP_NAME_NUMBERS,
    "0"
  );

  const numberOfReplicates = existingPanelMember
    ? existingPanelMember.numberOfReplicates
    : dilution.replicatesPerPanelMember ?? 0;

  const defaultStepName = `PM${stepNamePrettyNumber}`;

  const stepName = existingPanelMember?.stepName ?? defaultStepName;

  const panelMember = {
    stepNumber: index + 1,
    diluentVolume: null,
    inputs: null,
    leftOver: null,
    totalVolume: null,
    ...(existingPanelMember ?? {}),
    customLeftOver: existingPanelMember?.customLeftOver
      ? existingPanelMember?.customLeftOver
      : dilution.dilutionDetails?.customLeftOver ?? null,
    numberOfReplicates: dilution.dilutionDetails?.customLeftOver
      ? existingPanelMember?.numberOfReplicates ?? 0
      : numberOfReplicates,
    replicateVolume: `${dilution.volumeOfReplicate}`,
    deadVolume: `${dilution.deadVolume ?? ""}`,
    diluent: dilution.dilutionDetails?.diluents![0] ?? null,
    stepName,
    isNegative: false,
    targets: getPanelMemberTargets(
      dilution,
      existingPanelMember,
      index,
      preDilutions,
      panelMembers
    ),
  };
  return panelMember;
};

export const getPanelMembersArray = (
  dilution: HighLevelMaterialDilution,
  preDilutions: PreDilution[]
): PanelMember[] => {
  const panelMembers: PanelMember[] = [];
  const hasNegative = dilution.dilutionDetails?.hasNegativePanelMember ?? false;
  const existingPanelMembersWithoutNegative =
    dilution.dilutionDetails?.panelMembers?.filter(
      (p) => p.isNegative !== true
    );
  const panelMembersCount = dilution.panelMembers ?? 0;

  for (let index = 0; index < panelMembersCount; index++) {
    const panelMember = getPanelMember(
      existingPanelMembersWithoutNegative,
      index,
      dilution,
      preDilutions,
      panelMembers
    );

    panelMembers.push(panelMember);
  }

  if (hasNegative) {
    const negativePanelMember = getNegativePanelMember(dilution, panelMembers);

    panelMembers.push(negativePanelMember);
  }

  return panelMembers;
};
