import Grid from "../../../../components/Grid/Grid";
import { PreDilutionStep } from "../../../../models/coform-models/PreDilutionStep";
import { Calculated } from "../../../../models/Dilution";
import styles from "./DilutionDetailsPagePreDilutionsSection.module.scss";
import { FlexLine } from "./FlexLine";

export const ConcentrationSection = ({
  input,
  preDilutionIndex,
  preDilutionStep,
  preDilutionStepIndex,
}: {
  preDilutionStepIndex: number;
  preDilutionStep: PreDilutionStep;
  input: {
    name: string;
    volume: Calculated;
    stockUnit: string;
    targetUnit: string;
  };
  preDilutionIndex: number;
}) => {
  return (
    <div className={styles.grid}>
      <Grid columnGap={64} rowGap={4} columns={2} container>
        <Grid item columnSpan={1}>
          {preDilutionStepIndex === 0 && (
            <FlexLine
              label={
                <>
                  Stock material
                  <br />
                  concentration
                </>
              }
              value={`${preDilutionStep.inputConcentration?.displayedValue} ${input.targetUnit}`}
              labelTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-stock-material-concentration-title-${preDilutionStepIndex}`}
              valueTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-input-concentration-value-${preDilutionStepIndex}`}
            />
          )}
          {preDilutionStepIndex !== 0 && (
            <FlexLine
              label={
                <>
                  Input material
                  <br />
                  concentration
                </>
              }
              value={`${preDilutionStep.inputConcentration?.displayedValue} ${input.targetUnit}`}
              labelTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-input-material-concentration-title-${preDilutionStepIndex}`}
              valueTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-input-concentration-value-${preDilutionStepIndex}`}
            />
          )}
        </Grid>
        <Grid item columnSpan={1}>
          <FlexLine
            label={
              <>
                Target
                <br />
                concentration
              </>
            }
            value={`${preDilutionStep.targetConcentration?.displayedValue} ${input.targetUnit}`}
            labelTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-target-concentration-title-${preDilutionStepIndex}`}
            valueTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-target-concentration-value-${preDilutionStepIndex}`}
          />
        </Grid>
      </Grid>
    </div>
  );
};
