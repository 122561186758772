import { yupResolver } from "@hookform/resolvers/yup";
import { OwcButton, OwcIcon, OwcTypography } from "@one/react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CoFormDilution } from "../../../../../models/Dilution";
import button from "../../../../../scss/button/Button.module.scss";
import { highLevelInfoSchema } from "../../../../schema/highLevelInfoSchema";
import { getInputMaterial } from "../../utils/getInputMaterial";
import MaterialFormContent from "../MaterialFormContent/MaterialFormContent";
import { useValidationParams } from "../ValidationParamsContext/useValidationParams";
import HighLevelInfoFormContent from "./HighLevelInfoFormContent";
import styles from "./HighLevelMaterialInfoForm.module.scss";
import { HighLevelMaterialDilution } from "./models/HighLevelMaterialDilution";
import { highLevelMaterialDilutionToDilution } from "./utils/highLevelMaterialDilutionToDilution";
import { SaveDraft } from "../PanelMemberForm/SaveDraft";

interface HighLevelMaterialInfoFormProps {
  dilution: CoFormDilution;
  title: string;
  onDilutionChange: (newFormula: HighLevelMaterialDilution) => void;
}

type ButtonsProps = {
  isHeader: boolean;
  dilution: CoFormDilution;
  onMaterialAdd?: () => void;
};

const Buttons = ({ dilution, isHeader, onMaterialAdd }: ButtonsProps) => {
  const navigate = useNavigate();
  return (
    <div className={styles.buttons}>
      {!isHeader && dilution.type === "CO_FORMULATED_DILUTION" && (
        <div className={styles.addMaterialButton}>
          <OwcButton
            onClick={onMaterialAdd}
            className={button.large}
            flat
            variant="secondary"
            data-testid="high-level-material-info-add-material-btn"
          >
            <div className={button.iconWrapper}>
              <OwcIcon name="sign_plus" color="inherit" />
              Add new material
            </div>
          </OwcButton>
        </div>
      )}
      <OwcButton
        onClick={() => navigate("/experiment-type")}
        className={button.large}
        flat
        variant="secondary"
        data-testid="high-level-material-info-cancel-btn"
      >
        Cancel
      </OwcButton>
      <SaveDraft />
      <OwcButton
        className={button.large}
        flat
        type="submit"
        data-testid="high-level-material-info-create-panel-btn"
      >
        Create panel members
      </OwcButton>
    </div>
  );
};

const HighLevelMaterialInfoForm = ({
  dilution,
  title,
  onDilutionChange,
}: HighLevelMaterialInfoFormProps) => {
  const validation = useValidationParams();
  const methods = useForm<HighLevelMaterialDilution>({
    defaultValues: dilution,
    resolver: yupResolver(highLevelInfoSchema),
    context: { validation },
  });
  const fieldArray = useFieldArray({
    control: methods.control,
    name: "dilutionDetails.inputMaterials",
  });
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((values) => {
          onDilutionChange(
            highLevelMaterialDilutionToDilution(
              values,
              methods.formState.defaultValues
            )
          );
        })}
        noValidate
        id="high-level-material-info-form"
      >
        <div className={styles.workflow}>
          <div className={styles.header}>
            <OwcTypography variant="title5">{title}</OwcTypography>
            <Buttons {...{ dilution, isHeader: true }} />
          </div>

          <div className={styles.wrapper}>
            <HighLevelInfoFormContent />
            <div
              className={styles.material}
              data-testid="high-level-material-info-material"
            >
              <OwcTypography variant="title6">Materials</OwcTypography>
              {fieldArray.fields.map((materialWithId, index) => {
                return (
                  <MaterialFormContent
                    fieldArray={fieldArray}
                    key={materialWithId.id}
                    typeOfDilution={dilution.type}
                    index={index}
                  />
                );
              })}
              {methods.formState.errors.dilutionDetails?.inputMaterials
                ?.message && (
                <OwcTypography data-testid="high-level-material-info-material-error">
                  {
                    methods.formState.errors.dilutionDetails?.inputMaterials
                      ?.message
                  }
                </OwcTypography>
              )}
            </div>
          </div>
          <Buttons
            {...{
              dilution,
              isHeader: false,
              onMaterialAdd: () => {
                const currentLength = fieldArray.fields.length;
                if (currentLength === 0) {
                  methods.setError("dilutionDetails.inputMaterials", {});
                }
                fieldArray.append(getInputMaterial(dilution.type));
              },
            }}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default HighLevelMaterialInfoForm;
