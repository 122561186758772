import { OperationVariables } from "apollo-client";
import { Reducer, useCallback, useEffect, useReducer, useRef } from "react";
import {
  drillDataByQuery,
  DrillHandler,
  QueryResponse,
} from "../helpers/drillDataByQuery";

export type useDrillDataProps<TResponse, TModel> = {
  params: OperationVariables | null;
  handler: DrillHandler<TResponse, TModel>;
  chunkSize?: number;
  fetchLimit?: number;
};

type DataState<T> = {
  loading: boolean;
  fetching: boolean;
  error: unknown;
  items: T[];
  refetchCounter: number;
  hasMoreData: boolean;
  nextToken: string | null | undefined;
};

const initialDataState = {
  loading: false,
  fetching: false,
  error: null,
  items: [],
  refetchCounter: 0,
  hasMoreData: false,
  nextToken: null,
};

export enum ReducerActionType {
  LOADING,
  DATA,
  NEXT_DATA,
  REFETECH,
  FETCH_MORE,
  ERROR,
}

type Action<T> =
  | { type: ReducerActionType.LOADING }
  | { type: ReducerActionType.REFETECH }
  | { type: ReducerActionType.FETCH_MORE }
  | { type: ReducerActionType.DATA; response: QueryResponse<T> }
  | { type: ReducerActionType.NEXT_DATA; response: QueryResponse<T> }
  | { type: ReducerActionType.ERROR; error: unknown };

function reducer<T>(state: DataState<T>, action: Action<T>): DataState<T> {
  switch (action.type) {
    case ReducerActionType.LOADING: {
      return {
        ...state,
        loading: !state.fetching,
        error: null,
      };
    }
    case ReducerActionType.DATA: {
      return {
        ...state,
        ...action.response,
        loading: false,
        fetching: false,
      };
    }
    case ReducerActionType.NEXT_DATA: {
      return {
        ...state,
        ...action.response,
        loading: false,
        fetching: false,
        items: state.nextToken
          ? state.items.concat(action.response.items)
          : action.response.items,
      };
    }
    case ReducerActionType.REFETECH: {
      return {
        ...state,
        fetching: false,
        nextToken: null,
        refetchCounter: state.refetchCounter + 1,
      };
    }
    case ReducerActionType.FETCH_MORE: {
      return {
        ...state,
        fetching: true,
        refetchCounter: state.refetchCounter + 1,
      };
    }
    case ReducerActionType.ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    /* istanbul ignore next */
    default: {
      return state;
    }
  }
}
const DEFAULT_CHUNK_SIZE = 30;

export const useDrillData = <TResponse, TModel>({
  params,
  handler,
  chunkSize = DEFAULT_CHUNK_SIZE,
  fetchLimit = Number.MAX_SAFE_INTEGER,
}: {
  params: OperationVariables | null;
  handler: DrillHandler<TResponse, TModel>;
  chunkSize?: number;
  fetchLimit?: number;
}) => {
  const lastParamsRef = useRef(params);
  const [state, dispatch] = useReducer<
    Reducer<DataState<TModel>, Action<TModel>>
  >(reducer, initialDataState);
  const refetch = useCallback(
    () => dispatch({ type: ReducerActionType.REFETECH }),
    []
  );
  const loadMore = useCallback(
    () => dispatch({ type: ReducerActionType.FETCH_MORE }),
    []
  );
  useEffect(() => {
    let ignore = false;
    (async () => {
      try {
        const shouldClearData = lastParamsRef.current !== params;
        dispatch({ type: ReducerActionType.LOADING });
        const { items, hasMoreData, nextToken } = await drillDataByQuery<
          TResponse,
          TModel
        >(
          { ...params, nextToken: shouldClearData ? null : state.nextToken },
          handler,
          chunkSize,
          fetchLimit
        );
        /* istanbul ignore next */
        if (ignore) return;
        if (shouldClearData) {
          dispatch({
            type: ReducerActionType.DATA,
            response: { items, hasMoreData, nextToken },
          });
        } else {
          dispatch({
            type: ReducerActionType.NEXT_DATA,
            response: { items, hasMoreData, nextToken },
          });
        }
        lastParamsRef.current = params;
      } catch (error) {
        dispatch({ type: ReducerActionType.ERROR, error });
      }
    })();
    return () => {
      ignore = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, state.refetchCounter]);
  return {
    ...state,
    refetch,
    loadMore,
  };
};
