import * as yup from "yup";

export const calculatedSchema = (schema: yup.AnySchema) =>
  yup.mixed().test({
    test: (value, context) => {
      return schema
        .validate(value?.displayedValue, {
          context: {
            ...(context.options.context ?? {}),
            parent: context.parent,
          },
        })
        .then(
          () => true,
          (error) => {
            if (yup.ValidationError.isError(error)) {
              return context.createError({
                message: error.message,
                type: error.type,
              });
            }
            throw error;
          }
        );
    },
  });

export const isCalculatedSchemaContextType = (
  v: yup.TestContext
): v is yup.TestContext<{ parent: unknown }> =>
  Boolean(v.options.context && "parent" in v.options.context);
