export const getDetailsInfoArray = (
  warningDiluentFactorMore: boolean,
  warningDiluentFactorLess: boolean,
  warningVolumeMaterial = false,
  warningVolumeDiluent = false
) => {
  const detailsArrayInfo = [];
  if (warningVolumeMaterial) {
    detailsArrayInfo.push({
      label: "Input material:",
      detailsInfo: "Input volume must be no less than 10 uL",
    });
  }
  if (warningVolumeDiluent) {
    detailsArrayInfo.push({
      label: "Diluent:",
      detailsInfo: "Diluent volume must be no less than 10 uL",
    });
  }
  if (warningDiluentFactorMore) {
    detailsArrayInfo.push({
      label: "Dilution factor:",
      detailsInfo: "Dilution factor must be no more than 100",
    });
  }
  if (warningDiluentFactorLess) {
    detailsArrayInfo.push({
      label: "Dilution factor:",
      detailsInfo: "Dilution factor must be no less than 1",
    });
  }

  return detailsArrayInfo;
};
