import { PanelMember } from "../../../../models/coform-models/PanelMember";
import { DilutionType } from "../../../../models/DilutionType";
import {
  DILUENT_FACTOR_MAX,
  DILUENT_FACTOR_MIN,
  VOLUME_MIN,
} from "../../constants";

export function getInputAndTargetDetails(
  panelMember: PanelMember,
  panelMemberIndex: number,
  type: DilutionType
) {
  const isFirstPanelMemberForCoFormulationType =
    type === "CO_FORMULATED_DILUTION" && panelMemberIndex === 0;

  return Array.from(
    {
      length: Math.max(
        // Add +1 because diluent input will be appended by PanelMemberInputsAndTargesInfoSection
        (panelMember?.inputs?.length ?? 0) +
          (isFirstPanelMemberForCoFormulationType ? 0 : 1),
        panelMember?.targets?.length ?? 0
      ),
    },
    (_, index) => {
      const target = panelMember?.targets?.[index] ?? null;
      const diluentInput = {
        name: panelMember.diluent?.stockName ?? undefined,
        inputVolume: panelMember.diluentVolume,
      };

      const inputs = isFirstPanelMemberForCoFormulationType
        ? [...(panelMember.inputs ?? [])]
        : [...(panelMember.inputs ?? []), diluentInput];
      const input = inputs[index];
      const warningDiluentFactorMore =
        Number(target?.dilutionFactor?.displayedValue) > DILUENT_FACTOR_MAX;
      const warningDiluentFactorLess =
        Number(target?.dilutionFactor?.displayedValue) < DILUENT_FACTOR_MIN;
      const warningVolumeInput =
        Number(input?.inputVolume?.displayedValue) < VOLUME_MIN;

      return {
        input,
        target,
        warningDiluentFactorMore,
        warningDiluentFactorLess,
        warningVolumeInput,
        isDiluentInput: input === diluentInput,
      };
    }
  );
}
