import { useNavigate } from "react-router-dom";
import Container from "../components/Container/Container";
import NotFoundPic from "../components/pic/NotFoundPic";
import Hero from "../components/shared/Hero/Hero";

const NotFoundScreen = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Hero
        pic={<NotFoundPic />}
        testId="notFound"
        title="Error 404"
        subtitle="Ups, something went wrong"
        action={() => navigate("/")}
        actionName="Go to main page"
      />
    </Container>
  );
};

export default NotFoundScreen;
