import ApolloClient, { OperationVariables } from "apollo-client";
import gql from "graphql-tag";

export type QueryResponse<T> = {
  items: T[];
  hasMoreData: boolean;
  nextToken?: string | null;
};

export type DrillHandler<T, P> = {
  gqlQuery: ReturnType<typeof gql>;
  mapResponse: (response: T) => QueryResponse<P>;
  client: ApolloClient<unknown>;
};

export const drillDataByQuery = async <TResponse, TModel>(
  variables: OperationVariables | null,
  handler: DrillHandler<TResponse, TModel>,
  chunkSize: number,
  fetchLimit: number = Number.MAX_SAFE_INTEGER,
  fetchedCount: number = 0
): Promise<QueryResponse<TModel>> => {
  const queryParams = {
    ...variables,
    limit: chunkSize,
  };
  const { data, errors } = await handler.client.query<TResponse>({
    query: handler.gqlQuery,
    fetchPolicy: "network-only",
    variables: queryParams,
  });
  /* istanbul ignore next */
  if (errors) {
    throw errors;
  }
  const responseMapped = handler.mapResponse(data);
  const count = fetchedCount + responseMapped.items.length;
  if (responseMapped.hasMoreData && count < fetchLimit) {
    const {
      items: itemsData,
      nextToken,
      hasMoreData,
    } = await drillDataByQuery(
      { ...variables, nextToken: responseMapped.nextToken },
      handler,
      chunkSize,
      fetchLimit,
      count
    );
    return {
      items: responseMapped.items.concat(itemsData),
      nextToken,
      hasMoreData,
    };
  }
  return responseMapped;
};
