import { OwcButton, OwcIcon, OwcIconButton } from "@one/react";
import { useState } from "react";
import { UseFieldArrayReturn, useFormContext } from "react-hook-form";
import Grid from "../../../../components/Grid/Grid";
import button from "../../../../scss/button/Button.module.scss";
import PreDilutionNameField from "./fields/PreDilutionNameField";
import PreDilutionStepDeadVolume from "./fields/PreDilutionStepDeadVolume";
import PreDilutionStepDilutionFactor from "./fields/PreDilutionStepDilutionFactor";
import { StockMaterialConcentrationRow } from "./fields/StockMaterialConcentrationRow";
import StockMaterialInfoColumn from "./fields/StockMaterialInfoColumn";
import styles from "./PreDilutionStepContent.module.scss";
import { useValidationParams } from "../../../common-dilution/serial-and-co-formulated-dilution/forms/ValidationParamsContext/useValidationParams";
import { MastermixDilution } from "../../../../models/Dilution";
import { usePreDilutionStepInsert } from "./hooks/usePreDilutionStepInsert";
import { usePreDilutionStepFactor } from "./fields/usePreDilutionStepFactor";
import { usePreDilutionStepRemove } from "./hooks/usePreDilutionStepRemove";
import { useHandleDiluentConfirm } from "./hooks/useHandleDiluentConfirm";
import PreDilutionTargetConcentrationField from "./fields/PreDilutionTargetConcentrationField";
import PreDilutionStepChangeDiluentDialog from "../../../change-diluent-for-step/PreDilutionStepChangeDiluentDialog";
import WarningInfoSection from "../../../common-dilution/waring-section/WarningInfoSection";
import { getDetailsInfoArray } from "../../../common-dilution/waring-section/getDetailsInfoArray";
import DiluionInfoColumn from "./DiluionInfoColumn";

export type PreDilutionStepContentProps = {
  groupIndex: number;
  reagentIndex: number;
  index: number;
  fieldArray: UseFieldArrayReturn<
    MastermixDilution,
    `dilutionDetails.groups.${number}.reagents.${number}.preDilution.dilutionSteps`,
    "id"
  >;
  remove: ReturnType<typeof usePreDilutionStepRemove>;
};

const PreDilutionStepContent = ({
  groupIndex,
  reagentIndex,
  index,
  fieldArray,
  remove,
}: PreDilutionStepContentProps) => {
  const insert = usePreDilutionStepInsert({
    fieldArray,
    groupIndex,
    reagentIndex,
  });
  const { waringFactorMore, waringFactorLess } = usePreDilutionStepFactor({
    groupIndex,
    reagentIndex,
    preDilutionStepIndex: index,
  });
  const { watch } = useFormContext<MastermixDilution>();
  const preDilutionStep = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${index}`
  );
  const handleDiluentConfirm = useHandleDiluentConfirm({
    groupIndex,
    reagentIndex,
    index,
  });

  const { numberOfPreDilutionsMax } = useValidationParams();
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div
      className={styles.step}
      data-testid={`groups-${groupIndex}-reagents-${reagentIndex}-pre-dilution-step-${index}`}
    >
      <Grid
        container
        data-center
        columnGap={40}
        rowGap={16}
        columns={2}
        data-testid={`pre-dilution-step-${index}`}
      >
        <Grid item columnSpan={1}>
          <PreDilutionNameField
            groupIndex={groupIndex}
            reagentIndex={reagentIndex}
            stepIndex={index}
          />
        </Grid>
        <Grid
          item
          columnSpan={1}
          style={{
            alignSelf: "stretch",
            padding: "1px",
            overflow: "visible",
          }}
        >
          <div className={styles.inputAndButtons}>
            <OwcButton
              flat
              variant="secondary"
              onClick={() => {
                insert(index);
              }}
              disabled={fieldArray.fields.length >= numberOfPreDilutionsMax}
              data-testid={`groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${index}.add-step-btn`}
            >
              Add pre-dilution step
            </OwcButton>
            <OwcButton
              flat
              variant="secondary"
              className={button.colorError}
              onClick={() => {
                remove(index);
              }}
              data-testid={`groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${index}.remove-step-btn`}
            >
              Delete
            </OwcButton>
          </div>
        </Grid>
        <Grid item columnSpan={1}>
          <StockMaterialInfoColumn
            groupIndex={groupIndex}
            reagentIndex={reagentIndex}
            stepIndex={index}
          />
        </Grid>

        <Grid item columnSpan={1}>
          <OwcIconButton
            data-testid={`groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${index}.info-edit-btn`}
            flat
            disableRipple
            onClick={(event) => setOpen(true)}
          >
            <OwcIcon name="edit" />
          </OwcIconButton>
          {preDilutionStep && (
            <PreDilutionStepChangeDiluentDialog
              open={open}
              onClose={() => setOpen(false)}
              onConfirm={handleDiluentConfirm}
              preDilutionStep={preDilutionStep}
              preDilutionIndex={reagentIndex}
            />
          )}
          <DiluionInfoColumn
            watchSource={`groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${index}.diluent`}
            testId={`groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${index}.diluent`}
          />
        </Grid>
        <Grid item columnSpan={1}>
          <StockMaterialConcentrationRow
            groupIndex={groupIndex}
            reagentIndex={reagentIndex}
            index={index}
          />
        </Grid>
        <Grid item columnSpan={1}>
          <PreDilutionStepDilutionFactor
            groupIndex={groupIndex}
            reagentIndex={reagentIndex}
            index={index}
          />
        </Grid>
        <Grid item columnSpan={1}>
          <PreDilutionTargetConcentrationField
            groupIndex={groupIndex}
            reagentIndex={reagentIndex}
            preDilutionStepIndex={index}
            preDilutionStepsCount={fieldArray.fields.length}
          />
        </Grid>
        <Grid item columnSpan={1}>
          <PreDilutionStepDeadVolume
            groupIndex={groupIndex}
            reagentIndex={reagentIndex}
            preDilutionStepIndex={index}
          />
        </Grid>
      </Grid>
      {(waringFactorMore || waringFactorLess) && (
        <div className={styles.warningInfo}>
          <WarningInfoSection
            details={getDetailsInfoArray(waringFactorMore, waringFactorLess)}
          />
        </div>
      )}
    </div>
  );
};

export default PreDilutionStepContent;
