import { Material } from "../../../models/coform-models/Material";

const MIN_NUMBERS_PRE_DILUTION_NAME = 2;
type MaterialKey = keyof Material;
export const getPreDilutionStepName = (
  stepIndex: number,
  material: Material,
  keyName: MaterialKey = "shortName"
) => {
  const stepNumbers = `${stepIndex + 1}`.padStart(
    MIN_NUMBERS_PRE_DILUTION_NAME,
    "0"
  );
  return `Pre-dilution ${stepNumbers} ${
    material?.[keyName] ? `(${material?.[keyName]})` : ""
  }`;
};
