import { HighLevelMaterialDilution } from "../serial-and-co-formulated-dilution/forms/HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";

export const fillHighLevelDataFromStep2 = (
  currentDilution: HighLevelMaterialDilution
) => {
  return {
    ...currentDilution,
    dilutionDetails: currentDilution.dilutionDetails
      ? {
          ...currentDilution.dilutionDetails,
          inputMaterials: currentDilution.dilutionDetails.inputMaterials.map(
            (material) => ({
              ...material,
              highLevelInfo: {
                numberOfPreDilutions:
                  currentDilution.dilutionDetails?.preDilutions?.find(
                    (preDilution) =>
                      preDilution.inputMaterial.id === material.id
                  )?.dilutionSteps?.length ?? 0,
              },
            })
          ),
        }
      : null,
  };
};
