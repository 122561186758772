import { yupResolver } from "@hookform/resolvers/yup";
import { OwcButton, OwcIcon, OwcTypography } from "@one/react";
import clsx from "clsx";
import { Mutation } from "react-apollo";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import UserNotAuthorWarning from "../../../components/shared/not-author-warning/UserNotAuthorWarning";
import { MastermixDilution } from "../../../models/Dilution";
import { DilutionInput } from "../../../models/DilutionInput";
import button from "../../../scss//button/Button.module.scss";
import { useRunGraphQlWithSnackbars } from "../../../utils/hooks/useRunGraphQlWithSnackbars";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { useHandleCalculateAndSaveMutationResult } from "../../common-dilution/serial-and-co-formulated-dilution/forms/PanelMemberForm/useHandleCalculateAndSaveMutationResult";
import { calculateAndSaveGql } from "../../mutations/calculateAndSaveGql";
import ManageMastermixFormContent from "./ManageMastermixFormContent";
import styles from "./ManageMastermixFormContent.module.scss";
import MastermixButtons from "./MastermixButtons";
import { mastermixSchema } from "./mastermixSchema/mastermixSchema";
import { getEmptyReagentGroup } from "./utils/getEmptyReagentGroup";
import { mastermixDilutionToDilutionInput } from "./utils/mastermixDilutionToDilutionInput";
import { useValidationParams } from "../../common-dilution/serial-and-co-formulated-dilution/forms/ValidationParamsContext/useValidationParams";

interface ManageMastermixFormProps {
  mastermixDilution: MastermixDilution;
}

const ManageMastermixForm = ({
  mastermixDilution,
}: ManageMastermixFormProps) => {
  const { userId } = useUserInfo() ?? {};
  const validation = useValidationParams();
  const methods = useForm<MastermixDilution>({
    defaultValues: mastermixDilution,
    resolver: yupResolver(mastermixSchema),
    context: {
      get getValues() {
        return methods.getValues;
      },
      validation,
    },
  });
  const fieldArray = useFieldArray<MastermixDilution, "dilutionDetails.groups">(
    {
      name: "dilutionDetails.groups",
      control: methods.control,
    }
  );

  const addNewGroup = () => {
    const customLength = fieldArray.fields.filter(
      (field) => field.groupType === "CUSTOM"
    ).length;
    fieldArray.append(
      getEmptyReagentGroup(`New group ${customLength + 1}`, "CUSTOM", 1)
    );
  };
  const handleCalculateAndSaveMutationResult =
    useHandleCalculateAndSaveMutationResult("/mastermix-details");
  const runGraphQlWithSnackbars = useRunGraphQlWithSnackbars({
    errorMessage: "Saving and calculating dilution went wrong!",
    successMessage: "Successfully saved and calculated dilution!",
  });

  return (
    <Mutation<
      { calculateAndSave: { id: string } | null },
      { dilution: DilutionInput }
    >
      mutation={calculateAndSaveGql}
    >
      {(calculateAndSave, { loading }) => {
        return (
          <FormProvider {...methods}>
            <form
              id="mastermix-form"
              noValidate
              onSubmit={methods.handleSubmit(async (values) => {
                await runGraphQlWithSnackbars(async () => {
                  const dilutionInput =
                    mastermixDilutionToDilutionInput(values);
                  const result = await calculateAndSave({
                    variables: { dilution: dilutionInput },
                  });
                  handleCalculateAndSaveMutationResult(result);
                });
              })}
            >
              <div className={styles.mainWrapper}>
                <div
                  data-testid="mastermix-title-up"
                  className={styles.infoWithBtnSection}
                >
                  <div className={styles.title}>
                    {mastermixDilution.id ? (
                      <>
                        <OwcTypography variant="title5">
                          {mastermixDilution.name}
                        </OwcTypography>
                        {mastermixDilution.elnId && (
                          <OwcTypography
                            className={styles.subtitle}
                            variant="subtitle2"
                          >
                            ({mastermixDilution.elnId})
                          </OwcTypography>
                        )}
                      </>
                    ) : (
                      <OwcTypography variant="title5">
                        Create Mastermix
                      </OwcTypography>
                    )}
                  </div>

                  <MastermixButtons loading={loading} />
                </div>
                {userId &&
                  mastermixDilution.id &&
                  mastermixDilution.createdBy !== userId && (
                    <UserNotAuthorWarning testId="dilution-panel-members-user-warning" />
                  )}
                <ManageMastermixFormContent fieldArray={fieldArray} />
                <div
                  data-testid="mastermix-title-down"
                  className={styles.infoWithBtnSection}
                >
                  <OwcButton
                    onClick={() => addNewGroup()}
                    className={clsx(button.large, button.secondaryBorder)}
                    flat
                    variant="secondary"
                    data-testid="mastermix-add-new-reagent-in-group-btn"
                  >
                    <div className={button.iconWrapper}>
                      <OwcIcon name="sign_plus" color="inherit" />
                      Add new group
                    </div>
                  </OwcButton>

                  <MastermixButtons loading={loading} />
                </div>
              </div>
            </form>
          </FormProvider>
        );
      }}
    </Mutation>
  );
};

export default ManageMastermixForm;
