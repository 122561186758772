import gql from "graphql-tag";
import { useEffect, useState } from "react";
import { Query, QueryResult } from "react-apollo";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Container from "../components/Container/Container";
import FullBlockProgress from "../components/FullBlockProgress/FullBlockProgress";
import NotFoundPic from "../components/pic/NotFoundPic";
import QueryWrapper from "../components/query-context/QueryWrapper";
import QueryError from "../components/QueryError";
import Hero from "../components/shared/Hero/Hero";
import { Dilution } from "../models/Dilution";

interface DilutionPageTemplateProps<T extends Dilution> {
  component: (props: { dilution: T }) => JSX.Element;
  titleForError: string;
  query: ReturnType<typeof gql>;
}

type QueryResultPickKeysPart1 = "loading" | "error";
type QueryResultPickKeysPart2 = "refetch" | "data";

type QueryResultPickKeys = QueryResultPickKeysPart1 | QueryResultPickKeysPart2;

const DilutionPageTemplateContent = <T extends Dilution>({
  error,
  loading,
  refetch,
  data,
  titleForError,
  component: Component,
}: Pick<
  QueryResult<{ getDilution: T | null }, { id: string }>,
  QueryResultPickKeys
> &
  Omit<DilutionPageTemplateProps<T>, "query">) => {
  const navigate = useNavigate();
  const [redirectOnEmptyRefetch, setRedirectOnEmptyRefetch] = useState(false);

  useEffect(() => {
    if (!loading && !error && data && data.getDilution) {
      setRedirectOnEmptyRefetch(true);
    }
  }, [data, error, loading]);

  if (error && !loading) {
    return (
      <Container>
        <QueryError error={error} refetch={refetch} />
      </Container>
    );
  }
  if (loading || !data) {
    return (
      <Container>
        <FullBlockProgress />
      </Container>
    );
  }

  if (!data.getDilution) {
    if (redirectOnEmptyRefetch) {
      return <Navigate to={"/"} />;
    }
    return (
      <Container>
        <Hero
          testId="no-dilution-found"
          actionName="Go to home page"
          pic={<NotFoundPic />}
          title={titleForError}
          subtitle={`went wrong`}
          action={() => navigate("/")}
        />
      </Container>
    );
  }

  return (
    <Container>
      <QueryWrapper {...{ refetch }}>
        <Component dilution={data.getDilution} />
      </QueryWrapper>
    </Container>
  );
};

const DilutionPageTemplate = <T extends Dilution>({
  component,
  titleForError,
  query,
}: DilutionPageTemplateProps<T>) => {
  const { id } = useParams();

  if (!id) {
    return <Navigate to={"/"} />;
  }
  return (
    <Query<{ getDilution: T | null }, { id: string }>
      query={query}
      variables={{
        id,
      }}
    >
      {({ loading, error, refetch, data }) => {
        return (
          <DilutionPageTemplateContent
            {...{ error, loading, refetch, data, titleForError, component }}
          />
        );
      }}
    </Query>
  );
};

export default DilutionPageTemplate;
