import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import OwcInputSuffix from "../../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { getNumberFieldInputProps } from "../../../../../../utils/helpers/getNumberFieldInputProps";
import { useValidationParams } from "../../ValidationParamsContext/useValidationParams";
import { HighLevelMaterialDilution } from "../models/HighLevelMaterialDilution";

const VolumeOfReplicateField = () => {
  const { volumeOfReplicateMin, volumeOfReplicateMax } = useValidationParams();
  return (
    <Controller<HighLevelMaterialDilution, "volumeOfReplicate">
      name="volumeOfReplicate"
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid="dilution-volumeOfReplicate-input"
          label="*Volume of Replicate"
          required
          type="number"
          min={volumeOfReplicateMin}
          max={volumeOfReplicateMax}
          {...getNumberFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid="dilution-volumeOfReplicate-error"
          >
            {error?.message}
          </span>
          <OwcInputSuffix>mL</OwcInputSuffix>
        </OwcInput>
      )}
    />
  );
};

export default VolumeOfReplicateField;
