import { useFormContext } from "react-hook-form";
import { HighLevelMaterialDilution } from "../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";

import { LabelDetailColumn } from "./LabelDetailColumn";

const DiluionInfoColumn = ({
  testId,
  watchSource,
}: {
  testId: string;
  watchSource:
    | `preDilutions.${number}.dilutionSteps.${number}.diluent`
    | `panelMembers.${number}.diluent`;
}) => {
  const { watch } = useFormContext<HighLevelMaterialDilution>();
  const diluentName = watch(`dilutionDetails.${watchSource}.stockName`);
  const diluentMaterialNo = watch(`dilutionDetails.${watchSource}.number`);
  const diluentLotNo = watch(`dilutionDetails.${watchSource}.lotNumber`);
  return (
    <LabelDetailColumn
      testId={testId}
      detail={`Material no. ${diluentMaterialNo ? diluentMaterialNo : ""}`}
      subdetail={`Lot no. ${diluentLotNo ? diluentLotNo : ""}`}
      label="Diluent"
    >
      {diluentName}
    </LabelDetailColumn>
  );
};

export default DiluionInfoColumn;
