import { useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../models/Dilution";
import { LabelDetailColumn } from "../../../common-dilution/serial-and-co-formulated-dilution/forms/PanelMemberForm/common/LabelDetailColumn";

const DiluionInfoColumn = ({
  testId,
  watchSource,
}: {
  testId: string;
  watchSource:
    | `diluent`
    | `groups.${number}.reagents.${number}.preDilution.dilutionSteps.${number}.diluent`;
}) => {
  const { watch } = useFormContext<MastermixDilution>();
  const diluentName = watch(`dilutionDetails.${watchSource}.stockName`);
  const diluentMaterialNo = watch(`dilutionDetails.${watchSource}.number`);
  const diluentLotNo = watch(`dilutionDetails.${watchSource}.lotNumber`);
  return (
    <LabelDetailColumn
      testId={testId}
      detail={`Material no. ${diluentMaterialNo ? diluentMaterialNo : ""}`}
      subdetail={`Lot no. ${diluentLotNo ? diluentLotNo : ""}`}
      label="Diluent"
    >
      {diluentName}
    </LabelDetailColumn>
  );
};

export default DiluionInfoColumn;
