import { PreDilution } from "../../../../../../models/coform-models/PreDilution";
import { PreDilutionStep } from "../../../../../../models/coform-models/PreDilutionStep";
import { Calculated } from "../../../../../../models/Dilution";
import { toFixed } from "../../../../../../utils/helpers/toFixed";
import { CONCENTRATION_LIMIT_COMMA } from "../../../../constants";

export const getPreDilutionInputConcentration = (
  currentDilutionStepIndex: number,
  preDilution: PreDilution,
  dilutionSteps: PreDilutionStep[]
): Calculated => {
  if (currentDilutionStepIndex === 0) {
    return {
      calculatedValue: toFixed(
        Number(preDilution.inputMaterial.stockConcentration) *
          Number(preDilution.inputMaterial.unitFactor),
        {
          exponential:
            preDilution.inputMaterial
              .stockConcentration!.toLowerCase()
              .includes("e") ||
            preDilution.inputMaterial.unitFactor!.toLowerCase().includes("e"),
          roundTo: null,
        }
      ),
      displayedValue: toFixed(
        Number(preDilution.inputMaterial.stockConcentration) *
          Number(preDilution.inputMaterial.unitFactor),
        {
          exponential:
            preDilution.inputMaterial
              .stockConcentration!.toLowerCase()
              .includes("e") ||
            preDilution.inputMaterial.unitFactor!.toLowerCase().includes("e"),
          roundTo: CONCENTRATION_LIMIT_COMMA,
        }
      ),
    };
  } else {
    return dilutionSteps[currentDilutionStepIndex - 1].targetConcentration!;
  }
};
