import { Content, ContentColumns } from "pdfmake/interfaces";

const header = (title: string): Content => {
  const imageSize = 40;
  const imageBoundaries = [imageSize, imageSize];

  return {
    columns: [
      {
        width: "*",
        text: title,
        style: "title",
      },
      {
        width: "auto",
        image: "logo",
        fit: imageBoundaries,
      },
    ],
    style: "header",
  } as ContentColumns;
};

export default header;
