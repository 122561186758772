import { useEffect, useState } from "react";
import { Dilution } from "../../../../models/Dilution";
import { StepType } from "../../../co-formulated-dilution/CoFormulatedDilutionWorkflow";
import { fillHighLevelDataFromStep2 } from "../../utils/fillHighLevelDataFromStep2";
import { getHighLevelMaterialDilution } from "../../../../utils/helpers/getHighLevelMaterialDilution";
import { highLevelInfoSchema } from "../../../schema/highLevelInfoSchema";
import { validationParams } from "../forms/ValidationParamsContext/ValidationParamsWrapper";
import { useLocation } from "react-router-dom";

export const useValidateStepForDraftDilution = ({
  dilution,
}: {
  dilution: Dilution | null;
}): { validated: boolean; stepType: StepType } => {
  const [currentStepState, setCurrentStep] = useState<{
    validated: boolean;
    stepType: StepType;
  }>({
    validated: false,
    stepType: "PanelMembers",
  });
  useEffect(() => {
    setCurrentStep((prev) => ({ ...prev, validated: false }));
    if (!dilution || dilution.type === "MASTERMIX") {
      setCurrentStep({ stepType: "PanelMembers", validated: true });
      return;
    }
    const highLevelData = fillHighLevelDataFromStep2(
      getHighLevelMaterialDilution(dilution)
    );
    const validate = async () => {
      const isValid = await highLevelInfoSchema.isValid(highLevelData, {
        context: { validation: validationParams },
      });
      setCurrentStep({
        stepType: isValid ? "PanelMembers" : "HighLevelMaterialInfo",
        validated: true,
      });
    };
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dilution?.id]);
  return { ...currentStepState };
};

export const useStepForDraftDilution = () => {
  const { state } = useLocation();
  return (state?.stepType as StepType) ?? "PanelMembers";
};
