import { OwcButton, OwcIcon } from "@one/react";
import clsx from "clsx";
import ButtonLoading from "../../components/button-loading/ButtonLoading";
import button from "../../scss/button/Button.module.scss";
import { DilutionShortInfo } from "../../views/main-view/MainView";
import { CardListView } from "./CardListView";
import { useDilutionListMode } from "./context/dilution-list-mode/useDilutionListMode";
import styles from "./DilutionsList.module.scss";
import TableListView from "./TableListView";

interface DilutionsListProps {
  dilutionsShortInfo: DilutionShortInfo[];
  loadMore: () => void;
  hasMore: boolean;
  loadingMore: boolean;
}

const DilutionsList = ({
  dilutionsShortInfo,
  loadMore,
  hasMore,
  loadingMore,
}: DilutionsListProps) => {
  const { mode } = useDilutionListMode();
  return (
    <div data-testid="dilution-list-main-container" className={styles.main}>
      {mode === "card" && (
        <CardListView dilutionsShortInfo={dilutionsShortInfo} />
      )}
      {mode === "list" && (
        <TableListView dilutionsShortInfo={dilutionsShortInfo} />
      )}
      {hasMore && (
        <OwcButton
          data-testid="dilution-list-load-more-btn"
          disabled={loadingMore}
          variant="secondary"
          flat
          className={clsx(button.colorPrimary, button.text, styles.loadMore)}
          onClick={loadMore}
        >
          {loadingMore && (
            <ButtonLoading testId="dilution-list-load-more-btn-loading" />
          )}
          <div className={button.wrapper}>
            Load more records
            <OwcIcon name={"chevron_down"} />
          </div>
        </OwcButton>
      )}
    </div>
  );
};

export default DilutionsList;
