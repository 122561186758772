import { useEffect } from "react";

export const usePreventScrollInNumberInput = () => {
  useEffect(() => {
    const listener = (event: WheelEvent) => {
      if ((document.activeElement as HTMLInputElement)?.type === "number") {
        event.preventDefault();
      }
    };
    window.addEventListener("wheel", listener, { passive: false });

    return () => window.removeEventListener("wheel", listener);
  }, []);
};
