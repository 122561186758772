import { useFormContext } from "react-hook-form";
import { LabelDetailColumn } from "../../../../common-dilution/serial-and-co-formulated-dilution/forms/PanelMemberForm/common/LabelDetailColumn";
import { MastermixDilution } from "../../../../../models/Dilution";

const StockMaterialInfoColumn = ({
  groupIndex,
  reagentIndex,
  stepIndex,
}: {
  groupIndex: number;
  reagentIndex: number;
  stepIndex: number;
}) => {
  const { watch } = useFormContext<MastermixDilution>();
  const stockMaterialName = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.inputMaterial.stockName`
  );
  const stockMaterialMaterialNo = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.inputMaterial.number`
  );
  const stockMaterialLotNo = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.inputMaterial.lotNumber`
  );
  return (
    <LabelDetailColumn
      label="Stock material"
      detail={`Material no. ${stockMaterialMaterialNo}`}
      subdetail={`Lot no. ${stockMaterialLotNo}`}
      testId={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${stepIndex}.stock-material`}
    >
      {stockMaterialName}
    </LabelDetailColumn>
  );
};

export default StockMaterialInfoColumn;
