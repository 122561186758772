import { OwcTypography } from "@one/react";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import Grid from "../../../../../../components/Grid/Grid";
import { getDetailsInfoArray } from "../../../../waring-section/getDetailsInfoArray";
import WarningInfoSection from "../../../../waring-section/WarningInfoSection";
import { HighLevelMaterialDilution } from "../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import DiluionInfoColumn from "../common/DiluionInfoColumn";
import { LabelDetailRow } from "../common/LabelDetailRow";
import NumberOfReplicatesField from "./fields/NumberOfReplicatesField";
import PanelMemberNameField from "./fields/PanelMemberNameField";
import styles from "./PanelMemberContent.module.scss";
import { TargetFormContent } from "./TargetFormContent";
import { usePanelMemberFactor } from "./usePanelMemberFactor";
import DiluentPanelVolumeField from "./fields/DiluentPanelVolumeField";
interface PanelMemberContentProps {
  panelMemberIndex: number;
}

const DynamicVolumeField = ({
  customLeftOver,
  panelMemberIndex,
}: {
  customLeftOver: string | null;
  panelMemberIndex: number;
}) =>
  customLeftOver ? (
    <DiluentPanelVolumeField panelMemberIndex={panelMemberIndex} />
  ) : (
    <NumberOfReplicatesField panelMemberIndex={panelMemberIndex} />
  );

const checkIsFirstPanelMemberCoFormulatedDilution = (
  panelMemberIndex: number,
  type: "SERIAL_DILUTION" | "CO_FORMULATED_DILUTION"
) => panelMemberIndex === 0 && type === "CO_FORMULATED_DILUTION";

const PanelMemberContent = ({ panelMemberIndex }: PanelMemberContentProps) => {
  const { waringFactorMore, waringFactorLess, factor, isNegative } =
    usePanelMemberFactor(panelMemberIndex);

  const { getValues, watch } = useFormContext<HighLevelMaterialDilution>();
  const inputMaterials = watch("dilutionDetails.inputMaterials") ?? [];
  const type = getValues("type");
  const isFirstPanelMemberCoFormulatedDilution =
    checkIsFirstPanelMemberCoFormulatedDilution(panelMemberIndex, type);
  const shouldDisplayWarning = waringFactorMore || waringFactorLess;
  const customLeftOver = watch(`dilutionDetails.customLeftOver`);
  return (
    <div className={styles.target}>
      <Grid container data-center columnGap={16} rowGap={16} columns={16}>
        <Grid item columnSpan={16}>
          <PanelMemberNameField panelMemberIndex={panelMemberIndex} />
        </Grid>
        {!isNegative && (
          <TargetFormContent panelMemberIndex={panelMemberIndex} />
        )}
        <Grid item columnSpan={7}>
          {!isFirstPanelMemberCoFormulatedDilution && (
            <DiluionInfoColumn
              watchSource={`panelMembers.${panelMemberIndex}.diluent`}
              testId={`panel-member-${panelMemberIndex}-diluent-info`}
            />
          )}
          {!isNegative && (
            <LabelDetailRow
              label={
                <div>
                  <OwcTypography variant="body1">Dilution Factor</OwcTypography>
                </div>
              }
              data-testid={`panel-member-${panelMemberIndex}-dilution-factor`}
            >
              <OwcTypography
                data-testid={`panel-member-${panelMemberIndex}-dilution-factor-value`}
                variant="title6"
                className={clsx({
                  [styles.warningAccent]: shouldDisplayWarning,
                })}
              >
                {isFirstPanelMemberCoFormulatedDilution
                  ? inputMaterials.length
                  : factor?.displayedValue ?? "unknown yet"}
              </OwcTypography>
            </LabelDetailRow>
          )}
        </Grid>
        <Grid item columnSpan={2}></Grid>
        <Grid item columnSpan={7}>
          <DynamicVolumeField
            customLeftOver={customLeftOver}
            panelMemberIndex={panelMemberIndex}
          />
        </Grid>
      </Grid>
      {!!shouldDisplayWarning && (
        <div className={styles.warningInfo}>
          <WarningInfoSection
            details={getDetailsInfoArray(waringFactorMore, waringFactorLess)}
          />
        </div>
      )}
    </div>
  );
};

export default PanelMemberContent;
