import { DilutionType, DilutionTypeValues } from "../../models/DilutionType";

export const getDilutionDetailsLink = (
  dilutionType: DilutionType,
  id: string
): string => {
  switch (dilutionType) {
    case DilutionTypeValues.SERIAL_DILUTION:
    case DilutionTypeValues.CO_FORMULATED_DILUTION:
      return `/dilution-details/${id}`;
    case DilutionTypeValues.MASTERMIX:
      return `/mastermix-details/${id}`;
    default:
      throw new Error(`Unknown type ${dilutionType}`);
  }
};

export const useDetailsLink = (dilutionType: DilutionType, id: string) => {
  return getDilutionDetailsLink(dilutionType, id);
};
