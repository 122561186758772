import { createContext } from "react";
import { SnackbarMessage } from "./models/SnackbarMessage";

export interface SnackbarContextType {
  addSnackbar: (snack: SnackbarMessage) => void;
}

export const SnackbarContext = createContext<SnackbarContextType>({
  addSnackbar: () => {},
});
