import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import OwcInputSuffix from "../../../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { CoFormDilution } from "../../../../../../../models/Dilution";
import { getNumberFieldInputProps } from "../../../../../../../utils/helpers/getNumberFieldInputProps";
import { useValidationParams } from "../../../ValidationParamsContext/useValidationParams";

interface PreDilutionStepDeadVolumeProps {
  preDilutionIndex: number;
  preDilutionStepIndex: number;
}

const PreDilutionStepDeadVolume = ({
  preDilutionIndex,
  preDilutionStepIndex,
}: PreDilutionStepDeadVolumeProps) => {
  const { preDilutionStepDeadVolumeMin, preDilutionStepDeadVolumeMax } =
    useValidationParams();
  return (
    <Controller<
      CoFormDilution,
      `dilutionDetails.preDilutions.${typeof preDilutionIndex}.dilutionSteps.${typeof preDilutionStepIndex}.deadVolume`
    >
      name={`dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${preDilutionStepIndex}.deadVolume`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`pre-dilution-step-dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${preDilutionStepIndex}.deadVolume-input`}
          label="*Dead volume"
          type="number"
          min={preDilutionStepDeadVolumeMin}
          max={preDilutionStepDeadVolumeMax}
          {...getNumberFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`pre-dilution-step-dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${preDilutionStepIndex}.deadVolume-error`}
          >
            {error?.message}
          </span>
          <OwcInputSuffix>mL</OwcInputSuffix>
        </OwcInput>
      )}
    />
  );
};

export default PreDilutionStepDeadVolume;
