import { useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../../models/Dilution";
import { useCallback } from "react";
import { addPreDilutionToReagent } from "../../utils/addPreDilutionToReagent";

export const useAddPredilutionToReagent = ({
  groupIndex,
  reagentIndex,
}: {
  groupIndex: number;
  reagentIndex: number;
}) => {
  const { watch, setValue } = useFormContext<MastermixDilution>();
  const reagent = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}`
  );
  const diluent = watch(`dilutionDetails.diluent`)!;
  const addPredilution = useCallback(() => {
    const predilution = addPreDilutionToReagent({ diluent, reagent });
    setValue(
      `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution`,
      predilution
    );
  }, [diluent, groupIndex, reagentIndex, reagent, setValue]);
  return {
    reagent,
    addPredilution,
  };
};
