import { MastermixDetails } from "../../../models/mastermix-models/MastermixDetails";
import { toFixed } from "../../../utils/helpers/toFixed";
import { thousand } from "../constants";

export function getTotalVolumeDetails(details: MastermixDetails) {
  const totalVolumeInMicroUnit = toFixed(
    Number(details.totalVolume?.displayedValue) * thousand,
    {
      exponential: details.totalVolume?.displayedValue
        .toLowerCase()
        .includes("e"),
      roundTo: 2,
    }
  );

  const totalVolumeWithoutMMx = toFixed(
    Number(totalVolumeInMicroUnit) -
      Number(details.diluent!.neededVolume?.displayedValue),
    {
      exponential:
        totalVolumeInMicroUnit.toLowerCase().includes("e") ||
        details
          .diluent!.neededVolume?.displayedValue.toLowerCase()
          .includes("e"),
      roundTo: 2,
    }
  );
  return { totalVolumeWithoutMMx, totalVolumeInMicroUnit };
}
