import { Diluent } from "../../../../models/Diluent";
import { Calculated } from "../../../../models/Dilution";
import { PreDilution } from "../../../../models/coform-models/PreDilution";
import { PreDilutionStep } from "../../../../models/coform-models/PreDilutionStep";
import { getPreDilutionInputConcentration } from "../../../common-dilution/serial-and-co-formulated-dilution/forms/HighLevelMaterialInfoForm/utils/getPreDilutionInputConcentration";
import { getPreDilutionStepName } from "../../../common-dilution/utils/getPreDilutionStepName";

const getTargetConcentration = (
  existingStep: PreDilutionStep | undefined
): Calculated => {
  return (
    existingStep?.targetConcentration ?? {
      calculatedValue: "",
      displayedValue: "",
    }
  );
};

export const getNewDilutionStep = ({
  preDilution,
  diluent,
}: {
  preDilution: PreDilution;
  diluent: Diluent;
}) => {
  const stepName = getPreDilutionStepName(
    0,
    preDilution.inputMaterial,
    "stockName"
  );
  const targetConcentration = getTargetConcentration(undefined);

  const newDilutionStep = {
    deadVolume: "",
    diluentVolume: null,
    dilutionFactor: {
      calculatedValue: "",
      displayedValue: "",
    },
    inputVolume: null,
    leftOver: null,
    inputName: "",
    stepNumber: 1,
    totalVolume: null,
    inputConcentration: getPreDilutionInputConcentration(0, preDilution, []),
    stepName,
    targetConcentration,
    diluent,
  };
  return newDilutionStep;
};
