import { OwcTypography } from "@one/react";
import clsx from "clsx";
import { toFixed } from "../../../../../../../utils/helpers/toFixed";

import { LabelDetailRow } from "../../common/LabelDetailRow";
import styles from "../PreDilutionStepContent.module.scss";
import { usePreDilutionStepFactor } from "./usePreDilutionStepFactor";
const PreDilutionStepDilutionFactor = ({
  preDilutionIndex,
  index,
}: {
  index: number;
  preDilutionIndex: number;
}) => {
  const { waringFactorMore, waringFactorLess, factor } =
    usePreDilutionStepFactor(preDilutionIndex, index);
  return (
    <LabelDetailRow
      label={
        <div>
          <OwcTypography variant="body1">Dilution Factor</OwcTypography>
        </div>
      }
      data-testid={`pre-dilutions-${preDilutionIndex}-step-${index}-dilution-factor`}
    >
      {
        <OwcTypography
          data-testid={`pre-dilutions-${preDilutionIndex}-step-${index}-dilution-factor-value`}
          variant="title6"
          className={clsx({
            [styles.warningAccent]: waringFactorMore || waringFactorLess,
          })}
        >
          {toFixed(factor) ?? "unknown yet"}
        </OwcTypography>
      }
    </LabelDetailRow>
  );
};
export default PreDilutionStepDilutionFactor;
