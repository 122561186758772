import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { MastermixDilution } from "../../../../models/Dilution";
import { getStringFieldInputProps } from "../../../../utils/helpers/getStringFieldInputProps";

const DiluentStockName = () => {
  return (
    <Controller<MastermixDilution, `dilutionDetails.diluent.stockName`>
      name={`dilutionDetails.diluent.stockName`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`dilutionDetails.diluent.stockName-input`}
          label="*Diluent name"
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.diluent.stockName-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default DiluentStockName;
