import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import OwcInputSuffix from "../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { getNumberFieldInputProps } from "../../../../../utils/helpers/getNumberFieldInputProps";
import { useValidationParams } from "../../../../common-dilution/serial-and-co-formulated-dilution/forms/ValidationParamsContext/useValidationParams";
import { MastermixDilution } from "../../../../../models/Dilution";

interface PreDilutionStepDeadVolumeProps {
  groupIndex: number;
  reagentIndex: number;
  preDilutionStepIndex: number;
}

const PreDilutionStepDeadVolume = ({
  groupIndex,
  reagentIndex,
  preDilutionStepIndex,
}: PreDilutionStepDeadVolumeProps) => {
  const { preDilutionStepDeadVolumeMin, preDilutionStepDeadVolumeMax } =
    useValidationParams();
  return (
    <Controller<
      MastermixDilution,
      `dilutionDetails.groups.${typeof groupIndex}.reagents.${typeof reagentIndex}.preDilution.dilutionSteps.${typeof preDilutionStepIndex}.deadVolume`
    >
      name={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${preDilutionStepIndex}.deadVolume`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${preDilutionStepIndex}.deadVolume-input`}
          label="*Dead volume"
          type="number"
          min={preDilutionStepDeadVolumeMin}
          max={preDilutionStepDeadVolumeMax}
          {...getNumberFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${preDilutionStepIndex}.deadVolume-error`}
          >
            {error?.message}
          </span>
          <OwcInputSuffix>μL</OwcInputSuffix>
        </OwcInput>
      )}
    />
  );
};

export default PreDilutionStepDeadVolume;
