import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { DilutionSampleLabelsForm } from "./DilutionSampleLabelsForm";
import { getStringFieldInputProps } from "../../utils/helpers/getStringFieldInputProps";
import { labelLengthLimit } from "./validationSchema";

const DilutionSampleLabelField = ({
  index,
  panelName,
  numberOfReplicates,
}: {
  index: number;
  panelName: string;
  numberOfReplicates: number;
}) => {
  return (
    <Controller<DilutionSampleLabelsForm, `labels.${typeof index}.labelName`>
      name={`labels.${index}.labelName`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          maxLength={labelLengthLimit - (`${numberOfReplicates}`.length + 1)}
          data-testid={`labels-${index}-labelName-input`}
          required
          label={`*Sample label for ${panelName}`}
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`labels-${index}-labelName-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default DilutionSampleLabelField;
