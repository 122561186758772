import { OwcIcon, OwcTypography } from "@one/react";
import clsx from "clsx";
import styles from "./WarningInfoSection.module.scss";
interface WarningInfoSectionProps {
  details: { label: string; detailsInfo: string }[];
}

const WarningInfoSection = ({ details }: WarningInfoSectionProps) => {
  return (
    <div className={clsx(styles.waringInfo)}>
      <div className={styles.waringInfoTitle}>
        <OwcIcon type="filled" variant="warning" name="alarm" />
        <OwcTypography
          variant="subtitle1"
          data-testid="warning-info-section-title"
        >
          Warning
        </OwcTypography>
      </div>
      {details.map((detail, index) => {
        return (
          <div
            className={styles.waringInfoDetailsRow}
            key={`${index}-${detail.label}`}
          >
            <OwcTypography
              data-testid={`warning-info-section-${index}-label`}
              variant="subtitle2"
            >
              {detail.label}
            </OwcTypography>
            <OwcTypography
              data-testid={`warning-info-section-${index}-info`}
              variant="body2"
            >
              {detail.detailsInfo}
            </OwcTypography>
          </div>
        );
      })}
    </div>
  );
};

export default WarningInfoSection;
