import Container from "../components/Container/Container";
import CoFormulatedDilutionWorkflow from "../features/co-formulated-dilution/CoFormulatedDilutionWorkflow";

const CoFormulatedCreatePage = () => {
  return (
    <Container>
      <CoFormulatedDilutionWorkflow />
    </Container>
  );
};

export default CoFormulatedCreatePage;
