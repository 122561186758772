import { v4 } from "uuid";
import { MastermixMaterial } from "../../../../models/coform-models/Material";
import { Reagent } from "../../../../models/mastermix-models/Reagent";

export const getEmptyReagent = (
  initialMaterialValues: Partial<MastermixMaterial> = {}
): Reagent => {
  return {
    preDilution: null,
    comment: null,
    pcrConcentration: {
      calculatedValue: "",
      displayedValue: "",
    },
    r2Concentration: {
      calculatedValue: "",
      displayedValue: "",
    },

    pcrVolume: {
      calculatedValue: "",
      displayedValue: "",
    },
    material: {
      internalLotNumber: "",
      targetUnit: "μM",
      unitFactor: "1",
      availableVolume: null,
      shortName: null,
      stockName: "",
      number: "",
      lotNumber: "",
      stockConcentration: "",
      stockUnit: "μM",
      id: v4(),
      neededVolume: null,
      ...initialMaterialValues,
    },
  };
};
