import * as yup from "yup";
import {
  panelMemberFormSchemaLeftOver,
  panelMemberFormSchemaReplicates,
} from "./panelMemberSchema";
import { preDilutionSchema } from "./preDilutionSchema";
import { checkIfEmpty } from "../../../../../schema/highLevelInfoSchema";

export const panelMemberFormSchema = yup.object({
  dilutionDetails: yup.object({
    panelMembers: yup.mixed().when("customLeftOver", {
      is: checkIfEmpty,
      then: yup.array(panelMemberFormSchemaReplicates),
      otherwise: yup.array(panelMemberFormSchemaLeftOver),
    }),
    preDilutions: yup.array(preDilutionSchema).nullable(),
  }),
});
