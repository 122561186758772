import { useFormContext } from "react-hook-form";
import { HighLevelMaterialDilution } from "../../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";

import { LabelDetailColumn } from "../../common/LabelDetailColumn";

const StockMaterialInfoColumn = ({
  preDilutionIndex,
  stepIndex,
}: {
  preDilutionIndex: number;
  stepIndex: number;
}) => {
  const { watch } = useFormContext<HighLevelMaterialDilution>();
  const stockMaterialName = watch(
    `dilutionDetails.preDilutions.${preDilutionIndex}.inputMaterial.stockName`
  );
  const stockMaterialMaterialNo = watch(
    `dilutionDetails.preDilutions.${preDilutionIndex}.inputMaterial.number`
  );
  const stockMaterialLotNo = watch(
    `dilutionDetails.preDilutions.${preDilutionIndex}.inputMaterial.lotNumber`
  );
  return (
    <LabelDetailColumn
      label="Stock material"
      detail={`Material no. ${stockMaterialMaterialNo}`}
      subdetail={`Lot no. ${stockMaterialLotNo}`}
      testId={`pre-dilutions-${preDilutionIndex}-step-${stepIndex}-stock-material`}
    >
      {stockMaterialName}
    </LabelDetailColumn>
  );
};

export default StockMaterialInfoColumn;
