import { OwcInput } from "@one/react";
import { Controller, useFormContext } from "react-hook-form";
import OwcInputSuffix from "../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { MastermixDilution } from "../../../../../models/Dilution";
import { getCalculatedOnChangeFieldInput } from "../../../../../utils/helpers/getCalculatedOnChangeFieldInput";
import { getCalculatedValueFieldInput } from "../../../../../utils/helpers/getCalculatedValueFieldInput";
import { getNumberFieldInputProps } from "../../../../../utils/helpers/getNumberFieldInputProps";
import {
  r2ConcentrationMax,
  r2ConcentrationMin,
} from "../../mastermixSchema/maxtermixSchemaMinMaxValue";
import { useUpdateReagentPcrConcentration } from "../hooks/useUpdateReagentPcrConcentration";

interface ReagentR2ConcentrationProps {
  groupIndex: number;
  reagentIndex: number;
}

const ReagentR2Concentration = ({
  groupIndex,
  reagentIndex,
}: ReagentR2ConcentrationProps) => {
  const { getValues, watch } = useFormContext<MastermixDilution>();
  const targetUnit = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.material.targetUnit`
  );
  const updateReagentPcrConcentration = useUpdateReagentPcrConcentration();
  return (
    <Controller<
      MastermixDilution,
      `dilutionDetails.groups.${typeof groupIndex}.reagents.${typeof reagentIndex}.r2Concentration`
    >
      name={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.r2Concentration`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.r2Concentration-input`}
          label="*Conc. in R2"
          {...getNumberFieldInputProps(field)}
          type="number"
          min={r2ConcentrationMin}
          max={r2ConcentrationMax}
          error={Boolean(error)}
          value={getCalculatedValueFieldInput(field.value)}
          onInputChange={(event) => {
            const theValue = getValues(
              `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.r2Concentration.displayedValue`
            );
            /* istanbul ignore next */
            if (theValue === event.detail) {
              // It's already set to that value; skip on change
              return;
            }
            getCalculatedOnChangeFieldInput(field.onChange)(event);
            updateReagentPcrConcentration(groupIndex, reagentIndex);
          }}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.r2Concentration-error`}
          >
            {error?.message}
          </span>
          <OwcInputSuffix
            data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.r2Concentration-suffix`}
          >
            {targetUnit}
          </OwcInputSuffix>
        </OwcInput>
      )}
    />
  );
};

export default ReagentR2Concentration;
