import { createContext } from "react";

export interface AppVersionsContextType {
  frontendVersion: {
    version?: string | null;
    pipelineId?: string | null;
    commitHash?: string | null;
  };
  backendVersion: {
    version?: string | null;
    pipelineId?: string | null;
    commitHash?: string | null;
  };
}

export const AppVersionsContext = createContext<AppVersionsContextType | null>(
  null
);
