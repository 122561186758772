import { useCallback, useState } from "react";
import { useApolloClient } from "../../components/apollo-client-context/ApolloClientWrapper";
import { useConfirm } from "../../components/confirm-context/useConfirm";
import { useUserInfo } from "../../utils/hooks/useUserInfo";

import UserNotAuthorWarning from "../../components/shared/not-author-warning/UserNotAuthorWarning";
import { useRunGraphQlWithSnackbars } from "../../utils/hooks/useRunGraphQlWithSnackbars";
import { deleteDilutionMutation } from "./deleteDilutionMutation.Gql";

export const useDeleteDilution = ({
  id,
  name,
  elnId,
  createdBy,
}: {
  id: string;
  name: string;
  elnId: string | null;
  createdBy?: string | null;
}) => {
  const confirm = useConfirm();
  const user = useUserInfo();
  const runGraphQlWithSnackbars = useRunGraphQlWithSnackbars({
    errorMessage: "Deleting dilution went wrong!",
    successMessage: "Successfully deleted dilution!",
  });

  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  return {
    loading,
    deleteDilution: useCallback(async () => {
      if (
        await confirm({
          description: (
            <>
              Do you really want to delete dilution: {name}
              {elnId ? ` (${elnId})` : ""}?
              {user?.userId !== createdBy && (
                <UserNotAuthorWarning testId="delete-dilution-warning" />
              )}
            </>
          ),
        })
      ) {
        setLoading(true);
        return runGraphQlWithSnackbars(
          async () => {
            const result = await client.mutate<{
              delete: {
                id: string;
              } | null;
            }>({
              mutation: deleteDilutionMutation,
              variables: { id },
            });
            if (result.errors || !result.data || !result.data.delete) {
              const error = new Error("Failed to do request");

              (error as unknown as Record<string, unknown>)["graphQLErrors"] =
                result.errors;

              throw error;
            }

            return true;
          },
          () => false,
          () => setLoading(false)
        );
      }

      return false;
    }, [
      confirm,
      id,
      name,
      elnId,
      client,
      user,
      createdBy,
      runGraphQlWithSnackbars,
    ]),
  };
};
