import { DiluentInput } from "../../../../models/DiluentInput";
import { ReagentGroupInput } from "../../../../models/mastermix-models/ReagentGroupInput";

export const getDiluentsArray = (
  diluent: DiluentInput,
  groups: ReagentGroupInput[] | null
): DiluentInput[] | null => {
  const diluents =
    groups
      ?.map(
        (group) =>
          group.reagents
            ?.map(
              (reagent) =>
                reagent?.preDilution?.dilutionSteps?.map(
                  (dilutionStep) => dilutionStep.diluent
                ) ?? []
            )
            .flatMap((x) => x) ?? []
      )
      .flatMap((x) => x)
      ?.map((x) => ({ ...x, id: `${x.number}-${x.lotNumber}` }))
      .filter((d) => d && d?.id !== diluent.id) ?? [];
  return Array.from(new Map(diluents.map((d) => [d.id, { ...d }])).values());
};
