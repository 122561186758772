import { OwcTypography } from "@one/react";
import clsx from "clsx";
import styles from "../PreDilutionStepContent.module.scss";
import { usePreDilutionStepFactor } from "./usePreDilutionStepFactor";
import { LabelDetailRow } from "../../../../common-dilution/serial-and-co-formulated-dilution/forms/PanelMemberForm/common/LabelDetailRow";
import { toFixed } from "../../../../../utils/helpers/toFixed";

const PreDilutionStepDilutionFactor = ({
  groupIndex,
  reagentIndex,
  index,
}: {
  groupIndex: number;
  reagentIndex: number;
  index: number;
}) => {
  const { waringFactorMore, waringFactorLess, factor } =
    usePreDilutionStepFactor({
      preDilutionStepIndex: index,
      groupIndex,
      reagentIndex,
    });
  return (
    <LabelDetailRow
      label={
        <div>
          <OwcTypography variant="body1">Dilution Factor</OwcTypography>
        </div>
      }
      data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${index}.factor`}
    >
      {
        <OwcTypography
          data-testid={`pre-dilution-step-${index}-dilution-factor-value`}
          variant="title6"
          className={clsx({
            [styles.warningAccent]: waringFactorMore || waringFactorLess,
          })}
        >
          {toFixed(factor) ?? "unknown yet"}
        </OwcTypography>
      }
    </LabelDetailRow>
  );
};
export default PreDilutionStepDilutionFactor;
