import { ContentTable } from "pdfmake/interfaces";
import { Material } from "../../../../models/coform-models/Material";
import { toFixed } from "../../../../utils/helpers/toFixed";
import { breakableString } from "../../utils/breakable-string";
import { widths, layout } from "./common";

const inputMaterialsTables = (inputMaterial: Material, title: string) => {
  const inputMaterialTable = (material: Material) => {
    const {
      stockName,
      number,
      lotNumber,
      stockConcentration,
      stockUnit,
      targetUnit,
      unitFactor,
    } = material;
    const tableBody = [
      ["Stock Material Name:", breakableString(stockName || "—")],
      ["Stock Material Number:", breakableString(number || "—")],
      ["Stock Material Lot Number:", breakableString(lotNumber || "—")],
      [
        "Stock Material Concentration:",
        breakableString(`${stockConcentration} ${stockUnit}`),
      ],
    ];

    if (stockUnit !== targetUnit) {
      const materialConcentration = toFixed(
        Number(stockConcentration) * Number(unitFactor),
        {
          exponential:
            stockConcentration!.toLowerCase().includes("e") ||
            unitFactor!.toLowerCase().includes("e"),
        }
      );

      tableBody.push([
        "Stock Material Concentration:",
        `${materialConcentration} ${targetUnit}`,
      ]);
    }

    return {
      style: ["table"],
      table: {
        widths: ["*", "*"],
        body: tableBody,
      },
      layout: "noBorders",
    };
  };
  const standaloneInputMaterialTable = (material: Material) => ({
    unbreakable: true,
    style: ["table", "marginBottomLarge"],
    table: {
      widths: ["*", "*"],
      body: [[inputMaterialTable(material)]],
    },
  });

  const result: ContentTable[] = [
    {
      style: ["table", "marginBottomLarge"],
      table: {
        widths,
        body: [
          [
            {
              text: breakableString(`${title}`),
              border: [1, 1, 0, 1],
              style: ["bold"],
            },
            { text: "", border: [0, 1, 0, 1] },
            {
              text: "",
              border: [0, 1, 1, 1],
              style: ["alignedToCenter"],
            },
          ],
        ],
      },
      layout,
    },
  ];
  result.push(standaloneInputMaterialTable(inputMaterial));

  return result;
};

export default inputMaterialsTables;
