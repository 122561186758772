import { OwcTypography } from "@one/react";
import clsx from "clsx";
import Grid from "../../../../components/Grid/Grid";
import { PanelMember } from "../../../../models/coform-models/PanelMember";
import { DilutionType } from "../../../../models/DilutionType";
import { getDetailsInfoArray } from "../../waring-section/getDetailsInfoArray";
import WarningInfoSection from "../../waring-section/WarningInfoSection";
import styles from "./DilutionDetailsPagePanelMembersSection.module.scss";
import { getInputAndTargetDetails } from "./getInputAndTargetDetails";
import { PanelMemberInputsAndTargesInfoSection } from "./PanelMemberInputsAndTargesInfoSection";
interface DilutionDetailsPagePanelMemberSectionProps {
  panelMember: PanelMember;
  panelMemberIndex: number;
  type: DilutionType;
  customLeftOver?: boolean;
}

const DilutionDetailsPagePanelMemberSection = ({
  panelMember,
  panelMemberIndex,
  type,
  customLeftOver,
}: DilutionDetailsPagePanelMemberSectionProps) => {
  const inputAndTargetDetails = getInputAndTargetDetails(
    panelMember,
    panelMemberIndex,
    type
  );

  const hasWarningSection = inputAndTargetDetails.some(
    ({
      warningDiluentFactorMore,
      warningDiluentFactorLess,
      warningVolumeInput,
    }) =>
      warningDiluentFactorMore || warningVolumeInput || warningDiluentFactorLess
  );
  const hasWarningFactor = inputAndTargetDetails.some(
    ({ warningDiluentFactorMore }) => warningDiluentFactorMore
  );
  const hasWarningFactorLess = inputAndTargetDetails.some(
    ({ warningDiluentFactorLess }) => warningDiluentFactorLess
  );
  const hasWarningVolumeInput = inputAndTargetDetails.some(
    ({ warningVolumeInput, isDiluentInput }) =>
      warningVolumeInput && !isDiluentInput
  );
  const hasWarningDiluentInput = inputAndTargetDetails.some(
    ({ warningVolumeInput, isDiluentInput }) =>
      warningVolumeInput && isDiluentInput
  );
  return (
    <div
      className={clsx(styles.panel, {
        [styles.warning]: hasWarningSection,
      })}
    >
      <OwcTypography
        data-testid={`panel-member-section-${panelMemberIndex}-title`}
        className={styles.subTitle}
        variant="title6"
      >
        {panelMember.stepName}
      </OwcTypography>
      <div
        className={clsx(styles.grid, { [styles.gridBlue]: !hasWarningSection })}
      >
        <Grid columnGap={64} rowGap={4} columns={2} container>
          <Grid item columnSpan={1}>
            <OwcTypography
              data-testid={`panel-member-section-${panelMemberIndex}-inputs-title`}
              variant="caption"
            >
              Inputs
            </OwcTypography>
          </Grid>
          <Grid item columnSpan={1}>
            {!panelMember.isNegative && (
              <div className={styles.flexLine}>
                <OwcTypography
                  data-testid={`panel-member-section-${panelMemberIndex}-dilution-factor-title`}
                  variant="caption"
                >
                  Dilution factor
                </OwcTypography>
                <OwcTypography
                  data-testid={`panel-member-section-${panelMemberIndex}-Target-concentration-title`}
                  variant="caption"
                >
                  Target concentration
                </OwcTypography>
              </div>
            )}
          </Grid>
          {inputAndTargetDetails.map((inputAndTarget, index) => (
            <PanelMemberInputsAndTargesInfoSection
              key={`${index}_${inputAndTarget.input?.name}_${inputAndTarget.target?.material?.stockName}`}
              panelMemberIndex={panelMemberIndex}
              index={index}
              {...inputAndTarget}
            />
          ))}
        </Grid>
      </div>
      <div className={styles.grid}>
        <Grid columnGap={64} rowGap={4} columns={2} container>
          <Grid item columnSpan={1}>
            <div className={styles.flexLine}>
              <OwcTypography
                data-testid={`panel-member-section-${panelMemberIndex}-total-volume-title`}
                variant="body1"
              >
                Total volume
              </OwcTypography>
              <OwcTypography
                data-testid={`panel-member-section-${panelMemberIndex}-total-volume-value`}
                variant="title6"
              >
                {panelMember.totalVolume?.displayedValue} mL
              </OwcTypography>
            </div>
            <div className={styles.flexLine}>
              <OwcTypography
                data-testid={`panel-member-section-${panelMemberIndex}-left-over-title`}
                variant="body2"
              >
                Left over
              </OwcTypography>
              <OwcTypography
                data-testid={`panel-member-section-${panelMemberIndex}-left-over-value`}
                variant="subtitle2"
              >
                {panelMember.leftOver?.displayedValue} mL
              </OwcTypography>
            </div>
          </Grid>
          {!customLeftOver && (
            <Grid item columnSpan={1}>
              <div className={styles.flexLine}>
                <OwcTypography
                  data-testid={`panel-member-section-${panelMemberIndex}-number-of-replicates-title`}
                  variant="body1"
                >
                  Number of replicates
                </OwcTypography>
                <OwcTypography
                  data-testid={`panel-member-section-${panelMemberIndex}-diluent-factor-value`}
                  variant="title6"
                >
                  {panelMember.numberOfReplicates}
                </OwcTypography>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      {hasWarningSection && (
        <WarningInfoSection
          details={getDetailsInfoArray(
            hasWarningFactor,
            hasWarningFactorLess,
            hasWarningVolumeInput,
            hasWarningDiluentInput
          )}
        />
      )}
    </div>
  );
};

export default DilutionDetailsPagePanelMemberSection;
