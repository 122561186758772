import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { MastermixDilution } from "../../../../../models/Dilution";
import { useUpdateDiluentId } from "./useUpdateDiluentId";
import { DiluentStepForm } from "../../../../change-diluent-for-step/DiluentStepForm";

export const useHandleDiluentConfirm = ({
  groupIndex,
  reagentIndex,
  index,
}: {
  groupIndex: number;
  reagentIndex: number;
  index: number;
}) => {
  const { setValue } = useFormContext<MastermixDilution>();
  const updateDiluentId = useUpdateDiluentId(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${index}.diluent`
  );
  return useCallback(
    (values: DiluentStepForm) => {
      setValue(
        `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${index}.diluent.number`,
        values.number
      );
      setValue(
        `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${index}.diluent.lotNumber`,
        values.lotNumber
      );
      setValue(
        `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${index}.diluent.stockName`,
        values.stockName
      );
      updateDiluentId();
    },
    [index, setValue, updateDiluentId, groupIndex, reagentIndex]
  );
};
