import { ReactNode } from "react";
import {
  ValidationParamsContext,
  ValidationParamsContextType,
} from "./ValidationParamsContext";

export const validationParams: ValidationParamsContextType = {
  replicatesPerPanelMemberMin: 1,
  replicatesPerPanelMemberMax: 999,

  volumeOfReplicateMin: 0.001,
  volumeOfReplicateMax: 9999.999,

  numberOfPanelMembersMin: 1,
  numberOfPanelMembersMax: 30,

  highLevelInfoDeadVolumeMin: 0,
  highLevelInfoDeadVolumeMax: 1000.0,

  numberOfPreDilutionsMin: 0,
  numberOfPreDilutionsMax: 10,

  unitFactorMin: 0.001,
  unitFactorMax: 9999.999,

  availableVolumeMin: 0,
  availableVolumeMax: 9999.999,

  stockConcentrationMin: 1e-6,
  stockConcentrationMax: 9.99e99,

  numberOfReplicatesMin: 0,
  numberOfReplicatesMax: 999,

  panelMemberTargetConcentrationMin: 1e-6,
  panelMemberTargetConcentrationMax: 9.99e99,

  preDilutionStepTargetConcentrationMin: 1e-6,
  preDilutionStepTargetConcentrationMax: 9.99e99,

  preDilutionStepDeadVolumeMin: 0.0,
  preDilutionStepDeadVolumeMax: 99.999,

  customLeftOverMin: 0,
  customLeftOverMax: 9999999.999,
};

interface ValidationParamsWrapperProps {
  children: ReactNode;
  value?: ValidationParamsContextType;
}

const ValidationParamsWrapper = ({
  children,
  value = validationParams,
}: ValidationParamsWrapperProps) => {
  return (
    <ValidationParamsContext.Provider value={value}>
      {children}
    </ValidationParamsContext.Provider>
  );
};

export default ValidationParamsWrapper;
