import { EXPORT_PDF_CONFIG } from "../../constants";
import { MASTERMIX_PDF_CONFIG } from "../mastermix-pdf.constants";

const firstColumnWidthFactor = 3;
const secondColumnWidthFactor = 7;
const horizontalPaddingCount = 2;

export const widths = [
  MASTERMIX_PDF_CONFIG.COLUMN_WIDTH * firstColumnWidthFactor -
    EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
  MASTERMIX_PDF_CONFIG.COLUMN_WIDTH * secondColumnWidthFactor +
    EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
  MASTERMIX_PDF_CONFIG.COLUMN_WIDTH -
    EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING * horizontalPaddingCount,
];

export const layout = {
  paddingTop: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
  paddingBottom: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
  paddingLeft: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
  paddingRight: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
};

export const CELL_WIDTH_ROUND_PRECISION = 3;
export const calculateCellWidth = (colNumbers: number) =>
  parseFloat(
    (MASTERMIX_PDF_CONFIG.COLUMNS_WIDTH / colNumbers).toFixed(
      CELL_WIDTH_ROUND_PRECISION
    )
  );

export const valueOrNa = (value: string | null) =>
  !value?.trim() ? "N/A" : value;
