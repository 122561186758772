import { useMemo } from "react";
import { Diluent } from "../../../../models/Diluent";
import { DETAILS_VOLUME_MIN } from "../../constants";

export const getDilutionDetails = (diluent?: Diluent) => {
  const hasWarningValue =
    diluent?.neededVolume?.calculatedValue &&
    Number(diluent?.neededVolume?.calculatedValue) < DETAILS_VOLUME_MIN;
  return {
    hasWarningValue,
  };
};

export const useDetailsInfo = () =>
  useMemo(
    () => [
      {
        label: "Diluent:",
        detailsInfo:
          "Mastermix not pipettable - please go back and check concentrations of your inputs",
      },
    ],
    []
  );
