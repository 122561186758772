import { Diluent } from "../../../../models/Diluent";
import { Material } from "../../../../models/coform-models/Material";
import { PreDilution } from "../../../../models/coform-models/PreDilution";
import { Reagent } from "../../../../models/mastermix-models/Reagent";
import { getNewDilutionStep } from "./getNewDilutionStep";

export const addPreDilutionToReagent = ({
  reagent,
  diluent,
}: {
  reagent: Reagent;
  diluent: Diluent;
}): PreDilution => {
  if (reagent?.preDilution) {
    return reagent.preDilution;
  }
  const preDilution: PreDilution = {
    preDilutionNumber: 1,
    inputMaterial: reagent.material as Material,
    dilutionSteps: null,
  };
  preDilution.dilutionSteps = [getNewDilutionStep({ diluent, preDilution })];
  return preDilution;
};
