import { OwcIcon, OwcIconButton, OwcTypography } from "@one/react";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../../models/Dilution";
import { useUpdateReactionVolume } from "../hooks/useUpdateReactionVolume";
import { useUpdateReagentsFinalAndR2Concentration } from "../hooks/useUpdateReagentsFinalAndR2Concentration";
import { useUpdateTotalVolume } from "../hooks/useUpdateTotalVolume";
import styles from "./ReactionVolumeInputs.module.scss";
import ReactionVolumeInputsDialog from "./ReactionVolumeInputsDialog";

const ReactionVolumeInputs = () => {
  const { watch, setValue } = useFormContext<MastermixDilution>();
  const r1Volume = watch(`dilutionDetails.r1Volume`);
  const r2Volume = watch(`dilutionDetails.r2Volume`);
  const eluateVolume = watch(`dilutionDetails.eluateVolume`);
  const updateReactionVolume = useUpdateReactionVolume();
  const updateReagentsFinalAndR2Concentration =
    useUpdateReagentsFinalAndR2Concentration();
  const updateTotalVolume = useUpdateTotalVolume();
  const details = useMemo(
    () => ({
      r1Volume,
      r2Volume,
      eluateVolume,
    }),
    [r1Volume, r2Volume, eluateVolume]
  );

  const [open, setOpen] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div>
        <OwcTypography
          data-testid={`mastermix-reaction-volume-inputs-r1Volume`}
          variant="caption"
        >
          R1: <span className={styles.value}>{r1Volume} μL</span>
        </OwcTypography>
        <OwcTypography
          data-testid={`mastermix-reaction-volume-inputs-r2Volume`}
          variant="caption"
        >
          R2: <span className={styles.value}>{r2Volume} μL</span>
        </OwcTypography>
        <OwcTypography
          data-testid={`mastermix-reaction-volume-inputs-eluateVolume`}
          variant="caption"
        >
          Eluate: <span className={styles.value}>{eluateVolume} μL</span>
        </OwcTypography>
        <ReactionVolumeInputsDialog
          details={details}
          open={open}
          close={() => {
            setOpen(false);
          }}
          onConfirm={(values) => {
            setValue(`dilutionDetails.r1Volume`, values.r1Volume);
            setValue(`dilutionDetails.r2Volume`, values.r2Volume);
            setValue(`dilutionDetails.eluateVolume`, values.eluateVolume);
            updateReactionVolume();
            updateTotalVolume();
            updateReagentsFinalAndR2Concentration();
          }}
        />
      </div>
      <OwcIconButton
        flat
        onClick={() => {
          setOpen(true);
        }}
        data-testid="mastermix-reaction-volume-inputs-edit-btn"
      >
        <OwcIcon name="edit" />
      </OwcIconButton>
    </div>
  );
};

export default ReactionVolumeInputs;
