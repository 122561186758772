import { Auth } from "@aws-amplify/auth";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import awsconfig from "./aws-exports";

// AppSync client instantiation
export const appSyncClient = new AWSAppSyncClient(
  {
    url: awsconfig.aws_appsync_graphqlEndpoint!,
    region: awsconfig.aws_appsync_region!,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () =>
        (await Auth.currentSession()).getAccessToken().getJwtToken(),
    },
    disableOffline: true,
    cacheOptions: {
      addTypename: false,
    },
  },
  {
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  }
);
