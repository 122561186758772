import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../../models/Dilution";

export const usePreDilutionAutoRemove = ({
  groupIndex,
  reagentIndex,
}: {
  groupIndex: number;
  reagentIndex: number;
}) => {
  const { watch, setValue } = useFormContext<MastermixDilution>();

  const dilutionSteps = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps`
  );

  useEffect(() => {
    if (!dilutionSteps || dilutionSteps.length === 0) {
      setValue(
        `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution`,
        null
      );
    }
  }, [dilutionSteps, groupIndex, reagentIndex, setValue]);
};
