import { useFormContext } from "react-hook-form";
import { usePreDilutionInputConcentration } from "../hooks/usePreDilutionInputConcentration";
import {
  DILUENT_FACTOR_MAX,
  DILUENT_FACTOR_MIN,
} from "../../../../common-dilution/constants";
import { MastermixDilution } from "../../../../../models/Dilution";
import { toFixed } from "../../../../../utils/helpers/toFixed";

export const usePreDilutionStepFactor = ({
  groupIndex,
  reagentIndex,
  preDilutionStepIndex,
}: {
  groupIndex: number;
  reagentIndex: number;
  preDilutionStepIndex: number;
}) => {
  const { watch } = useFormContext<MastermixDilution>();
  const inputConcentration = usePreDilutionInputConcentration({
    groupIndex,
    reagentIndex,
    preDilutionStepIndex,
  });
  const input = Number(inputConcentration.calculatedValue || "0");
  const target = Number(
    watch(
      `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${preDilutionStepIndex}.targetConcentration.calculatedValue`
    ) || "0"
  );
  const factor = target === 0 ? null : +toFixed(input / target, { roundTo: 2 });
  const waringFactorMore = (factor ?? 0) > DILUENT_FACTOR_MAX;
  const waringFactorLess = (factor ?? 1) < DILUENT_FACTOR_MIN;
  return { waringFactorMore, waringFactorLess, factor };
};
