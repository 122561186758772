import { useFieldArray } from "react-hook-form";
import { HighLevelMaterialDilution } from "../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";

import PreDilutionContent from "../PreDilutionContent/PreDilutionContent";

const PreDilutionsContent = () => {
  const fieldArray = useFieldArray<
    HighLevelMaterialDilution,
    `dilutionDetails.preDilutions`
  >({
    name: `dilutionDetails.preDilutions`,
  });
  return (
    <>
      {fieldArray.fields?.map((field, preDilutionIndex) => (
        <PreDilutionContent
          key={field.id}
          fieldArray={fieldArray}
          preDilutionIndex={preDilutionIndex}
        />
      ))}
    </>
  );
};

export default PreDilutionsContent;
