import { Route, Routes } from "react-router-dom";
import AppVersionsPage from "./views/AppVersionsPage";
import CoFormulatedCreatePage from "./views/CoFormulatedCreatePage";
import CoFormulatedEditPage from "./views/CoFormulatedEditPage";
import ExperimentTypePage from "./views/ExperimentTypePage/ExperimentTypePage";
import MainView from "./views/main-view/MainView";
import MastermixDetailsPageView from "./views/mastermix-print-page/MastermixDetailsPageView";
import MastermixCreatePageView from "./views/MastermixCreatePageView";
import MastermixEditPageView from "./views/MastermixEditPageView";
import NotFoundScreen from "./views/NotFoundScreen";
import DilutionDetailsPageView from "./views/serial-print-page/DilutionDetailsPageView";
import SerialCreatePage from "./views/SerialCreatePage";
import SerialEditPage from "./views/SerialEditPage";

const Routing = () => {
  return (
    <Routes>
      <Route path="" index element={<MainView />} />
      <Route path="/serial" element={<SerialCreatePage />} />
      <Route path="/co-formulated" element={<CoFormulatedCreatePage />} />
      <Route path="/serial-edit/:id" element={<SerialEditPage />} />
      <Route
        path="/co-formulated-edit/:id"
        element={<CoFormulatedEditPage />}
      />
      <Route path="/mastermix" element={<MastermixCreatePageView />} />
      <Route
        path="/mastermix-details/:id"
        element={<MastermixDetailsPageView />}
      />
      <Route path="/mastermix-edit/:id" element={<MastermixEditPageView />} />
      <Route path="/experiment-type" element={<ExperimentTypePage />} />
      <Route
        path="/dilution-details/:id"
        element={<DilutionDetailsPageView />}
      />
      <Route path="/versions" element={<AppVersionsPage />} />
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  );
};

export default Routing;
