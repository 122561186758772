import * as yup from "yup";

export const labelLengthLimit = 23;

const printLabelSchema = yup.object().shape({
  panelName: yup.string().required("Panel name is required"),
  labelName: yup.string().required("Label is required"),
  numberOfReplicates: yup
    .number()
    .min(0, "Number of replicates must be greater than or equal to 0")
    .required("Number of replicates is required"),
});

export const validationSchema = yup.object().shape({
  labels: yup
    .array(printLabelSchema)
    .required()
    .min(1, "Number of labels must be greater than or equal to 1"),
});
