import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import OwcInputSuffix from "../../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { CoFormDilution } from "../../../../../../models/Dilution";
import { getStringFieldInputProps } from "../../../../../../utils/helpers/getStringFieldInputProps";
import { useValidationParams } from "../../ValidationParamsContext/useValidationParams";

const MaterialAvailableVolumeField = ({ index }: { index: number }) => {
  const { availableVolumeMin, availableVolumeMax } = useValidationParams();
  return (
    <Controller<
      CoFormDilution,
      `dilutionDetails.inputMaterials.${typeof index}.availableVolume`
    >
      name={`dilutionDetails.inputMaterials.${index}.availableVolume`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`inputMaterials-availableVolume-${index}-input`}
          label="Stock mat. available volume"
          type="number"
          min={availableVolumeMin}
          max={availableVolumeMax}
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`inputMaterials-availableVolume-${index}-error`}
          >
            {error?.message}
          </span>
          <OwcInputSuffix>mL</OwcInputSuffix>
        </OwcInput>
      )}
    />
  );
};

export default MaterialAvailableVolumeField;
