import { OwcInput } from "@one/react";
import { Controller, useFormContext } from "react-hook-form";
import OwcInputSuffix from "../../../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { CoFormDilution } from "../../../../../../../models/Dilution";
import { getCalculatedOnChangeFieldInput } from "../../../../../../../utils/helpers/getCalculatedOnChangeFieldInput";
import { getCalculatedValueFieldInput } from "../../../../../../../utils/helpers/getCalculatedValueFieldInput";
import { useValidationParams } from "../../../ValidationParamsContext/useValidationParams";
import { LabelDetailRow } from "../../common/LabelDetailRow";

interface PreDilutionTargetConcentrationFieldProps {
  preDilutionIndex: number;
  preDilutionStepIndex: number;
  preDilutionStepsCount: number;
}

const PreDilutionTargetConcentrationFieldInput = ({
  preDilutionIndex,
  preDilutionStepIndex,
}: PreDilutionTargetConcentrationFieldProps) => {
  const { watch } = useFormContext<CoFormDilution>();
  const targetUnit = watch(
    `dilutionDetails.preDilutions.${preDilutionIndex}.inputMaterial.targetUnit`
  );
  const {
    preDilutionStepTargetConcentrationMin,
    preDilutionStepTargetConcentrationMax,
  } = useValidationParams();
  return (
    <Controller<
      CoFormDilution,
      `dilutionDetails.preDilutions.${typeof preDilutionIndex}.dilutionSteps.${typeof preDilutionStepIndex}.targetConcentration`
    >
      name={`dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${preDilutionStepIndex}.targetConcentration`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`pre-dilution-step-dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${preDilutionStepIndex}.targetConcentration-input`}
          label="*Target concentration"
          type="number"
          {...field}
          value={getCalculatedValueFieldInput(field.value)}
          min={preDilutionStepTargetConcentrationMin}
          max={preDilutionStepTargetConcentrationMax}
          onInputChange={(event) => {
            getCalculatedOnChangeFieldInput(field.onChange)(event);
          }}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`pre-dilution-step-dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${preDilutionStepIndex}.targetConcentration-error`}
          >
            {error?.message}
          </span>
          <OwcInputSuffix>{targetUnit}</OwcInputSuffix>
        </OwcInput>
      )}
    />
  );
};

const PreDilutionTargetConcentrationFieldInfo = ({
  preDilutionIndex,
  preDilutionStepIndex,
}: PreDilutionTargetConcentrationFieldProps) => {
  const { watch } = useFormContext<CoFormDilution>();
  const targetConcentration = watch(
    `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${preDilutionStepIndex}.targetConcentration`
  );
  const targetUnit = watch(
    `dilutionDetails.preDilutions.${preDilutionIndex}.inputMaterial.targetUnit`
  );

  return (
    <LabelDetailRow
      label="Target concentration"
      data-testid={`pre-dilution-step-dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${preDilutionStepIndex}.targetConcentration-info`}
    >
      {targetConcentration?.displayedValue === ""
        ? "unknown yet"
        : `${targetConcentration?.displayedValue} ${targetUnit}`}
    </LabelDetailRow>
  );
};

const PreDilutionTargetConcentrationField = ({
  preDilutionIndex,
  preDilutionStepIndex,
  preDilutionStepsCount,
}: PreDilutionTargetConcentrationFieldProps) => {
  const { watch } = useFormContext<CoFormDilution>();
  const type = watch("type");

  if (
    type === "CO_FORMULATED_DILUTION" &&
    preDilutionStepIndex + 1 === preDilutionStepsCount
  ) {
    return (
      <PreDilutionTargetConcentrationFieldInfo
        {...{ preDilutionIndex, preDilutionStepIndex, preDilutionStepsCount }}
      />
    );
  }

  return (
    <PreDilutionTargetConcentrationFieldInput
      {...{ preDilutionIndex, preDilutionStepIndex, preDilutionStepsCount }}
    />
  );
};

export default PreDilutionTargetConcentrationField;
