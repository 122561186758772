import { useFormContext } from "react-hook-form";
import { CoFormDilution } from "../../../../../../../models/Dilution";

import { LabelDetailRow } from "../../common/LabelDetailRow";
import { usePreDilutionInputConcentration } from "../hooks/usePreDilutionInputConcentration";

export const StockMaterialConcentrationRow = ({
  index,
  preDilutionIndex,
}: {
  index: number;
  preDilutionIndex: number;
}) => {
  const { watch } = useFormContext<CoFormDilution>();
  const preDilution = watch(`dilutionDetails.preDilutions.${preDilutionIndex}`);
  const inputConcentration = usePreDilutionInputConcentration(
    preDilutionIndex,
    index
  );
  return (
    <LabelDetailRow
      label={
        index === 0 ? "Stock material concentration" : "Input concentration"
      }
      data-testid={`pre-dilutions-${preDilutionIndex}-step-${index}-stock-or-input-concentration`}
    >
      {inputConcentration && inputConcentration.displayedValue
        ? `${inputConcentration.displayedValue} ${preDilution.inputMaterial.targetUnit}`
        : "unknown yet"}
    </LabelDetailRow>
  );
};
