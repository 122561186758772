import { ContentTable } from "pdfmake/interfaces";
import { Material } from "../../../../models/coform-models/Material";
import { Diluent } from "../../../../models/Diluent";
import { toFixed } from "../../../../utils/helpers/toFixed";
import { breakableString } from "../../utils/breakable-string";
import { diluentTable } from "../../shared/diluentTable";

const inputMaterialsTables = (
  inputMaterials: Material[],
  diluents: Diluent[]
) => {
  const inputMaterialTable = (inputMaterial: Material) => {
    const {
      stockName,
      number,
      lotNumber,
      stockConcentration,
      stockUnit,
      targetUnit,
      unitFactor,
    } = inputMaterial;
    const tableBody = [
      ["Stock Material Name:", breakableString(stockName || "—")],
      ["Stock Material Number:", breakableString(number || "—")],
      ["Stock Material Lot Number:", breakableString(lotNumber || "—")],
      [
        "Stock Material Concentration:",
        breakableString(`${stockConcentration} ${stockUnit}`),
      ],
    ];

    if (stockUnit !== targetUnit) {
      const materialConcentration = toFixed(
        Number(stockConcentration) * Number(unitFactor),
        {
          exponential:
            stockConcentration!.toLowerCase().includes("e") ||
            unitFactor!.toLowerCase().includes("e"),
        }
      );

      tableBody.push([
        "Stock Material Concentration:",
        `${materialConcentration} ${targetUnit}`,
      ]);
    }

    return {
      style: ["table"],
      table: {
        body: tableBody,
      },
      layout: "noBorders",
    };
  };

  const inputMaterialWithDiluentTable = (
    inputMaterial: Material,
    diluent: Diluent,
    diluentIndex: number
  ) => ({
    style: ["table", "marginBottomLarge"],
    table: {
      widths: ["*", "*"],
      body: [
        [
          inputMaterialTable(inputMaterial),
          diluentTable(diluent, diluentIndex),
        ],
      ],
    },
  });
  const standaloneInputMaterialTable = (inputMaterial: Material) => ({
    unbreakable: true,
    style: ["table", "marginBottomLarge"],
    table: {
      widths: ["*", "*"],
      body: [[inputMaterialTable(inputMaterial), ""]],
    },
  });
  const standaloneDiluentTable = (diluent: Diluent, diluentIndex: number) => ({
    unbreakable: true,
    style: ["table", "marginBottomLarge"],
    table: {
      widths: ["*", "*"],
      body: [["", diluentTable(diluent, diluentIndex)]],
    },
  });

  const result: ContentTable[] = [
    {
      style: ["table", "tableTitle"],
      table: {
        widths: ["*", "*"],
        body: [["Materials", "Diluents"]],
      },
      layout: "noBorders",
    },
  ];

  for (let i = 0; i < Math.max(inputMaterials.length, diluents.length); i++) {
    const material = inputMaterials[i];
    const diluent = diluents[i];

    if (material && diluent) {
      result.push(inputMaterialWithDiluentTable(material, diluent, i + 1));
    } else if (material) {
      result.push(standaloneInputMaterialTable(material));
    } else if (diluent) {
      result.push(standaloneDiluentTable(diluent, i + 1));
    }
  }

  return result;
};

export default inputMaterialsTables;
