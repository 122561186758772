import { OwcProgressSpinner } from "@one/react";
import styles from "./FullBlockProgress.module.scss";

const FullBlockProgress = () => {
  return (
    <div data-testid="full-block-progress" className={styles.wrapper}>
      <OwcProgressSpinner />
    </div>
  );
};

export default FullBlockProgress;
