import { ContentTable } from "pdfmake/interfaces";
import { EXPORT_PDF_CONFIG } from "../../constants";
import { calculateRowHeight } from "../../utils/calculate-heights";
import {
  CellData,
  generateSingleValueCellTable,
  generateSingleValueCellWithUnitTable,
  generateTableCellContent,
} from "../../utils/generate-cell-table";
import { parseToBreakableStrings } from "../../utils/parse-to-breakable-strings";
import { applyVerticalAlignment } from "../../utils/vertical-aligment";
import { MASTERMIX_PDF_CONFIG } from "../mastermix-pdf.constants";

export interface ResultsTableRow {
  group: CellData;
  materialAbbreviation: CellData;
  materialName: CellData;
  lotNumber: CellData;
  matNumber: CellData;
  internalLot: CellData;
  stockConcentration: CellData;
  concentrationInR2: CellData;
  concentrationInPcr: CellData;
  volumeInPcr: CellData;
  volumeForAllReactions: CellData;
  comment: CellData;
}

export interface ResultsTableInput {
  rows: ResultsTableRow[];
}

const materialNameColumnIndex = 2;

const columnsAlignedToLeft = [1, materialNameColumnIndex];

const marginRightForNormalText = 4;

const normalTextMargin = [0, marginRightForNormalText, 0, 0];
const resultsTable = (resultsData: ResultsTableInput) => {
  const tableHeaderNames = [
    "Group",
    "Material Abbreviation",
    "Material name",
    "Lot number",
    "Mat. number",
    "Internal Lot",
    "Stock conc",
    "Concentration in R2 (*)",
    "Concentration in PCR (**)",
    "Volume in PCR (μL) (***)",
    "Volume for all reactions (μL) (****)",
    "Comment",
  ];

  const headers = tableHeaderNames.map((tableHeaderName) => {
    const style = ["bold"];

    return generateSingleValueCellTable({ value: tableHeaderName, style });
  });

  const rows = resultsData.rows.map((row: ResultsTableRow, rowIndex: number) =>
    Object.values(row).map(
      ({ value, unit, rowSpan = 1, colSpan = 1 }, index) => {
        value = parseToBreakableStrings(value);
        const style = [];

        // Align first two columns to the left
        if (columnsAlignedToLeft.includes(index)) {
          style.push("alignedToLeft");
        }

        // Bold material name values
        if (index === materialNameColumnIndex) {
          style.push("bold");
        }

        if (index === 0) {
          const normalText = {
            text: value,
            rowSpan,
            colSpan,
            style,
            margin: normalTextMargin,
          };

          return normalText;
        }

        // Return value with unit for concentration values
        if (unit) {
          return generateSingleValueCellWithUnitTable({ value, unit });
        }

        return generateTableCellContent(rowIndex, {
          value,
          style,
          rowSpan,
          colSpan,
        });
      }
    )
  );

  return {
    style: ["table", "alignedToCenter"],
    table: {
      headerRows: 1,
      widths: [...Array(tableHeaderNames.length)].map(
        () => MASTERMIX_PDF_CONFIG.COLUMN_WIDTH
      ),
      dontBreakRows: true,
      body: [headers, ...rows],
    },
    layout: {
      paddingTop: (rowIndex: number, node: ContentTable) => {
        const rowHeight = calculateRowHeight(
          rowIndex,
          headers,
          rows as ContentTable[][],
          MASTERMIX_PDF_CONFIG.COLUMN_WIDTH
        );
        applyVerticalAlignment(
          node,
          rowIndex,
          rowHeight,
          MASTERMIX_PDF_CONFIG.COLUMN_WIDTH
        );

        return EXPORT_PDF_CONFIG.OUTSIDE_CELL_PADDING;
      },
      paddingBottom: () => EXPORT_PDF_CONFIG.OUTSIDE_CELL_PADDING,
      paddingLeft: () => EXPORT_PDF_CONFIG.OUTSIDE_CELL_PADDING,
      paddingRight: () => EXPORT_PDF_CONFIG.OUTSIDE_CELL_PADDING,
    },
  };
};

export default resultsTable;
