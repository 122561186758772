import { MastermixDetails } from "../../../../models/mastermix-models/MastermixDetails";
import { Reagent } from "../../../../models/mastermix-models/Reagent";
import { ReagentGroup } from "../../../../models/mastermix-models/ReagentGroup";
import {
  getLastPredilutionStep,
  getPredilutionLastStepName,
} from "../../../mastermix-dilution/manage-mastermix/utils/getLastPredilutionStep";
import { valueOrNa } from "./common";
import resultsTable, {
  ResultsTableInput,
  ResultsTableRow,
} from "./results-table";

const getMapReagent =
  (group: ReagentGroup) => (reagent: Reagent, predilutionId: number) => {
    const lastPredilutionStep = getLastPredilutionStep(reagent);

    return {
      group: {
        value: group.name,
        rowSpan: group.reagents!.length,
      },
      materialAbbreviation: {
        value: valueOrNa(reagent.material.shortName),
      },
      materialName: {
        value: reagent.material.stockName,
      },
      lotNumber: {
        value: reagent.material.lotNumber,
      },
      matNumber: { value: reagent.material.number },
      internalLot: {
        value: valueOrNa(reagent.material.internalLotNumber),
      },
      stockConcentration: {
        value: reagent.material.stockConcentration,
        unit: reagent.material.targetUnit ?? undefined,
      },
      concentrationInR2: {
        value: reagent.r2Concentration?.displayedValue ?? null,
        unit: reagent.material.targetUnit ?? undefined,
      },
      concentrationInPcr: {
        value: reagent.pcrConcentration?.displayedValue ?? null,
        unit: reagent.material.targetUnit ?? undefined,
      },
      volumeInPcr: { value: reagent.pcrVolume?.displayedValue ?? null },
      volumeForAllReactions: {
        value: reagent.material.neededVolume?.displayedValue ?? null,
      },
      comment: {
        value: valueOrNa(reagent.comment),
      },
      ...(lastPredilutionStep
        ? {
            comment: {
              value: `Pre-Dil needed (see Dilution ${predilutionId}) `,
            },
            materialAbbreviation: {
              value: lastPredilutionStep?.stepName,
            },
            materialName: {
              value: getPredilutionLastStepName(reagent),
            },
            stockConcentration: {
              value: lastPredilutionStep?.targetConcentration?.calculatedValue!,
              unit: reagent.material.targetUnit ?? undefined,
            },
          }
        : null),
    };
  };

const mastermixTable = (details: MastermixDetails) => {
  let preDilutionId = 0;
  const rows: ResultsTableRow[] = details
    .groups!.map((group) => {
      return group.reagents!.map((reagent) => {
        if (reagent.preDilution) {
          preDilutionId++;
        }
        return getMapReagent(group)(reagent, preDilutionId);
      });
    })
    .flatMap((x) => x);

  const resultsTableInput: ResultsTableInput = {
    rows,
  };

  return [resultsTable(resultsTableInput)];
};

export default mastermixTable;
