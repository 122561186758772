import { OwcButton, OwcModalDialog, OwcTypography } from "@one/react";
import * as React from "react";
import styles from "./Dialog.module.scss";

type OwcModalDialogProps = React.ComponentProps<typeof OwcModalDialog>;

type OwcButtonProps = Partial<React.ComponentProps<typeof OwcButton>>;

export interface DialogProps extends OwcModalDialogProps {
  open: boolean;
  onClose: () => void;
  size?: OwcModalDialogProps["size"];
  title?: string;
  children?: React.ReactNode;
  buttons?: {
    label: string;
    closeOnClick: boolean;
    action?: () => void;
    buttonProps?: OwcButtonProps;
  }[];
}

const Dialog = ({
  open,
  onClose,
  size = "md",
  title = "",
  children,
  buttons = [],
  ...props
}: DialogProps) => {
  return (
    <OwcModalDialog
      size={size}
      visible={open}
      onVisibleChange={(e) => {
        if (e.detail === false) {
          onClose();
        }
      }}
      data-testid="dialog"
      {...props}
    >
      <OwcTypography slot="header" data-testid="dialog-title" variant="title5">
        {title}
      </OwcTypography>
      {children}
      <div
        data-testid="dialog-actions"
        slot="actions"
        className={styles.actions}
      >
        {buttons.map((btn, index) => (
          <OwcButton
            {...(btn.buttonProps ?? {})}
            data-testid={`dialog-actions-btn-${index}`}
            key={index}
            onClick={() => {
              if (btn.closeOnClick) {
                onClose();
              }
              btn.action?.();
            }}
          >
            {btn.label}
          </OwcButton>
        ))}
      </div>
    </OwcModalDialog>
  );
};
export default Dialog;
