import { OwcButton, OwcIcon, OwcIconButton } from "@one/react";
import { useState } from "react";
import { UseFieldArrayReturn, useFormContext } from "react-hook-form";
import Grid from "../../../../../../components/Grid/Grid";
import { CoFormDilution } from "../../../../../../models/Dilution";
import button from "../../../../../../scss/button/Button.module.scss";
import { getDetailsInfoArray } from "../../../../waring-section/getDetailsInfoArray";
import WarningInfoSection from "../../../../waring-section/WarningInfoSection";
import { HighLevelMaterialDilution } from "../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { useValidationParams } from "../../ValidationParamsContext/useValidationParams";
import DiluionInfoColumn from "../common/DiluionInfoColumn";
import PreDilutionNameField from "./fields/PreDilutionNameField";
import PreDilutionStepDeadVolume from "./fields/PreDilutionStepDeadVolume";
import PreDilutionStepDilutionFactor from "./fields/PreDilutionStepDilutionFactor";
import PreDilutionTargetConcentrationField from "./fields/PreDilutionTargetConcentrationField";
import { StockMaterialConcentrationRow } from "./fields/StockMaterialConcentrationRow";
import StockMaterialInfoColumn from "./fields/StockMaterialInfoColumn";
import { usePreDilutionStepFactor } from "./fields/usePreDilutionStepFactor";
import { useHandleDiluentConfirm } from "./hooks/useHandleDiluentConfirm";
import { usePreDilutionStepInsert } from "./hooks/usePreDilutionStepInsert";
import { usePreDilutionStepRemove } from "./hooks/usePreDilutionStepRemove";
import styles from "./PreDilutionStepContent.module.scss";
import PreDilutionStepChangeDiluentDialog from "../../../../../change-diluent-for-step/PreDilutionStepChangeDiluentDialog";

export interface PreDilutionStepContentProps {
  index: number;
  preDilutionIndex: number;
  fieldArray: UseFieldArrayReturn<
    HighLevelMaterialDilution,
    `dilutionDetails.preDilutions.${number}.dilutionSteps`,
    "id"
  >;
  remove: ReturnType<typeof usePreDilutionStepRemove>;
}

const PreDilutionStepContent = ({
  preDilutionIndex,
  index,
  fieldArray,
  remove,
}: PreDilutionStepContentProps) => {
  const insert = usePreDilutionStepInsert(preDilutionIndex, fieldArray);
  const { waringFactorMore, waringFactorLess } = usePreDilutionStepFactor(
    preDilutionIndex,
    index
  );
  const { watch } = useFormContext<CoFormDilution>();
  const preDilutionStep = watch(
    `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${index}`
  );

  const handleDiluentConfirm = useHandleDiluentConfirm(preDilutionIndex, index);

  const { numberOfPreDilutionsMax } = useValidationParams();
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className={styles.step}>
      <Grid
        container
        data-center
        columnGap={40}
        rowGap={16}
        columns={2}
        data-testid={`pre-dilutions-${preDilutionIndex}-step-${index}`}
      >
        <Grid item columnSpan={1}>
          <PreDilutionNameField
            preDilutionIndex={preDilutionIndex}
            stepIndex={index}
          />
        </Grid>
        <Grid
          item
          columnSpan={1}
          style={{
            alignSelf: "stretch",
            padding: "1px",
            overflow: "visible",
          }}
        >
          <div className={styles.inputAndButtons}>
            <OwcButton
              flat
              variant="secondary"
              onClick={() => {
                insert(index);
              }}
              disabled={fieldArray.fields.length >= numberOfPreDilutionsMax}
              data-testid={`pre-dilutions-${preDilutionIndex}-step-${index}-add-step-btn`}
            >
              Add pre-dilution step
            </OwcButton>
            <OwcButton
              flat
              variant="secondary"
              className={button.colorError}
              onClick={() => {
                remove(index);
              }}
              data-testid={`pre-dilutions-${preDilutionIndex}-step-${index}-remove-step-btn`}
            >
              Delete
            </OwcButton>
          </div>
        </Grid>
        <Grid item columnSpan={1}>
          <StockMaterialInfoColumn
            stepIndex={index}
            preDilutionIndex={preDilutionIndex}
          />
        </Grid>

        <Grid item columnSpan={1}>
          <OwcIconButton
            data-testid={`pre-dilution-step-${index}-diluent-info-edit-btn`}
            flat
            disableRipple
            onClick={(event) => setOpen(true)}
          >
            <OwcIcon name="edit" />
          </OwcIconButton>
          <PreDilutionStepChangeDiluentDialog
            open={open}
            onClose={() => setOpen(false)}
            onConfirm={handleDiluentConfirm}
            preDilutionStep={preDilutionStep}
            preDilutionIndex={preDilutionIndex}
          />
          <DiluionInfoColumn
            watchSource={`preDilutions.${preDilutionIndex}.dilutionSteps.${index}.diluent`}
            testId={`pre-dilution-step-${index}-diluent-info`}
          />
        </Grid>
        <Grid item columnSpan={1}>
          <StockMaterialConcentrationRow
            preDilutionIndex={preDilutionIndex}
            index={index}
          />
        </Grid>
        <Grid item columnSpan={1}>
          <PreDilutionStepDilutionFactor
            index={index}
            preDilutionIndex={preDilutionIndex}
          />
        </Grid>
        <Grid item columnSpan={1}>
          <PreDilutionTargetConcentrationField
            preDilutionIndex={preDilutionIndex}
            preDilutionStepIndex={index}
            preDilutionStepsCount={fieldArray.fields.length}
          />
        </Grid>
        <Grid item columnSpan={1}>
          <PreDilutionStepDeadVolume
            preDilutionIndex={preDilutionIndex}
            preDilutionStepIndex={index}
          />
        </Grid>
      </Grid>
      {(waringFactorMore || waringFactorLess) && (
        <div className={styles.warningInfo}>
          <WarningInfoSection
            details={getDetailsInfoArray(waringFactorMore, waringFactorLess)}
          />
        </div>
      )}
    </div>
  );
};

export default PreDilutionStepContent;
