import { useCallback, useEffect, useState } from "react";
import { UseFieldArrayReturn, useFormContext } from "react-hook-form";
import { getPreDilutionStepName } from "../../../../../utils/getPreDilutionStepName";
import { HighLevelMaterialDilution } from "../../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { useUpdateFirstPanelMemberTarget } from "../../PanelMemberContent/fields/useUpdateFirstPanelMemberTarget";

export const usePreDilutionStepInsert = (
  preDilutionIndex: number,
  fieldArray: UseFieldArrayReturn<
    HighLevelMaterialDilution,
    `dilutionDetails.preDilutions.${typeof preDilutionIndex}.dilutionSteps`,
    "id"
  >
) => {
  const { watch, setValue, getValues } =
    useFormContext<HighLevelMaterialDilution>();
  const type = watch("type");
  const deadVolume = watch(`deadVolume`);
  const diluent = watch("dilutionDetails.diluents.0");
  const inputMaterial = watch(
    `dilutionDetails.preDilutions.${preDilutionIndex}.inputMaterial`
  );
  const { insert } = fieldArray;
  const preDilutionStepValues = watch(
    `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps`
  );
  const firstPanelMember = watch(`dilutionDetails.panelMembers.0`);
  const targetIndex =
    firstPanelMember?.targets?.findIndex(
      ({ material }) => material.id === inputMaterial.id
    ) ?? 0;
  const updateFirstPanelMemberTarget = useUpdateFirstPanelMemberTarget(
    0,
    targetIndex
  );
  const [insertIndex, setInsertIndex] = useState(-1);
  const [updateFirstPanelMember, setUpdateFirstPanelMember] = useState(false);

  useEffect(() => {
    if (updateFirstPanelMember) {
      updateFirstPanelMemberTarget(
        firstPanelMember.targets![targetIndex].targetConcentration!
      );
      setUpdateFirstPanelMember(false);
    }
  }, [
    updateFirstPanelMemberTarget,
    firstPanelMember,
    targetIndex,
    updateFirstPanelMember,
  ]);

  useEffect(() => {
    if (insertIndex !== -1) {
      const newIndex = insertIndex + 1;
      insert(newIndex, {
        deadVolume: "",
        diluent,
        diluentVolume: null,
        dilutionFactor: {
          calculatedValue: "",
          displayedValue: "",
        },
        inputConcentration: {
          calculatedValue: "",
          displayedValue: "",
        },
        inputName: "",
        inputVolume: null,
        leftOver: null,
        stepName: getPreDilutionStepName(newIndex, inputMaterial),
        stepNumber: newIndex + 1,
        targetConcentration: {
          calculatedValue: "",
          displayedValue: "",
        },
        totalVolume: null,
      });
      const steps = getValues(
        `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps`
      );

      setInsertIndex(-1);

      if (type === "CO_FORMULATED_DILUTION" && steps?.length === newIndex + 1) {
        // Defer updating last dilution step target to the next render cycle
        setUpdateFirstPanelMember(true);
      }
    }
  }, [
    insertIndex,
    setInsertIndex,
    insert,
    deadVolume,
    diluent,
    inputMaterial,
    firstPanelMember,
    preDilutionIndex,
    getValues,
    updateFirstPanelMemberTarget,
    targetIndex,
    type,
  ]);

  return useCallback(
    (index: number) => {
      preDilutionStepValues?.forEach(
        (preDilutionStep, preDilutionStepIndex) => {
          if (preDilutionStepIndex > index) {
            const defaultOldName = getPreDilutionStepName(
              preDilutionStepIndex,
              inputMaterial
            );
            const nextIndex = preDilutionStepIndex + 1;
            const stepName = getPreDilutionStepName(nextIndex, inputMaterial);
            if (defaultOldName === preDilutionStep.stepName) {
              setValue(
                `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${preDilutionStepIndex}.stepName`,
                stepName
              );
              preDilutionStep.stepName = stepName;
            }
            setValue(
              `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${preDilutionStepIndex}.stepNumber`,
              nextIndex + 1
            );
          }
        }
      );
      setInsertIndex(index);
    },
    [
      setValue,
      inputMaterial,
      preDilutionStepValues,
      preDilutionIndex,
      setInsertIndex,
    ]
  );
};
