import { OwcIcon, OwcTable, OwcTableBody, OwcTypography } from "@one/react";
import { format } from "date-fns";
import TransparentLink from "../../components/TransparentLink/TransparentLink";
import { DilutionShortInfo } from "../../views/main-view/MainView";
import { DilutionTableHeader } from "./DilutionTableHeader";
import { DilutionTableRow } from "./DilutionTableRow";

import styles from "./TableListView.module.scss";

export const DateAndTime = ({
  date,
  testId,
}: {
  date: string;
  testId: string;
}) => (
  <>
    <OwcTypography data-testid={`${testId}-date`} variant="body2">
      {format(new Date(date), "dd.MM.yyyy")}
    </OwcTypography>
    <OwcTypography
      data-testid={`${testId}-time`}
      className={styles.time}
      variant="body2"
    >
      {format(new Date(date), "HH:mm")}
    </OwcTypography>
  </>
);

interface TableListViewProps {
  dilutionsShortInfo: DilutionShortInfo[];
}

const TableListView = ({ dilutionsShortInfo }: TableListViewProps) => {
  return (
    <OwcTable
      height="calc(100vh - 72px - 24px - 48px - 58px - 40px - 24px - 48px)"
      data-testid="dilution-list-table"
      className={styles.table}
    >
      <DilutionTableHeader />
      <OwcTableBody data-testid="dilution-list-table-body">
        {dilutionsShortInfo.map((info) => (
          <DilutionTableRow key={info.id} info={info} />
        ))}
      </OwcTableBody>
      <TransparentLink slot="header" to={"/experiment-type"}>
        <button
          data-testid="dilution-list-table-add-btn"
          className={styles.addRowbtn}
        >
          <div className={styles.addRow}>
            <OwcIcon
              data-testid="dilution-card-list-add-btn-icon"
              variant="primary"
              name="sign_plus"
            />
            <OwcTypography variant="subtitle1" className={styles.addText}>
              Add new dilution
            </OwcTypography>
          </div>
        </button>
      </TransparentLink>
    </OwcTable>
  );
};

export default TableListView;
