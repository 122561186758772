import clsx from "clsx";
import { HTMLAttributes } from "react";
import styles from "./Grid.module.scss";

interface GridProps extends HTMLAttributes<HTMLDivElement> {
  columnSpan?: number;
  columnGap?: number;
  rowGap?: number;
  columns?: number;
  container?: boolean;
  item?: boolean;
}

const Grid = ({
  columnSpan,
  columnGap,
  rowGap,
  columns,
  container,
  item,
  ...props
}: GridProps) => {
  return (
    <div
      {...props}
      className={clsx(
        {
          [styles.container]: container,
          [styles.item]: item,
        },
        props.className
      )}
      style={
        container
          ? {
              "--grid-column-gap": "" + columnGap + "px",
              "--grid-row-gap": "" + rowGap + "px",
              "--grid-col-template": "" + columns,
              ...(props.style ? props.style : {}),
            }
          : item
          ? {
              "--grid-size": "" + columnSpan,
              ...(props.style ? props.style : {}),
            }
          : {
              ...(props.style ? props.style : {}),
            }
      }
    />
  );
};

export default Grid;
