import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { HighLevelMaterialDilution } from "../models/HighLevelMaterialDilution";
import { getStringFieldInputProps } from "../../../../../../utils/helpers/getStringFieldInputProps";
import OwcInputSuffix from "../../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { useValidationParams } from "../../ValidationParamsContext/useValidationParams";

const DiluentPanelVolumeField = () => {
  const { customLeftOverMin, customLeftOverMax } = useValidationParams();
  return (
    <Controller<HighLevelMaterialDilution, "dilutionDetails.customLeftOver">
      name="dilutionDetails.customLeftOver"
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid="dilution-customLeftOver-input"
          label="Panel volume needed"
          min={customLeftOverMin}
          max={customLeftOverMax}
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
          type="number"
        >
          <span slot="error-text" data-testid="dilution-customLeftOver-error">
            {error?.message}
          </span>
          <OwcInputSuffix>mL</OwcInputSuffix>
        </OwcInput>
      )}
    />
  );
};

export default DiluentPanelVolumeField;
