import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import OwcInputSuffix from "../../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { getNumberFieldInputProps } from "../../../../../../utils/helpers/getNumberFieldInputProps";
import { useValidationParams } from "../../ValidationParamsContext/useValidationParams";
import { HighLevelMaterialDilution } from "../models/HighLevelMaterialDilution";

const DeadVolumeField = () => {
  const { highLevelInfoDeadVolumeMin, highLevelInfoDeadVolumeMax } =
    useValidationParams();
  return (
    <Controller<HighLevelMaterialDilution, "deadVolume">
      name="deadVolume"
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid="dilution-deadVolume-input"
          label="Dead volume"
          type="number"
          min={highLevelInfoDeadVolumeMin}
          max={highLevelInfoDeadVolumeMax}
          {...getNumberFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span slot="error-text" data-testid="dilution-deadVolume-error">
            {error?.message}
          </span>
          <OwcInputSuffix>mL</OwcInputSuffix>
        </OwcInput>
      )}
    />
  );
};

export default DeadVolumeField;
