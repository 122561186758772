import * as yup from "yup";
import { Maybe } from "yup/lib/types";

export const numberKeepStringValidator = <T extends Maybe<number>>(
  numberSchema: yup.NumberSchema<T>
) => {
  return {
    test: (value: unknown, context: yup.TestContext) => {
      return numberSchema
        .validate(value, {
          context: context.options.context,
        })
        .then(
          () => true,
          (error) => {
            if (yup.ValidationError.isError(error)) {
              return context.createError({
                message: error.message,
              });
            }
            throw error;
          }
        );
    },
  };
};
