import { useFormContext } from "react-hook-form";
import {
  Calculated,
  CoFormDilution,
} from "../../../../../../../models/Dilution";
import { getPreDilutionInputConcentration } from "../../../HighLevelMaterialInfoForm/utils/getPreDilutionInputConcentration";

export const usePreDilutionInputConcentration = (
  preDilutionIndex: number,
  preDilutionStepIndex: number
): Calculated => {
  const { watch } = useFormContext<CoFormDilution>();
  const preDilution = watch(`dilutionDetails.preDilutions.${preDilutionIndex}`);

  return getPreDilutionInputConcentration(
    preDilutionStepIndex,
    preDilution,
    preDilution.dilutionSteps ?? []
  );
};
