import { OwcListItem } from "@one/react";
import { useNavigate } from "react-router-dom";
import ButtonLoading from "../../../components/button-loading/ButtonLoading";
import { useQueryContext } from "../../../components/query-context/useQueryContext";
import { DilutionTypeValues } from "../../../models/DilutionType";
import { useNavigateToEditPage } from "../../hooks/useNavigateToEditPage";

export const MastermixDetailsPageButtonsMenuItems = ({
  deleteDilution,
  id,
  pendingAction,
  setMenuOpen,
  status,
  generateMastermixPdf,
  generateDilutionJSON,
}: {
  id: string;
  pendingAction: boolean;
  status: string;
  setMenuOpen: (open: boolean) => void;
  deleteDilution: () => Promise<boolean>;
  generateMastermixPdf: () => void;
  generateDilutionJSON: () => void;
}) => {
  const navigate = useNavigate();
  const handlePrintPdf = () => generateMastermixPdf();
  const handleGoToEditPage = useNavigateToEditPage();
  const { refetch } = useQueryContext();
  return (
    <>
      <OwcListItem
        data-testid="mastermix-details-page-more-menu-edit-btn"
        iconType="outlined"
        icon="edit"
        noBorder
        onClick={() => handleGoToEditPage(id, DilutionTypeValues.MASTERMIX)}
        disabled={pendingAction || status === "VERIFIED"}
      >
        {pendingAction && (
          <ButtonLoading testId="mastermix-details-page-more-menu-edit-btn-loading" />
        )}
        Edit
      </OwcListItem>
      <OwcListItem
        data-testid="mastermix-details-page-more-menu-print-btn"
        iconType="outlined"
        icon="upload"
        onClick={handlePrintPdf}
        noBorder
        disabled={pendingAction}
      >
        {pendingAction && (
          <ButtonLoading testId="mastermix-details-page-more-menu-print-btn-loading" />
        )}
        Export PDF
      </OwcListItem>
      <OwcListItem
        data-testid="mastermix-details-page-more-menu-export-json-btn"
        iconType="outlined"
        icon="upload"
        onClick={generateDilutionJSON}
        noBorder
        disabled={pendingAction}
      >
        {pendingAction && (
          <ButtonLoading testId="mastermix-details-page-more-menu-export-json-btn-loading" />
        )}
        Export JSON
      </OwcListItem>
      <OwcListItem
        data-testid="mastermix-details-page-more-menu-delete-btn"
        iconType="outlined"
        icon="delete"
        noBorder
        onClick={async () => {
          setMenuOpen(false);
          if (await deleteDilution()) {
            navigate("/");
          }
          refetch();
        }}
        disabled={pendingAction}
      >
        {pendingAction && (
          <ButtonLoading testId="mastermix-details-page-more-menu-delete-btn-loading" />
        )}
        Delete
      </OwcListItem>
    </>
  );
};
