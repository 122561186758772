import { DilutionDetails } from "./coform-models/DilutionDetails";
import { DilutionType } from "./DilutionType";
import { MastermixDetails } from "./mastermix-models/MastermixDetails";

export type StatusDilutionType = "CALCULATED" | "VERIFIED" | "DRAFT";

export const StatusDilutionTypeLabels: Record<StatusDilutionType, string> = {
  CALCULATED: "Calculated",
  VERIFIED: "Verified",
  DRAFT: "Draft",
};
export interface DilutionBase {
  id: string;
  type: DilutionType;
  name: string;
  elnId: string | null;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;
  createdBy?: string;
  location?: string;
  appVersion?: string;
  status?: StatusDilutionType | null;
}
export interface CoFormDilution extends DilutionBase {
  type: "SERIAL_DILUTION" | "CO_FORMULATED_DILUTION";
  dilutionDetails: DilutionDetails | null;
}
export interface MastermixDilution extends DilutionBase {
  type: "MASTERMIX";
  dilutionDetails: MastermixDetails | null;
}

export type Dilution = CoFormDilution | MastermixDilution;

export interface Calculated {
  //  # original non rounded value
  calculatedValue: string;
  // # formatted value
  displayedValue: string;
}
