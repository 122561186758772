import { OwcCard, OwcTypography } from "@one/react";
import clsx from "clsx";
import Grid from "../../../../components/Grid/Grid";
import { Diluent } from "../../../../models/Diluent";
import mastermixSharedStyles from "../MastermixDetailsPage.module.scss";
import WarningInfoSection from "../../../common-dilution/waring-section/WarningInfoSection";
import { getDilutionDetails, useDetailsInfo } from "./getDilutionDetails";

const MastermixDetailsPageReagentsDiluent = ({
  diluent,
}: {
  diluent?: Diluent;
}) => {
  const detailsInfo = useDetailsInfo();
  if (!diluent) {
    return null;
  }
  const { hasWarningValue } = getDilutionDetails(diluent);
  return (
    <OwcCard
      className={mastermixSharedStyles.card}
      data-testid={`mastermix-details-page-diluent-section`}
    >
      <div className={mastermixSharedStyles.cardContent}>
        <OwcTypography
          className={mastermixSharedStyles.cardTitle}
          data-testid="mastermix-details-page-diluent-title"
          variant="title5"
        >
          Diluent
        </OwcTypography>

        <div
          className={clsx(mastermixSharedStyles.grid, {
            [mastermixSharedStyles.gridBlue]: !hasWarningValue,
            [mastermixSharedStyles.warning]: hasWarningValue,
          })}
        >
          <Grid columnGap={16} rowGap={4} columns={16} container>
            <Grid item columnSpan={7}>
              <OwcTypography
                data-testid={`mastermix-details-page-diluent-name-title`}
                variant="caption"
              >
                Diluent name
              </OwcTypography>
            </Grid>
            <Grid item columnSpan={3}>
              <OwcTypography
                data-testid={`mastermix-details-page-diluent-lot-number-title`}
                variant="caption"
              >
                Lot number
              </OwcTypography>
            </Grid>
            <Grid item columnSpan={3}>
              <OwcTypography
                data-testid={`mastermix-details-page-diluent-internal-lot-number-title`}
                variant="caption"
              >
                Internal lot number
              </OwcTypography>
            </Grid>
            <Grid item columnSpan={3}>
              <OwcTypography
                className={mastermixSharedStyles.toRight}
                data-testid={`mastermix-details-page-diluent-volume-title`}
                variant="caption"
              >
                Volume
              </OwcTypography>
            </Grid>
            <Grid item columnSpan={7}>
              <OwcTypography
                data-testid={`mastermix-details-page-diluent-name-value`}
                variant="subtitle1"
              >
                {diluent.stockName}
              </OwcTypography>
            </Grid>
            <Grid item columnSpan={3}>
              <OwcTypography
                data-testid={`mastermix-details-page-diluent-lot-number-value`}
                variant="subtitle1"
              >
                {diluent.lotNumber}
              </OwcTypography>
            </Grid>
            <Grid item columnSpan={3}>
              <OwcTypography
                data-testid={`mastermix-details-page-diluent-internal-lot-number-value`}
                variant="subtitle1"
              >
                N/A
              </OwcTypography>
            </Grid>
            <Grid item columnSpan={3}>
              <OwcTypography
                className={clsx(
                  mastermixSharedStyles.volume,
                  mastermixSharedStyles.toRight,
                  {
                    [mastermixSharedStyles.warningAccent]: hasWarningValue,
                  }
                )}
                data-testid={`mastermix-details-page-diluent-volume-value`}
                variant="subtitle1"
              >
                {diluent.neededVolume?.displayedValue} µL
              </OwcTypography>
            </Grid>
          </Grid>
        </div>
        {hasWarningValue && <WarningInfoSection details={detailsInfo} />}
      </div>
    </OwcCard>
  );
};

export default MastermixDetailsPageReagentsDiluent;
