import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../../models/Dilution";
import { getReactionVolume } from "../utils/getReactionVolume";

export const useUpdateReactionVolume = () => {
  const { getValues, setValue, formState } =
    useFormContext<MastermixDilution>();

  return useCallback(() => {
    const r1Volume = getValues(`dilutionDetails.r1Volume`);
    const r2Volume = getValues(`dilutionDetails.r2Volume`);
    const eluateVolume = getValues(`dilutionDetails.eluateVolume`);
    const reactionVolume = getReactionVolume(r1Volume, r2Volume, eluateVolume);
    setValue(`dilutionDetails.reactionVolume`, reactionVolume, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: formState.submitCount > 0,
    });
  }, [getValues, setValue, formState]);
};
