import { OwcButton, OwcIcon } from "@one/react";
import { useFormContext } from "react-hook-form";
import ButtonLoading from "../../../../../components/button-loading/ButtonLoading";
import button from "../../../../../scss/button/Button.module.scss";
import { HighLevelMaterialDilution } from "../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { useHasDilutionFactorError } from "./hooks/useHasDilutionFactorError";
import styles from "./PanelMemberForm.module.scss";
import { SaveDraft } from "./SaveDraft";

const PanelMemberButtons = ({
  onBack,
  loading,
}: {
  onBack: (currentDilution: HighLevelMaterialDilution) => void;
  loading: boolean;
}) => {
  const methods = useFormContext<HighLevelMaterialDilution>();

  const hasDilutionFactorError = useHasDilutionFactorError(methods);
  return (
    <div className={styles.buttons}>
      <OwcButton
        data-testid="serial-dilution-panel-members-go-back-btn"
        onClick={() => onBack(methods.getValues())}
        className={button.large}
        flat
        variant="secondary"
        disabled={loading}
      >
        {loading && (
          <ButtonLoading testId="serial-dilution-panel-members-go-back-btn-loading" />
        )}
        <div className={button.iconWrapper}>
          <OwcIcon name="arrow_leftwards" color="inherit" />
          Go back
        </div>
      </OwcButton>
      <SaveDraft step="second" />
      <OwcButton
        className={button.large}
        flat
        type="submit"
        data-testid="serial-dilution-panel-members-calc-save-btn"
        disabled={loading || hasDilutionFactorError}
      >
        {loading && (
          <ButtonLoading testId="serial-dilution-panel-members-calc-save-btn-loading" />
        )}
        Calculate & Save
      </OwcButton>
    </div>
  );
};

export default PanelMemberButtons;
