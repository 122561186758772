import { useContext } from "react";
import { AppVersionsContext } from "./AppVersionsContext";

export const useAppVersions = () => {
  const context = useContext(AppVersionsContext);

  if (!context) {
    throw new Error(
      "You cannot use useAppVersions outside AppVersionsWrapper!"
    );
  }

  return context;
};
