import { DiluentInput } from "../../../../models/DiluentInput";
import { MastermixDilution } from "../../../../models/Dilution";
import { MastermixDilutionInput } from "../../../../models/DilutionInput";
import { Material } from "../../../../models/coform-models/Material";
import { MaterialInput } from "../../../../models/coform-models/MaterialInput";
import { PreDilution } from "../../../../models/coform-models/PreDilution";
import { MastermixDetailsInput } from "../../../../models/mastermix-models/MastermixDetailsInput";
import { toFixed } from "../../../../utils/helpers/toFixed";
import { CONCENTRATION_LIMIT_COMMA } from "../../../common-dilution/constants";
import { getPreDilutionInputConcentration } from "../../../common-dilution/serial-and-co-formulated-dilution/forms/HighLevelMaterialInfoForm/utils/getPreDilutionInputConcentration";
import { getDiluentId } from "../../../common-dilution/serial-and-co-formulated-dilution/utils/getDiluentId";
import { getDiluentsArray } from "./getDiluentsArray";

const materialToInput = ({
  neededVolume,
  highLevelInfo,
  ...material
}: Material): MaterialInput => ({
  ...material,
});

const alignPreDilutionSteps = (
  preDilution: PreDilution
): PreDilution | null => {
  if (!preDilution || !preDilution.dilutionSteps) {
    return null;
  }

  return {
    ...preDilution,
    dilutionSteps: preDilution.dilutionSteps.map((step, stepIndex) => {
      const inputConcentration = getPreDilutionInputConcentration(
        stepIndex,
        preDilution,
        preDilution?.dilutionSteps ?? []
      );
      const input = Number(inputConcentration.calculatedValue);
      const target = Number(step.targetConcentration!.calculatedValue);
      const factorCalulated = input / target;
      const factor =
        factorCalulated !== null
          ? {
              calculatedValue: toFixed(factorCalulated, { roundTo: null }),
              displayedValue: toFixed(factorCalulated, { roundTo: 2 }),
            }
          : null;
      return {
        ...step,
        inputConcentration,
        dilutionFactor: factor,
      };
    }),
  };
};

export const preDilutionToPreDilutionInput = (
  preDilution: PreDilution | null
) =>
  preDilution
    ? {
        ...preDilution,
        inputMaterial: materialToInput(preDilution.inputMaterial),
        dilutionSteps:
          alignPreDilutionSteps(preDilution)?.dilutionSteps?.map((step) => {
            const { neededVolume: _, ...stepDiluent } = step.diluent;
            return {
              deadVolume: step.deadVolume,
              diluent: stepDiluent,
              dilutionFactor: step.dilutionFactor,
              inputConcentration: {
                calculatedValue: step.inputConcentration?.calculatedValue!,
                displayedValue: toFixed(
                  Number(step.inputConcentration?.displayedValue!),
                  {
                    roundTo: CONCENTRATION_LIMIT_COMMA,
                    exponential: step.inputConcentration?.displayedValue
                      .toLowerCase()
                      .includes("e"),
                  }
                ),
              },
              stepName: step.stepName,
              stepNumber: step.stepNumber,
              targetConcentration: step.targetConcentration,
            };
          }) ?? null,
      }
    : null;

export const mastermixDilutionToDilutionInput = (
  values: MastermixDilution
): MastermixDilutionInput => {
  const { id, dilutionDetails, ...dilution } = values;
  if (!dilutionDetails) {
    return {
      elnId: dilution.elnId,
      name: dilution.name,
      type: dilution.type,
      dilutionDetails: null,
      mastermixDetails: null,
      id: id ? id : null,
    };
  }

  const { neededVolume, ...diluent } = dilutionDetails.diluent!;
  const groups = dilutionDetails.groups!.map(({ totalVolume, ...group }) => ({
    ...group,
    reagents: group.reagents!.map(
      ({
        pcrVolume,
        material: { neededVolume: _, ...material },
        preDilution,
        ...reagent
      }) => ({
        ...reagent,
        material,
        preDilution: preDilutionToPreDilutionInput(preDilution),
      })
    ),
  }));
  const diluentInput = {
    ...diluent,
    id: getDiluentId({
      number: diluent.number,
      lotNumber: diluent.lotNumber,
    }),
  } as DiluentInput;
  const dilutionDetailsInput: MastermixDetailsInput = {
    ...dilutionDetails,
    diluent: diluentInput,
    groups,
    preDiluents: getDiluentsArray(diluentInput, groups),
  };

  return {
    elnId: dilution.elnId,
    name: dilution.name,
    type: dilution.type,
    mastermixDetails: dilutionDetailsInput,
    id: id ? id : null,
    dilutionDetails: null,
  };
};
