/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { numberKeepStringValidator } from "../../../../../utils/helpers/numberKeepStringValidator";
import {
  eluateVolumeNotEmpty,
  r1VolumeNotEmpty,
  r2VolumeNotEmpty,
} from "../../mastermixSchema/mastermixSchemaNotEmpty";
import {
  eluateVolumeMax,
  eluateVolumeMin,
  r1VolumeMax,
  r1VolumeMin,
  r2VolumeMax,
  r2VolumeMin,
} from "../../mastermixSchema/maxtermixSchemaMinMaxValue";

export const reactionVolumeInputsSchema = yup.object({
  r1Volume: yup
    .string()
    .typeError(r1VolumeNotEmpty)
    .test(
      numberKeepStringValidator(
        yup
          .number()
          .required(r1VolumeNotEmpty)
          .typeError(r1VolumeNotEmpty)
          .min(r1VolumeMin, "R1 must be greater than or equal to ${min}")
          .max(r1VolumeMax, "R1 must be less than or equal to ${max}")
      )
    ),
  r2Volume: yup
    .string()
    .typeError(r2VolumeNotEmpty)
    .test(
      numberKeepStringValidator(
        yup
          .number()
          .required(r2VolumeNotEmpty)
          .typeError(r2VolumeNotEmpty)
          .min(r2VolumeMin, "R2 must be greater than or equal to ${min}")
          .max(r2VolumeMax, "R2 must be less than or equal to ${max}")
      )
    ),
  eluateVolume: yup
    .string()
    .typeError(eluateVolumeNotEmpty)
    .test(
      numberKeepStringValidator(
        yup
          .number()
          .required(eluateVolumeNotEmpty)
          .typeError(eluateVolumeNotEmpty)
          .min(
            eluateVolumeMin,
            "Eluate must be greater than or equal to ${min}"
          )
          .max(eluateVolumeMax, "Eluate must be less than or equal to ${max}")
      )
    ),
});
