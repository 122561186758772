import { v4 } from "uuid";
import { HighLevelMaterialDilution } from "../../features/common-dilution/serial-and-co-formulated-dilution/forms/HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { CoFormDilution } from "../../models/Dilution";

const DEFAULT_REPLICATES_PER_PANEL_MEMBER = 1;

export const getHighLevelMaterialDilution = (
  dilution: CoFormDilution
): HighLevelMaterialDilution => {
  const replicatesPerPanelMember = dilution.dilutionDetails?.panelMembers
    ? dilution.dilutionDetails.panelMembers.reduce(
        (acc, member) =>
          member.numberOfReplicates === acc
            ? acc
            : DEFAULT_REPLICATES_PER_PANEL_MEMBER,
        dilution.dilutionDetails.panelMembers[0]?.numberOfReplicates ??
          DEFAULT_REPLICATES_PER_PANEL_MEMBER
      )
    : DEFAULT_REPLICATES_PER_PANEL_MEMBER;

  return {
    ...dilution,
    panelMembers:
      dilution.dilutionDetails?.panelMembers.filter(
        ({ isNegative }) => isNegative !== true
      ).length ?? 1,
    replicatesPerPanelMember,
    volumeOfReplicate:
      dilution.dilutionDetails?.panelMembers[0]?.replicateVolume ?? null,
    deadVolume: dilution.dilutionDetails?.panelMembers[0]?.deadVolume ?? null,
    dilutionDetails: dilution.dilutionDetails
      ? {
          ...dilution.dilutionDetails,
          inputMaterials: dilution.dilutionDetails.inputMaterials.map(
            (material, index) => ({
              ...material,
              highLevelInfo: {
                numberOfPreDilutions:
                  dilution.dilutionDetails?.preDilutions?.[index]?.dilutionSteps
                    ?.length ?? 1,
              },
              id: material.id ?? v4(),
            })
          ),
        }
      : null,
  };
};
