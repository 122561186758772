import { useCallback } from "react";
import { useSnackbar } from "../../components/shared/snackbar/useSnackbar";

export const useRunGraphQlWithSnackbars = ({
  errorMessage,
  successMessage,
}: {
  errorMessage: string;
  successMessage: string;
}) => {
  const addSnackbar = useSnackbar();

  return useCallback(
    async <T, U>(
      fn: () => Promise<T>,
      catchFn?: () => Promise<U> | U,
      finallyFn?: () => void
    ) => {
      try {
        const result = await fn();
        addSnackbar({
          message: successMessage,
          severity: "success",
        });

        return result;
      } catch (e) {
        if (isGraphQLError(e)) {
          addSnackbar({
            message: e.graphQLErrors[0].message,
            severity: "error",
          });
        } else {
          addSnackbar({
            message: errorMessage,
            severity: "error",
          });
        }
        return (await catchFn?.()) as U;
      } finally {
        finallyFn?.();
      }
    },
    [addSnackbar, errorMessage, successMessage]
  );
};
const isGraphQLErrorInternal = (
  e: unknown
): e is Error & { graphQLErrors: unknown[] } => {
  return (
    e instanceof Error &&
    "graphQLErrors" in e &&
    Boolean(e["graphQLErrors"]) &&
    Array.isArray(e["graphQLErrors"])
  );
};

const isMessageObject = (
  value: unknown
): value is object & Record<"message", unknown> => {
  return value !== null && typeof value === "object" && "message" in value;
};

function isGraphQLError(
  e: unknown
): e is Error & { graphQLErrors: { message: string }[] } {
  return (
    isGraphQLErrorInternal(e) &&
    Boolean(e.graphQLErrors.length) &&
    isMessageObject(e.graphQLErrors[0]) &&
    e.graphQLErrors[0].message !== "string"
  );
}
