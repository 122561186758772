const RocheLogoSmall = () => {
  return (
    <svg
      data-testid="roche-logo-small"
      width="48"
      height="26"
      viewBox="0 0 48 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_473_1101)">
        <path
          d="M32.5679 10.3001C32.268 10.1001 31.768 9.9001 31.268 9.9001C30.768 9.9001 30.268 10.1001 29.968 10.3001V7.1001H28.368V17.6001H29.968V12.1001C29.968 11.7001 30.268 11.3001 30.868 11.3001C31.168 11.3001 31.468 11.4001 31.668 11.7001C31.868 12.0001 31.868 12.4001 31.868 12.8001V17.6001H33.468V12.7001C33.468 11.4001 33.3679 10.9001 32.5679 10.3001Z"
          fill="#0066B7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7239 13.9C19.7239 14.9 19.7239 15.2 19.7239 15.3C19.624 15.9 19.424 16.5 18.7239 16.5C18.0239 16.5 17.8239 15.9 17.7239 15.3C17.7239 15.3 17.7239 15 17.7239 13.9C17.7239 12.9 17.7239 12.4 17.7239 12.4C17.7239 11.8 18.0239 11.2 18.7239 11.2C19.424 11.2 19.7239 11.8 19.7239 12.3C19.7239 12.5 19.7239 12.9 19.7239 13.9ZM21.324 12.7C21.124 10.5 19.824 10 18.824 10C17.324 10 16.424 10.9 16.324 12.7C16.324 12.8 16.324 13.1 16.324 13.9C16.324 14.5 16.324 15 16.324 15.1C16.424 16.8 17.424 17.8 18.824 17.8C20.324 17.8 21.224 16.8 21.324 15.1C21.324 15 21.324 14.5 21.324 13.9C21.324 13.6 21.324 12.9 21.324 12.7Z"
          fill="#0066B7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.139 13.0001C36.139 12.7001 36.139 12.6001 36.139 12.5001C36.139 11.9001 36.439 11.4001 37.139 11.4001C37.939 11.4001 38.039 12.1001 38.139 12.6001C38.139 12.8001 38.139 13.0001 38.139 13.1001H36.139M38.039 15.1001C38.039 15.1001 38.039 15.2001 38.039 15.4001C38.039 15.8001 37.839 16.6001 37.039 16.6001C36.339 16.6001 36.139 16.0001 36.039 15.4001C36.039 15.2001 36.039 15.0001 36.039 14.4001H39.539C39.539 14.4001 39.539 13.9001 39.539 13.6001C39.539 13.5001 39.539 13.3001 39.539 12.8001C39.539 12.1001 39.239 11.4001 38.839 10.9001C38.439 10.4001 37.739 10.1001 37.039 10.1001C35.639 10.1001 34.739 11.0001 34.539 12.8001C34.539 13.0001 34.539 13.2001 34.539 14.0001C34.539 14.6001 34.539 15.1001 34.539 15.2001C34.639 16.8001 35.639 17.9001 37.039 17.9001C38.439 17.9001 39.439 16.9001 39.539 15.1001H38.039Z"
          fill="#0066B7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.946 1.5L46.805 13.1L36.946 24.5H11.1535L1.29449 13L11.1535 1.49999H36.946V1.5ZM37.2448 0.5H10.7552L0 13L10.7552 25.5H37.2448L48 13.1L37.2448 0.5Z"
          fill="#0066B7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4531 8.60021H12.253C13.053 8.60021 13.553 9.00021 13.553 9.80021C13.553 9.90021 13.553 9.90021 13.553 10.0002C13.553 10.1002 13.553 10.2002 13.553 10.2002C13.553 10.7002 13.353 11.5002 12.353 11.5002H11.553V8.60022M15.253 17.0002L15.053 14.4002C14.9531 13.3002 14.653 12.6002 14.053 12.2002C14.4531 11.9002 15.053 11.3002 15.053 10.0002C15.053 8.0002 13.753 7.3002 12.253 7.2002H9.85303V17.6002H11.453V12.9002H12.353C12.853 12.9002 13.453 13.3002 13.553 14.6002L13.753 17.0002C13.753 17.3002 13.853 17.6002 13.853 17.6002H15.453C15.3531 17.7002 15.2531 17.4002 15.2531 17.0002L15.253 17.0002Z"
          fill="#0066B7"
        />
        <path
          d="M25.895 14.9998C25.895 15.0998 25.895 15.1998 25.895 15.2998C25.895 15.8998 25.695 16.4998 24.895 16.4998C24.195 16.4998 23.995 15.7998 23.895 15.2998C23.895 14.9998 23.895 14.7998 23.895 13.8998C23.895 12.8998 23.895 12.5998 23.895 12.3998C23.995 11.7998 24.195 11.2998 24.895 11.1998C25.595 11.1998 25.895 11.7998 25.895 12.2998C25.895 12.2998 25.895 12.3998 25.895 12.6998H27.495C27.495 12.5998 27.495 12.4998 27.495 12.4998C27.295 10.2998 25.995 9.7998 24.995 9.7998C23.495 9.7998 22.595 10.6998 22.495 12.4998C22.495 12.5998 22.495 12.8998 22.495 13.6998C22.495 14.2998 22.495 14.7998 22.495 14.8998C22.595 16.5998 23.595 17.5998 24.995 17.5998C26.495 17.5998 27.395 16.6998 27.495 14.8998C27.495 14.7998 27.495 14.7998 27.495 14.6998H25.895"
          fill="#0066B7"
        />
      </g>
      <defs>
        <clipPath id="clip0_473_1101">
          <rect
            width="48"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RocheLogoSmall;
