import { OwcButton, OwcCard, OwcTypography } from "@one/react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import Container from "../../components/Container/Container";
import TestTubs from "../../components/pic/TestTubs";
import buttonStyles from "../../scss/button/Button.module.scss";
import styles from "./ExperimentTypePage.module.scss";

const ExperimentTypePage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <div className={styles.cardWrapper}>
        <OwcCard>
          <div data-testid="ExperimentTypePage" className={styles.card}>
            <TestTubs />
            <OwcTypography
              data-testid="ExperimentTypePage-title"
              className={styles.title}
              variant="title5"
            >
              What type of dilution you <br /> would like to create?
            </OwcTypography>
            <OwcButton
              flat
              className={clsx(buttonStyles.large, buttonStyles.fullWidth)}
              onClick={() => navigate("/serial")}
              data-testid="ExperimentTypePage-serial-btn"
            >
              Serial Dilution (by concentration)
            </OwcButton>
            <OwcButton
              flat
              className={clsx(buttonStyles.large, buttonStyles.fullWidth)}
              onClick={() => navigate("/co-formulated")}
              data-testid="ExperimentTypePage-co-form-btn"
            >
              Co-formulated Dilution
            </OwcButton>
            <OwcButton
              flat
              className={clsx(buttonStyles.large, buttonStyles.fullWidth)}
              onClick={() => navigate("/mastermix")}
              data-testid="ExperimentTypePage-form-btn"
            >
              Mastermix (MMx)
            </OwcButton>
          </div>
          <OwcButton
            slot="footer"
            flat
            className={clsx(buttonStyles.large)}
            data-testid="ExperimentTypePage-cancel-btn"
            onClick={() => navigate("/")}
            variant="secondary"
            style={{ alignSelf: "flex-end" }}
          >
            Cancel
          </OwcButton>
        </OwcCard>
      </div>
    </Container>
  );
};

export default ExperimentTypePage;
