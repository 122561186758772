import { BrowserRouter } from "react-router-dom";
import App from "./App";
import AppVersionsWrapper from "./components/app-version/context/AppVersionsWrapper";
import ConfirmContextWrapper from "./components/confirm-context/ConfirmContextWrapper";
import SnackbarWrapper from "./components/shared/snackbar/SnackbarWrapper";
import ThemeProvider from "./components/ThemeProvider";

//#endregion
const Main = () => {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <ConfirmContextWrapper>
          <SnackbarWrapper>
            <AppVersionsWrapper>
              <App />
            </AppVersionsWrapper>
          </SnackbarWrapper>
        </ConfirmContextWrapper>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default Main;
