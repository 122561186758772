import { yupResolver } from "@hookform/resolvers/yup";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import Dialog, { DialogProps } from "../../../../../components/shared/Dialog";
import ReactionVolumeInputsDialogContent from "./ReactionVolumeInputsDialogContent";
import { ReactionVolumeInputsForm } from "./ReactionVolumeInputsForm";
import { reactionVolumeInputsSchema } from "./reactionVolumeInputsSchema";

interface ReactionVolumeInputsDialogProps {
  open: boolean;
  close: () => void;
  onConfirm: (values: ReactionVolumeInputsForm) => void;
  details: ReactionVolumeInputsForm;
}

const ReactionVolumeInputsDialog = ({
  open,
  close,
  onConfirm,
  details,
}: ReactionVolumeInputsDialogProps) => {
  const methods = useForm<ReactionVolumeInputsForm>({
    defaultValues: details,
    resolver: yupResolver(reactionVolumeInputsSchema),
  });
  const handleCancel = () => {
    methods.reset();
    close();
  };
  const handleConfirm = (values: ReactionVolumeInputsForm) => {
    onConfirm(values);
    methods.reset(values);
    close();
  };
  const formId = "reaction-volume-inputs-dialog-form";
  const buttons: DialogProps["buttons"] = [
    {
      closeOnClick: true,
      label: "Cancel",
      buttonProps: {
        variant: "secondary",
        flat: true,
      },
    },
    {
      closeOnClick: false,
      label: "Confirm",
      buttonProps: {
        type: "submit",
        flat: true,
        ...{ form: formId },
      },
    },
  ];
  return createPortal(
    <FormProvider {...methods}>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          methods.handleSubmit((values) => {
            if (methods.formState.isDirty) {
              handleConfirm(values);
            } else {
              handleCancel();
            }
          })(event);
        }}
        id={formId}
      >
        <Dialog
          disableBackdropClick
          disableEscapeKeydown
          title={`You are editing R1, R2 and Eluate volumes - inputs to reaction volume`}
          buttons={buttons}
          onClose={handleCancel}
          open={open}
        >
          <ReactionVolumeInputsDialogContent />
        </Dialog>
      </form>
    </FormProvider>,
    document.body
  );
};

export default ReactionVolumeInputsDialog;
