import { Material } from "../../../../../../models/coform-models/Material";
import { PanelMember } from "../../../../../../models/coform-models/PanelMember";
import { PreDilution } from "../../../../../../models/coform-models/PreDilution";
import { Calculated } from "../../../../../../models/Dilution";
import { toFixed } from "../../../../../../utils/helpers/toFixed";
import { CONCENTRATION_LIMIT_COMMA } from "../../../../constants";

export const getPanelMemberInputConcentration = (
  panelMemberIndex: number,
  materialIndex: number,
  preDilutions: PreDilution[],
  panelMembers: PanelMember[],
  material: Material
): Calculated => {
  if (panelMemberIndex === 0) {
    const preDilution = preDilutions.find(
      (thePreDilution) => thePreDilution.inputMaterial.id === material.id
    );
    const dilutionSteps = preDilution?.dilutionSteps;
    if (dilutionSteps?.length) {
      return dilutionSteps.at(-1)!.targetConcentration!;
    } else {
      return {
        calculatedValue: toFixed(
          Number(material.stockConcentration) * Number(material.unitFactor),
          {
            exponential:
              material.stockConcentration!.toLowerCase().includes("e") ||
              material.unitFactor!.toLowerCase().includes("e"),
            roundTo: null,
          }
        ),
        displayedValue: toFixed(
          Number(material.stockConcentration) * Number(material.unitFactor),
          {
            exponential:
              material.stockConcentration!.toLowerCase().includes("e") ||
              material.unitFactor!.toLowerCase().includes("e"),
            roundTo: CONCENTRATION_LIMIT_COMMA,
          }
        ),
      };
    }
  } else {
    return panelMembers[panelMemberIndex - 1].targets![materialIndex]
      .targetConcentration!;
  }
};
