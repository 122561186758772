export const limitComma = (limit: number, errorMessage: string) => {
  return {
    message: errorMessage,
    test: (value: string | undefined | null) => {
      if (
        value &&
        !isNaN(Number(value)) &&
        (value.toLowerCase().includes("e") ? `${Number(value)}` : value).split(
          /[.,]/
        )[1]?.length > limit
      ) {
        return false;
      }

      return true;
    },
  };
};
