import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Calculated } from "../../../../../../../models/Dilution";
import { toFixed } from "../../../../../../../utils/helpers/toFixed";
import { HighLevelMaterialDilution } from "../../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { CONCENTRATION_LIMIT_COMMA } from "../../../../../constants";

export function useUpdateFirstPanelMemberTarget(
  panelMemberIndex: number,
  targetIndex: number
) {
  const { watch, setValue } = useFormContext<HighLevelMaterialDilution>();
  const targetMaterialId = watch(
    `dilutionDetails.panelMembers.${panelMemberIndex}.targets.${targetIndex}.material.id`
  );
  const preDilutions = watch(`dilutionDetails.preDilutions`);
  const inputMaterialsLength =
    watch(`dilutionDetails.inputMaterials`)?.length ?? 0;

  return useCallback(
    (value: Calculated) => {
      const preDilutionIndex = (preDilutions ?? []).findIndex(
        (item) => item.inputMaterial.id === targetMaterialId
      );
      if (preDilutionIndex === -1) {
        return;
      }

      const preDilution = (preDilutions ?? [])[preDilutionIndex];

      if (
        !preDilution.dilutionSteps ||
        preDilution.dilutionSteps.length === 0
      ) {
        return;
      }
      const { dilutionSteps } = preDilution;

      if (value.calculatedValue === "") {
        setValue(
          `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${
            dilutionSteps.length - 1
          }.targetConcentration`,
          {
            calculatedValue: "",
            displayedValue: "",
          }
        );
        return;
      }

      const numberValue = Number(value.calculatedValue);

      /* istanbul ignore next */
      if (isNaN(numberValue)) {
        return;
      }

      setValue(
        `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${
          dilutionSteps.length - 1
        }.targetConcentration`,
        {
          calculatedValue: toFixed(numberValue * inputMaterialsLength, {
            exponential: value.calculatedValue.toLowerCase().includes("e"),
            roundTo: null,
          }),
          displayedValue: toFixed(numberValue * inputMaterialsLength, {
            exponential: value.calculatedValue.toLowerCase().includes("e"),
            roundTo: CONCENTRATION_LIMIT_COMMA,
          }),
        }
      );
    },
    [inputMaterialsLength, preDilutions, setValue, targetMaterialId]
  );
}
