const RocheLogoBig = () => {
  return (
    <svg
      data-testid="roche-logo-big"
      width="192"
      height="100"
      viewBox="0 0 192 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_490_9817)">
        <path
          d="M130.272 39.1999C129.072 38.3999 127.072 37.5999 125.072 37.5999C123.072 37.5999 121.072 38.3999 119.872 39.1999V26.3999H113.472V68.3999H119.872V46.3999C119.872 44.7999 121.072 43.1999 123.472 43.1999C124.672 43.1999 125.872 43.5999 126.672 44.7999C127.472 45.9999 127.472 47.5999 127.472 49.1999V68.3999H133.872V48.7999C133.872 43.5999 133.472 41.5999 130.272 39.1999Z"
          fill="#0066B7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.8959 53.6C78.8959 57.6 78.8959 58.8 78.8959 59.2C78.496 61.6 77.6959 64 74.8959 64C72.0958 64 71.2958 61.5999 70.8959 59.2C70.8959 59.2 70.8959 58 70.8959 53.6C70.8959 49.6 70.8959 47.6 70.8959 47.6C70.8959 45.2 72.0958 42.8 74.8959 42.8C77.6959 42.8 78.8959 45.2 78.8959 47.2C78.8959 48 78.8959 49.6 78.8959 53.6ZM85.296 48.8C84.496 40 79.296 38 75.296 38C69.296 38 65.6959 41.6 65.296 48.8C65.296 49.2 65.296 50.4 65.296 53.6C65.296 56 65.296 58 65.296 58.4C65.6959 65.2 69.6959 69.2 75.296 69.2C81.296 69.2 84.8961 65.2 85.296 58.4C85.296 58 85.296 56 85.296 53.6C85.296 52.4 85.296 49.6 85.296 48.8Z"
          fill="#0066B7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.556 49.9999C144.556 48.7999 144.556 48.3999 144.556 47.9999C144.556 45.5999 145.756 43.5999 148.556 43.5999C151.756 43.5999 152.156 46.3999 152.556 48.3999C152.556 49.1999 152.556 49.9999 152.556 50.3999H144.556M152.156 58.3999C152.156 58.3999 152.156 58.7999 152.156 59.5999C152.156 61.1999 151.356 64.3999 148.156 64.3999C145.356 64.3999 144.556 61.9999 144.156 59.5999C144.156 58.7999 144.156 57.9999 144.156 55.5999H158.156C158.156 55.5999 158.156 53.5999 158.156 52.3999C158.156 51.9999 158.156 51.1999 158.156 49.1999C158.156 46.3999 156.956 43.5999 155.356 41.5999C153.756 39.5999 150.956 38.3999 148.156 38.3999C142.556 38.3999 138.956 41.9999 138.156 49.1999C138.156 49.9999 138.156 50.7999 138.156 53.9999C138.156 56.3999 138.156 58.3999 138.156 58.7999C138.556 65.1999 142.556 69.5999 148.156 69.5999C153.756 69.5999 157.756 65.5999 158.156 58.3999H152.156Z"
          fill="#0066B7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M147.784 4L187.22 50.4L147.784 96H44.614L5.17796 50L44.614 3.99996H147.784V4ZM148.979 0H43.0208L0 50L43.0208 100H148.979L192 50.4L148.979 0Z"
          fill="#0066B7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.8121 32.3998H49.0121C52.212 32.3998 54.212 33.9998 54.212 37.1998C54.212 37.5998 54.212 37.5998 54.212 37.9998C54.212 38.3998 54.212 38.7998 54.212 38.7998C54.212 40.7998 53.412 43.9998 49.412 43.9998H46.212V32.3999M61.0121 65.9998L60.212 55.5998C59.8121 51.1998 58.612 48.3998 56.212 46.7998C57.8121 45.5998 60.212 43.1998 60.212 37.9998C60.212 29.9998 55.0121 27.1998 49.0121 26.7998H39.412V68.3998H45.8119V49.5998H49.412C51.412 49.5998 53.8119 51.1998 54.212 56.3998L55.0121 65.9998C55.0121 67.1998 55.412 68.3998 55.412 68.3998H61.8119C61.4123 68.7998 61.0121 67.5998 61.0121 65.9998L61.0121 65.9998Z"
          fill="#0066B7"
        />
        <path
          d="M103.58 58.0002C103.58 58.4002 103.58 58.8002 103.58 59.2002C103.58 61.6002 102.78 64.0002 99.5801 64.0002C96.78 64.0002 95.98 61.2002 95.5801 59.2002C95.5801 58.0002 95.5801 57.2002 95.5801 53.6002C95.5801 49.6002 95.5801 48.4002 95.5801 47.6002C95.98 45.2002 96.78 43.2002 99.5801 42.8002C102.38 42.8002 103.58 45.2002 103.58 47.2002C103.58 47.2002 103.58 47.6002 103.58 48.8002H109.98C109.98 48.4002 109.98 48.0002 109.98 48.0002C109.18 39.2002 103.98 37.2002 99.98 37.2002C93.98 37.2002 90.3799 40.8002 89.98 48.0002C89.98 48.4002 89.98 49.6002 89.98 52.8002C89.98 55.2002 89.98 57.2002 89.98 57.6002C90.3799 64.4002 94.3799 68.4002 99.98 68.4002C105.98 68.4002 109.58 64.8002 109.98 57.6002C109.98 57.2002 109.98 57.2002 109.98 56.8002H103.58"
          fill="#0066B7"
        />
      </g>
      <defs>
        <clipPath id="clip0_490_9817">
          <rect width="192" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RocheLogoBig;
