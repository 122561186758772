import { OwcCard, OwcTypography } from "@one/react";
import Grid from "../../../../components/Grid/Grid";
import { Material } from "../../../../models/coform-models/Material";
import styles from "./DilutionDetailsPageMaterialsSection.module.scss";
import { StockMaterialBlueGrid } from "./StockMaterialBlueGrid";
interface DilutionDetailsPageMaterialsSectionProps {
  materials: Material[];
}

const DilutionDetailsPageMaterialsSection = ({
  materials,
}: DilutionDetailsPageMaterialsSectionProps) => {
  return (
    <OwcCard
      className={styles.card}
      data-testid={`dilution-details-page-materials-section`}
    >
      <div className={styles.cardContent}>
        <OwcTypography
          data-testid={`dilution-details-page-materials-section-main-title`}
          className={styles.mainTitle}
          variant="title6"
        >
          Stock materials
        </OwcTypography>

        {materials.map((material, materialIndex) => {
          return (
            <div key={materialIndex} className={styles.material}>
              <StockMaterialBlueGrid
                materialIndex={materialIndex}
                nameLabel={"Stock material name"}
                nameValue={material.stockName!}
                lotNumber={material.lotNumber}
                neededVolume={material.neededVolume}
                testIdGrid="material"
              />
              <div className={styles.grid}>
                <Grid columnGap={16} rowGap={4} columns={16} container>
                  <Grid item columnSpan={8}>
                    <OwcTypography
                      data-testid={`dilution-details-page-materials-section-material-number-value-${materialIndex}`}
                      variant="caption"
                    >
                      Material number:{" "}
                      <span className={styles.gray800}>{material.number}</span>
                    </OwcTypography>
                  </Grid>
                  <Grid item columnSpan={8}>
                    <OwcTypography
                      data-testid={`dilution-details-page-materials-section-material-concentration-value-${materialIndex}`}
                      variant="caption"
                    >
                      Concentration:{" "}
                      <span className={styles.gray800}>
                        {material.stockConcentration} {material.stockUnit}
                      </span>
                    </OwcTypography>
                  </Grid>
                </Grid>
              </div>
            </div>
          );
        })}
      </div>
    </OwcCard>
  );
};

export default DilutionDetailsPageMaterialsSection;
