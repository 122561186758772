import { useCallback, useEffect, useState } from "react";
import { UseFieldArrayReturn, useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../../models/Dilution";
import { getPreDilutionStepName } from "../../../../common-dilution/utils/getPreDilutionStepName";

export const usePreDilutionStepInsert = ({
  groupIndex,
  reagentIndex,
  fieldArray,
}: {
  groupIndex: number;
  reagentIndex: number;
  fieldArray: UseFieldArrayReturn<
    MastermixDilution,
    `dilutionDetails.groups.${typeof groupIndex}.reagents.${typeof reagentIndex}.preDilution.dilutionSteps`,
    "id"
  >;
}) => {
  const { watch, setValue, getValues } = useFormContext<MastermixDilution>();
  const type = watch("type");
  const diluent = watch("dilutionDetails.diluent")!;
  const inputMaterial = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.inputMaterial`
  );
  const { insert } = fieldArray;
  const preDilutionStepValues = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps`
  );
  const [insertIndex, setInsertIndex] = useState(-1);

  useEffect(() => {
    if (insertIndex !== -1) {
      const newIndex = insertIndex + 1;
      insert(newIndex, {
        deadVolume: "",
        diluent,
        diluentVolume: null,
        dilutionFactor: {
          calculatedValue: "",
          displayedValue: "",
        },
        inputConcentration: {
          calculatedValue: "",
          displayedValue: "",
        },
        inputName: "",
        inputVolume: null,
        leftOver: null,
        stepName: getPreDilutionStepName(newIndex, inputMaterial, "stockName"),
        stepNumber: newIndex + 1,
        targetConcentration: {
          calculatedValue: "",
          displayedValue: "",
        },
        totalVolume: null,
      });
      setInsertIndex(-1);
    }
  }, [
    groupIndex,
    reagentIndex,
    insertIndex,
    setInsertIndex,
    insert,
    diluent,
    inputMaterial,
    getValues,
    type,
  ]);

  return useCallback(
    (index: number) => {
      preDilutionStepValues?.forEach(
        (preDilutionStep, preDilutionStepIndex) => {
          if (preDilutionStepIndex > index) {
            const defaultOldName = getPreDilutionStepName(
              preDilutionStepIndex,
              inputMaterial
            );
            const nextIndex = preDilutionStepIndex + 1;
            const stepName = getPreDilutionStepName(nextIndex, inputMaterial);
            if (defaultOldName === preDilutionStep.stepName) {
              setValue(
                `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${preDilutionStepIndex}.stepName`,
                stepName
              );
              preDilutionStep.stepName = stepName;
            }
            setValue(
              `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${preDilutionStepIndex}.stepNumber`,
              nextIndex + 1
            );
          }
        }
      );
      setInsertIndex(index);
    },
    [
      groupIndex,
      reagentIndex,
      setValue,
      inputMaterial,
      preDilutionStepValues,
      setInsertIndex,
    ]
  );
};
