import { Calculated } from "../../../../../models/Dilution";
import { toFixed } from "../../../../../utils/helpers/toFixed";

const Thousand = 1000;

const TotalVolumeRoundTo = 3;
export const getTotalVolume = (
  r2Volume: string,
  numberOfReactions: number | string | null
): Calculated => {
  if (
    numberOfReactions !== "0" &&
    numberOfReactions &&
    numberOfReactions !== "" &&
    r2Volume !== "" &&
    !isNaN(Number(numberOfReactions)) &&
    !isNaN(Number(r2Volume))
  ) {
    return {
      calculatedValue: toFixed(
        (Number(numberOfReactions) * Number(r2Volume)) / Thousand,
        {
          exponential: r2Volume.toLowerCase().includes("e"),
          roundTo: null,
        }
      ),
      displayedValue: toFixed(
        (Number(numberOfReactions) * Number(r2Volume)) / Thousand,
        {
          exponential: r2Volume.toLowerCase().includes("e"),
          roundTo: TotalVolumeRoundTo,
        }
      ),
    };
  }

  return {
    calculatedValue: "",
    displayedValue: "",
  };
};
