import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { getStringFieldInputProps } from "../../../../../../utils/helpers/getStringFieldInputProps";
import { useUpdateDiluentId } from "../../../utils/useUpdateDiluentId";
import { HighLevelMaterialDilution } from "../models/HighLevelMaterialDilution";

const DiluentNumberField = () => {
  const updateDiluentId = useUpdateDiluentId("dilutionDetails.diluents.0");
  return (
    <Controller<HighLevelMaterialDilution, "dilutionDetails.diluents.0.number">
      name="dilutionDetails.diluents.0.number"
      render={({ field, fieldState: { error } }) => {
        const props = getStringFieldInputProps(field);
        return (
          <OwcInput
            data-testid="dilution-diluentNumber-input"
            label="*Diluent mat. no."
            required
            {...props}
            onInputChange={(event) => {
              props.onInputChange(event);
              updateDiluentId();
            }}
            error={Boolean(error)}
          >
            <span slot="error-text" data-testid="dilution-diluentNumber-error">
              {error?.message}
            </span>
          </OwcInput>
        );
      }}
    />
  );
};

export default DiluentNumberField;
