import { Target } from "../../../../../../models/coform-models/Target";
import { Calculated } from "../../../../../../models/Dilution";
import { getPanelMemberInputConcentration } from "../../HighLevelMaterialInfoForm/utils/getPanelMemberInputConcentration";
import { PanelMemberFormContext } from "../PanelMemberFormContext";

export const getPanelMemberDetails = (
  target: Target,
  context: PanelMemberFormContext
) => {
  const panelMembers = context.getValues("dilutionDetails.panelMembers");
  const panelMemberIndex = panelMembers.findIndex((panelMember) =>
    panelMember.targets!.some((t) => t === target)
  );

  if (panelMemberIndex === -1) {
    return null;
  }

  return {
    panelMembers,
    panelMemberIndex,
    targets: panelMembers[panelMemberIndex].targets,
  };
};
export const getFactorDetails = (
  target: Target,
  result: Exclude<ReturnType<typeof getPanelMemberDetails>, null>,
  context: PanelMemberFormContext
) => {
  const targetConcentration = target.targetConcentration;

  const { panelMemberIndex, panelMembers, targets } = result;

  const targetIndex = targets?.indexOf(target) ?? -1;

  if (targetIndex === -1) {
    return null;
  }

  const inputConcentration = getPanelMemberInputConcentration(
    panelMemberIndex,
    targetIndex,
    context.getValues("dilutionDetails.preDilutions") ?? [],
    panelMembers,
    target.material
  );

  if (checkTargetAndInputValue(targetConcentration, inputConcentration)) {
    return null;
  }

  const targetConc = Number(targetConcentration.calculatedValue);
  const inputConc = Number(inputConcentration.calculatedValue);

  if (isNaN(targetConc) || isNaN(inputConc)) {
    return null;
  }

  const factor = targetConc === 0 ? null : inputConc / targetConc;

  if (factor === null) {
    return null;
  }

  return {
    factor,
    input: inputConcentration,
    target: targetConcentration,
  };
};
function checkTargetAndInputValue(
  targetConcentration: Calculated | null,
  inputConcentration: Calculated
): targetConcentration is null {
  return (
    !targetConcentration ||
    !targetConcentration.calculatedValue ||
    !inputConcentration ||
    !inputConcentration.calculatedValue
  );
}
