import { UseFormReturn } from "react-hook-form";
import { HighLevelMaterialDilution } from "../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { PANEL_MEMBER_DILUTION_FACTOR_ERROR } from "../schema/panelMemberSchema";

export const useHasDilutionFactorError = (
  methods: UseFormReturn<HighLevelMaterialDilution>
) => {
  return (
    methods.formState.errors.dilutionDetails?.panelMembers?.some?.((member) =>
      member?.targets?.some?.(
        (target) =>
          target?.targetConcentration?.type ===
          PANEL_MEMBER_DILUTION_FACTOR_ERROR
      )
    ) ?? false
  );
};
