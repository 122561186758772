import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../../models/Dilution";

export const useUpdateReagentsFinalAndR2Concentration = () => {
  const { getValues, setValue } = useFormContext<MastermixDilution>();

  return useCallback(() => {
    const groups = getValues(`dilutionDetails.groups`);
    groups?.forEach((group, groupIndex) =>
      group.reagents!.forEach((_, reagentIndex) => {
        setValue(
          `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.r2Concentration`,
          {
            calculatedValue: "",
            displayedValue: "",
          }
        );
        setValue(
          `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.pcrConcentration`,
          {
            calculatedValue: "",
            displayedValue: "",
          }
        );
      })
    );
  }, [getValues, setValue]);
};
