import Container from "../components/Container/Container";
import ValidationParamsWrapper from "../features/common-dilution/serial-and-co-formulated-dilution/forms/ValidationParamsContext/ValidationParamsWrapper";
import ManageMastermixForm from "../features/mastermix-dilution/manage-mastermix/ManageMastermixForm";
import { getEmptyMastermix } from "../features/mastermix-dilution/manage-mastermix/utils/getEmptyMasterMix";
import { useMastermixValidationParams } from "../features/hooks/useMastermixValidationParams";

const MastermixCreatePageView = () => {
  const mastermixDilution = getEmptyMastermix();
  const validationParamsValue = useMastermixValidationParams();
  return (
    <ValidationParamsWrapper value={validationParamsValue}>
      <Container>
        <ManageMastermixForm mastermixDilution={mastermixDilution} />
      </Container>
    </ValidationParamsWrapper>
  );
};

export default MastermixCreatePageView;
