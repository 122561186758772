import AppVersions from "../components/app-version/AppVersions";
import Container from "../components/Container/Container";

const AppVersionsPage = () => {
  return (
    <Container>
      <AppVersions />
    </Container>
  );
};

export default AppVersionsPage;
