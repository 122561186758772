import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { DiluentStepForm } from "../DiluentStepForm";
import { getStringFieldInputProps } from "../../../utils/helpers/getStringFieldInputProps";

const PreDilutionStepDiluentNameField = () => {
  return (
    <Controller<DiluentStepForm, "stockName">
      name="stockName"
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid="stockName-input"
          label="*Diluent name"
          required
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span slot="error-text" data-testid="stockName-error">
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default PreDilutionStepDiluentNameField;
