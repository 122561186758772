import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { MastermixDilution } from "../../../../models/Dilution";
import { getNumberFieldInputProps } from "../../../../utils/helpers/getNumberFieldInputProps";
import {
  numberOfReactionsMax,
  numberOfReactionsMin,
} from "../mastermixSchema/maxtermixSchemaMinMaxValue";
import { useUpdateTotalVolume } from "./hooks/useUpdateTotalVolume";

const NumberOfReactions = () => {
  const updateTotalVolume = useUpdateTotalVolume();

  return (
    <Controller<MastermixDilution, `dilutionDetails.numberOfReactions`>
      name={`dilutionDetails.numberOfReactions`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`dilutionDetails.numberOfReactions-input`}
          label="*Number of reactions"
          {...getNumberFieldInputProps(field)}
          type="number"
          error={Boolean(error)}
          required
          min={numberOfReactionsMin}
          max={numberOfReactionsMax}
          onInputChange={(event) => {
            field.onChange(event.detail);

            updateTotalVolume();
          }}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.numberOfReactions-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default NumberOfReactions;
