import { OwcInput } from "@one/react";
import { Controller, useFormContext } from "react-hook-form";
import OwcInputSuffix from "../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { getCalculatedOnChangeFieldInput } from "../../../../../utils/helpers/getCalculatedOnChangeFieldInput";
import { getCalculatedValueFieldInput } from "../../../../../utils/helpers/getCalculatedValueFieldInput";
import { useValidationParams } from "../../../../common-dilution/serial-and-co-formulated-dilution/forms/ValidationParamsContext/useValidationParams";
import { MastermixDilution } from "../../../../../models/Dilution";

interface PreDilutionTargetConcentrationFieldProps {
  groupIndex: number;
  reagentIndex: number;
  preDilutionStepIndex: number;
  preDilutionStepsCount: number;
}

const PreDilutionTargetConcentrationFieldInput = ({
  groupIndex,
  reagentIndex,
  preDilutionStepIndex,
}: PreDilutionTargetConcentrationFieldProps) => {
  const { watch } = useFormContext<MastermixDilution>();
  const targetUnit = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.inputMaterial.targetUnit`
  );
  const {
    preDilutionStepTargetConcentrationMin,
    preDilutionStepTargetConcentrationMax,
  } = useValidationParams();
  return (
    <Controller<
      MastermixDilution,
      `dilutionDetails.groups.${typeof groupIndex}.reagents.${typeof reagentIndex}.preDilution.dilutionSteps.${typeof preDilutionStepIndex}.targetConcentration`
    >
      name={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${preDilutionStepIndex}.targetConcentration`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${preDilutionStepIndex}.targetConcentration-input`}
          label="*Target concentration"
          type="number"
          {...field}
          value={getCalculatedValueFieldInput(field.value)}
          min={preDilutionStepTargetConcentrationMin}
          max={preDilutionStepTargetConcentrationMax}
          onInputChange={(event) => {
            getCalculatedOnChangeFieldInput(field.onChange)(event);
          }}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${preDilutionStepIndex}.targetConcentration-error`}
          >
            {error?.message}
          </span>
          <OwcInputSuffix>{targetUnit}</OwcInputSuffix>
        </OwcInput>
      )}
    />
  );
};

const PreDilutionTargetConcentrationField = ({
  groupIndex,
  reagentIndex,
  preDilutionStepIndex,
  preDilutionStepsCount,
}: PreDilutionTargetConcentrationFieldProps) => {
  return (
    <PreDilutionTargetConcentrationFieldInput
      {...{
        groupIndex,
        reagentIndex,
        preDilutionStepIndex,
        preDilutionStepsCount,
      }}
    />
  );
};

export default PreDilutionTargetConcentrationField;
