import { OwcTypography } from "@one/react";
import clsx from "clsx";
import Grid from "../../../../../components/Grid/Grid";
import mastermixSharedStyles from "../../MastermixDetailsPage.module.scss";

const MastermixDetailsPageGroupHeader = ({ index }: { index: number }) => {
  return (
    <div
      className={clsx(
        mastermixSharedStyles.grid,
        mastermixSharedStyles.gridBlue
      )}
    >
      <Grid columnGap={16} rowGap={4} columns={16} container>
        <Grid item columnSpan={7}>
          <OwcTypography
            data-testid={`mastermix-details-page-group-header-material-name-${index}`}
            variant="subtitle2"
          >
            Material name
          </OwcTypography>
        </Grid>
        <Grid item columnSpan={3}>
          <OwcTypography
            data-testid={`mastermix-details-page-group-header-lot-number-${index}`}
            variant="subtitle2"
          >
            Lot number
          </OwcTypography>
        </Grid>
        <Grid item columnSpan={3}>
          <OwcTypography
            data-testid={`mastermix-details-page-group-header-internal-lot-number-${index}`}
            variant="subtitle2"
          >
            Internal lot number
          </OwcTypography>
        </Grid>
        <Grid item columnSpan={3}>
          <OwcTypography
            className={mastermixSharedStyles.toRight}
            data-testid={`mastermix-details-page-group-header-total-volume-${index}`}
            variant="subtitle2"
          >
            Volume for all reactions
          </OwcTypography>
        </Grid>
      </Grid>
    </div>
  );
};

export default MastermixDetailsPageGroupHeader;
