import { useCallback, useState } from "react";
import { useApolloClient } from "../../components/apollo-client-context/ApolloClientWrapper";
import { useConfirm } from "../../components/confirm-context/useConfirm";
import { useRunGraphQlWithSnackbars } from "../../utils/hooks/useRunGraphQlWithSnackbars";
import { copyDilutionMutation } from "./copyDilutionMutation.Gql";

export const useCopyDilution = ({
  id,
  name,
  elnId,
}: {
  id: string;
  name: string;
  elnId: string | null;
}) => {
  const confirm = useConfirm();

  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const runGraphQlWithSnackbars = useRunGraphQlWithSnackbars({
    errorMessage: "Copying dilution went wrong!",
    successMessage: "Successfully copied dilution!",
  });

  return {
    loading,
    copyDilution: useCallback(async () => {
      if (
        await confirm({
          description: `Do you really want to copy this dilution: ${name}${
            elnId ? ` (${elnId})` : ""
          }?`,
        })
      ) {
        setLoading(true);
        return runGraphQlWithSnackbars(
          async () => {
            const result = await client.mutate<{
              clone: { id: string } | null;
            }>({
              mutation: copyDilutionMutation,
              variables: { id },
            });
            if (result.errors || !result.data || !result.data.clone) {
              const error = new Error("Failed to do request");

              (error as unknown as Record<string, unknown>)["graphQLErrors"] =
                result.errors;

              throw error;
            }

            return true;
          },
          () => false,
          () => setLoading(false)
        );
      }
      return false;
    }, [confirm, id, name, elnId, runGraphQlWithSnackbars, client]),
  };
};
