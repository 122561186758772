import { PanelMember } from "../../../../../../models/coform-models/PanelMember";
import { PreDilution } from "../../../../../../models/coform-models/PreDilution";
import { Target } from "../../../../../../models/coform-models/Target";
import { toFixed } from "../../../../../../utils/helpers/toFixed";
import { CONCENTRATION_LIMIT_COMMA } from "../../../../constants";
import { HighLevelMaterialDilution } from "../models/HighLevelMaterialDilution";
import { getPanelMemberInputConcentration } from "./getPanelMemberInputConcentration";

export const getPanelMemberTargets = (
  dilution: HighLevelMaterialDilution,
  existingPanelMember: PanelMember | undefined,
  index: number,
  preDilutions: PreDilution[],
  panelMembers: PanelMember[]
): Target[] => {
  return (
    dilution.dilutionDetails?.inputMaterials?.map((material, materialIndex) => {
      const existingTarget = existingPanelMember?.targets?.find(
        (target) => target.material.id === material.id
      );
      const inputConcentration = getPanelMemberInputConcentration(
        index,
        materialIndex,
        preDilutions,
        panelMembers,
        material
      );
      return {
        dilutionFactor: { calculatedValue: "", displayedValue: "" },
        targetConcentration:
          dilution.type === "CO_FORMULATED_DILUTION" &&
          index === 0 &&
          inputConcentration.calculatedValue !== ""
            ? {
                calculatedValue: toFixed(
                  Number(inputConcentration.calculatedValue) /
                    (dilution.dilutionDetails?.inputMaterials.length ?? 1),
                  {
                    roundTo: null,
                  }
                ),
                displayedValue: toFixed(
                  Number(inputConcentration.calculatedValue) /
                    (dilution.dilutionDetails?.inputMaterials.length ?? 1),
                  {
                    roundTo: CONCENTRATION_LIMIT_COMMA,
                  }
                ),
              }
            : {
                calculatedValue: "",
                displayedValue: "",
              },
        ...(existingTarget ?? {}),
        inputConcentration,
        material,
      };
    }) ?? []
  );
};
