import { FormState } from "react-hook-form";
import { PreDilutionStep } from "../../../../../../models/coform-models/PreDilutionStep";
import { Diluent } from "../../../../../../models/Diluent";
import { HighLevelMaterialDilution } from "../models/HighLevelMaterialDilution";
import { compareFormDiluents } from "./compareFormDiluents";

export const getDilutionStepDiluent = (
  dilution: HighLevelMaterialDilution,
  existingStep: PreDilutionStep | undefined,
  oldDiluent: FormState<Diluent>["defaultValues"] | undefined
): Diluent => {
  if (!oldDiluent || !existingStep) {
    return existingStep?.diluent ?? dilution.dilutionDetails?.diluents![0]!;
  }

  const isDetailsDiluentEqual = compareFormDiluents(
    dilution.dilutionDetails?.diluents![0]!,
    oldDiluent
  );

  if (isDetailsDiluentEqual) {
    return existingStep.diluent;
  }

  const isStepDiluentEqual = compareFormDiluents(
    oldDiluent,
    existingStep.diluent
  );

  if (isStepDiluentEqual) {
    return dilution.dilutionDetails?.diluents![0]!;
  }

  return existingStep.diluent;
};
