import DilutionDetailsPage from "../../features/common-dilution/dilution-details-page/DilutionDetailsPage";
import DilutionPageTemplate from "../DilutionPageTemplate";
import { getCoFormDilutionQueryGql } from "./queries/getCoFormDilutionQueryGql";

const DilutionDetailsPageView = () => (
  <DilutionPageTemplate
    component={DilutionDetailsPage}
    titleForError={`Loading display details page for dilution`}
    query={getCoFormDilutionQueryGql}
  />
);

export default DilutionDetailsPageView;
