import { StyleDictionary } from "pdfmake/interfaces";

export const generateMargin = (
  leftMargin: number,
  topMargin: number,
  rightMargin: number,
  bottomMargin: number
): [number, number, number, number] => {
  return [leftMargin, topMargin, rightMargin, bottomMargin];
};
export const fontNormalSize = 12;
export const fontSmallSize = 9;
export const fontExtraSmallSize = 8;

export const marginLargeSize = 20;
export const marginNormalSize = 10;
export const marginSmallSize = 5;

const globalStyles: StyleDictionary = {
  header: {
    margin: marginLargeSize,
  },
  footerNumber: {
    margin: [0, marginSmallSize, marginLargeSize, marginNormalSize],
    alignment: "right",
    fontSize: fontExtraSmallSize,
  },
  footerTitle: {
    alignment: "center",
    fontSize: fontSmallSize,
  },
  title: {
    margin: generateMargin(0, marginSmallSize, 0, 0),
    fontSize: fontNormalSize,
    bold: true,
  },
};

const tableStyles: StyleDictionary = {
  tableTitle: {
    margin: generateMargin(0, 0, 0, marginNormalSize),
    fontSize: fontNormalSize,
    bold: true,
  },
  table: {
    fontSize: fontSmallSize,
  },
};

const helpers: StyleDictionary = {
  bold: {
    bold: true,
  },
  noMargin: {
    margin: 0,
  },
  alignedToLeft: {
    alignment: "left",
  },
  alignedToCenter: {
    alignment: "center",
  },
  fontNormal: {
    fontSize: fontNormalSize,
  },
  fontSmall: {
    fontSize: fontSmallSize,
  },
  fontExtraSmall: {
    fontSize: fontExtraSmallSize,
  },
  marginTopLarge: {
    margin: generateMargin(0, marginLargeSize, 0, 0),
  },
  marginTopNormal: {
    margin: generateMargin(0, marginNormalSize, 0, 0),
  },
  marginTopSmall: {
    margin: generateMargin(0, marginSmallSize, 0, 0),
  },
  marginBottomLarge: {
    margin: generateMargin(0, 0, 0, marginLargeSize),
  },
  marginBottomNormal: {
    margin: generateMargin(0, 0, 0, marginNormalSize),
  },
  marginBottomSmall: {
    margin: generateMargin(0, 0, 0, marginSmallSize),
  },
  marginLeftLarge: {
    margin: generateMargin(marginLargeSize, 0, 0, 0),
  },
  marginLeftNormal: {
    margin: generateMargin(marginNormalSize, 0, 0, 0),
  },
  marginLeftSmall: {
    margin: generateMargin(marginSmallSize, 0, 0, 0),
  },
};

const styles = {
  ...globalStyles,
  ...tableStyles,
  ...helpers,
};

export default styles;
