import {
  StatusDilutionType,
  StatusDilutionTypeLabels,
} from "../../../models/Dilution";
import { breakableString } from "../utils/breakable-string";

const idTable = ({
  elnId,
  status,
  updatedBy,
}: {
  elnId?: string | null;
  status?: StatusDilutionType | null;
  updatedBy?: string | null;
}) => ({
  style: ["table", "marginBottomLarge"],
  table: {
    widths: ["*", "*"],
    body: [
      [
        {
          table: {
            body: [
              ["eLN ID:", breakableString(elnId || "—")],
              ["Status:", status ? StatusDilutionTypeLabels[status] : "—"],
            ],
          },
          layout: "noBorders",
        },
        {
          table: {
            body: [["User ID:", updatedBy || "—"]],
          },
          layout: "noBorders",
        },
      ],
    ],
  },
});

export default idTable;
