import { UseFormGetValues } from "react-hook-form";
import { TestContext } from "yup";
import { HighLevelMaterialDilution } from "../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";

export type PanelMemberFormContext = {
  getValues: UseFormGetValues<HighLevelMaterialDilution>;
};

export const isPanelMemberFormContextType = (
  v: TestContext
): v is TestContext<PanelMemberFormContext> =>
  Boolean(v.options.context && "getValues" in v.options.context);
