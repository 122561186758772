import { useCallback, useEffect, useState } from "react";
import { UseFieldArrayReturn, useFormContext } from "react-hook-form";
import { useConfirm } from "../../../../../../../components/confirm-context/useConfirm";
import { getPreDilutionStepName } from "../../../../../utils/getPreDilutionStepName";

import { HighLevelMaterialDilution } from "../../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { useUpdateFirstPanelMemberTarget } from "../../PanelMemberContent/fields/useUpdateFirstPanelMemberTarget";

export const usePreDilutionStepRemove = (
  preDilutionIndex: number,
  fieldArray: UseFieldArrayReturn<
    HighLevelMaterialDilution,
    `dilutionDetails.preDilutions.${typeof preDilutionIndex}.dilutionSteps`,
    "id"
  >
) => {
  const confirm = useConfirm();
  const { watch, setValue, trigger } =
    useFormContext<HighLevelMaterialDilution>();
  const type = watch("type");
  const inputMaterial = watch(
    `dilutionDetails.preDilutions.${preDilutionIndex}.inputMaterial`
  );
  const { remove } = fieldArray;
  const preDilutionStepValues = watch(
    `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps`
  );
  const [removeIndex, setRemoveIndex] = useState(-1);
  const [preRemoveIndex, setPreRemoveIndex] = useState(-1);
  const [updateFirstPanelMember, setUpdateFirstPanelMember] = useState(false);
  const firstPanelMember = watch(`dilutionDetails.panelMembers.0`);
  const targetIndex =
    firstPanelMember?.targets?.findIndex(
      ({ material }) => material.id === inputMaterial.id
    ) ?? 0;
  const updateFirstPanelMemberTarget = useUpdateFirstPanelMemberTarget(
    0,
    targetIndex
  );

  useEffect(() => {
    if (updateFirstPanelMember) {
      updateFirstPanelMemberTarget(
        firstPanelMember.targets![targetIndex].targetConcentration!
      );
      trigger(`dilutionDetails.panelMembers.0.targets`);
      setUpdateFirstPanelMember(false);
    }
  }, [
    updateFirstPanelMember,
    updateFirstPanelMemberTarget,
    targetIndex,
    firstPanelMember,
    trigger,
  ]);

  useEffect(() => {
    if (removeIndex !== -1) {
      // Remove pre-dilution step
      remove(removeIndex);
      setRemoveIndex(-1);
      if (type === "CO_FORMULATED_DILUTION") {
        // Defer updating last dilution step target to the next render cycle
        setUpdateFirstPanelMember(true);
      }
    }
  }, [
    removeIndex,
    setRemoveIndex,
    remove,
    firstPanelMember,
    targetIndex,
    trigger,
    updateFirstPanelMemberTarget,
    type,
  ]);

  useEffect(() => {
    if (preRemoveIndex !== -1) {
      setPreRemoveIndex(-1);
      // Defer removal to the next render cycle, again, because one render cycle might not be enough
      setRemoveIndex(preRemoveIndex);
    }
  }, [preRemoveIndex, setPreRemoveIndex, setRemoveIndex]);

  return useCallback(
    async (index: number) => {
      const step = preDilutionStepValues?.find((_, i) => i === index);
      if (!step) {
        return;
      }
      if (
        await confirm({
          description: `Do you really want to delete step ${step?.stepName}?`,
        })
      ) {
        preDilutionStepValues?.forEach(
          (preDilutionStep, preDilutionStepIndex) => {
            if (preDilutionStepIndex > index) {
              const defaultOldName = getPreDilutionStepName(
                preDilutionStepIndex,
                inputMaterial
              );
              const stepName = getPreDilutionStepName(
                preDilutionStepIndex - 1,
                inputMaterial
              );
              if (defaultOldName === preDilutionStep.stepName) {
                setValue(
                  `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${preDilutionStepIndex}.stepName`,
                  stepName
                );
                preDilutionStep.stepName = stepName;
              }
              setValue(
                `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${preDilutionStepIndex}.stepNumber`,
                preDilutionStepIndex
              );
            }
          }
        );
        // Defer removal to the next render cycle, because doing it immediately may lead to re-entering value, due to the setValue calls
        setPreRemoveIndex(index);
      }
    },
    [
      setPreRemoveIndex,
      setValue,
      inputMaterial,
      preDilutionStepValues,
      preDilutionIndex,
      confirm,
    ]
  );
};
