import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { useValidationParams } from "../../../ValidationParamsContext/useValidationParams";
import { CoFormDilution } from "../../../../../../../models/Dilution";
import { getStringFieldInputProps } from "../../../../../../../utils/helpers/getStringFieldInputProps";
import OwcInputSuffix from "../../../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";

const DiluentPanelVolumeField = ({
  panelMemberIndex,
}: {
  panelMemberIndex: number;
}) => {
  const { customLeftOverMin, customLeftOverMax } = useValidationParams();
  return (
    <Controller<
      CoFormDilution,
      `dilutionDetails.panelMembers.${typeof panelMemberIndex}.customLeftOver`
    >
      name={`dilutionDetails.panelMembers.${panelMemberIndex}.customLeftOver`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid="panel-member-customLeftOver-input"
          label="*Panel volume needed"
          min={customLeftOverMin}
          max={customLeftOverMax}
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
          type="number"
        >
          <span slot="error-text" data-testid="dilution-customLeftOver-error">
            {error?.message}
          </span>
          <OwcInputSuffix>mL</OwcInputSuffix>
        </OwcInput>
      )}
    />
  );
};

export default DiluentPanelVolumeField;
