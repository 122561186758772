import { CoFormDilution } from "../../../../models/Dilution";
import { HighLevelMaterialDilution } from "../forms/HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { getInputMaterial } from "./getInputMaterial";

export const getEmptySerialAndCoFormulationDilution = (
  type: CoFormDilution["type"]
): HighLevelMaterialDilution => {
  const inputMaterials =
    type === "CO_FORMULATED_DILUTION"
      ? [
          getInputMaterial("CO_FORMULATED_DILUTION"),
          getInputMaterial("CO_FORMULATED_DILUTION"),
        ]
      : [getInputMaterial("SERIAL_DILUTION")];

  return {
    id: "",
    name: "",
    type,
    dilutionDetails: {
      hasNegativePanelMember: false,
      customLeftOver: null,
      diluents: [
        {
          id: "",
          lotNumber: null,
          number: null,
          shortName: "",
          stockName: "",
          neededVolume: null,
        },
      ],
      inputMaterials,
      panelMembers: [],
      preDilutions: [],
    },
    elnId: null,
    deadVolume: null,
    panelMembers: null,
    replicatesPerPanelMember: 1,
    volumeOfReplicate: null,
  };
};
