import { ContentTable, TableCell } from "pdfmake/interfaces";
import { EXPORT_PDF_CONFIG } from "../constants";
import { TableCellWithPrivateProperties } from "./vertical-aligment";

export interface Inlines {
  height: number;
  width: number;
  trailingCut: number;
  leadingCut: number;
  lineEnd?: boolean;
}

const horizontalPaddingCount = 2;
export const calculateCellLines = (
  rowIndex: number,
  inlines: Inlines[] | undefined,
  cellWidth: number
): number => {
  if (!inlines) {
    return 0;
  }

  let height = 0;
  let width = 0;
  let isLastLine = false;
  inlines.forEach((node, index) => {
    isLastLine
      ? (height += node.height)
      : (height = Math.max(node.height, height));
    width += node.width + node.trailingCut + node.leadingCut;

    isLastLine = Boolean(node.lineEnd) || index + 1 === inlines.length;
  });

  const isHeader = rowIndex === 0;
  const approximateNumberOfLines =
    Math.ceil(
      width /
        (cellWidth -
          EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING * horizontalPaddingCount)
    ) || 1;

  return isHeader ? height : height * approximateNumberOfLines;
};

export const calculateHeights = (
  rowIndex: number,
  cell: TableCell,
  cellWidth: number
): number => {
  const { _inlines, _height, table } = cell as TableCellWithPrivateProperties;

  if (_inlines && _inlines.length) {
    return calculateCellLines(rowIndex, _inlines, cellWidth);
  }

  if (table) {
    const tableBodies = table.body.map((content) => content[0]);
    const innerTableHeights = tableBodies.map((innerCell: TableCell) =>
      calculateHeights(rowIndex, innerCell, cellWidth)
    );

    return innerTableHeights.reduce((acc, value) => acc + value, 0);
  }

  if (_height) {
    return _height;
  }

  return 0;
};

export const calculateRowHeight = (
  rowIndex: number,
  headers: ContentTable[] | null,
  rows: ContentTable[][],
  cellWidth: number
): number => {
  const isHeader = rowIndex === 0 && headers;

  let row = rows[rowIndex - 1];

  if (headers) {
    row = isHeader ? headers : rows[rowIndex - 1];
  }

  const cellsHeights = row.map((cell: ContentTable) =>
    isHeader
      ? EXPORT_PDF_CONFIG.HEADERS_ROW_HEIGHT
      : calculateHeights(rowIndex, cell, cellWidth)
  );

  return Math.max(...cellsHeights);
};
