import { Fragment } from "react";
import { MastermixDilution } from "../../../../models/Dilution";
import DilutionDetailsPagePreDilutionsSection from "../../../common-dilution/dilution-details-page/pre-dilutions-section/DilutionDetailsPagePreDilutionsSection";

const MastermixDetailsPagePredilutionsSection = ({
  dilution,
}: {
  dilution: MastermixDilution;
}) => {
  return (
    <>
      {dilution.dilutionDetails?.groups!.map((group, index) => {
        return (
          <Fragment key={group.id}>
            {group?.reagents?.map((reagent) =>
              reagent.preDilution ? (
                <DilutionDetailsPagePreDilutionsSection
                  key={`${reagent?.preDilution.inputMaterial.id}+${index}`}
                  preDilutionIndex={index}
                  preDilution={reagent?.preDilution}
                  unit="μL"
                />
              ) : null
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default MastermixDetailsPagePredilutionsSection;
