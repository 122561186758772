import { OwcButton, OwcIcon } from "@one/react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../models/Dilution";
import button from "../../../../scss/button/Button.module.scss";
import ReagentContent from "../reagent/ReagentContent";
import { getEmptyReagent } from "../utils/getEmptyReagent";
import GroupName from "./GroupName";
import styles from "./GroupName.module.scss";
interface ReagentGroupContentProps {
  groupIndex: number;
}

const ReagentGroupContent = ({ groupIndex }: ReagentGroupContentProps) => {
  const { watch } = useFormContext<MastermixDilution>();
  const group = watch(`dilutionDetails.groups.${groupIndex}`);
  const fieldArray = useFieldArray<
    MastermixDilution,
    `dilutionDetails.groups.${typeof groupIndex}.reagents`
  >({
    name: `dilutionDetails.groups.${groupIndex}.reagents`,
  });

  const addNewReagentToGroup = () => {
    fieldArray.append(getEmptyReagent());
  };
  return (
    <>
      <GroupName groupIndex={groupIndex} />
      {fieldArray.fields.map((field, index) => (
        <ReagentContent
          key={field.id}
          groupIndex={groupIndex}
          reagentIndex={index}
          fieldArray={fieldArray}
        />
      ))}
      <div className={styles.addMaterialButton}>
        <OwcButton
          onClick={() => addNewReagentToGroup()}
          className={button.large}
          flat
          variant="secondary"
          data-testid={`mastermix-add-new-reagent-in-group-btn-${groupIndex}`}
        >
          <div className={button.iconWrapper}>
            <OwcIcon name="sign_plus" color="inherit" />
            Add new {group.name}
          </div>
        </OwcButton>
      </div>
    </>
  );
};

export default ReagentGroupContent;
