import { OwcTableHeader, OwcTableHeaderCell } from "@one/react";

export const DilutionTableHeader = () => {
  return (
    <OwcTableHeader sticky data-testid="dilution-list-table-header">
      <OwcTableHeaderCell data-testid="dilution-list-table-header-name">
        Experiment name
      </OwcTableHeaderCell>
      <OwcTableHeaderCell data-testid="dilution-list-table-header-status">
        Status
      </OwcTableHeaderCell>
      <OwcTableHeaderCell data-testid="dilution-list-table-header-userId">
        user ID
      </OwcTableHeaderCell>
      <OwcTableHeaderCell data-testid="dilution-list-table-header-elnId">
        eLN ID
      </OwcTableHeaderCell>
      <OwcTableHeaderCell data-testid="dilution-list-table-header-createdAt">
        Created at
      </OwcTableHeaderCell>
      <OwcTableHeaderCell data-testid="dilution-list-table-header-updatedAt">
        Updated at
      </OwcTableHeaderCell>
      <OwcTableHeaderCell data-testid="dilution-list-table-header-type">
        Type
      </OwcTableHeaderCell>
      <OwcTableHeaderCell
        align="right"
        data-testid="dilution-list-table-header-actions"
      >
        Actions
      </OwcTableHeaderCell>
    </OwcTableHeader>
  );
};
