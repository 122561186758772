import gql from "graphql-tag";

export const getMastermixDilutionQueryGql = gql`
  query GetDilution($id: ID!) {
    getDilution(id: $id) {
      elnId
      id
      name
      type
      createdBy
      createdAt
      updatedBy
      updatedAt
      status
      appVersion
      dilutionDetails {
        ... on MastermixDetails {
          diluent {
            id
            lotNumber
            neededVolume {
              calculatedValue
              displayedValue
            }
            number
            shortName
            stockName
          }
          preDiluents {
            id
            lotNumber
            neededVolume {
              calculatedValue
              displayedValue
            }
            number
            shortName
            stockName
          }
          groups {
            groupType
            id
            name
            totalVolume {
              calculatedValue
              displayedValue
            }
            reagents {
              comment
              pcrConcentration {
                calculatedValue
                displayedValue
              }
              material {
                availableVolume
                id
                unitFactor
                targetUnit
                stockUnit
                stockName
                stockConcentration
                shortName
                number
                neededVolume {
                  calculatedValue
                  displayedValue
                }
                lotNumber
                internalLotNumber
              }
              pcrVolume {
                calculatedValue
                displayedValue
              }
              r2Concentration {
                calculatedValue
                displayedValue
              }
              preDilution {
                dilutionSteps {
                  deadVolume
                  inputName
                  diluent {
                    id
                    lotNumber
                    neededVolume {
                      calculatedValue
                      displayedValue
                    }
                    number
                    stockName
                    shortName
                  }
                  dilutionFactor {
                    calculatedValue
                    displayedValue
                  }
                  diluentVolume {
                    calculatedValue
                    displayedValue
                  }
                  inputConcentration {
                    calculatedValue
                    displayedValue
                  }
                  inputVolume {
                    calculatedValue
                    displayedValue
                  }
                  leftOver {
                    calculatedValue
                    displayedValue
                  }
                  stepNumber
                  stepName
                  targetConcentration {
                    calculatedValue
                    displayedValue
                  }
                  totalVolume {
                    calculatedValue
                    displayedValue
                  }
                }
                inputMaterial {
                  id
                  availableVolume
                  lotNumber
                  number
                  neededVolume {
                    calculatedValue
                    displayedValue
                  }
                  shortName
                  stockConcentration
                  stockName
                  stockUnit
                  unitFactor
                  targetUnit
                  internalLotNumber
                }
                preDilutionNumber
              }
            }
          }
          eluateVolume
          numberOfReactions
          r1Volume
          r2Volume
          reactionVolume {
            calculatedValue
            displayedValue
          }
          totalVolume {
            calculatedValue
            displayedValue
          }
        }
      }
    }
  }
`;
