import { Route, Routes } from "react-router-dom";
import AppVersionsPage from "./views/AppVersionsPage";
import LoginPage from "./views/LoginPage";
import NotFoundScreen from "./views/NotFoundScreen";

const AnonymousRouting = () => {
  return (
    <Routes>
      <Route path="" index element={<LoginPage />} />
      <Route path="/versions" element={<AppVersionsPage />} />
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  );
};

export default AnonymousRouting;
