import { OwcInput } from "@one/react";

import { Controller } from "react-hook-form";
import { getNumberFieldInputProps } from "../../../../../../utils/helpers/getNumberFieldInputProps";
import { HighLevelMaterialDilution } from "../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { useValidationParams } from "../../ValidationParamsContext/useValidationParams";

const MaterialNumberOfPreDilutionsField = ({ index }: { index: number }) => {
  const { numberOfPreDilutionsMin, numberOfPreDilutionsMax } =
    useValidationParams();
  return (
    <Controller<
      HighLevelMaterialDilution,
      `dilutionDetails.inputMaterials.${typeof index}.highLevelInfo.numberOfPreDilutions`
    >
      name={`dilutionDetails.inputMaterials.${index}.highLevelInfo.numberOfPreDilutions`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`inputMaterials-numberOfPreDilutions-${index}-input`}
          label="*No. of pre-dilutions"
          required
          type="number"
          min={numberOfPreDilutionsMin}
          max={numberOfPreDilutionsMax}
          {...getNumberFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`inputMaterials-numberOfPreDilutions-${index}-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default MaterialNumberOfPreDilutionsField;
