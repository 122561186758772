import { Calculated } from "../../../../../models/Dilution";
import { toFixed } from "../../../../../utils/helpers/toFixed";
import { pcrConcentrationLimitComma } from "../../mastermixSchema/mastermixGroupSchema";

export const getReagentPcrConcentration = (
  r2Volume: string,
  reactionVolume: Calculated,
  reagentR2Concentration: Calculated | null
): Calculated => {
  if (verifyNumbers(r2Volume, reactionVolume, reagentR2Concentration)) {
    const pcrConcetrationValue =
      (Number(r2Volume) * Number(reagentR2Concentration.calculatedValue)) /
      Number(reactionVolume.calculatedValue);

    return {
      calculatedValue: toFixed(pcrConcetrationValue, {
        exponential:
          reactionVolume.calculatedValue.toLowerCase().includes("e") ||
          reagentR2Concentration.calculatedValue.toLowerCase().includes("e") ||
          r2Volume.toLowerCase().includes("e"),
        roundTo: null,
      }),
      displayedValue: toFixed(pcrConcetrationValue, {
        exponential:
          reactionVolume.calculatedValue.toLowerCase().includes("e") ||
          reagentR2Concentration.calculatedValue.toLowerCase().includes("e") ||
          r2Volume.toLowerCase().includes("e"),
        roundTo: pcrConcentrationLimitComma,
      }),
    };
  }
  return {
    calculatedValue: "",
    displayedValue: "",
  };
};
function verifyNumbers(
  r2Volume: string,
  reactionVolume: Calculated,
  reagentR2Concentration: Calculated | null
): reagentR2Concentration is Calculated {
  return (
    (r2Volume !== "" &&
      !isNaN(Number(r2Volume)) &&
      reactionVolume.calculatedValue !== "" &&
      !isNaN(Number(reactionVolume.calculatedValue)) &&
      reagentR2Concentration &&
      reagentR2Concentration.calculatedValue !== "" &&
      !isNaN(Number(reagentR2Concentration.calculatedValue))) ??
    false
  );
}
