import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { CoFormDilution } from "../../../../../../../models/Dilution";
import { getNumberFieldInputProps } from "../../../../../../../utils/helpers/getNumberFieldInputProps";
import { useValidationParams } from "../../../ValidationParamsContext/useValidationParams";

const NumberOfReplicatesField = ({
  panelMemberIndex,
}: {
  panelMemberIndex: number;
}) => {
  const { numberOfReplicatesMin, numberOfReplicatesMax } =
    useValidationParams();
  return (
    <Controller<
      CoFormDilution,
      `dilutionDetails.panelMembers.${typeof panelMemberIndex}.numberOfReplicates`
    >
      name={`dilutionDetails.panelMembers.${panelMemberIndex}.numberOfReplicates`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid="panel-member-numberOfReplicates-input"
          label="*Number of replicates"
          type="number"
          min={numberOfReplicatesMin}
          max={numberOfReplicatesMax}
          {...getNumberFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid="panel-member-numberOfReplicates-error"
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default NumberOfReplicatesField;
