export type DataCSV = Record<string, string | number>[];

export const convertToCSV = (data: DataCSV) => {
  const headers = Object.keys(data[0]);
  const csvData = [headers.join(",")];
  data.forEach((item) => {
    const row = headers.map((header) => item[header]);
    csvData.push(row.join(","));
  });
  return csvData.join("\n");
};

export const downloadCSV = (
  data: DataCSV,
  filename = "Export Sample Labels",
  mapToCSV = convertToCSV
) => {
  const csvContent = mapToCSV(data);
  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
