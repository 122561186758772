import { OwcTypography } from "@one/react";
import clsx from "clsx";
import Grid from "../../../../components/Grid/Grid";
import { PreDilutionStep } from "../../../../models/coform-models/PreDilutionStep";
import { Calculated } from "../../../../models/Dilution";
import {
  DILUENT_FACTOR_MAX,
  DILUENT_FACTOR_MIN,
  VOLUME_MIN,
} from "../../constants";
import { getDetailsInfoArray } from "../../waring-section/getDetailsInfoArray";
import WarningInfoSection from "../../waring-section/WarningInfoSection";
import { ConcentrationSection } from "./ConcentrationSection";
import styles from "./DilutionDetailsPagePreDilutionsSection.module.scss";
import { FlexLine } from "./FlexLine";

interface DilutionDetailsPagePreDilutionStepProps {
  preDilutionStep: PreDilutionStep;
  preDilutionStepIndex: number;
  preDilutionIndex: number;
  input: {
    name: string;
    volume: Calculated;
    stockUnit: string;
    targetUnit: string;
  };
  unit: string;
}

const DilutionDetailsPagePreDilutionStep = ({
  preDilutionStep,
  preDilutionIndex,
  preDilutionStepIndex,
  input,
  unit
}: DilutionDetailsPagePreDilutionStepProps) => {
  const warningVolumeMaterial =
    Number(input.volume?.calculatedValue) < VOLUME_MIN;
  const warningVolumeDiluent =
    Number(preDilutionStep.diluentVolume?.calculatedValue) < VOLUME_MIN;
  const warningDiluentFactorMore =
    Number(preDilutionStep.dilutionFactor?.calculatedValue) >
    DILUENT_FACTOR_MAX;
  const warningDiluentFactorLess =
    Number(preDilutionStep.dilutionFactor?.calculatedValue) <
    DILUENT_FACTOR_MIN;
  const warningSection =
    Number(warningVolumeMaterial) +
    Number(warningVolumeDiluent) +
    Number(warningDiluentFactorMore) +
    Number(warningDiluentFactorLess);

  return (
    <div
      className={clsx(styles.step, {
        [styles.warning]: warningSection > 0,
      })}
    >
      <OwcTypography
        data-testid={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-title-${preDilutionStepIndex}`}
        className={styles.subTitle}
        variant="title6"
      >
        {preDilutionStep.stepName}
      </OwcTypography>
      <div className={clsx(styles.grid, styles.gridBlue)}>
        <Grid columnGap={64} rowGap={4} columns={2} container>
          <Grid item columnSpan={1}>
            {preDilutionStepIndex === 0 && (
              <OwcTypography
                data-testid={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-stock-material-title-${preDilutionStepIndex}`}
                variant="caption"
              >
                Stock material
              </OwcTypography>
            )}
            {preDilutionStepIndex > 0 && (
              <OwcTypography
                data-testid={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-input-material-title-${preDilutionStepIndex}`}
                variant="caption"
              >
                Input material
              </OwcTypography>
            )}
          </Grid>
          <Grid item columnSpan={1}>
            <OwcTypography
              data-testid={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-diluent-title-${preDilutionStepIndex}`}
              variant="caption"
            >
              Diluent
            </OwcTypography>
          </Grid>
          <Grid item columnSpan={1}>
            <FlexLine
              hasAccent={true}
              hasWarning={warningVolumeMaterial}
              label={input.name}
              value={`${input.volume?.displayedValue ?? "-"} ${unit}`}
              labelTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-input-material-name-value-${preDilutionStepIndex}`}
              valueTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-input-material-volume-value-${preDilutionStepIndex}`}
              keepAlign
            />
          </Grid>
          <Grid item columnSpan={1}>
            <FlexLine
              hasAccent={true}
              hasWarning={warningVolumeDiluent}
              label={preDilutionStep.diluent.stockName}
              value={`${
                preDilutionStep.diluentVolume?.displayedValue ?? "-"
              } ${unit}`}
              labelTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-diluent-name-value-${preDilutionStepIndex}`}
              valueTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-diluent-volume-value-${preDilutionStepIndex}`}
            />
          </Grid>
        </Grid>
      </div>
      <ConcentrationSection
        {...{ preDilutionStepIndex, preDilutionStep, input, preDilutionIndex }}
      />
      <div className={styles.grid}>
        <Grid columnGap={64} rowGap={4} columns={2} container>
          <Grid item columnSpan={1}>
            <FlexLine
              label="Total volume"
              value={`${preDilutionStep.totalVolume?.displayedValue} ${unit}`}
              labelTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-total-volume-title-${preDilutionStepIndex}`}
              valueTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-total-volume-value-${preDilutionStepIndex}`}
            />
            <FlexLine
              label="Left over"
              labelVariant="body2"
              value={`${preDilutionStep.leftOver?.displayedValue} ${unit}`}
              valueVariant="subtitle2"
              labelTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-left-over-title-${preDilutionStepIndex}`}
              valueTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-left-over-value-${preDilutionStepIndex}`}
            />
          </Grid>
          <Grid item columnSpan={1}>
            <FlexLine
              hasWarning={warningDiluentFactorMore || warningDiluentFactorLess}
              label="Dilution factor"
              value={`${preDilutionStep.dilutionFactor?.displayedValue}`}
              labelTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-diluent-factor-title-${preDilutionStepIndex}`}
              valueTestId={`pre-dilution-section-${preDilutionIndex}-pre-dilution-step-diluent-factor-value-${preDilutionStepIndex}`}
            />
          </Grid>
        </Grid>
      </div>
      {warningSection > 0 && (
        <WarningInfoSection
          details={getDetailsInfoArray(
            warningDiluentFactorMore,
            warningDiluentFactorLess,
            warningVolumeMaterial,
            warningVolumeDiluent
          )}
        />
      )}
    </div>
  );
};

export default DilutionDetailsPagePreDilutionStep;
