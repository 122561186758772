import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { MastermixDilution } from "../../../../models/Dilution";
import { getStringFieldInputProps } from "../../../../utils/helpers/getStringFieldInputProps";

const Name = () => {
  return (
    <Controller<MastermixDilution, `name`>
      name={`name`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`name-input`}
          label="*Name of Mastermix"
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
          required
        >
          <span slot="error-text" data-testid={`name-error`}>
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default Name;
