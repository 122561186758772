import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useUpdateDiluentId } from "../../../../utils/useUpdateDiluentId";
import { HighLevelMaterialDilution } from "../../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { DiluentStepForm } from "../../../../../../change-diluent-for-step/DiluentStepForm";

export const useHandleDiluentConfirm = (
  preDilutionIndex: number,
  index: number
) => {
  const { setValue } = useFormContext<HighLevelMaterialDilution>();
  const updateDiluentId = useUpdateDiluentId(
    `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${index}.diluent`
  );
  return useCallback(
    (values: DiluentStepForm) => {
      setValue(
        `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${index}.diluent.number`,
        values.number
      );
      setValue(
        `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${index}.diluent.lotNumber`,
        values.lotNumber
      );
      setValue(
        `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${index}.diluent.stockName`,
        values.stockName
      );
      updateDiluentId();
    },
    [preDilutionIndex, index, setValue, updateDiluentId]
  );
};
