const TestTubs = () => {
  return (
    <svg
      data-testid="testtubs-pic"
      width="130"
      height="108"
      viewBox="0 0 130 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M110.995 18H18.865V22.36H110.995V18Z" fill="#CDE0F5" />
      <path d="M18.8649 7H13.7249V93.53H18.8649V7Z" fill="#E0ECF9" />
      <path d="M113.565 7H108.425V93.53H113.565V7Z" fill="#E0ECF9" />
      <path
        d="M46.785 10.97C46.785 10.2593 46.5026 9.57758 46 9.07498C45.4974 8.57238 44.8157 8.29004 44.105 8.29004H30.4649C29.7817 8.30599 29.1305 8.58335 28.6456 9.06503C28.1607 9.54671 27.879 10.196 27.8585 10.8792C27.838 11.5624 28.0802 12.2274 28.5354 12.7373C28.9905 13.2472 29.6238 13.5631 30.3049 13.62V75.8C30.3049 77.6565 31.0425 79.437 32.3552 80.7498C33.668 82.0625 35.4484 82.8 37.3049 82.8C39.1615 82.8 40.942 82.0625 42.2547 80.7498C43.5675 79.437 44.3049 77.6565 44.3049 75.8V13.62C44.977 13.5744 45.6068 13.2755 46.0671 12.7837C46.5274 12.2918 46.784 11.6437 46.785 10.97Z"
        fill="#E9F4FF"
      />
      <path
        d="M44.285 37.7501V75.8C44.285 77.6566 43.5475 79.4371 42.2347 80.7498C40.922 82.0626 39.1415 82.8 37.285 82.8C35.4285 82.8 33.648 82.0626 32.3353 80.7498C31.0225 79.4371 30.285 77.6566 30.285 75.8V34.8C32.7766 34.9556 35.1867 35.7475 37.285 37.1001C39.4652 38.148 41.9491 38.3786 44.285 37.7501Z"
        fill="#80B3E6"
      />
      <path
        d="M73.145 10.97C73.145 10.2593 72.8627 9.57758 72.3601 9.07498C71.8575 8.57238 71.1758 8.29004 70.465 8.29004H56.8251C56.1418 8.30599 55.4906 8.58335 55.0057 9.06503C54.5208 9.54671 54.2391 10.196 54.2186 10.8792C54.1981 11.5624 54.4403 12.2274 54.8955 12.7373C55.3506 13.2472 55.9839 13.5631 56.6651 13.62V75.8C56.5832 76.7656 56.7028 77.7377 57.0162 78.6547C57.3295 79.5717 57.8299 80.4137 58.4855 81.1273C59.1412 81.8408 59.9379 82.4105 60.8252 82.8002C61.7124 83.1899 62.671 83.3911 63.6401 83.3911C64.6091 83.3911 65.5676 83.1899 66.4549 82.8002C67.3422 82.4105 68.1389 81.8408 68.7945 81.1273C69.4502 80.4137 69.9506 79.5717 70.2639 78.6547C70.5773 77.7377 70.6969 76.7656 70.615 75.8V13.62C71.296 13.5867 71.9382 13.2932 72.409 12.8C72.8798 12.3069 73.1433 11.6518 73.145 10.97Z"
        fill="#E9F4FF"
      />
      <path
        d="M70.615 37.7501V75.8C70.6968 76.7657 70.5772 77.7377 70.2639 78.6548C69.9505 79.5718 69.4501 80.4137 68.7945 81.1273C68.1388 81.8409 67.3421 82.4105 66.4548 82.8002C65.5676 83.1899 64.6091 83.3911 63.64 83.3911C62.6709 83.3911 61.7124 83.1899 60.8251 82.8002C59.9378 82.4105 59.1411 81.8409 58.4855 81.1273C57.8298 80.4137 57.3295 79.5718 57.0161 78.6548C56.7027 77.7377 56.5832 76.7657 56.665 75.8V34.8C59.1566 34.9556 61.5667 35.7475 63.665 37.1001C65.8312 38.1341 68.2945 38.3645 70.615 37.7501Z"
        fill="#BFD9F2"
      />
      <path
        d="M99.835 10.97C99.835 10.261 99.554 9.5808 99.0536 9.07849C98.5531 8.57618 97.874 8.29268 97.165 8.29004H83.515C82.8322 8.30795 82.1823 8.58683 81.6988 9.06934C81.2154 9.55184 80.9353 10.2013 80.9161 10.884C80.8969 11.5667 81.14 12.2309 81.5955 12.7398C82.0511 13.2487 82.6843 13.5637 83.365 13.62V75.8C83.2749 76.7697 83.3882 77.7475 83.6975 78.6709C84.0068 79.5943 84.5054 80.443 85.1614 81.1627C85.8174 81.8825 86.6164 82.4574 87.5072 82.8508C88.398 83.2442 89.3611 83.4474 90.335 83.4474C91.3088 83.4474 92.2719 83.2442 93.1628 82.8508C94.0536 82.4574 94.8526 81.8825 95.5086 81.1627C96.1646 80.443 96.6631 79.5943 96.9725 78.6709C97.2818 77.7475 97.395 76.7697 97.305 75.8V13.62C97.986 13.5867 98.6282 13.2932 99.099 12.8C99.5698 12.3069 99.8332 11.6518 99.835 10.97Z"
        fill="#E9F4FF"
      />
      <path
        d="M97.285 37.7501V75.8C97.3751 76.7697 97.2618 77.7475 96.9525 78.6709C96.6432 79.5943 96.1446 80.443 95.4886 81.1628C94.8326 81.8825 94.0337 82.4574 93.1428 82.8508C92.252 83.2442 91.2889 83.4474 90.315 83.4474C89.3412 83.4474 88.3781 83.2442 87.4872 82.8508C86.5964 82.4574 85.7974 81.8825 85.1415 81.1628C84.4855 80.443 83.9869 79.5943 83.6776 78.6709C83.3682 77.7475 83.255 76.7697 83.345 75.8V34.8C85.8364 34.957 88.2461 35.7488 90.345 37.1001C92.5075 38.1351 94.968 38.3656 97.285 37.7501Z"
        fill="#80B3E6"
      />
      <path d="M117.845 90.5H11.155V100.78H117.845V90.5Z" fill="#CDE0F5" />
      <path d="M103.065 16.01H24.2249V24.34H103.065V16.01Z" fill="#E0ECF9" />
    </svg>
  );
};

export default TestTubs;
