import { useCallback, useEffect, useState } from "react";
import { UseFieldArrayReturn, useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../../models/Dilution";
import { useConfirm } from "../../../../../components/confirm-context/useConfirm";
import { getPreDilutionStepName } from "../../../../common-dilution/utils/getPreDilutionStepName";

export const usePreDilutionStepRemove = ({
  groupIndex,
  reagentIndex,
  fieldArray,
}: {
  groupIndex: number;
  reagentIndex: number;
  fieldArray: UseFieldArrayReturn<
    MastermixDilution,
    `dilutionDetails.groups.${typeof groupIndex}.reagents.${typeof reagentIndex}.preDilution.dilutionSteps`,
    "id"
  >;
}) => {
  const confirm = useConfirm();
  const { watch, setValue, trigger } = useFormContext<MastermixDilution>();
  const inputMaterial = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.inputMaterial`
  );
  const { remove } = fieldArray;
  const preDilutionStepValues = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps`
  );
  const [removeIndex, setRemoveIndex] = useState(-1);
  const [preRemoveIndex, setPreRemoveIndex] = useState(-1);

  useEffect(() => {
    if (removeIndex !== -1) {
      // Remove pre-dilution step
      remove(removeIndex);
      setRemoveIndex(-1);
    }
  }, [removeIndex, setRemoveIndex, remove, trigger]);

  useEffect(() => {
    if (preRemoveIndex !== -1) {
      setPreRemoveIndex(-1);
      // Defer removal to the next render cycle, again, because one render cycle might not be enough
      setRemoveIndex(preRemoveIndex);
    }
  }, [preRemoveIndex, setPreRemoveIndex, setRemoveIndex]);

  return useCallback(
    async (index: number) => {
      const step = preDilutionStepValues?.find((_, i) => i === index);
      if (!step) {
        return;
      }
      if (
        await confirm({
          description: `Do you really want to delete step ${step?.stepName}?`,
        })
      ) {
        preDilutionStepValues?.forEach(
          (preDilutionStep, preDilutionStepIndex) => {
            if (preDilutionStepIndex > index) {
              const defaultOldName = getPreDilutionStepName(
                preDilutionStepIndex,
                inputMaterial
              );
              const stepName = getPreDilutionStepName(
                preDilutionStepIndex - 1,
                inputMaterial
              );
              if (defaultOldName === preDilutionStep.stepName) {
                setValue(
                  `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${preDilutionStepIndex}.stepName`,
                  stepName
                );
                preDilutionStep.stepName = stepName;
              }
              setValue(
                `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${preDilutionStepIndex}.stepNumber`,
                preDilutionStepIndex
              );
            }
          }
        );
        // Defer removal to the next render cycle, because doing it immediately may lead to re-entering value, due to the setValue calls
        setPreRemoveIndex(index);
      }
    },
    [
      groupIndex,
      reagentIndex,
      setPreRemoveIndex,
      setValue,
      inputMaterial,
      preDilutionStepValues,
      confirm,
    ]
  );
};
