import { DILUTION_PDF_CONFIG } from "../dilution-pdf/dilution-pdf.constants";

export const breakableString = (str: string) => {
  return str
    .split(/\s/g)
    .map((word) =>
      word.replace(
        new RegExp(
          `(.{${DILUTION_PDF_CONFIG.MAX_CHARS_LIMIT_FOR_VALUES}})`,
          "g"
        ),
        "$1\u200B"
      )
    )
    .join(" ");
};
