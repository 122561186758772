import { breakableString } from "../../utils/breakable-string";
import { PanelMember } from "../../../../models/coform-models/PanelMember";
const unit = "mL";
const withUnit = (val: string | null) => (val ? `${val} ${unit}` : "-");

const summaryTables = (
  panelMembers: PanelMember[],
  customLeftOver?: string | null
) => {
  const numberOfPanelMembers = panelMembers?.length;
  const volumeOfReplicate = panelMembers?.[0]?.replicateVolume;
  const deadVolume = panelMembers?.[0]?.deadVolume;
  const body = [];
  body.push(["Number of Panel Members:", numberOfPanelMembers]);
  if (!customLeftOver) {
    body.push([
      "Volume of Replicate:",
      breakableString(withUnit(volumeOfReplicate)),
    ]);
  }
  body.push([
    "Dead Volume each Panel Member:",
    breakableString(withUnit(deadVolume)),
  ]);
  return {
    style: ["table", "marginBottomLarge"],
    table: {
      widths: ["*", "*"],
      body: [
        [
          {
            table: {
              body,
            },
            layout: "noBorders",
          },
        ],
      ],
    },
  };
};

export default summaryTables;
