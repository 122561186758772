/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { numberKeepStringValidator } from "../../../../utils/helpers/numberKeepStringValidator";
import { calculatedSchema } from "../../../../utils/schema/calculatedSchema";
import { mastermixGroupSchema } from "./mastermixGroupSchema";
import {
  diluentLotNumberVolumeNotEmpty,
  diluentNumberVolumeNotEmpty,
  diluentStockNameVolumeNotEmpty,
  nameNotEmpty,
  numberOfReactionsVolumeNotEmpty,
  reactionVolumeNotEmpty,
  totalVolumeNotEmpty,
} from "./mastermixSchemaNotEmpty";
import {
  numberOfReactionsMax,
  numberOfReactionsMin,
  reactionVolumeMax,
  reactionVolumeMin,
} from "./maxtermixSchemaMinMaxValue";

export const mastermixSchema = yup.object({
  name: yup.string().required(nameNotEmpty).typeError(nameNotEmpty),
  dilutionDetails: yup.object({
    totalVolume: calculatedSchema(
      yup
        .string()
        .typeError(totalVolumeNotEmpty)
        .test(
          numberKeepStringValidator(
            yup
              .number()
              .required(totalVolumeNotEmpty)
              .typeError(totalVolumeNotEmpty)
          )
        )
    ),
    reactionVolume: calculatedSchema(
      yup
        .string()
        .typeError(reactionVolumeNotEmpty)
        .test(
          numberKeepStringValidator(
            yup
              .number()
              .required(reactionVolumeNotEmpty)
              .typeError(reactionVolumeNotEmpty)
              .min(
                reactionVolumeMin,
                "PCR reaction volume must be greater than or equal to ${min}, please change value in R1, R2 and Eluate"
              )
              .max(
                reactionVolumeMax,
                "PCR reaction volume must be less than or equal to ${max}, please change value in R1, R2 and Eluate"
              )
          )
        )
    ),
    numberOfReactions: yup
      .number()
      .integer("Number of reactions must be an integer")
      .required(numberOfReactionsVolumeNotEmpty)
      .typeError(numberOfReactionsVolumeNotEmpty)
      .min(
        numberOfReactionsMin,
        "Number of reactions must be greater than or equal to ${min}"
      )
      .max(
        numberOfReactionsMax,
        "Number of reactions must be less than or equal to ${max}"
      ),

    diluent: yup
      .object({
        stockName: yup
          .string()
          .required(diluentStockNameVolumeNotEmpty)
          .typeError(diluentStockNameVolumeNotEmpty),
        number: yup
          .string()
          .required(diluentNumberVolumeNotEmpty)
          .typeError(diluentNumberVolumeNotEmpty),
        lotNumber: yup
          .string()
          .required(diluentLotNumberVolumeNotEmpty)
          .typeError(diluentLotNumberVolumeNotEmpty),
      })
      .required(),
    groups: yup.array(mastermixGroupSchema),
  }),
});
