import { OwcListItem } from "@one/react";
import ButtonLoading from "../../components/button-loading/ButtonLoading";
import { DilutionType } from "../../models/DilutionType";
import { DilutionShortInfo } from "../../views/main-view/MainView";

const ShortCardMenuItems = ({
  index,
  handleGoToEditPage,
  dilutionShortInfo,
  pendingAction,
  setMenuOpen,
  copyDilution,
  clearFilter,
  refetch,
  deleteDilution,
}: {
  index: number;
  handleGoToEditPage: (id: string, type: DilutionType) => void;
  dilutionShortInfo: DilutionShortInfo;
  pendingAction: boolean;
  setMenuOpen: (open: boolean) => void;
  copyDilution: () => Promise<boolean>;
  clearFilter: () => void;
  refetch: () => void;
  deleteDilution: () => Promise<boolean>;
}) => {
  return (
    <>
      <OwcListItem
        data-testid={`dilutions-list-card-list-item-${index}-more-menu-edit-btn`}
        iconType="legacy"
        icon="edit"
        noBorder
        onClick={() =>
          handleGoToEditPage(dilutionShortInfo.id, dilutionShortInfo.type)
        }
        disabled={pendingAction || dilutionShortInfo.status === "VERIFIED"}
      >
        {pendingAction && (
          <ButtonLoading
            testId={`dilutions-list-card-list-item-${index}-more-menu-edit-btn-loading`}
          />
        )}
        Edit
      </OwcListItem>
      <OwcListItem
        data-testid={`dilutions-list-card-list-item-${index}-more-menu-copy-btn`}
        iconType="legacy"
        icon="copy"
        noBorder
        disabled={pendingAction}
        onClick={async () => {
          setMenuOpen(false);
          if (await copyDilution()) {
            clearFilter();
          }
          refetch();
        }}
      >
        {pendingAction && (
          <ButtonLoading
            testId={`dilutions-list-card-list-item-${index}-more-menu-copy-btn-loading`}
          />
        )}
        Make a copy
      </OwcListItem>
      <OwcListItem
        data-testid={`dilutions-list-card-list-item-${index}-more-menu-delete-btn`}
        iconType="outlined"
        icon="delete"
        noBorder
        disabled={pendingAction}
        onClick={async () => {
          setMenuOpen(false);
          await deleteDilution();
          refetch();
        }}
      >
        {pendingAction && (
          <ButtonLoading
            testId={`dilutions-list-card-list-item-${index}-more-menu-delete-btn-loading`}
          />
        )}
        Delete
      </OwcListItem>
    </>
  );
};

export default ShortCardMenuItems;
