import { CognitoUser } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import AnonymousRouting from "./AnonymousRouting";
import "./App.css";
import styles from "./App.module.scss";
import AppBar from "./components/AppBar/AppBar";
import Container from "./components/Container/Container";
import FullBlockProgress from "./components/FullBlockProgress/FullBlockProgress";
import Routing from "./Routing";
import { usePreventScrollInNumberInput } from "./utils/hooks/usePreventScrollInNumberInput";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const checkAuthenticated = async () => {
      try {
        const data: CognitoUser = await Auth.currentAuthenticatedUser();
        setIsAuthenticated(Boolean(data));
      } catch (e) {
        setIsAuthenticated(false);
      }
    };

    checkAuthenticated();
  }, []);

  usePreventScrollInNumberInput();

  return (
    <div data-testid="app-box" className={styles.wrapper}>
      <AppBar isAuthenticated={isAuthenticated} />
      <div className={styles.innerWrapper}>
        {isAuthenticated === null && (
          <Container>
            <FullBlockProgress />
          </Container>
        )}
        {isAuthenticated === false && <AnonymousRouting />}
        {isAuthenticated === true && <Routing />}
      </div>
    </div>
  );
}

export default App;
