import { useContext } from "react";
import { ValidationParamsContext } from "./ValidationParamsContext";

export const useValidationParams = () => {
  const context = useContext(ValidationParamsContext);

  if (!context) {
    throw new Error(
      "You cannot use useValidationParams outside ValidationParamsWrapper!"
    );
  }

  return context;
};
