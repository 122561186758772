import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { MastermixDilution } from "../../../../../models/Dilution";
import { getStringFieldInputProps } from "../../../../../utils/helpers/getStringFieldInputProps";
import { maxNumbersFormComment } from "../../mastermixSchema/mastermixGroupSchema";

interface ReagentCommentProps {
  groupIndex: number;
  reagentIndex: number;
}

const ReagentComment = ({ groupIndex, reagentIndex }: ReagentCommentProps) => {
  return (
    <Controller<
      MastermixDilution,
      `dilutionDetails.groups.${typeof groupIndex}.reagents.${typeof reagentIndex}.comment`
    >
      name={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.comment`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          maxLength={maxNumbersFormComment}
          data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.comment-input`}
          label="Comment"
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.comment-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default ReagentComment;
