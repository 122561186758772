import { useCallback } from "react";
import { FetchResult } from "react-apollo";
import { Navigate, useNavigate } from "react-router-dom";
import {
  DilutionType,
  DilutionTypeValues,
} from "../../../../../models/DilutionType";
import { Dilution } from "../../../../../models/Dilution";
import FullBlockProgress from "../../../../../components/FullBlockProgress/FullBlockProgress";
import { useValidateStepForDraftDilution } from "../../utils/useValidateStepForDraftDilution";

export const useHandleSaveMutationResult = <T,>({
  navigatePrefix,
  getId,
}: {
  getId: (result: FetchResult<T>) => string;
  navigatePrefix: string;
}) => {
  const navigate = useNavigate();
  return useCallback(
    (result: void | FetchResult<T>) => {
      /* istanbul ignore next */
      if (!result) {
        return false;
      }
      const id = getId(result);
      if (result.errors || !result.data || !id) {
        const error = new Error("Failed to do request");

        (error as unknown as Record<string, unknown>)["graphQLErrors"] =
          result.errors;

        throw error;
      }
      navigate(`${navigatePrefix}`);
      return true;
    },
    [navigate, navigatePrefix, getId]
  );
};

export const getDilutionDetailsDraftLink = (
  dilutionType: DilutionType,
  id: string
): string => {
  switch (dilutionType) {
    case DilutionTypeValues.SERIAL_DILUTION:
      return `/serial-edit/${id}`;
    case DilutionTypeValues.CO_FORMULATED_DILUTION:
      return `/co-formulated-edit/${id}`;
    case DilutionTypeValues.MASTERMIX:
      return `/mastermix-edit/${id}`;
    default:
      throw new Error(`Unknown type ${dilutionType}`);
  }
};

export const DraftRedirectWrapper = <T extends Dilution | null>({
  children,
  dilution,
}: {
  dilution: T;
  children: React.ReactNode;
}) => {
  const { validated, stepType } = useValidateStepForDraftDilution({ dilution });
  if (dilution && dilution.status === "DRAFT") {
    if (!validated) {
      return <FullBlockProgress />;
    }
    const detailsLink = getDilutionDetailsDraftLink(dilution.type, dilution.id);
    return <Navigate to={detailsLink} state={{ stepType }} />;
  }
  return <>{children}</>;
};
