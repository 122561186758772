import { OwcButton } from "@one/react";
import { FetchResult, Mutation } from "react-apollo";
import ButtonLoading from "../../../../../components/button-loading/ButtonLoading";
import button from "../../../../../scss/button/Button.module.scss";
import { saveDraftGql } from "../../../../mutations/saveDraftGql";
import { DilutionInput } from "../../../../../models/DilutionInput";
import { useRunGraphQlWithSnackbars } from "../../../../../utils/hooks/useRunGraphQlWithSnackbars";
import { useFormContext } from "react-hook-form";
import { panelMemberDilutionToDilution } from "./utils/panelMemberDilutionToDilution";
import { HighLevelMaterialDilution } from "../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { useHandleSaveMutationResult } from "./useHandleSaveMutationResult";
import { highLevelMaterialDilutionToDilution } from "../HighLevelMaterialInfoForm/utils/highLevelMaterialDilutionToDilution";

const getId = (
  result: FetchResult<{ saveDraft: { id: string } | null }>
): string => result?.data?.saveDraft?.id ?? "";

export const handleNumberOfReplicates = (value: DilutionInput) =>
  ({
    ...value,
    dilutionDetails: {
      ...value?.dilutionDetails,
      panelMembers: value?.dilutionDetails?.panelMembers?.map((p) => ({
        ...p,
        numberOfReplicates: p?.numberOfReplicates || null,
      })),
    },
  } as DilutionInput);

export const SaveDraft = ({
  step = "first",
}: {
  step?: "first" | "second";
}) => {
  const handleSaveMutationResult = useHandleSaveMutationResult({
    navigatePrefix: "/",
    getId,
  });
  const runGraphQlWithSnackbars = useRunGraphQlWithSnackbars({
    errorMessage: "Saving draft dilution went wrong!",
    successMessage: "Successfully saved draft dilution!",
  });
  const {
    getValues,
    trigger,
    formState: { defaultValues },
  } = useFormContext<HighLevelMaterialDilution>();
  return (
    <Mutation<{ saveDraft: { id: string } | null }, { dilution: DilutionInput }>
      mutation={saveDraftGql}
    >
      {(saveDraft, { loading }) => (
        <OwcButton
          className={button.large}
          flat
          type="button"
          data-testid="save-draft-btn"
          disabled={loading}
          onClick={async () => {
            const validation = await trigger("name", { shouldFocus: true });
            if (validation) {
              await runGraphQlWithSnackbars(async () => {
                const values: HighLevelMaterialDilution = {
                  ...getValues(),
                  panelMembers: getValues()?.panelMembers || 1,
                };
                let dilution: HighLevelMaterialDilution;
                if (step === "first") {
                  dilution = highLevelMaterialDilutionToDilution(
                    values,
                    defaultValues
                  );
                } else {
                  dilution = { ...values };
                }
                const dilutionInput = panelMemberDilutionToDilution(dilution);
                const result = await saveDraft({
                  variables: {
                    dilution: handleNumberOfReplicates(dilutionInput),
                  },
                });
                handleSaveMutationResult(result);
              });
            }
          }}
        >
          {loading && <ButtonLoading testId="save-draft-btn-loading" />}
          Save
        </OwcButton>
      )}
    </Mutation>
  );
};
