import { ReactNode } from "react";
import { Link } from "react-router-dom";

export const TransparentLink = ({
  to,
  children,
  slot,
}: {
  to: string;
  children: ReactNode;
  slot?: string;
}) => {
  return (
    <Link
      to={to}
      slot={slot}
      style={{
        color: "inherit",
        textDecoration: "inherit",
        display: "inherit",
      }}
    >
      {children}
    </Link>
  );
};

export default TransparentLink;
