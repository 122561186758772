import { EXPORT_PDF_CONFIG } from "../constants";
import { calculateMultipleTablesHeights } from "./calculate-multiple-tables-heights";

export interface CellData {
  value: string | string[] | null;
  unit?: string;
  colSpan?: number;
  rowSpan?: number;
  style?: string[];
  styles?: (string[] | undefined)[];
  margin?: number[];
  noBorder?: boolean;
}

export const generateMultipleValuesCellTable = (
  rowIndex: number,
  cellData: CellData
) => {
  const { style, noBorder, styles } = cellData;
  const values = cellData.value as string[];

  const tableItems = values.map((value, index) => [
    {
      text: value,
      border: noBorder
        ? [0, 0, 0, 0]
        : index === values.length - 1
        ? [0, 0, 0, 0]
        : [0, 0, 0, 1],
      style: styles?.[index],
    },
  ]);
  const heights = calculateMultipleTablesHeights(rowIndex, values);

  return {
    style: ["noMargin", ...(style ? style : [])],
    table: {
      widths: ["*"],
      heights,
      body: tableItems,
    },
    layout: {
      paddingTop: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
      paddingBottom: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
      paddingLeft: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
      paddingRight: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
    },
  };
};

export const generateSingleValueCellTable = ({
  value,
  style,
  margin,
}: CellData) => {
  return {
    table: {
      widths: ["*"],
      body: [
        [
          {
            text: value,
            style,
            border: [0, 0, 0, 0],
            margin,
          },
        ],
      ],
    },
    layout: {
      paddingTop: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
      paddingBottom: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
      paddingLeft: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
      paddingRight: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
    },
  };
};

export const generateSingleValueCellWithUnitTable = ({
  value,
  unit,
  style,
}: CellData) => {
  return {
    table: {
      widths: ["*", "auto"],
      body: [
        [
          {
            text: value,
            style,
            border: [0, 0, 0, 0],
          },
          {
            text: unit,
            style,
            border: [0, 0, 0, 0],
          },
        ],
      ],
    },
    layout: {
      paddingTop: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
      paddingBottom: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
      paddingLeft: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
      paddingRight: () => EXPORT_PDF_CONFIG.INSIDE_CELL_PADDING,
    },
  };
};

export const generateTableCellContent = (
  rowIndex: number,
  cellData: CellData
) => {
  return Array.isArray(cellData.value)
    ? generateMultipleValuesCellTable(rowIndex, cellData)
    : generateSingleValueCellTable(cellData);
};
