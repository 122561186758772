import { OwcIcon, OwcIconButton, OwcInput, OwcTypography } from "@one/react";
import { useState } from "react";
import { Controller, ControllerRenderProps, FieldError } from "react-hook-form";
import { MastermixDilution } from "../../../../models/Dilution";
import { getStringFieldInputProps } from "../../../../utils/helpers/getStringFieldInputProps";
import styles from "./GroupName.module.scss";

const GroupNameInput = ({
  error,
  field,
  groupIndex,
}: {
  groupIndex: number;
  field: ControllerRenderProps<
    MastermixDilution,
    `dilutionDetails.groups.${typeof groupIndex}.name`
  >;
  error: FieldError | undefined;
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={styles.groupName}>
      {!visible && (
        <>
          <OwcIconButton
            disableRipple
            data-testid={`dilutionDetails.groups.${groupIndex}-edit-btn`}
            flat
            onClick={() => setVisible(true)}
          >
            <OwcIcon
              data-testid="reagent-group-name-edit-btn"
              name={"edit"}
              type={"legacy"}
            />
          </OwcIconButton>
          <OwcTypography
            variant="subtitle1"
            data-testid={`dilutionDetails.groups.${groupIndex}.name-subtitle`}
          >
            {field.value}
          </OwcTypography>
        </>
      )}
      {visible && (
        <OwcInput
          label="Group name"
          className={styles.input}
          data-testid={`dilutionDetails.groups.${groupIndex}.name-input`}
          {...getStringFieldInputProps(field)}
          autoFocus={true}
          onBlur={() => {
            field.onBlur();
            setVisible(false);
          }}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.groups.${groupIndex}.name-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    </div>
  );
};

interface GroupNameProps {
  groupIndex: number;
}

const GroupName = ({ groupIndex }: GroupNameProps) => {
  return (
    <Controller<
      MastermixDilution,
      `dilutionDetails.groups.${typeof groupIndex}.name`
    >
      name={`dilutionDetails.groups.${groupIndex}.name`}
      render={({ field, fieldState: { error } }) => (
        <GroupNameInput error={error} field={field} groupIndex={groupIndex} />
      )}
    />
  );
};

export default GroupName;
