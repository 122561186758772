import React, { useCallback, useState } from "react";

import Dialog, { DialogProps } from "../shared/Dialog";
import { DialogConfirmOptionsProps } from "../shared/DialogConfirmOptionsProps";
import { ConfirmContext } from "./confirm";

const DEFAULT_OPTIONS: DialogConfirmOptionsProps = {
  title: "Attention",
  description: "Do you want delete this element?",
  confirmationText: "Yes",
  cancellationText: "Cancel",
  size: "md",
};

const buildOptions = (
  defaultOptions: DialogConfirmOptionsProps,
  options: DialogConfirmOptionsProps
) => {
  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...options,
  };
};

interface ConfirmContextWrapperProps {
  children: React.ReactNode;
  defaultOptions?: DialogConfirmOptionsProps;
}

const ConfirmContextWrapper = ({
  children,
  defaultOptions = DEFAULT_OPTIONS,
}: ConfirmContextWrapperProps) => {
  const [options, setOptions] = useState({
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
  });
  const [resolvedValue, setResolveValue] = useState<
    ((reason: boolean) => void)[]
  >([]);
  const [resolveConfirm] = resolvedValue;

  const confirm = useCallback((optionsInline: DialogConfirmOptionsProps) => {
    return new Promise((resolve: (val: boolean) => void) => {
      setOptions(buildOptions(defaultOptions, optionsInline));
      setResolveValue([resolve]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    setResolveValue([]);
  }, []);

  const handleCancel = useCallback(() => {
    resolveConfirm(false);
  }, [resolveConfirm]);

  const handleConfirm = useCallback(() => {
    resolveConfirm(true);
  }, [resolveConfirm]);

  const buttons: DialogProps["buttons"] = [
    {
      closeOnClick: true,
      action: handleCancel,
      label: options.cancellationText ?? "",
      buttonProps: {
        variant: "secondary",
        flat: true,
      },
    },
    {
      closeOnClick: true,
      label: options.confirmationText ?? "",
      action: handleConfirm,
      buttonProps: {
        type: "button",
        flat: true,
      },
    },
  ];
  return (
    <>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      <Dialog
        {...options}
        open={resolvedValue.length === 1}
        onClose={handleClose}
        buttons={buttons}
      >
        {options.description}
      </Dialog>
    </>
  );
};

export default ConfirmContextWrapper;
