import { OwcTypography } from "@one/react";
import { HTMLAttributes } from "react";
import styles from "./OwcInputSuffix.module.scss";

type OwcInputSuffixProps = HTMLAttributes<HTMLOwcTypographyElement>;

const OwcInputSuffix = ({ children, ...props }: OwcInputSuffixProps) => {
  return (
    <OwcTypography
      slot="suffix"
      variant="body1"
      className={styles.suffix}
      {...props}
    >
      {children}
    </OwcTypography>
  );
};

export default OwcInputSuffix;
