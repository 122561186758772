import { yupResolver } from "@hookform/resolvers/yup";
import { OwcTypography } from "@one/react";
import { Mutation } from "react-apollo";
import { FormProvider, useForm } from "react-hook-form";
import UserNotAuthorWarning from "../../../../../components/shared/not-author-warning/UserNotAuthorWarning";

import { DilutionInput } from "../../../../../models/DilutionInput";
import { useRunGraphQlWithSnackbars } from "../../../../../utils/hooks/useRunGraphQlWithSnackbars";

import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import { calculateAndSaveGql } from "../../../../mutations/calculateAndSaveGql";

import { HighLevelMaterialDilution } from "../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { useValidationParams } from "../ValidationParamsContext/useValidationParams";
import { useTriggerErrorsOnEnter } from "./hooks/useTriggerErrorsOnEnter";
import PanelMemberButtons from "./PanelMemberButtons";
import styles from "./PanelMemberForm.module.scss";
import PanelMembersContent from "./PanelMembersContent/PanelMembersContent";
import PreDilutionsContent from "./PreDilutionsContent/PreDilutionsContent";
import { panelMemberFormSchema } from "./schema/panelMemberFormSchema";
import { useHandleCalculateAndSaveMutationResult } from "./useHandleCalculateAndSaveMutationResult";
import { panelMemberDilutionToDilution } from "./utils/panelMemberDilutionToDilution";

interface PanelMemberFormProps {
  dilution: HighLevelMaterialDilution;
  onBack: (currentDilution: HighLevelMaterialDilution) => void;
}

const PanelMemberForm = ({ dilution, onBack }: PanelMemberFormProps) => {
  const { userId } = useUserInfo() ?? {};
  const validation = useValidationParams();
  const methods = useForm<HighLevelMaterialDilution>({
    defaultValues: dilution,
    resolver: yupResolver(panelMemberFormSchema),
    context: {
      get getValues() {
        return methods.getValues;
      },
      validation,
    },
  });
  const preDilutions = methods.watch("dilutionDetails.preDilutions");
  const handleCalculateAndSaveMutationResult =
    useHandleCalculateAndSaveMutationResult("/dilution-details");
  const runGraphQlWithSnackbars = useRunGraphQlWithSnackbars({
    errorMessage: "Saving and calculating dilution went wrong!",
    successMessage: "Successfully saved and calculated dilution!",
  });

  useTriggerErrorsOnEnter(methods);

  return (
    <Mutation<
      { calculateAndSave: { id: string } | null },
      { dilution: DilutionInput }
    >
      mutation={calculateAndSaveGql}
    >
      {(calculateAndSave, { loading }) => {
        return (
          <FormProvider {...methods}>
            <form
              noValidate
              onSubmit={methods.handleSubmit(async (values) => {
                await runGraphQlWithSnackbars(async () => {
                  const { ...dilutionInput } =
                    panelMemberDilutionToDilution(values);

                  const result = await calculateAndSave({
                    variables: { dilution: dilutionInput },
                  });
                  handleCalculateAndSaveMutationResult(result);
                });
              })}
              id={
                dilution.type === "SERIAL_DILUTION"
                  ? "serial-dilution-panel-member-form"
                  : "co-formulated-dilution-panel-member-form"
              }
            >
              <div className={styles.workflow}>
                <div
                  data-testid="serial-dilution-panel-members-title"
                  className={styles.header}
                >
                  <div className={styles.title}>
                    <OwcTypography variant="title5">
                      {dilution.name}
                    </OwcTypography>
                    {dilution.elnId && (
                      <OwcTypography
                        className={styles.subtitle}
                        variant="subtitle2"
                      >
                        ({dilution.elnId})
                      </OwcTypography>
                    )}
                  </div>

                  <PanelMemberButtons onBack={onBack} loading={loading} />
                </div>
                {userId && dilution.id && dilution.createdBy !== userId && (
                  <UserNotAuthorWarning testId="dilution-panel-members-user-warning" />
                )}
                <div className={styles.wrapper}>
                  <div
                    className={styles.preDilution}
                    data-testid="serial-dilution-panel-members-panel-members"
                  >
                    <OwcTypography variant="subtitle1">
                      Panel members
                    </OwcTypography>
                    <PanelMembersContent />
                  </div>
                  {(preDilutions?.length ?? 0) > 0 && (
                    <div
                      className={styles.preDilution}
                      data-testid="serial-dilution-panel-members-pre-dilutions"
                    >
                      <OwcTypography variant="subtitle1">
                        Pre-dilutions
                      </OwcTypography>
                      <PreDilutionsContent />
                    </div>
                  )}
                </div>
                <PanelMemberButtons onBack={onBack} loading={loading} />
              </div>
            </form>
          </FormProvider>
        );
      }}
    </Mutation>
  );
};

export default PanelMemberForm;
