import { useCallback } from "react";
import { FieldPathByValue, useFormContext } from "react-hook-form";
import { Diluent } from "../../../../models/Diluent";
import { HighLevelMaterialDilution } from "../forms/HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { getDiluentId } from "./getDiluentId";

export const useUpdateDiluentId = (
  prefix: FieldPathByValue<HighLevelMaterialDilution, Diluent>
) => {
  const { getValues, setValue } = useFormContext<HighLevelMaterialDilution>();

  return useCallback(() => {
    const number = getValues(`${prefix}.number`);
    const lotNumber = getValues(`${prefix}.lotNumber`);

    const id = getDiluentId({ number, lotNumber });
    setValue(`${prefix}.id`, id);

    return id;
  }, [getValues, setValue, prefix]);
};
