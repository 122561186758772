import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import {
  Calculated,
  CoFormDilution,
} from "../../../../../../../models/Dilution";
import { getPanelMemberInputConcentration } from "../../../HighLevelMaterialInfoForm/utils/getPanelMemberInputConcentration";

export const usePanelMemberInputConcentration = (
  panelMemberIndex: number
): ((currentTargetIndex: number) => Calculated) => {
  const { watch } = useFormContext<CoFormDilution>();
  const preDilutions = watch("dilutionDetails.preDilutions");
  const panelMembers = watch(`dilutionDetails.panelMembers`);

  const getInputConcentration = useCallback(
    (currentTargetIndex: number) => {
      return getPanelMemberInputConcentration(
        panelMemberIndex,
        currentTargetIndex,
        preDilutions ?? [],
        panelMembers,
        panelMembers[panelMemberIndex].targets![currentTargetIndex].material
      );
    },
    [panelMemberIndex, preDilutions, panelMembers]
  );

  return getInputConcentration;
};
