import { v4 } from "uuid";
import { Material } from "../../../../models/coform-models/Material";
import { DilutionType } from "../../../../models/DilutionType";

export const getInputMaterial = (type: DilutionType): Material => {
  const materialId = v4();
  const numberOfPreDilutions = null;
  return {
    highLevelInfo: {
      numberOfPreDilutions,
    },
    id: materialId,
    availableVolume: null,
    lotNumber: null,
    number: null,
    shortName: "",
    stockConcentration: "",
    internalLotNumber: null,
    stockName: "",
    stockUnit: "cp/mL",
    targetUnit: "cp/mL",
    unitFactor: "1.00",
    neededVolume: null,
  };
};
