import { createContext } from "react";

export interface ValidationParamsContextType {
  replicatesPerPanelMemberMin: number;
  replicatesPerPanelMemberMax: number;

  volumeOfReplicateMin: number;
  volumeOfReplicateMax: number;

  numberOfPanelMembersMin: number;
  numberOfPanelMembersMax: number;

  highLevelInfoDeadVolumeMin: number;
  highLevelInfoDeadVolumeMax: number;

  numberOfPreDilutionsMin: number;
  numberOfPreDilutionsMax: number;

  unitFactorMin: number;
  unitFactorMax: number;

  availableVolumeMin: number;
  availableVolumeMax: number;

  stockConcentrationMin?: number;
  stockConcentrationMax?: number;

  numberOfReplicatesMin: number;
  numberOfReplicatesMax: number;

  panelMemberTargetConcentrationMin: number;
  panelMemberTargetConcentrationMax: number;

  preDilutionStepTargetConcentrationMin: number;
  preDilutionStepTargetConcentrationMax: number;

  preDilutionStepDeadVolumeMin: number;
  preDilutionStepDeadVolumeMax: number;

  customLeftOverMin: number;
  customLeftOverMax: number;
}

export const ValidationParamsContext =
  createContext<ValidationParamsContextType | null>(null);
