import {
  ContentTable,
  TableCell,
  TableCellProperties,
} from "pdfmake/interfaces";
import { calculateHeights, Inlines } from "./calculate-heights";

export interface TableCellWithPrivateProperties extends TableCellProperties {
  table: TableCellWithPrivateProperties;
  body: TableCell[][];
  _inlines: Inlines[];
  _height: number;
  _margin: [number, number, number, number];
}

export const applyVerticalAlignment = (
  node: ContentTable,
  rowIndex: number,
  height: number,
  cellWidth: number
) => {
  const cellsHeights = node.table.body[rowIndex].map((cell: TableCell) =>
    calculateHeights(rowIndex, cell, cellWidth)
  );

  node.table.body[rowIndex].forEach((cell, ci) => {
    const hasInnerTable =
      (cell as { table: TableCellWithPrivateProperties }).table &&
      (cell as { table: TableCellWithPrivateProperties }).table.body.length > 1;

    if (hasInnerTable) {
      return;
    }

    const heightDivider = 2;
    const topMargin = (height - cellsHeights[ci]) / heightDivider;
    const { _margin } = cell as TableCellWithPrivateProperties;
    const hasTopMargin = Boolean(_margin && (_margin[1] || _margin[1] === 0));

    _margin
      ? (_margin[1] = hasTopMargin ? _margin[1] : topMargin)
      : ((cell as TableCellWithPrivateProperties)._margin = [
          0,
          topMargin,
          0,
          0,
        ]);
  });
};
