import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import Grid from "../../../../../../components/Grid/Grid";
import LabelWithArrow from "../../../../../../components/LabelWithArrow/LabelWithArrow";
import { CoFormDilution } from "../../../../../../models/Dilution";
import { toFixed } from "../../../../../../utils/helpers/toFixed";

import { LabelDetailRow } from "../common/LabelDetailRow";
import TargetConcentrationField from "./fields/TargetConcentrationField";
import { usePanelMemberInputConcentration } from "./hooks/usePanelMemberInputConcentration";
import { CONCENTRATION_LIMIT_COMMA } from "../../../../constants";

export const TargetFormContent = ({
  panelMemberIndex,
}: {
  panelMemberIndex: number;
}) => {
  const { watch } = useFormContext<CoFormDilution>();
  const targets = watch(
    `dilutionDetails.panelMembers.${panelMemberIndex}.targets`
  );
  const getInputConcentration =
    usePanelMemberInputConcentration(panelMemberIndex);
  return (
    <>
      {targets?.map((target, targetIndex) => (
        <Fragment key={target.material.id}>
          <Grid item columnSpan={7}>
            <LabelDetailRow
              label="Input concentration"
              data-testid={`panel-member-${panelMemberIndex}-target-${targetIndex}-input-concentration`}
            >
              {getInputConcentration(targetIndex).displayedValue
                ? `${toFixed(
                    Number(getInputConcentration(targetIndex).displayedValue),
                    {
                      exponential: getInputConcentration(targetIndex)
                        .displayedValue.toLowerCase()
                        .includes("e"),
                      roundTo: CONCENTRATION_LIMIT_COMMA,
                    }
                  )} ${target.material.targetUnit}`
                : "unknown yet"}
            </LabelDetailRow>
          </Grid>
          <Grid
            item
            columnSpan={2}
            style={{ overflow: "visible", paddingTop: "var(--one-spacer-12)" }}
          >
            {target.material.shortName && (
              <LabelWithArrow
                data-testid={`panel-member-${panelMemberIndex}-target-${targetIndex}-material-short-name`}
              >
                {target.material.shortName}
              </LabelWithArrow>
            )}
          </Grid>
          <Grid
            item
            columnSpan={7}
            style={{ position: "relative", overflow: "visible" }}
          >
            <TargetConcentrationField
              panelMemberIndex={panelMemberIndex}
              targetIndex={targetIndex}
            />
          </Grid>
        </Fragment>
      ))}
    </>
  );
};
