import { OwcButton, OwcTypography } from "@one/react";
import { ReactNode } from "react";
import DWMLogo from "../pic/DWMLogo";
import RocheLogoSmall from "../pic/RocheLogoSmall";
import TransparentLink from "../TransparentLink/TransparentLink";
import styles from "./AppBar.module.scss";

export interface AppBarContentProps {
  children: ReactNode;
}
export const AppBarContent = ({ children }: AppBarContentProps) => {
  return (
    <>
      <TransparentLink to={"/"}>
        <OwcButton
          flat
          type="button"
          variant="secondary"
          data-testid="app-bar-content"
          className={styles.logoButton}
          disableRipple
        >
          <div className={styles.content}>
            <DWMLogo />
            <OwcTypography
              data-testid="app-bar-content-dwm-title"
              className={styles.textLogo}
              variant="subtitle1"
            >
              DWM
            </OwcTypography>
          </div>
        </OwcButton>
      </TransparentLink>
      <div className={styles.content}>
        {children}
        <RocheLogoSmall />
      </div>
    </>
  );
};
