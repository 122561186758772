import { ContentTable } from "pdfmake/interfaces";
import { Diluent } from "../../../../models/Diluent";
import { diluentTable } from "../../shared/diluentTable";

const diluentsTables = (diluents: Diluent[]) => {
  const standaloneDiluentTable = (diluent: Diluent, diluentIndex: number) => ({
    unbreakable: true,
    style: ["table", "marginBottomLarge"],
    table: {
      widths: ["*", "*"],
      body: [[diluentTable(diluent, diluentIndex)]],
    },
  });
  const result: ContentTable[] = [
    {
      style: ["table", "tableTitle"],
      table: {
        widths: ["*", "*"],
        body: [["Additional diluents"]],
      },
      layout: "noBorders",
    },
  ];

  for (let i = 0; i < diluents.length; i++) {
    const diluent = diluents[i];
    result.push(standaloneDiluentTable(diluent, i + 1));
  }

  return result;
};
export default diluentsTables;
