import { PageOrientation, TDocumentDefinitions } from "pdfmake/interfaces";
import { EXPORT_PDF_CONFIG } from "../constants";
import images from "../images";
import footer from "../shared/footer";
import header from "../shared/header";
import styles, { generateMargin, marginLargeSize } from "../styles";

export const generateTemplate = ({
  pageOrientation = "portrait",
  dilutionName,
}: {
  pageOrientation?: PageOrientation;
  dilutionName: string;
}): TDocumentDefinitions => {
  const topPageMultiplier = 2;
  const pageMargins = generateMargin(
    marginLargeSize,
    EXPORT_PDF_CONFIG.PAGE_VERTICAL_MARGIN * topPageMultiplier,
    marginLargeSize,
    EXPORT_PDF_CONFIG.PAGE_VERTICAL_MARGIN
  );

  return {
    pageSize: "A4",
    pageOrientation,
    pageMargins,
    header: () => header(dilutionName),
    footer: (currentPage: number) => footer(currentPage, dilutionName),
    content: [],
    styles,
    images,
  };
};
