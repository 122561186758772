import { format } from "date-fns";

const metaDataTable = ({
  printedBy,
  appVersion,
  additionalRows,
}: {
  appVersion?: string | null;
  printedBy?: string;

  additionalRows?: [string, string][];
}) => ({
  style: ["table", "marginTopLarge"],
  unbreakable: true,
  table: {
    dontBreakRows: true,
    body: [
      ["Date of printing:", format(new Date(), "dd-MMM-yyyy")],
      ["Printed by:", printedBy || "—"],
      ...(additionalRows ?? []),
      ["Version of application: ", appVersion ?? "N/A"],
    ],
  },
  layout: "noBorders",
});

export default metaDataTable;
