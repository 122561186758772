import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../../models/Dilution";
import { getReagentPcrConcentration } from "../utlis/getReagentPcrConcentration";

export const useUpdateReagentPcrConcentration = () => {
  const { getValues, setValue, formState } =
    useFormContext<MastermixDilution>();

  return useCallback(
    (groupIndex: number, reagentIndex: number) => {
      const r2Volume = getValues(`dilutionDetails.r2Volume`);
      const reactionVolume = getValues(`dilutionDetails.reactionVolume`);
      const reagentR2Concentration = getValues(
        `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.r2Concentration`
      );
      const prcConcentration = getReagentPcrConcentration(
        r2Volume,
        reactionVolume,
        reagentR2Concentration
      );
      setValue(
        `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.pcrConcentration`,
        prcConcentration,
        {
          shouldValidate: formState.submitCount > 0,
        }
      );
    },
    [setValue, getValues, formState]
  );
};
