import { OwcCard, OwcDivider } from "@one/react";
import { Fragment } from "react";
import {
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
} from "react-hook-form";
import { HighLevelMaterialDilution } from "../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { usePreDilutionStepRemove } from "../PreDilutionStepContent/hooks/usePreDilutionStepRemove";
import PreDilutionStepContent from "../PreDilutionStepContent/PreDilutionStepContent";
import { usePreDilutionAutoRemove } from "./hooks/usePreDilutionAutoRemove";
import styles from "./PreDilutionContent.module.scss";

const PreDilutionContent = ({
  preDilutionIndex,
  fieldArray: preDilutionsFieldArray,
}: {
  preDilutionIndex: number;
  fieldArray: UseFieldArrayReturn<
    HighLevelMaterialDilution,
    `dilutionDetails.preDilutions`,
    "id"
  >;
}) => {
  const { watch } = useFormContext<HighLevelMaterialDilution>();
  const preDilution = watch(`dilutionDetails.preDilutions.${preDilutionIndex}`);
  const fieldArray = useFieldArray<
    HighLevelMaterialDilution,
    `dilutionDetails.preDilutions.${typeof preDilutionIndex}.dilutionSteps`
  >({
    name: `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps`,
  });
  const { fields } = fieldArray;
  const remove = usePreDilutionStepRemove(preDilutionIndex, fieldArray);

  usePreDilutionAutoRemove(preDilutionIndex, preDilutionsFieldArray);

  return (
    <OwcCard
      className={styles.card}
      key={preDilution.preDilutionNumber}
      data-testid={`pre-dilution-${preDilutionIndex}-card`}
    >
      <div className={styles.cardContent}>
        {fields?.map((dilutionStep, index, arr) => {
          return (
            <Fragment key={dilutionStep.id}>
              <PreDilutionStepContent
                {...{
                  fieldArray,
                  index,
                  preDilutionIndex,
                  remove,
                }}
              />
              {index + 1 !== arr.length && <OwcDivider />}
            </Fragment>
          );
        })}
      </div>
    </OwcCard>
  );
};

export default PreDilutionContent;
