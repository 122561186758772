import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { MastermixDilution } from "../../../../models/Dilution";
import { getStringFieldInputProps } from "../../../../utils/helpers/getStringFieldInputProps";

const DiluentLotNumber = () => {
  return (
    <Controller<MastermixDilution, `dilutionDetails.diluent.lotNumber`>
      name={`dilutionDetails.diluent.lotNumber`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`dilutionDetails.diluent.lotNumber-input`}
          label="*Diluent lot no."
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
          required
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.diluent.lotNumber-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default DiluentLotNumber;
