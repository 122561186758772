import { EXPORT_PDF_CONFIG } from "../constants";
import { DILUTION_PDF_CONFIG } from "../dilution-pdf/dilution-pdf.constants";

const savedHeightsForRow: { [key: number]: number[] } = {};
export const calculateMultipleTablesHeights = (
  rowIndex: number,
  values: string[]
): number[] => {
  const approximateNumbersOfLines = values.map((value) => {
    return (
      Math.ceil(
        value.length / DILUTION_PDF_CONFIG.MAX_CHARS_LIMIT_FOR_VALUES
      ) || 1
    );
  });
  const approximateHeights = approximateNumbersOfLines.map((line, index) => {
    const prevHeight =
      savedHeightsForRow[rowIndex] && savedHeightsForRow[rowIndex][index];
    let approximateHeight = line * EXPORT_PDF_CONFIG.CELL_LINE_HEIGHT;

    if (prevHeight && prevHeight > approximateHeight) {
      approximateHeight = prevHeight;
    }

    return approximateHeight;
  });

  savedHeightsForRow[rowIndex] = approximateHeights;
  return approximateHeights;
};
