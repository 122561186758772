import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { MastermixDilution } from "../../../../models/Dilution";
import { getStringFieldInputProps } from "../../../../utils/helpers/getStringFieldInputProps";

const DiluentNumber = () => {
  return (
    <Controller<MastermixDilution, `dilutionDetails.diluent.number`>
      name={`dilutionDetails.diluent.number`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`dilutionDetails.diluent.number-input`}
          label="*Diluent mat. no."
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.diluent.number-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default DiluentNumber;
