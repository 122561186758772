import { OWCTheme } from "@one/design-tokens/js/legacy/light";
import { OwcThemeProvider } from "@one/react";
import React from "react";

interface MaterialThemeProps {
  children: React.ReactNode;
}

const customTheme: Partial<OWCTheme> = {
  "one-typography-desktop-badge-font-weight": "var(--one-text-font-weight-500)",
  "one-text-title-5-font-weight": "var(--one-text-font-weight-500)",
  "one-typography-mobile-title-5-font-weight":
    "var(--one-text-font-weight-500)",
  "one-typography-tablet-title-5-font-weight":
    "var(--one-text-font-weight-500)",
  "one-typography-desktop-title-5-font-weight":
    "var(--one-text-font-weight-500)",
  "one-text-title-5-line-height": "var(--one-text-line-height-117)",
  "one-typography-mobile-title-5-line-height":
    "var(--one-text-line-height-117)",
  "one-typography-tablet-title-5-line-height":
    "var(--one-text-line-height-117)",
  "one-typography-desktop-title-5-line-height":
    "var(--one-text-line-height-117)",
  "one-text-title-4-font-weight": "var(--one-text-font-weight-500)",
  "one-typography-mobile-title-4-font-weight":
    "var(--one-text-font-weight-500)",
  "one-typography-tablet-title-4-font-weight":
    "var(--one-text-font-weight-500)",
  "one-typography-desktop-title-4-font-weight":
    "var(--one-text-font-weight-500)",
  "one-text-title-4-line-height": "var(--one-text-line-height-117)",
  "one-typography-mobile-title-4-line-height":
    "var(--one-text-line-height-117)",
  "one-typography-tablet-title-4-line-height":
    "var(--one-text-line-height-117)",
  "one-typography-desktop-title-4-line-height":
    "var(--one-text-line-height-117)",
  "one-text-subtitle-1-font-weight": "var(--one-text-font-weight-500)",
  "one-typography-mobile-subtitle-1-font-weight":
    "var(--one-text-font-weight-500)",
  "one-typography-tablet-subtitle-1-font-weight":
    "var(--one-text-font-weight-500)",
  "one-typography-desktop-subtitle-1-font-weight":
    "var(--one-text-font-weight-500)",
  "one-text-subtitle-1-line-height": "var(--one-text-line-height-135)",
  "one-typography-mobile-subtitle-1-line-height":
    "var(--one-text-line-height-135)",
  "one-typography-tablet-subtitle-1-line-height":
    "var(--one-text-line-height-135)",
  "one-typography-desktop-subtitle-1-line-height":
    "var(--one-text-line-height-135)",
  "one-text-subtitle-2-line-height": "var(--one-text-line-heigh-77)",
  "one-typography-mobile-subtitle-2-line-height":
    "var(--one-text-line-height-77)",
  "one-typography-tablet-subtitle-2-line-height":
    "var(--one-text-line-height-77)",
  "one-typography-desktop-subtitle-2-line-height":
    "var(--one-text-line-height-77)",
  "one-text-title-6-line-height": "var(--one-text-line-height-130)",
  "one-typography-mobile-title-6-line-height":
    "var(--one-text-line-height-130)",
  "one-typography-tablet-title-6-line-height":
    "var(--one-text-line-height-130)",
  "one-typography-desktop-title-6-line-height":
    "var(--one-text-line-height-130)",
  "one-text-title-6-font-size": "var(--one-text-font-size-px-18)",
  "one-typography-mobile-title-6-font-size": "var(--one-text-font-size-px-18)",
  "one-typography-tablet-title-6-font-size": "var(--one-text-font-size-px-18)",
  "one-typography-desktop-title-6-font-size": "var(--one-text-font-size-px-18)",
  "one-color-interaction-disabled-neutral-1": "var(--one-color-gray-50)",
  "one-color-interaction-disabled-neutral-2": "var(--one-color-gray-500)",
  "one-typography-mobile-body-1-font-size": "var(--one-text-font-size-px-16)",
  "one-typography-tablet-body-1-font-size": "var(--one-text-font-size-px-16)",
  "one-typography-desktop-body-1-font-size": "var(--one-text-font-size-px-16)",
  "one-typography-mobile-body-1-line-height": "var(--one-text-line-height-135)",
  "one-typography-tablet-body-1-line-height": "var(--one-text-line-height-135)",
  "one-typography-desktop-body-1-line-height":
    "var(--one-text-line-height-135)",
  "one-typography-mobile-caption-line-height":
    "var(--one-text-line-height-135)",
  "one-typography-tablet-caption-line-height":
    "var(--one-text-line-height-135)",
  "one-typography-desktop-caption-line-height":
    "var(--one-text-line-height-135)",
  "one-typography-mobile-badge-line-height": "var(--one-text-line-height-135)",
  "one-typography-tablet-badge-line-height": "var(--one-text-line-height-135)",
  "one-typography-desktop-badge-line-height": "var(--one-text-line-height-135)",
};

const ThemeProvider = ({ children }: MaterialThemeProps) => {
  return (
    <OwcThemeProvider customTheme={customTheme}>{children}</OwcThemeProvider>
  );
};

export default ThemeProvider;
