import { ReactNode, useCallback, useState } from "react";
import { DilutionType } from "../../../../models/DilutionType";
import { DilutionListFilterContext } from "./DilutionListFilterContext";

interface DilutionListFilterWrapperProps {
  children: ReactNode;
}

const DilutionListFilterWrapper = ({
  children,
}: DilutionListFilterWrapperProps) => {
  const [searchText, setSearchText] = useState("");
  const [type, setType] = useState<"ALL" | DilutionType>("ALL");

  const clearFilter = useCallback(() => {
    setType("ALL");
    setSearchText("");
  }, []);

  return (
    <DilutionListFilterContext.Provider
      value={{
        searchText,
        setSearchText,
        type,
        setType,
        hasFilter: Boolean(searchText) || type !== "ALL",
        clearFilter,
      }}
    >
      {children}
    </DilutionListFilterContext.Provider>
  );
};

export default DilutionListFilterWrapper;
