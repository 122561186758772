import { OwcIcon, OwcTypography } from "@one/react";
import styles from "./Warning.module.scss";

interface UserNotAuthorWarningProps {
  testId: string;
}

const UserNotAuthorWarning = ({ testId }: UserNotAuthorWarningProps) => {
  return (
    <div data-testid={testId} className={styles.banner}>
      <OwcIcon name="alarm" />
      <OwcTypography variant="body1">
        Please keep in mind that you are not an author of this dilution.
      </OwcTypography>
    </div>
  );
};

export default UserNotAuthorWarning;
