import { Reagent } from "../../../../models/mastermix-models/Reagent";

export const getLastPredilutionStep = (reagent: Reagent) =>
  reagent?.preDilution
    ? reagent?.preDilution?.dilutionSteps?.[
        reagent?.preDilution?.dilutionSteps.length - 1
      ] ?? null
    : null;

export const getPredilutionLastStepName = (reagent: Reagent) => {
  const lastPredilutionStep = getLastPredilutionStep(reagent);
  return (
    `${lastPredilutionStep?.stepName}` +
    (`${lastPredilutionStep?.stepName}`
      .trim()
      .endsWith(`(${reagent.material.stockName})`)
      ? ""
      : ` (${reagent.material.stockName})`)
  );
};

export const getReagentName = (reagent: Reagent) => `${
  reagent.material.stockName
} (
      ${
        !reagent.material.shortName || reagent.material.shortName === ""
          ? "N/A"
          : reagent.material.shortName
      }
      )`;
