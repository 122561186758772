import { OwcBanner, OwcButton, OwcTypography } from "@one/react";
import { ReactNode } from "react";

interface QueryErrorProps {
  message?: ReactNode;
  unknownMessage?: string;
  error: unknown;
  refetch: () => void;
}

const QueryError = ({
  error,
  refetch,
  message,
  unknownMessage = "Ups, something went wrong!",
}: QueryErrorProps) => {
  const refetchButton = (
    <OwcButton
      slot="actions"
      data-testid="error-try-again-btn"
      onClick={() => refetch()}
      color="inherit"
      variant="secondary"
      flat
    >
      Try again
    </OwcButton>
  );

  return (
    <OwcBanner data-testid="error" type="error">
      {message && (
        <OwcTypography variant="subtitle1" data-testid="error-title">
          {message}
        </OwcTypography>
      )}
      <OwcTypography data-testid="error-text">{unknownMessage}</OwcTypography>
      {refetchButton}
    </OwcBanner>
  );
};

export default QueryError;
