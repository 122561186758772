import { Content } from "pdfmake/interfaces";

const footer = (currentPage: number, dilutionName: string): Content => [
  {
    text: `This document is the electronic record of ${dilutionName}.`,

    style: "footerTitle",
  },
  {
    text: currentPage.toString(),
    style: "footerNumber",
  },
];

export default footer;
