import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../../models/Dilution";
import { getReagentR2Concentration } from "../utlis/getReagentR2Concentration";

export const useUpdateReagentR2Concentration = () => {
  const { getValues, setValue, formState } =
    useFormContext<MastermixDilution>();

  return useCallback(
    (groupIndex: number, reagentIndex: number) => {
      const r2Volume = getValues(`dilutionDetails.r2Volume`);
      const reactionVolume = getValues(`dilutionDetails.reactionVolume`);
      const pcrConcentration = getValues(
        `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.pcrConcentration`
      );
      const reagentR2Concentration = getReagentR2Concentration(
        r2Volume,
        reactionVolume,
        pcrConcentration
      );
      setValue(
        `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.r2Concentration`,
        reagentR2Concentration,
        {
          shouldValidate: formState.submitCount > 0,
        }
      );
    },
    [setValue, getValues, formState]
  );
};
