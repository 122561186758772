import gql from "graphql-tag";

export const getDilutionsListQueryGql = gql`
  query ListSortedDilutions(
    $filter: ModelDilutionFilterInput
    $nextToken: String
    $limit: Int = 30
    $location: String = "Rotkreuz"
    $sortDirection: ModelSortDirection = DESC
  ) {
    listSortedDilutions(
      filter: $filter
      nextToken: $nextToken
      limit: $limit
      location: $location
      sortDirection: $sortDirection
    ) {
      items {
        id
        elnId
        name
        updatedAt
        createdAt
        createdBy
        type
        updatedBy
        status
      }
      nextToken
    }
  }
`;
