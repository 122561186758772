import { MastermixDetails } from "../../../../models/mastermix-models/MastermixDetails";
import { getTotalVolumeDetails } from "../../../mastermix-dilution/utils/getTotalVolumeDetails";
import { breakableString } from "../../utils/breakable-string";
import { widths, layout } from "./common";

const volumesTable = (details: MastermixDetails) => {
  const { totalVolumeWithoutMMx } = getTotalVolumeDetails(details);

  return {
    style: ["table", "marginBottomLarge"],
    table: {
      widths,
      body: [
        [
          {
            text: "Total volume MMx without Diluent [μL]",
            border: [1, 0, 0, 1],
          },
          { text: "", border: [0, 0, 0, 1] },
          {
            text: breakableString(`${totalVolumeWithoutMMx}`),
            border: [0, 0, 1, 1],
            style: ["alignedToCenter"],
          },
        ],
        [
          {
            text: breakableString(
              `${details.diluent!.stockName} volume to add [μL]`
            ),
            style: ["bold"],
            border: [1, 1, 0, 1],
          },
          { text: "", border: [0, 0, 0, 1] },
          {
            text: breakableString(
              `${details.diluent!.neededVolume?.displayedValue}`
            ),
            border: [0, 1, 1, 1],
            style: ["alignedToCenter"],
          },
        ],
      ],
    },
    layout,
  };
};

export default volumesTable;
