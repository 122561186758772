/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { limitComma } from "../../../../../../utils/helpers/limitComma";
import { numberKeepStringValidator } from "../../../../../../utils/helpers/numberKeepStringValidator";
import { calculatedSchema } from "../../../../../../utils/schema/calculatedSchema";

const targetConcentrationMessage =
  "Target concentration must be a valid number";
const deadVolumeMessage = "Dead volume must be a valid number";
const DEAD_VOLUME_LIMIT_COMMA = 3;

export const preDilutionStepSchema = yup.object({
  stepName: yup
    .string()
    .required("Pre-dilution name cannot be empty")
    .typeError("Pre-dilution name cannot be empty"),
  targetConcentration: calculatedSchema(
    yup
      .string()
      .typeError(targetConcentrationMessage)
      .test(
        numberKeepStringValidator(
          yup
            .number()
            .required(targetConcentrationMessage)
            .typeError(targetConcentrationMessage)
            .min(
              yup.ref("$validation.preDilutionStepTargetConcentrationMin"),
              "Target concentration must be greater than or equal to ${min}"
            )
            .max(
              yup.ref("$validation.preDilutionStepTargetConcentrationMax"),
              "Target concentration must be less than or equal to ${max}"
            )
        )
      )
  ),

  deadVolume: yup
    .string()
    .typeError(deadVolumeMessage)
    .test(
      numberKeepStringValidator(
        yup
          .number()
          .required(deadVolumeMessage)
          .typeError(deadVolumeMessage)
          .min(
            yup.ref("$validation.preDilutionStepDeadVolumeMin"),
            "Dead volume must be greater than or equal to ${min}"
          )
          .max(
            yup.ref("$validation.preDilutionStepDeadVolumeMax"),
            "Dead volume must be less than or equal to ${max}"
          )
      )
    )
    .test(
      limitComma(
        DEAD_VOLUME_LIMIT_COMMA,
        "Dead volume must have at most 3 digits after comma"
      )
    ),
});

export const preDilutionSchema = yup.object({
  dilutionSteps: yup.array(preDilutionStepSchema).nullable(),
});
