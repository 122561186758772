import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { MastermixDilution } from "../../../../../../models/Dilution";
import { getStringFieldInputProps } from "../../../../../../utils/helpers/getStringFieldInputProps";
import { useUpdatePredulutionInputMaterial } from "../../../pre-dilution-step-content/hooks/useUpdatePredulutionInputMaterial";

interface MaterialLotNumberProps {
  groupIndex: number;
  reagentIndex: number;
}

const MaterialLotNumber = ({
  groupIndex,
  reagentIndex,
}: MaterialLotNumberProps) => {
  const updateFirstPredilution = useUpdatePredulutionInputMaterial({
    groupIndex,
    reagentIndex,
    updateSource: `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.inputMaterial.lotNumber`,
  });
  return (
    <Controller<
      MastermixDilution,
      `dilutionDetails.groups.${typeof groupIndex}.reagents.${typeof reagentIndex}.material.lotNumber`
    >
      name={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.material.lotNumber`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.material.lotNumber-input`}
          label="*Material lot no."
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
          required
          onInputChange={(event) => {
            field.onChange(event.detail);
            updateFirstPredilution(event.detail);
          }}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}..material.lotNumber-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default MaterialLotNumber;
