import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { CoFormDilution } from "../../../../../../../models/Dilution";
import { getStringFieldInputProps } from "../../../../../../../utils/helpers/getStringFieldInputProps";

const PreDilutionNameField = ({
  preDilutionIndex,
  stepIndex,
}: {
  preDilutionIndex: number;
  stepIndex: number;
}) => {
  return (
    <Controller<
      CoFormDilution,
      `dilutionDetails.preDilutions.${typeof preDilutionIndex}.dilutionSteps.${typeof stepIndex}.stepName`
    >
      name={`dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${stepIndex}.stepName`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${stepIndex}.stepName-input`}
          label="*Pre-dilution name"
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${stepIndex}.stepName-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default PreDilutionNameField;
