import { OwcIcon, OwcIconButton, OwcInput } from "@one/react";
import { Controller, useFormContext } from "react-hook-form";
import OwcInputSuffix from "../../../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { CoFormDilution } from "../../../../../../../models/Dilution";
import button from "../../../../../../../scss/button/Button.module.scss";
import { getCalculatedOnChangeFieldInput } from "../../../../../../../utils/helpers/getCalculatedOnChangeFieldInput";
import { getCalculatedValueFieldInput } from "../../../../../../../utils/helpers/getCalculatedValueFieldInput";
import { HighLevelMaterialDilution } from "../../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { useValidationParams } from "../../../ValidationParamsContext/useValidationParams";
import styles from "./TargetConcentrationField.module.scss";
import { useUpdateFirstPanelMemberTarget } from "./useUpdateFirstPanelMemberTarget";
import { useUpdateNotFirstPanelMemberTargets } from "./useUpdateNotFirstPanelMemberTargets";

const ClipperIcon = () => (
  <div data-testid="clipper-icon" className={styles.clipper}>
    <OwcIcon className={styles.clipperIcon} name="link" />
  </div>
);

const TargetConcentrationField = ({
  panelMemberIndex,
  targetIndex,
}: {
  panelMemberIndex: number;
  targetIndex: number;
}) => {
  const { watch, trigger, getValues } =
    useFormContext<HighLevelMaterialDilution>();
  const targetUnit = watch(
    `dilutionDetails.panelMembers.${panelMemberIndex}.targets.${targetIndex}.material.targetUnit`
  );
  const type = watch("type");
  const {
    panelMemberTargetConcentrationMin,
    panelMemberTargetConcentrationMax,
  } = useValidationParams();

  const updateFirstPanelMemberTarget = useUpdateFirstPanelMemberTarget(
    panelMemberIndex,
    targetIndex
  );
  const updateNotFirstPanelMemberTargets = useUpdateNotFirstPanelMemberTargets(
    panelMemberIndex,
    targetIndex
  );
  const isNotFirstCoFormPanelMember =
    type === "CO_FORMULATED_DILUTION" && panelMemberIndex > 0;

  return (
    <>
      {isNotFirstCoFormPanelMember && targetIndex > 0 && <ClipperIcon />}
      <Controller<
        CoFormDilution,
        `dilutionDetails.panelMembers.${typeof panelMemberIndex}.targets.${typeof targetIndex}.targetConcentration`
      >
        name={`dilutionDetails.panelMembers.${panelMemberIndex}.targets.${targetIndex}.targetConcentration`}
        render={({ field, fieldState: { error } }) => (
          <OwcInput
            data-testid={`panel-member-dilutionDetails.panelMembers.${panelMemberIndex}.targets.${targetIndex}.targetConcentration-input`}
            label="*Target concentration"
            type="number"
            min={panelMemberTargetConcentrationMin}
            max={panelMemberTargetConcentrationMax}
            {...field}
            value={getCalculatedValueFieldInput(field.value)}
            onInputChange={(event) => {
              const theValue = getValues(
                `dilutionDetails.panelMembers.${panelMemberIndex}.targets.${targetIndex}.targetConcentration.displayedValue`
              );
              /* istanbul ignore next */
              if (theValue === event.detail) {
                // It's already set to that value; skip on change
                return;
              }

              getCalculatedOnChangeFieldInput(field.onChange)(event);

              trigger(
                `dilutionDetails.panelMembers.${panelMemberIndex}.targets`
              );
              trigger(
                `dilutionDetails.panelMembers.${panelMemberIndex + 1}.targets`
              );

              if (type === "CO_FORMULATED_DILUTION" && panelMemberIndex === 0) {
                updateFirstPanelMemberTarget({
                  calculatedValue: event.detail,
                  displayedValue: event.detail,
                });
              }
            }}
            error={Boolean(error)}
          >
            <span
              slot="error-text"
              data-testid={`panel-member-dilutionDetails.panelMembers.${panelMemberIndex}.targets.${targetIndex}.targetConcentration.-error`}
            >
              {error?.message}
            </span>
            <div slot="suffix">
              <OwcInputSuffix slot={undefined}>{targetUnit}</OwcInputSuffix>
              {isNotFirstCoFormPanelMember && (
                <OwcIconButton
                  data-testid={`panel-member-dilutionDetails.panelMembers.${panelMemberIndex}.targets.${targetIndex}.targetConcentration-recalculate-btn`}
                  flat
                  className={button.colorPrimary}
                  onClick={() => {
                    updateNotFirstPanelMemberTargets(field.value);

                    trigger(
                      `dilutionDetails.panelMembers.${panelMemberIndex}.targets`
                    );
                    trigger(
                      `dilutionDetails.panelMembers.${
                        panelMemberIndex + 1
                      }.targets`
                    );
                  }}
                >
                  <OwcIcon variant="primary" name="priority_low" />
                </OwcIconButton>
              )}
            </div>
          </OwcInput>
        )}
      />
    </>
  );
};

export default TargetConcentrationField;
