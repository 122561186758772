import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import OwcInputSuffix from "../../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { getNumberFieldInputProps } from "../../../../../../utils/helpers/getNumberFieldInputProps";
import {
  r1VolumeMax,
  r1VolumeMin,
} from "../../../mastermixSchema/maxtermixSchemaMinMaxValue";
import { ReactionVolumeInputsForm } from "../ReactionVolumeInputsForm";

const R1Volume = () => {
  return (
    <Controller<ReactionVolumeInputsForm, `r1Volume`>
      name={`r1Volume`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`r1Volume-input`}
          label="*R1"
          {...getNumberFieldInputProps(field)}
          type="number"
          error={Boolean(error)}
          min={r1VolumeMin}
          max={r1VolumeMax}
          required
        >
          <span slot="error-text" data-testid={`r1Volume-error`}>
            {error?.message}
          </span>
          <OwcInputSuffix>μL</OwcInputSuffix>
        </OwcInput>
      )}
    />
  );
};

export default R1Volume;
