import MastermixDetailsPage from "../../features/mastermix-dilution/display-mastermix/MastermixDetailsPage";
import DilutionPageTemplate from "../DilutionPageTemplate";
import { getMastermixDilutionQueryGql } from "./queries/getMastermixDilutionQueryGql";

const MastermixDetailsPageView = () => {
  return (
    <DilutionPageTemplate
      component={MastermixDetailsPage}
      titleForError={`Loading display details page for dilution`}
      query={getMastermixDilutionQueryGql}
    />
  );
};

export default MastermixDetailsPageView;
