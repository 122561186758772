import { OwcButton, OwcCard, OwcIcon, OwcInput } from "@one/react";
import clsx from "clsx";
import { useCallback } from "react";
import {
  Controller,
  UseFieldArrayReturn,
  useFormContext,
} from "react-hook-form";
import { useConfirm } from "../../../../../components/confirm-context/useConfirm";
import Grid from "../../../../../components/Grid/Grid";
import { Dilution } from "../../../../../models/Dilution";
import { DilutionType } from "../../../../../models/DilutionType";
import button from "../../../../../scss/button/Button.module.scss";
import { HighLevelMaterialDilution } from "../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import MaterialAvailableVolumeField from "./fields/MaterialAvailableVolumeField";
import MaterialLotNumberField from "./fields/MaterialLotNumberField";
import MaterialNumberField from "./fields/MaterialNumberField";
import MaterialNumberOfPreDilutionsField from "./fields/MaterialNumberOfPreDilutionsField";
import MaterialShortNameField from "./fields/MaterialShortNameField";
import MaterialStockConcentrationField from "./fields/MaterialStockConcentrationField";
import MaterialStockNameField from "./fields/MaterialStockNameField";
import MaterialStockUnitField from "./fields/MaterialStockUnitField";
import MaterialTargetUnitField from "./fields/MaterialTargetUnitField";
import MaterialUnitFactorField from "./fields/MaterialUnitFactorField";
import styles from "./MaterialFormContent.module.scss";

export interface MaterialFormContentProps {
  index: number;
  typeOfDilution: DilutionType;
  fieldArray: UseFieldArrayReturn<
    HighLevelMaterialDilution,
    "dilutionDetails.inputMaterials",
    "id"
  >;
}

const MaterialFormContent = ({
  index,
  typeOfDilution,
  fieldArray,
}: MaterialFormContentProps) => {
  const confirm = useConfirm();
  const { watch, trigger } = useFormContext<HighLevelMaterialDilution>();
  const material = watch(`dilutionDetails.inputMaterials.${index}`);
  const remove = useCallback(async () => {
    if (
      await confirm({
        description: `Do you really want to delete material ${material?.shortName}?`,
      })
    ) {
      fieldArray.remove(index);
      // if the last material is being removed, then trigger validation
      if (fieldArray.fields.length === 1) {
        trigger("dilutionDetails.inputMaterials");
      }
    }
  }, [material, index, fieldArray, confirm, trigger]);

  return (
    <OwcCard data-testid="material-form-content">
      <div className={styles.cardContent}>
        <Grid container columnGap={24} rowGap={16} columns={12}>
          <Grid
            item
            {...(typeOfDilution === "CO_FORMULATED_DILUTION"
              ? { columnSpan: 6 }
              : { columnSpan: 12 })}
          >
            <MaterialShortNameField index={index} />
          </Grid>
          {typeOfDilution === "CO_FORMULATED_DILUTION" && fieldArray && (
            <Grid item className={styles.buttons} columnSpan={6}>
              <OwcButton
                onClick={() => remove()}
                className={clsx(button.large, button.colorError)}
                flat
                variant="secondary"
                data-testid={`material-form-content-delete-material-${index}-btn`}
              >
                Delete
              </OwcButton>
            </Grid>
          )}
          <Grid item columnSpan={6}>
            <MaterialStockNameField index={index} />
          </Grid>
          <Grid item columnSpan={6} className={styles.materialConcentration}>
            <MaterialStockConcentrationField index={index} />
            <MaterialStockUnitField index={index} />
          </Grid>
          <Grid item className={styles.stockMaterial} columnSpan={6}>
            <MaterialNumberField index={index} />
            <MaterialLotNumberField index={index} />
          </Grid>
          <Grid item columnSpan={6} className={styles.unitFactor}>
            <MaterialUnitFactorField index={index} />
            <Controller<
              Dilution,
              `dilutionDetails.inputMaterials.${typeof index}.stockUnit`
            >
              name={`dilutionDetails.inputMaterials.${index}.stockUnit`}
              render={({ field: { value } }) => (
                <OwcInput disabled label={value ?? ""} />
              )}
            />
            <div className={styles.withArrow}>
              <MaterialTargetUnitField index={index} />
              <OwcIcon name="arrow_rightwards" className={styles.arrow} />
            </div>
          </Grid>
          <Grid item columnSpan={6}>
            <MaterialAvailableVolumeField index={index} />
          </Grid>
          <Grid item columnSpan={6}>
            <MaterialNumberOfPreDilutionsField index={index} />
          </Grid>
        </Grid>
      </div>
    </OwcCard>
  );
};

export default MaterialFormContent;
