import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { CoFormDilution } from "../../../../../../models/Dilution";
import { getNumberFieldInputProps } from "../../../../../../utils/helpers/getNumberFieldInputProps";
import { useValidationParams } from "../../ValidationParamsContext/useValidationParams";

const MaterialStockConcentrationField = ({ index }: { index: number }) => {
  const { stockConcentrationMin, stockConcentrationMax } =
    useValidationParams();
  return (
    <Controller<
      CoFormDilution,
      `dilutionDetails.inputMaterials.${typeof index}.stockConcentration`
    >
      name={`dilutionDetails.inputMaterials.${index}.stockConcentration`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`inputMaterials-stockConcentration-${index}-input`}
          label="*Stock mat. concentration"
          required
          type="number"
          min={stockConcentrationMin}
          max={stockConcentrationMax}
          {...getNumberFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`inputMaterials-stockConcentration-${index}-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default MaterialStockConcentrationField;
