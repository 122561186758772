import { OwcButton, OwcExpandable, OwcIcon } from "@one/react";
import clsx from "clsx";
import { HTMLAttributes, ReactNode, useState } from "react";
import styles from "./Collapse.module.scss";

interface CollapseProps extends HTMLAttributes<HTMLOwcExpandableElement> {
  defaultExpanded?: boolean;
  titleSlot?: ReactNode;
  testId: string;
  children?: ReactNode;
}

const Collapse = ({
  defaultExpanded = true,
  titleSlot,
  testId,
  children,
  ...props
}: CollapseProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <OwcExpandable
      data-testid={`${testId}`}
      variant="dense"
      {...props}
      className={clsx(styles.collapse, props.className)}
      expanded={expanded}
      onStatusChanged={(event) => setExpanded(event.detail)}
    >
      <div slot="control">
        <OwcButton data-testid={`${testId}-btn`} flat variant="secondary">
          <div className={styles.controlWrapper}>
            {expanded ? "collapse" : "expand"}
            <OwcIcon
              data-testid={`${testId}-icon`}
              name={expanded ? "chevron_up" : "chevron_down"}
            />
          </div>
        </OwcButton>
      </div>
      {titleSlot && (
        <div data-testid={`${testId}-title`} slot="title">
          {titleSlot}
        </div>
      )}
      {children && (
        <div data-testid={`${testId}-content`} slot="content">
          {children}
        </div>
      )}
    </OwcExpandable>
  );
};

export default Collapse;
