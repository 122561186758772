import { OwcButton } from "@one/react";
import { useNavigate } from "react-router-dom";
import ButtonLoading from "../../../components/button-loading/ButtonLoading";
import button from "../../../scss//button/Button.module.scss";
import styles from "./ManageMastermixFormContent.module.scss";
import { SaveMastermixDraft } from "./SaveMastermixDraft";

const MastermixButtons = ({ loading }: { loading: boolean }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.buttons} data-testid="mastermix-buttons">
      <OwcButton
        data-testid="mastermix-cancel-btn"
        onClick={() => navigate("/experiment-type")}
        className={button.large}
        flat
        variant="secondary"
        disabled={loading}
      >
        {loading && <ButtonLoading testId="mastermix-cancel-btn-loading" />}
        Cancel
      </OwcButton>
      <SaveMastermixDraft />
      <OwcButton
        className={button.large}
        flat
        type="submit"
        data-testid="mastermix-calc-save-btn"
        disabled={loading}
      >
        {loading && <ButtonLoading testId="mastermix-calc-save-btn-loading" />}
        Calculate & Save
      </OwcButton>
    </div>
  );
};

export default MastermixButtons;
