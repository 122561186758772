import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../../models/Dilution";
import { getTotalVolume } from "../utils/getTotalVolume";

export const useUpdateTotalVolume = () => {
  const { getValues, setValue, formState } =
    useFormContext<MastermixDilution>();

  return useCallback(() => {
    const r2Volume = getValues(`dilutionDetails.r2Volume`);
    const numberOfReactions = getValues(`dilutionDetails.numberOfReactions`);
    const totalVolume = getTotalVolume(r2Volume, numberOfReactions);
    setValue("dilutionDetails.totalVolume", totalVolume, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: formState.submitCount > 0,
    });
  }, [getValues, setValue, formState]);
};
