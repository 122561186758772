import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DilutionType } from "../../models/DilutionType";

export const useNavigateToEditPage = () => {
  const navigate = useNavigate();
  return useCallback(
    (id: string, type: DilutionType) => {
      const urls: Record<DilutionType, string> = {
        CO_FORMULATED_DILUTION: `/co-formulated-edit/${id}`,
        SERIAL_DILUTION: `/serial-edit/${id}`,
        MASTERMIX: `/mastermix-edit/${id}`,
      };
      navigate(urls[type]);
    },
    [navigate]
  );
};
