import { useContext } from "react";
import { DilutionListModeContext } from "./DilutionListModeContext";

export const useDilutionListMode = () => {
  const context = useContext(DilutionListModeContext);

  if (!context) {
    throw new Error(
      "You cannot use useDilutionListMode outside DilutionListModeWrapper!"
    );
  }

  return context;
};
