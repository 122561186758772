import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";

export const getNumberFieldInputProps = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>(
  field: ControllerRenderProps<TFieldValues, TName>
) => ({
  ...field,
  onInputChange: (event: CustomEvent<string>) => field.onChange(event.detail),
  value:
    field.value !== null && field.value !== undefined ? `${field.value}` : "",
});
