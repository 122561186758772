import { PreDilutionInput } from "../../../../../../models/coform-models/PreDilutionInput";
import { DiluentInput } from "../../../../../../models/DiluentInput";

export const getDiluentsArray = (
  preDilutions: PreDilutionInput[] | null,
  firstDiluent: DiluentInput
): DiluentInput[] | null => {
  if (!preDilutions) {
    return [firstDiluent];
  }

  const diluentsWithoutFirst = preDilutions
    .flatMap(
      (preDilution) =>
        preDilution.dilutionSteps?.map((step) => step.diluent) ?? []
    )
    .filter((diluent) => diluent.id !== firstDiluent.id);

  const uniqueDiluents = Array.from(
    new Map(
      diluentsWithoutFirst.map((diluent) => [diluent.id, { ...diluent }])
    ).values()
  );

  uniqueDiluents.unshift(firstDiluent);

  return uniqueDiluents;
};
