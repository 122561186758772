import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { getStringFieldInputProps } from "../../../../../utils/helpers/getStringFieldInputProps";
import { MastermixDilution } from "../../../../../models/Dilution";

const PreDilutionNameField = ({
  groupIndex,
  reagentIndex,
  stepIndex,
}: {
  groupIndex: number;
  reagentIndex: number;
  stepIndex: number;
}) => {
  return (
    <Controller<
      MastermixDilution,
      `dilutionDetails.groups.${typeof groupIndex}.reagents.${typeof reagentIndex}.preDilution.dilutionSteps.${typeof stepIndex}.stepName`
    >
      name={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${stepIndex}.stepName`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${stepIndex}.stepName-input`}
          label="*Pre-dilution name"
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.${stepIndex}.stepName-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default PreDilutionNameField;
