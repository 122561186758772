import { useFormContext } from "react-hook-form";
import { Calculated } from "../../../../../../models/Dilution";
import { toFixed } from "../../../../../../utils/helpers/toFixed";
import { DILUENT_FACTOR_MAX, DILUENT_FACTOR_MIN } from "../../../../constants";
import { HighLevelMaterialDilution } from "../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";

import { usePanelMemberInputConcentration } from "./hooks/usePanelMemberInputConcentration";

export const usePanelMemberFactor = (panelMemberIndex: number) => {
  const { watch } = useFormContext<HighLevelMaterialDilution>();
  const getInputConcentration =
    usePanelMemberInputConcentration(panelMemberIndex);
  const isNegative = watch(
    `dilutionDetails.panelMembers.${panelMemberIndex}.isNegative`
  );
  if (isNegative) {
    return {
      factor: null,
      waringFactorMore: false,
      waringFactorLess: false,
      isNegative,
    };
  }
  const targetsOrNull = watch(
    `dilutionDetails.panelMembers.${panelMemberIndex}.targets`
  );
  const targets = targetsOrNull ?? [];
  const allDilutionFactorsInTargetsArray: string[] = [];
  targets.forEach((theTarget, targetIndex) => {
    const theInput = getInputConcentration(targetIndex);
    if (
      theInput &&
      theInput.calculatedValue &&
      theTarget.targetConcentration &&
      theTarget.targetConcentration?.calculatedValue
    ) {
      allDilutionFactorsInTargetsArray.push(
        (
          Number(theInput.calculatedValue) /
          Number(theTarget.targetConcentration.calculatedValue)
        ).toString()
      );
    } else {
      allDilutionFactorsInTargetsArray.push("");
    }
  });
  const allSame = allDilutionFactorsInTargetsArray.every(
    (value) => allDilutionFactorsInTargetsArray[0] === value
  );
  const target = Number(targets[0].targetConcentration?.calculatedValue || 0);
  const input = getInputConcentration(0).calculatedValue;
  const factor: Calculated | null =
    allSame && target !== 0 && input !== ""
      ? {
          calculatedValue: toFixed(Number(input) / target, { roundTo: null }),
          displayedValue: toFixed(Number(input) / target, { roundTo: 2 }),
        }
      : null;
  const waringFactorMore =
    (factor ? Number(factor.displayedValue) : 0) > DILUENT_FACTOR_MAX;
  const waringFactorLess =
    (factor ? Number(factor.calculatedValue) : 1) < DILUENT_FACTOR_MIN;
  return { factor, waringFactorMore, waringFactorLess, isNegative };
};
