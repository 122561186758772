import { useCallback } from "react";
import { FieldPathByValue, useFormContext } from "react-hook-form";

import { MastermixDilution } from "../../../../../models/Dilution";
import { Diluent } from "../../../../../models/Diluent";
import { getDiluentId } from "../../../../common-dilution/serial-and-co-formulated-dilution/utils/getDiluentId";

export const useUpdateDiluentId = (
  prefix: FieldPathByValue<MastermixDilution, Diluent>
) => {
  const { getValues, setValue } = useFormContext<MastermixDilution>();

  return useCallback(() => {
    const number = getValues(`${prefix}.number`);
    const lotNumber = getValues(`${prefix}.lotNumber`);

    const id = getDiluentId({ number, lotNumber });
    setValue(`${prefix}.id`, id);

    return id;
  }, [getValues, setValue, prefix]);
};
