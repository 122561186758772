import { ReactNode } from "react";
import { QueryContext, QueryContextType } from "./QueryContext";

interface QueryWrapperProps extends QueryContextType {
  children: ReactNode;
}

const QueryWrapper = ({ children, ...props }: QueryWrapperProps) => {
  return (
    <QueryContext.Provider value={props}>{children}</QueryContext.Provider>
  );
};

export default QueryWrapper;
