import { yupResolver } from "@hookform/resolvers/yup";
import { createPortal } from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import Dialog, { DialogProps } from "../../components/shared/Dialog";
import { PreDilutionStep } from "../../models/coform-models/PreDilutionStep";
import { DiluentStepForm } from "./DiluentStepForm";
import PreDilutionStepChangeDiluentDialogContent from "./PreDilutionStepChangeDiluentDialogContent";
import { useValidationParams } from "../common-dilution/serial-and-co-formulated-dilution/forms/ValidationParamsContext/useValidationParams";

interface PreDilutionStepChangeDiluentDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (values: DiluentStepForm) => void;
  preDilutionStep: PreDilutionStep;
  preDilutionIndex: number;
}
export const schema = yup.object({
  stockName: yup
    .string()
    .required("Diluent material name cannot be empty")
    .typeError("Diluent material name cannot be empty"),

  number: yup
    .string()
    .required("Diluent material number cannot be empty")
    .typeError("Diluent material number cannot be empty"),
});

const PreDilutionStepChangeDiluentDialog = ({
  open,
  onClose,
  onConfirm,
  preDilutionStep,
  preDilutionIndex,
}: PreDilutionStepChangeDiluentDialogProps) => {
  const validation = useValidationParams();
  const diluent = preDilutionStep.diluent;
  const methods = useForm<DiluentStepForm>({
    defaultValues: {
      lotNumber: diluent.lotNumber,
      number: diluent.number,
      /* istanbul ignore next */
      stockName: diluent.stockName ?? "",
    },
    resolver: yupResolver(schema),
    context: { validation },
  });
  const handleCancel = () => {
    methods.reset();
    onClose();
  };
  const handleConfirm = (values: DiluentStepForm) => {
    onConfirm(values);
    methods.reset(values);
    onClose();
  };

  const formId = `change-diluent-for-step-form-${preDilutionIndex}-${preDilutionStep.stepNumber}`;

  const buttons: DialogProps["buttons"] = [
    {
      closeOnClick: true,
      action: handleCancel,
      label: "Cancel",
      buttonProps: {
        variant: "secondary",
        flat: true,
      },
    },
    {
      closeOnClick: false,
      label: "Confirm",
      buttonProps: {
        type: "submit",
        flat: true,
        ...{ form: formId },
      },
    },
  ];
  return createPortal(
    <FormProvider {...methods}>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          methods.handleSubmit((values) => {
            handleConfirm(values);
          })(event);
        }}
        noValidate
        id={formId}
      >
        <Dialog
          disableBackdropClick
          disableEscapeKeydown
          onClose={handleCancel}
          title={`You are editing diluent for pre dilution step: "${preDilutionStep.stepName}"`}
          buttons={buttons}
          open={open}
        >
          <PreDilutionStepChangeDiluentDialogContent />
        </Dialog>
      </form>
    </FormProvider>,
    document.body
  );
};

export default PreDilutionStepChangeDiluentDialog;
