import { OwcButton, OwcIcon, OwcIconButton, OwcMenu } from "@one/react";
import { useRef, useState } from "react";
import { createPortal } from "react-dom";
import ButtonLoading from "../../../components/button-loading/ButtonLoading";
import { useQueryContext } from "../../../components/query-context/useQueryContext";
import { StatusDilutionType } from "../../../models/Dilution";

import button from "../../../scss/button/Button.module.scss";
import { useDeleteDilution } from "../../delete-dilution/useDeleteDilution";
import { useMarkAsVerifiedDilution } from "../../mark-as-verified-dilution/useMarkAsVerifiedDilution";
import styles from "./MastermixDetailsPageButtons.module.scss";
import { MastermixDetailsPageButtonsMenuItems } from "./MastermixDetailsPageButtonsMenuItems";

const MastermixDetailsPageButtons = ({
  id,
  name,
  elnId,
  status,
  createdBy,
  generateMastermixPdf,
  generateDilutionJSON,
}: {
  id: string;
  name: string;
  elnId: string | null;
  status: StatusDilutionType;
  createdBy: string | null | undefined;
  generateMastermixPdf: () => void;
  generateDilutionJSON: () => void;
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLOwcIconButtonElement | null>(null);
  const handleMenu = () => {
    setMenuOpen((prev) => !prev);
  };
  const { refetch } = useQueryContext();
  const { deleteDilution, loading: pendingDelete } = useDeleteDilution({
    id,
    elnId,
    name,
    createdBy,
  });
  const { markAsVerifiedDilution, loading: pendingVerified } =
    useMarkAsVerifiedDilution({
      id,
      elnId,
      name,
    });

  const pendingAction = pendingDelete || pendingVerified;

  return (
    <div className={styles.buttons}>
      <OwcButton
        className={button.large}
        flat
        variant="secondary"
        data-testid="mastermix-details-page-start-experiment-btn"
        disabled={pendingAction}
      >
        {pendingAction && (
          <ButtonLoading testId="mastermix-details-page-start-experiment-btn-loading" />
        )}
        Start experiment
      </OwcButton>
      <OwcButton
        className={button.large}
        flat
        variant="primary"
        data-testid="mastermix-details-page-verify-btn"
        onClick={async () => {
          setMenuOpen(false);
          await markAsVerifiedDilution();
          refetch();
        }}
        disabled={pendingAction || status === "VERIFIED"}
      >
        {pendingAction && (
          <ButtonLoading testId="mastermix-details-page-verify-btn-loading" />
        )}
        Verify
      </OwcButton>
      <OwcIconButton
        disableRipple
        data-testid="mastermix-details-page-more-menu-btn"
        onClick={handleMenu}
        ref={menuRef}
        flat
      >
        <OwcIcon
          data-testid="mastermix-details-page-more-menu-btn-icon"
          name="more_horizontal"
          type={"legacy"}
        />
      </OwcIconButton>

      {menuRef.current &&
        createPortal(
          <OwcMenu
            data-testid="mastermix-details-page-more-menu"
            anchor={menuRef.current}
            visible={Boolean(menuOpen)}
            disablePortal
            onClickOutside={() => {
              setMenuOpen(false);
            }}
            alignment="right"
          >
            <MastermixDetailsPageButtonsMenuItems
              id={id}
              pendingAction={pendingAction}
              status={status}
              setMenuOpen={setMenuOpen}
              deleteDilution={deleteDilution}
              generateMastermixPdf={generateMastermixPdf}
              generateDilutionJSON={generateDilutionJSON}
            />
          </OwcMenu>,
          document.body
        )}
    </div>
  );
};

export default MastermixDetailsPageButtons;
