import Container from "../components/Container/Container";
import SerialDilutionWorkflow from "../features/serial-dilution/SerialDilutionWorkflow/SerialDilutionWorkflow";

const SerialCreatePage = () => {
  return (
    <Container>
      <SerialDilutionWorkflow />
    </Container>
  );
};

export default SerialCreatePage;
