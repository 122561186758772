import { useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../../models/Dilution";
import PreDilutionContent from "../pre-dilution-content/PreDilutionContent";
import { OwcTypography } from "@one/react";
import styles from "./ReagentsPredilution.module.scss";

export interface ReagentGroupsContentProps {
  groupIndex: number;
}

const ReagentPredilution = ({
  groupIndex,
  reagentIndex,
}: ReagentGroupsContentProps & { reagentIndex: number }) => {
  const { watch } = useFormContext<MastermixDilution>();
  const preDilutionNumber = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.preDilutionNumber`
  );
  if (!preDilutionNumber) {
    return null;
  }
  return (
    <div
      className={styles.preDilution}
      data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.pre-dilution`}
    >
      <OwcTypography variant="subtitle1">Pre-dilutions</OwcTypography>
      <PreDilutionContent
        groupIndex={groupIndex}
        reagentIndex={reagentIndex}
        key={`${groupIndex}-${reagentIndex}-${preDilutionNumber}`}
      />
    </div>
  );
};

const ReagentsPredilution = ({ groupIndex }: ReagentGroupsContentProps) => {
  const { watch } = useFormContext<MastermixDilution>();
  const reagents = watch(`dilutionDetails.groups.${groupIndex}.reagents`);
  return (
    <>
      {reagents?.map((_, index) => {
        return (
          <ReagentPredilution
            groupIndex={groupIndex}
            reagentIndex={index}
            key={`group-${groupIndex}-reagent-${index}-predilution`}
          />
        );
      })}
    </>
  );
};

export default ReagentsPredilution;
