import { OwcTypography } from "@one/react";
import { TypographyVariant } from "@one/web-components";
import clsx from "clsx";
import { ReactNode } from "react";
import styles from "./DilutionDetailsPagePreDilutionsSection.module.scss";

export const FlexLine = ({
  label,
  labelTestId,
  labelVariant = "body1",
  valueTestId,
  value,
  valueVariant = "title6",
  hasWarning = false,
  hasAccent = false,
  keepAlign = false,
}: {
  label: ReactNode;
  labelTestId: string;
  labelVariant?: TypographyVariant;
  valueTestId: string;
  value: ReactNode;
  valueVariant?: TypographyVariant;
  hasWarning?: boolean;
  hasAccent?: boolean;
  keepAlign?: boolean;
}) => (
  <div
    className={clsx({
      [styles.flexLine]: true,
      [styles.keepAlign]: keepAlign,
    })}
  >
    <OwcTypography data-testid={labelTestId} variant={labelVariant}>
      {label}
    </OwcTypography>
    <OwcTypography
      data-testid={valueTestId}
      variant={valueVariant}
      className={clsx({
        [styles.accent]: hasAccent,
        [styles.warningAccent]: hasWarning,
      })}
    >
      {value}
    </OwcTypography>
  </div>
);
