import { OwcTypography } from "@one/react";
import { DilutionShortInfo } from "../../views/main-view/MainView";
import styles from "./DilutionsList.module.scss";

const DilutionShortInfoCardContent = ({
  index,
  dilutionShortInfo,
}: {
  index: number;
  dilutionShortInfo: DilutionShortInfo;
}) => {
  return (
    <div
      data-testid={`dilution-card-list-dilution-${index}-card-btn-body`}
      className={styles.cardBody}
    >
      <OwcTypography
        data-testid={`dilution-card-list-dilution-${index}-card-btn-body-name`}
        className={styles.title}
        variant="subtitle1"
      >
        {dilutionShortInfo.name}
      </OwcTypography>
      <OwcTypography
        data-testid={`dilution-card-list-dilution-${index}-card-btn-body-userId`}
        variant="subtitle2"
      >
        {dilutionShortInfo.updatedBy}
      </OwcTypography>

      <OwcTypography
        data-testid={`dilution-card-list-dilution-${index}-card-btn-body-elnId`}
        variant="subtitle2"
      >
        {dilutionShortInfo.elnId || " "}
      </OwcTypography>
    </div>
  );
};

export default DilutionShortInfoCardContent;
