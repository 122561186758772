import { useContext } from "react";
import { DilutionListFilterContext } from "./DilutionListFilterContext";

export const useDilutionListFilter = () => {
  const context = useContext(DilutionListFilterContext);

  if (!context) {
    throw new Error(
      "You cannot use useDilutionListFilter outside DilutionListFilterWrapper!"
    );
  }

  return context;
};
