import { breakableString } from "./breakable-string";

export const parseToBreakableStrings = (value: string | string[] | null) => {
  if (!value) {
    return "";
  }
  if (Array.isArray(value)) {
    return value.map((rowItem) => breakableString(rowItem));
  }
  return breakableString(value);
};
