import clsx from "clsx";
import { HTMLAttributes } from "react";
import styles from "./Container.module.scss";

const Container = ({
  fullWidth = false,
  ...props
}: HTMLAttributes<HTMLDivElement> & { fullWidth?: boolean }) => {
  return (
    <div
      {...props}
      className={clsx(
        styles.container,
        {
          [styles.fullWidth]: fullWidth,
        },
        props.className
      )}
    />
  );
};

export default Container;
