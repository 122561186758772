import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { DiluentStepForm } from "../DiluentStepForm";
import { getStringFieldInputProps } from "../../../utils/helpers/getStringFieldInputProps";

const PreDilutionStepDiluentLotNumberField = () => {
  return (
    <Controller<DiluentStepForm, "lotNumber">
      name="lotNumber"
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid="lotNumber-input"
          label="Diluent lot no."
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span slot="error-text" data-testid="lotNumber-error">
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default PreDilutionStepDiluentLotNumberField;
