import { ReactNode, useEffect, useState } from "react";
import packageJson from "../../../../package.json";
import awsExports from "../../../aws-exports";
import {
  AppVersionsContext,
  AppVersionsContextType,
} from "./AppVersionsContext";

interface AppVersionsWrapperProps {
  children: ReactNode;
}

const AppVersionsWrapper = ({ children }: AppVersionsWrapperProps) => {
  const [value, setValue] = useState<AppVersionsContextType>({
    frontendVersion: {},
    backendVersion: {},
  });
  const getFrontendVersion = () => {
    setValue((prev) => ({
      ...prev,
      frontendVersion: {
        version: packageJson.version,
        pipelineId: awsExports.pipeline_id,
        commitHash: awsExports.commit_hash,
      },
    }));
  };

  const getBackendVersion = () => {
    fetch("version.json")
      .then((response) => response.json())
      .then((result) => {
        const { commitHash, pipelineId, version } = result;
        setValue((prev) => ({
          ...prev,
          backendVersion: {
            version,
            pipelineId,
            commitHash,
          },
        }));
      })
      .catch(() => {
        setValue((prev) => ({
          ...prev,
          backendVersion: {
            version: null,
            pipelineId: null,
            commitHash: null,
          },
        }));
      });
  };

  useEffect(() => {
    getFrontendVersion();
    getBackendVersion();
  }, []);

  return (
    <AppVersionsContext.Provider value={value}>
      {children}
    </AppVersionsContext.Provider>
  );
};

export default AppVersionsWrapper;
