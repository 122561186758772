import {
  OwcIcon,
  OwcInput,
  OwcListItem,
  OwcSelectDropdown,
  OwcTypography,
} from "@one/react";
import { useEffect, useState } from "react";
import ButtonToggle from "../../components/button-toggle/ButtonToggle";
import { DilutionType } from "../../models/DilutionType";
import { useDebounce } from "../../utils/hooks/useDebounce";
import { useDilutionListFilter } from "./context/dilution-list-filter/useDilutionListFilter";
import { useDilutionListMode } from "./context/dilution-list-mode/useDilutionListMode";
import styles from "./DilutionsList.module.scss";

const options: { label: string; value: "ALL" | DilutionType }[] = [
  {
    label: "All dilutions",
    value: "ALL",
  },
  {
    label: "Serial dilutions",
    value: "SERIAL_DILUTION",
  },
  {
    label: "Co-formulated dilutions",
    value: "CO_FORMULATED_DILUTION",
  },
  {
    label: "Mastermix (MMx)",
    value: "MASTERMIX",
  },
];
const DEBOUNCED_TIME = 500;
export const DilutionsListHeader = () => {
  const { type, setType, searchText, setSearchText } = useDilutionListFilter();
  const { mode, setMode } = useDilutionListMode();
  const [value, setValue] = useState(searchText);
  const debouncedValue = useDebounce(value, DEBOUNCED_TIME);

  useEffect(() => {
    setSearchText(debouncedValue);
  }, [debouncedValue, setSearchText]);

  return (
    <div data-testid="dilution-list-header" className={styles.headerSection}>
      <OwcTypography data-testid="dilution-list-header-title" variant="title5">
        Dilutions
      </OwcTypography>
      <div
        data-testid="dilution-list-header-filtering-section"
        className={styles.filteringSection}
      >
        <OwcTypography
          data-testid="dilution-list-header-filtering-section-show"
          variant="body1"
        >
          Show:
        </OwcTypography>
        <OwcSelectDropdown
          data-testid="dilution-list-header-filtering-section-types-select"
          outlined={true}
          onSelectChange={(event) => {
            setType(options[event.detail[0]].value);
          }}
        >
          {options.map((option) => (
            <OwcListItem
              data-testid={`dilution-list-header-filtering-section-type-select-item-${option.value}`}
              selected={option.value === type}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </OwcListItem>
          ))}
        </OwcSelectDropdown>
        <OwcInput
          data-testid="dilution-list-header-filtering-section-search-input"
          variant="outlined"
          label="Search for dilution..."
          value={value}
          onInputChange={(event) => {
            setValue(event.detail || "");
          }}
        >
          <div slot="prefix">
            <OwcIcon name="search" />
          </div>
        </OwcInput>
        <ButtonToggle
          testId="dilution-list-header-filtering-section-view-switcher"
          value={mode}
          leftValue={"card" as const}
          rightValue={"list" as const}
          onChange={setMode}
          leftTestId="dilution-list-header-filtering-section-view-switcher-card-btn"
          rightTestId="dilution-list-header-filtering-section-view-switcher-list-btn"
          leftIconProps={{
            name: "overview",
            type: "legacy",
          }}
          rightIconProps={{
            name: "list_bullet",
            type: "outlined",
          }}
        />
      </div>
    </div>
  );
};
