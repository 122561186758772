import { OwcTypography } from "@one/react";
import clsx from "clsx";
import Grid from "../../../../components/Grid/Grid";
import { Calculated } from "../../../../models/Dilution";
import styles from "./DilutionDetailsPageMaterialsSection.module.scss";

export const StockMaterialBlueGrid = ({
  materialIndex,
  nameLabel,
  nameValue,
  lotNumber,
  neededVolume,
  testIdGrid,
  unit,
}: {
  materialIndex: number;
  nameLabel: string;
  nameValue: string;
  lotNumber: string | null;
  neededVolume: Calculated | null;
  testIdGrid: string;
  unit?: string;
}) => (
  <div className={clsx(styles.grid, styles.gridBlue)}>
    <Grid columnGap={16} rowGap={4} columns={16} container>
      <Grid item columnSpan={8}>
        <OwcTypography
          data-testid={`dilution-details-page-materials-section-${testIdGrid}-name-title-${materialIndex}`}
          variant="caption"
        >
          {nameLabel}
        </OwcTypography>
      </Grid>
      <Grid item columnSpan={4}>
        <OwcTypography
          data-testid={`dilution-details-page-materials-section-${testIdGrid}-lot-title-${materialIndex}`}
          variant="caption"
        >
          Lot number
        </OwcTypography>
      </Grid>
      <Grid item columnSpan={4}>
        <OwcTypography
          className={styles.toRight}
          data-testid={`dilution-details-page-materials-section-${testIdGrid}-volume-title-${materialIndex}`}
          variant="caption"
        >
          Volume needed
        </OwcTypography>
      </Grid>
      <Grid item columnSpan={8}>
        <OwcTypography
          data-testid={`dilution-details-page-materials-section-${testIdGrid}-name-value-${materialIndex}`}
          variant="subtitle1"
        >
          {nameValue}
        </OwcTypography>
      </Grid>
      <Grid item columnSpan={4}>
        <OwcTypography
          data-testid={`dilution-details-page-materials-section-${testIdGrid}-lot-value-${materialIndex}`}
          variant="subtitle1"
        >
          {lotNumber}
        </OwcTypography>
      </Grid>
      <Grid item columnSpan={4}>
        <OwcTypography
          className={clsx(styles.volume, styles.toRight)}
          data-testid={`dilution-details-page-materials-section-${testIdGrid}-volume-value-${materialIndex}`}
          variant="subtitle1"
        >
          {neededVolume?.displayedValue} {unit ?? "mL"}
        </OwcTypography>
      </Grid>
    </Grid>
  </div>
);
