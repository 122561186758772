import { OwcButton, OwcTypography } from "@one/react";
import { ComponentProps, ReactNode } from "react";
import buttonStyles from "../../../scss/button/Button.module.scss";
import styles from "./Hero.module.scss";

type OwcButtonProps = ComponentProps<typeof OwcButton>;

interface HeroProps {
  title: string;
  subtitle?: string;
  action?: () => void;
  pic: ReactNode;
  actionName?: string;
  testId: string;
  actionProps?: OwcButtonProps;
  children?: string | JSX.Element | JSX.Element[];
}

const Hero = ({
  title,
  subtitle,
  action,
  pic,
  actionName,
  testId,
  actionProps,
  children,
}: HeroProps) => {
  return (
    <div data-testid={testId} className={styles.wrapper}>
      {pic}
      <OwcTypography
        data-testid={`${testId}-title`}
        style={{
          marginTop: "var(--one-spacer-32)",
          color: "var(--one-color-blue-900)",
        }}
        variant="title4"
      >
        {title}
      </OwcTypography>
      {subtitle && (
        <OwcTypography
          data-testid={`${testId}-subtitle`}
          style={{
            marginTop: "var(--one-spacer-8)",
          }}
          variant="subtitle1"
        >
          {subtitle}
        </OwcTypography>
      )}
      <OwcButton
        data-testid={`${testId}-btn`}
        onClick={action}
        className={buttonStyles.large}
        style={{
          marginTop: "var(--one-spacer-24)",
        }}
        flat
        {...actionProps}
      >
        {actionName}
      </OwcButton>
      {children}
    </div>
  );
};

export default Hero;
