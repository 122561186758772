import { OwcTypography } from "@one/react";
import clsx from "clsx";
import { HTMLAttributes, ReactNode } from "react";
import styles from "../PanelMemberForm.module.scss";

export const LabelDetailRow = ({
  label,
  children,
  ...props
}: {
  label: ReactNode;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>) => (
  <div
    {...props}
    className={clsx(
      styles.flexBetween,
      styles.middleWithInput,
      props.className
    )}
  >
    <div>{label}</div>
    <OwcTypography variant="title6">{children}</OwcTypography>
  </div>
);
