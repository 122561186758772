import { OwcCard, OwcDivider } from "@one/react";
import { Fragment } from "react";
import clsx from "clsx";
import { useFieldArray, useFormContext } from "react-hook-form";
import styles from "./PreDilutionContent.module.scss";
import { MastermixDilution } from "../../../../models/Dilution";
import PreDilutionStepContent from "../pre-dilution-step-content/PreDilutionStepContent";
import { usePreDilutionStepRemove } from "../pre-dilution-step-content/hooks/usePreDilutionStepRemove";
import { usePreDilutionAutoRemove } from "./hooks/usePreDilutionAutoRemove";

const PreDilutionContent = ({
  reagentIndex,
  groupIndex,
}: {
  groupIndex: number;
  reagentIndex: number;
}) => {
  const { watch } = useFormContext<MastermixDilution>();
  const preDilution = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution`
  );
  const fieldArray = useFieldArray<
    MastermixDilution,
    `dilutionDetails.groups.${typeof groupIndex}.reagents.${typeof reagentIndex}.preDilution.dilutionSteps`
  >({
    name: `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps`,
  });
  const { fields } = fieldArray;
  const remove = usePreDilutionStepRemove({
    fieldArray,
    groupIndex,
    reagentIndex,
  });
  usePreDilutionAutoRemove({ groupIndex, reagentIndex });
  return (
    <OwcCard
      className={clsx({
        [styles.card]: !!preDilution?.preDilutionNumber,
      })}
      key={preDilution?.preDilutionNumber}
      data-testid={`mastermix-dilution-group-${groupIndex}-reagent-${reagentIndex}-predilution-card`}
    >
      <div className={styles.cardContent}>
        {fields?.map((dilutionStep, index, arr) => {
          return (
            <Fragment key={dilutionStep.id}>
              <PreDilutionStepContent
                {...{
                  fieldArray,
                  groupIndex,
                  reagentIndex,
                  index,
                  remove,
                }}
              />
              {index + 1 !== arr.length && <OwcDivider />}
            </Fragment>
          );
        })}
      </div>
    </OwcCard>
  );
};

export default PreDilutionContent;
