import Grid from "../../components/Grid/Grid";
import PreDilutionStepDiluentLotNumberField from "./fields/PreDilutionStepDiluentLotNumberField";
import PreDilutionStepDiluentNameField from "./fields/PreDilutionStepDiluentNameField";
import PreDilutionStepDiluentNumberField from "./fields/PreDilutionStepDiluentNumberField";

const PreDilutionStepChangeDiluentDialogContent = () => {
  return (
    <Grid columnGap={12} rowGap={4} columns={12} container>
      <Grid item columnSpan={4}>
        <PreDilutionStepDiluentNameField />
      </Grid>
      <Grid item columnSpan={4}>
        <PreDilutionStepDiluentNumberField />
      </Grid>
      <Grid item columnSpan={4}>
        <PreDilutionStepDiluentLotNumberField />
      </Grid>
    </Grid>
  );
};

export default PreDilutionStepChangeDiluentDialogContent;
