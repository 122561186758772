import { OwcTypography } from "@one/react";
import { ReactNode } from "react";
import styles from "../PanelMemberForm.module.scss";

export const LabelDetailColumn = ({
  label,
  children,
  detail,
  subdetail,
  testId,
  detailProps: { color: detailColor = "gray800" } = {},
}: {
  label: ReactNode;
  children: ReactNode;
  detail: ReactNode;
  subdetail?: ReactNode;
  testId: string;
  detailProps?: {
    color?: "gray800" | "gray600" | "primary";
  };
}) => (
  <>
    <OwcTypography
      data-testid={`${testId}-label`}
      variant="caption"
      className={styles.gray600}
    >
      {label}
    </OwcTypography>
    <OwcTypography
      data-testid={`${testId}-children`}
      variant="body1"
      className={styles.gray800}
    >
      {children}
    </OwcTypography>
    <OwcTypography
      data-testid={`${testId}-detail`}
      variant="badge"
      className={styles[detailColor]}
    >
      {detail}
    </OwcTypography>
    {subdetail && (
      <OwcTypography
        data-testid={`${testId}-subdetail`}
        variant="badge"
        className={styles[detailColor]}
      >
        {subdetail}
      </OwcTypography>
    )}
  </>
);
