import { TDocumentDefinitions } from "pdfmake/interfaces";
import { MastermixDilution } from "../../../models/Dilution";
import { UserInfo } from "../../../utils/hooks/useUserInfo";

import idTable from "../shared/id-table";
import metaDataTable from "../shared/meta-data-table";
import { generateTemplate } from "../utils/generate-template";
import infoTable from "./tables/info-table";
import mastermixTable from "./tables/mastermix-table";
import volumesTable from "./tables/volumes-table";
import reagentsWithPredilutionTables, {
  getReagentsWithPredilution,
} from "./tables/reagents-with-pre-dilutions-tables";
import additionalDilutionsTables from "./tables/diluents-tables";
export const generateMastermixPdf = (
  dilution: MastermixDilution,
  userInfo: UserInfo | null
): TDocumentDefinitions => {
  const { elnId, status, updatedAt, updatedBy, name } = dilution;
  const { groups, preDiluents } = dilution.dilutionDetails!;
  const reagentsWithPredilution = getReagentsWithPredilution(groups ?? []);
  return {
    ...generateTemplate({ pageOrientation: "landscape", dilutionName: name }),
    content: [
      idTable({ elnId, status, updatedBy }),
      infoTable(dilution.dilutionDetails!),
      mastermixTable(dilution.dilutionDetails!),
      volumesTable(dilution.dilutionDetails!),
      preDiluents && preDiluents?.length
        ? additionalDilutionsTables(preDiluents)
        : [],
      reagentsWithPredilution && reagentsWithPredilution.length
        ? reagentsWithPredilutionTables(reagentsWithPredilution)
        : [],
      metaDataTable({
        printedBy: userInfo?.userId,
        appVersion: dilution?.appVersion,
        additionalRows: [
          ["Last modified by:", updatedBy || "—"],
          [
            "Last modified at:",
            updatedAt ? new Date(updatedAt).toLocaleString("en-GB") : "—",
          ],
          ["*", "Concentration in PCR * PCR Reaction Volume / Volume of R2"],
          ["**", "Concentration in R2 * Volume of R2 / PCR Reaction Volume"],
          [
            "***",
            "Concentration in PCR * PCR Reaction Volume / Stock Concentration",
          ],
          [
            "****",
            "Concentration R2 * Amount of total MMx R2 * 1000 / Stock Concentration",
          ],
          ["*****", "Total Volume / Dilution Factor"],
          ["******", "Total Volume - Volume of Predecessor"],
          ["*******", "Source Concentration / Target Concentration"],
          ["********", "Volume needed for next step + Dead Volume"],
        ],
      }),
    ],
  };
};
