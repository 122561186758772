import { MastermixMaterial } from "../../../../models/coform-models/Material";
import { ReagentGroup } from "../../../../models/mastermix-models/ReagentGroup";
import { REAGENT_GROUP_TYPE_TO_NAME } from "./getEmptyMasterMix";
import { getEmptyReagent } from "./getEmptyReagent";
import { getEmptyReagentGroup } from "./getEmptyReagentGroup";

const genericsMaterialsInfoArray: Partial<MastermixMaterial>[] = [
  { number: "11889516102", stockName: "dATP" },
  { number: "11889508102", stockName: "dCTP" },
  { number: "11889524102", stockName: "dGTP" },
  { number: "11889532102", stockName: "dUTP" },
  { number: "06641253001", stockName: "UNG" },
  { stockName: "Aptamer" },
  {
    number: "07006209001",
    stockName: "Bulk ENZ ZO5 D",
    shortName: "Polymerase",
  },
];
export const getEmptyReagentGroupGenerics = (): ReagentGroup => {
  return {
    ...getEmptyReagentGroup(
      REAGENT_GROUP_TYPE_TO_NAME["GENERIC"],
      "GENERIC",
      0
    ),
    reagents: genericsMaterialsInfoArray.map((material) =>
      getEmptyReagent(material)
    ),
  };
};
