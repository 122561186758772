import { useCallback, useState } from "react";
import { useApolloClient } from "../../components/apollo-client-context/ApolloClientWrapper";
import { useConfirm } from "../../components/confirm-context/useConfirm";
import { useRunGraphQlWithSnackbars } from "../../utils/hooks/useRunGraphQlWithSnackbars";
import { markAsVerifiedDilutionMutation } from "./markAsVerifiedDilutionMutation.Gql";

export const useMarkAsVerifiedDilution = ({
  id,
  name,
  elnId,
}: {
  id: string;
  name: string;
  elnId: string | null;
}) => {
  const confirm = useConfirm();
  const runGraphQlWithSnackbars = useRunGraphQlWithSnackbars({
    errorMessage: "Marking dilution went wrong!",
    successMessage: "Successfully marked dilution!",
  });

  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  return {
    loading,
    markAsVerifiedDilution: useCallback(async () => {
      if (
        await confirm({
          description: `
          Are you sure, you want to mark this dilution "${name}${
            elnId ? ` (${elnId})` : ""
          }" as verified? If you verify your Dilution, it will be no longer editable.`,
        })
      ) {
        setLoading(true);
        return runGraphQlWithSnackbars(
          async () => {
            const result = await client.mutate<{
              markAsVerified: { id: string } | null;
            }>({
              mutation: markAsVerifiedDilutionMutation,
              variables: { id },
            });

            if (result.errors || !result.data || !result.data.markAsVerified) {
              const error = new Error("Failed to do request");

              (error as unknown as Record<string, unknown>)["graphQLErrors"] =
                result.errors;

              throw error;
            }

            return true;
          },
          () => false,
          () => setLoading(false)
        );
      }

      return false;
    }, [confirm, id, name, elnId, client, runGraphQlWithSnackbars]),
  };
};
