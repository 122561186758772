const config = {
  aws_project_region: "eu-central-1",
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: "eu-central-1_EdCHAE83j",
  aws_user_pools_web_client_id: "7de07vovok0lucsepkgbeqsepf",
  oauth: {
    domain: "auth.stage.dwm.navify.com",
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: `${window.location?.origin}/`,
    redirectSignOut: `${window.location?.origin}/`,
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint: "https://api.stage.dwm.navify.com/graphql",
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  pipeline_id: "18168663",
  commit_hash: "5a21e2fc",
};

export const federated = {
  googleClientId: "Roche",
};

export default config;
