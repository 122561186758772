import { useCallback } from "react";
import { FetchResult } from "react-apollo";
import { useNavigate } from "react-router-dom";

export const useHandleCalculateAndSaveMutationResult = (
  navigatePrefix: string
) => {
  const navigate = useNavigate();

  return useCallback(
    (
      result: void | FetchResult<{ calculateAndSave: { id: string } | null }>
    ) => {
      /* istanbul ignore next */
      if (!result) {
        return false;
      }
      if (result.errors || !result.data || !result.data?.calculateAndSave?.id) {
        const error = new Error("Failed to do request");

        (error as unknown as Record<string, unknown>)["graphQLErrors"] =
          result.errors;

        throw error;
      }
      navigate(`${navigatePrefix}/${result.data.calculateAndSave.id}`);
      return true;
    },
    [navigate, navigatePrefix]
  );
};
