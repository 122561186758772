import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import OwcInputSuffix from "../../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { getNumberFieldInputProps } from "../../../../../../utils/helpers/getNumberFieldInputProps";
import {
  eluateVolumeMax,
  eluateVolumeMin,
} from "../../../mastermixSchema/maxtermixSchemaMinMaxValue";
import { ReactionVolumeInputsForm } from "../ReactionVolumeInputsForm";

const EluateVolume = () => {
  return (
    <Controller<ReactionVolumeInputsForm, `eluateVolume`>
      name={`eluateVolume`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`eluateVolume-input`}
          label="*Eluate"
          {...getNumberFieldInputProps(field)}
          type="number"
          error={Boolean(error)}
          min={eluateVolumeMin}
          max={eluateVolumeMax}
          required
        >
          <span slot="error-text" data-testid={`eluateVolume-error`}>
            {error?.message}
          </span>
          <OwcInputSuffix>μL</OwcInputSuffix>
        </OwcInput>
      )}
    />
  );
};

export default EluateVolume;
