import { useFormContext } from "react-hook-form";
import { Target } from "../../../../../../../models/coform-models/Target";
import { Calculated } from "../../../../../../../models/Dilution";
import { toFixed } from "../../../../../../../utils/helpers/toFixed";
import { HighLevelMaterialDilution } from "../../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { getPanelMemberInputConcentration } from "../../../HighLevelMaterialInfoForm/utils/getPanelMemberInputConcentration";
import { CONCENTRATION_LIMIT_COMMA } from "../../../../../constants";

export const useUpdateTargetsInPanelMember = (targetIndex: number) => {
  const { watch, setValue } = useFormContext<HighLevelMaterialDilution>();
  const preDilutions = watch(`dilutionDetails.preDilutions`) ?? [];
  const panelMembers = watch(`dilutionDetails.panelMembers`);

  return (
    targetsToUpdate: Target[],
    dilutionFactor: Calculated,
    panelIndex: number
  ) => {
    targetsToUpdate.forEach((otherTarget, otherTargetIndex) => {
      if (otherTargetIndex === targetIndex) {
        return;
      }

      const otherInputConcentration = getPanelMemberInputConcentration(
        panelIndex,
        otherTargetIndex,
        preDilutions,
        panelMembers,
        otherTarget.material
      );
      const otherInputNumber = Number(otherInputConcentration.displayedValue);

      if (
        otherInputConcentration.calculatedValue === "" ||
        isNaN(otherInputNumber) ||
        otherInputNumber === 0 ||
        !dilutionFactor
      ) {
        return;
      }
      const dilutionFactorNumber = Number(dilutionFactor.calculatedValue);

      const newTargetConcentration = {
        calculatedValue: toFixed(otherInputNumber / dilutionFactorNumber, {
          roundTo: null,
          exponential: otherInputConcentration.calculatedValue
            .toLowerCase()
            .includes("e"),
        }),
        displayedValue: toFixed(otherInputNumber / dilutionFactorNumber, {
          roundTo: CONCENTRATION_LIMIT_COMMA,
          exponential: otherInputConcentration.calculatedValue
            .toLowerCase()
            .includes("e"),
        }),
      };
      setValue(
        `dilutionDetails.panelMembers.${panelIndex}.targets.${otherTargetIndex}.targetConcentration`,
        newTargetConcentration
      );
    });
  };
};
