import { yupResolver } from "@hookform/resolvers/yup";
import { createPortal } from "react-dom";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import Dialog, { DialogProps } from "../../components/shared/Dialog";
import { PanelMember } from "../../models/coform-models/PanelMember";
import { getDefaultValues } from "./utils/helpers";
import { validationSchema } from "./validationSchema";
import { DilutionSampleLabelsForm } from "./DilutionSampleLabelsForm";
import DilutionSampleLabelField from "./DilutionSampleLabelField";
import Grid from "../../components/Grid/Grid";

export interface DilutionSampleLabelsDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (values: DilutionSampleLabelsForm["labels"]) => void;
  panelMembers: PanelMember[];
}

const DilutionSampleLabelsDialog = ({
  open,
  onClose,
  onConfirm,
  panelMembers,
}: DilutionSampleLabelsDialogProps) => {
  const methods = useForm<DilutionSampleLabelsForm>({
    defaultValues: {
      labels: getDefaultValues(panelMembers),
    },
    resolver: yupResolver(validationSchema),
  });

  const fieldArray = useFieldArray({
    control: methods.control,
    name: "labels",
  });

  const handleCancel = () => {
    methods.reset();
    onClose();
  };
  const handleConfirm = (values: DilutionSampleLabelsForm) => {
    onConfirm(values.labels);
    methods.reset(methods.formState.defaultValues);
    onClose();
  };

  const formId = `dilutionSampleLabels`;

  const buttons: DialogProps["buttons"] = [
    {
      closeOnClick: true,
      action: handleCancel,
      label: "Cancel",
      buttonProps: {
        variant: "secondary",
        flat: true,
      },
    },
    {
      closeOnClick: false,
      label: "Print",
      buttonProps: {
        type: "submit",
        flat: true,
        ...{ form: formId },
      },
    },
  ];
  return createPortal(
    <FormProvider {...methods}>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          methods.handleSubmit((values) => {
            handleConfirm(values);
          })(event);
        }}
        noValidate
        id={formId}
      >
        <Dialog
          disableBackdropClick
          disableEscapeKeydown
          onClose={handleCancel}
          title={`Please enter names for the Sample Labels`}
          buttons={buttons}
          open={open}
        >
          <Grid columnGap={8} rowGap={8} columns={12} container>
            {fieldArray.fields.map((panelWithId, index) => {
              return (
                <Grid item key={panelWithId.id} columnSpan={12}>
                  <DilutionSampleLabelField
                    index={index}
                    numberOfReplicates={panelWithId.numberOfReplicates}
                    panelName={
                      methods.formState.defaultValues?.labels?.[index]
                        ?.panelName!
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </Dialog>
      </form>
    </FormProvider>,
    document.body
  );
};

export default DilutionSampleLabelsDialog;
