import { OwcCard, OwcDivider, OwcTypography } from "@one/react";
import { Fragment } from "react";
import Grid from "../../../../components/Grid/Grid";
import { Diluent } from "../../../../models/Diluent";
import styles from "./DilutionDetailsPageMaterialsSection.module.scss";
import { StockMaterialBlueGrid } from "./StockMaterialBlueGrid";
interface DilutionDetailsPageDiluentSectionProps {
  diluents: Diluent[] | null;
  title?: string;
  unit?: string;
}

const DilutionDetailsPageDiluentSection = ({
  diluents,
  title = "Diluents",
  unit = "mL",
}: DilutionDetailsPageDiluentSectionProps) => {
  if (!diluents) {
    return null;
  }

  return (
    <OwcCard
      className={styles.card}
      data-testid={`dilution-details-page-diluent-section`}
    >
      <div className={styles.cardContent}>
        <OwcTypography
          data-testid={`dilution-details-page-diluent-section-main-title`}
          className={styles.mainTitle}
          variant="title6"
        >
          {title}
        </OwcTypography>

        {diluents.map((diluent, index) => (
          <Fragment key={diluent.id}>
            <StockMaterialBlueGrid
              materialIndex={index}
              nameLabel={"Diluent name"}
              nameValue={diluent.stockName!}
              lotNumber={diluent.lotNumber}
              neededVolume={diluent.neededVolume}
              testIdGrid="diluent"
              unit={unit}
            />
            <div className={styles.grid}>
              <Grid columnGap={16} rowGap={4} columns={16} container>
                <Grid item columnSpan={8}>
                  <OwcTypography
                    data-testid={`dilution-details-page-diluent-section-diluent-number-value-${index}`}
                    variant="caption"
                  >
                    Material number:{" "}
                    <span className={styles.gray800}>{diluent.number}</span>
                  </OwcTypography>
                </Grid>
                <Grid item columnSpan={8}>
                  <OwcTypography
                    data-testid={`dilution-details-page-diluent-section-diluent-concentration-value-${index}`}
                    variant="caption"
                  >
                    Concentration: <span className={styles.gray800}>N/A</span>
                  </OwcTypography>
                </Grid>
              </Grid>
            </div>
            {index !== diluents.length - 1 && <OwcDivider />}
          </Fragment>
        ))}
      </div>
    </OwcCard>
  );
};

export default DilutionDetailsPageDiluentSection;
