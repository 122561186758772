import { OwcButton, OwcCard, OwcIcon, OwcTypography } from "@one/react";
import { useNavigate } from "react-router-dom";
import Collapse from "../../../components/collapse/Collapse";
import { CoFormDilution } from "../../../models/Dilution";
import button from "../../../scss//button/Button.module.scss";
import { useGenerateJSON } from "../../hooks/useGenerateJSON";
import { useGenerateDilutionPdf } from "../../pdf-export/hooks/useGenerateDilutionPdf";
import styles from "./DilutionDetailsPage.module.scss";
import DilutionDetailsPageButtons from "./DilutionDetailsPageButtons";
import DilutionDetailsPageDiluentSection from "./materials-section/DilutionDetailsPageDiluentSection";
import DilutionDetailsPageMaterialsSection from "./materials-section/DilutionDetailsPageMaterialsSection";
import DilutionDetailsPagePanelMemberSection from "./panel-members-section/DilutionDetailsPagePanelMemberSection";
import DilutionDetailsPagePreDilutionsSection from "./pre-dilutions-section/DilutionDetailsPagePreDilutionsSection";
import { DraftRedirectWrapper } from "../serial-and-co-formulated-dilution/forms/PanelMemberForm/useHandleSaveMutationResult";
import DilutionSampleLabelsDialog from "../../print-labels/DilutionSampleLabelsDialog";
import { useSampleLables } from "../../print-labels/utils/useSampleLables";

interface DilutionDetailsPageProps {
  dilution: CoFormDilution;
}
const DilutionDetailsPage = ({ dilution }: DilutionDetailsPageProps) => {
  const navigate = useNavigate();
  const generateDilutionPdf = useGenerateDilutionPdf(dilution);
  const preDilutionCount = dilution?.dilutionDetails?.preDilutions?.length ?? 0;
  const generateDilutionJSON = useGenerateJSON(dilution, dilution.name);
  const { generateCSV, open, closePrintLabelsDialog, openPrintLabelsDialog } =
    useSampleLables();
  const buttonProps = {
    id: dilution.id,
    type: dilution.type,
    name: dilution.name,
    elnId: dilution.elnId,
    status: dilution.status!,
    customLeftOver: !!dilution.dilutionDetails?.customLeftOver,
    generateDilutionPdf,
    generateDilutionJSON,
    generateDilutionLabelsCSV: openPrintLabelsDialog,
  };
  return (
    <>
      <DraftRedirectWrapper dilution={dilution}>
        <div className={styles.mainBox}>
          <div
            data-testid="serial-dilution-print-title"
            className={styles.header}
          >
            <div className={styles.title}>
              <OwcTypography
                data-testid="serial-dilution-print-title-name"
                variant="title5"
              >
                {dilution.name}
              </OwcTypography>
              {dilution.elnId && (
                <OwcTypography
                  data-testid="serial-dilution-print-title-elnid"
                  className={styles.subtitle}
                  variant="subtitle2"
                >
                  ({dilution.elnId})
                </OwcTypography>
              )}
            </div>

            <DilutionDetailsPageButtons
              createdBy={dilution.createdBy}
              {...buttonProps}
            />
          </div>
          <div className={styles.wrapper}>
            <div>
              <div
                className={styles.section}
                data-testid="serial-dilution-print-materials"
              >
                <Collapse
                  className={styles.panelMemberCollapse}
                  testId={`serial-dilution-print-page-panel-members-collapse`}
                  titleSlot={
                    <OwcTypography
                      data-testid="serial-dilution-print-materials-title"
                      variant="subtitle1"
                    >
                      Materials needed
                    </OwcTypography>
                  }
                >
                  {(dilution?.dilutionDetails?.inputMaterials.length ?? 0) >
                    0 && (
                    <div className={styles.materialsCard}>
                      <DilutionDetailsPageMaterialsSection
                        materials={
                          dilution?.dilutionDetails?.inputMaterials ?? []
                        }
                      />
                      <DilutionDetailsPageDiluentSection
                        diluents={dilution?.dilutionDetails?.diluents ?? null}
                      />
                    </div>
                  )}
                </Collapse>
              </div>
            </div>
            {preDilutionCount > 0 && (
              <div
                className={styles.section}
                data-testid="serial-dilution-print-pre-dilutions"
              >
                <OwcTypography
                  data-testid="serial-dilution-print-pre-dilutions-title"
                  variant="subtitle1"
                >
                  Pre-dilutions
                </OwcTypography>
                {dilution.dilutionDetails?.preDilutions?.map(
                  (preDilution, index) => (
                    <DilutionDetailsPagePreDilutionsSection
                      key={`${preDilution.inputMaterial.id}+${index}`}
                      preDilutionIndex={index}
                      preDilution={preDilution}
                    />
                  )
                )}
              </div>
            )}
            <div
              className={styles.section}
              data-testid="serial-dilution-print-panel-members"
            >
              <Collapse
                className={styles.panelMemberCollapse}
                testId={`serial-dilution-print-page-panel-members-collapse`}
                titleSlot={
                  <OwcTypography
                    data-testid="serial-dilution-print-panel-members-title"
                    variant="subtitle1"
                  >
                    Panel members
                  </OwcTypography>
                }
              >
                <OwcCard
                  className={styles.card}
                  data-testid={`panel-member-section-card`}
                >
                  <div className={styles.cardContent}>
                    {dilution.dilutionDetails?.panelMembers.map(
                      (panelMember, index) => (
                        <DilutionDetailsPagePanelMemberSection
                          key={`${panelMember.stepNumber}`}
                          panelMember={panelMember}
                          panelMemberIndex={index}
                          type={dilution.type}
                          customLeftOver={
                            !!dilution.dilutionDetails?.customLeftOver
                          }
                        />
                      )
                    )}
                  </div>
                </OwcCard>
              </Collapse>
            </div>
          </div>
          <div
            data-testid="serial-dilution-print-footer"
            className={styles.footer}
          >
            <OwcButton
              onClick={() => navigate("/")}
              data-testid="serial-dilution-print-back-btn"
              className={button.large}
              flat
              variant="secondary"
            >
              <div className={button.iconWrapper}>
                <OwcIcon
                  data-testid="serial-dilution-print-back-btn-icon"
                  name="arrow_leftwards"
                  color="inherit"
                />
                Go to list of dilutions
              </div>
            </OwcButton>
            <DilutionDetailsPageButtons {...buttonProps} />
          </div>
        </div>
      </DraftRedirectWrapper>
      <DilutionSampleLabelsDialog
        onClose={closePrintLabelsDialog}
        onConfirm={generateCSV}
        open={open}
        panelMembers={dilution.dilutionDetails?.panelMembers ?? []}
      />
    </>
  );
};
export default DilutionDetailsPage;
