import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { MastermixDilution } from "../../../models/Dilution";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { generateMastermixPdf } from "../mastermix-pdf";

export const useGenerateMastermixPdf = (dilution: MastermixDilution) => {
  const userInfo = useUserInfo();
  return () => {
    const pdf = pdfMake.createPdf(
      generateMastermixPdf(dilution, userInfo),
      undefined,
      undefined,
      pdfFonts.pdfMake?.vfs
    );

    pdf.download(
      `${dilution.name} - ${new Date().toLocaleString("en-US")}.pdf`
    );
  };
};
