import { PreDilution } from "../../../../models/coform-models/PreDilution";
import { PreDilutionStep } from "../../../../models/coform-models/PreDilutionStep";
import { Diluent } from "../../../../models/Diluent";
import resultsTable, {
  ResultsTableInput,
  ResultsTableRow,
} from "./results-table";

const preDilutionsTables = (
  preDilutions: PreDilution[],
  diluents: Diluent[]
) => {
  const sections = preDilutions.map((preDilution: PreDilution) => {
    const rows: ResultsTableRow[] = preDilution.dilutionSteps!.map(
      (dilutionStep: PreDilutionStep) => {
        const {
          stepName,
          inputConcentration,
          inputVolume,
          inputName,
          diluentVolume,
          dilutionFactor,
          targetConcentration,
          totalVolume,
          diluent,
          deadVolume,
        } = dilutionStep;

        const diluentIndex = diluents.findIndex((d) => d.id === diluent.id);

        return {
          stepName: { value: stepName },
          predecessorName: { value: inputName },
          inputConcentration: { value: inputConcentration?.displayedValue },
          inputVolume: { value: inputVolume?.displayedValue },
          diluentVolume: {
            value: [
              diluentVolume?.displayedValue ?? "",
              diluentIndex > -1 ? `(${diluentIndex + 1})` : "",
            ],
            noBorder: true,
            styles: [undefined, ["bold"]],
          },
          dilutionFactor: { value: dilutionFactor?.displayedValue },
          targetConcentration: { value: targetConcentration?.displayedValue },
          totalVolume: { value: totalVolume?.displayedValue },
          deadVolume: { value: deadVolume },
        } as ResultsTableRow;
      }
    );

    const resultsTableInput: ResultsTableInput = {
      rows,
      targetUnit: preDilution.inputMaterial.targetUnit ?? "-",
    };

    return {
      unbreakable: true,
      table: {
        dontBreakRows: true,
        body: [
          [
            {
              style: ["table"],
              table: {
                body: [
                  [
                    {
                      text: "Pre Dilution:",
                      bold: true,
                    },
                    {
                      text: preDilution.inputMaterial.shortName || "—",
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
          ],
          [
            resultsTable(resultsTableInput, [
              "Total Volume\n [mL]*****",
              "Dead volume\n[mL]",
            ]),
          ],
        ],
      },
      layout: "noBorders",
    };
  });

  return [
    {
      style: "tableTitle",
      text: "Pre-Dilutions",
    },
    ...sections,
  ];
};

export default preDilutionsTables;
