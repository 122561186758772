import { Navigate } from "react-router-dom";
import CoFormulatedDilutionWorkflow from "../features/co-formulated-dilution/CoFormulatedDilutionWorkflow";
import { CoFormDilution } from "../models/Dilution";
import { getHighLevelMaterialDilution } from "../utils/helpers/getHighLevelMaterialDilution";
import DilutionPageTemplate from "./DilutionPageTemplate";
import { getCoFormDilutionQueryGql } from "./serial-print-page/queries/getCoFormDilutionQueryGql";
import { useStepForDraftDilution } from "../features/common-dilution/serial-and-co-formulated-dilution/utils/useValidateStepForDraftDilution";

const CoFormulatedEditPageContent = ({
  dilution,
}: {
  dilution: CoFormDilution;
}) => {
  const initialStep = useStepForDraftDilution();
  if (dilution.status === "VERIFIED") {
    return <Navigate to="/" />;
  }

  return (
    <CoFormulatedDilutionWorkflow
      initialDilution={getHighLevelMaterialDilution(dilution)}
      initialStep={initialStep}
    />
  );
};

const CoFormulatedEditPage = () => (
  <DilutionPageTemplate
    component={CoFormulatedEditPageContent}
    titleForError={`Loading edit page for dilution`}
    query={getCoFormDilutionQueryGql}
  />
);

export default CoFormulatedEditPage;
