import {
  OwcIcon,
  OwcIconButton,
  OwcListItem,
  OwcMenu,
  OwcTypography,
} from "@one/react";
import { Auth } from "aws-amplify";
import { useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import styles from "./AppBar.module.scss";

const getFirstLetterFormName = (name: string) => {
  return name[0];
};

export const AuthenticatedContent = () => {
  const navigate = useNavigate();
  const userInfo = useUserInfo();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLOwcIconButtonElement | null>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOpen((prev) => !prev);
  };

  const handleVersionsClick = () => {
    navigate("/versions");
    setMenuOpen(false);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleLogout = () => {
    Auth.signOut();
    handleClose();
  };
  return (
    <div
      data-testid="app-bar-content-authenticated-box"
      className={styles.authContent}
    >
      <OwcTypography
        data-testid="app-bar-content-authenticated-box-user-name"
        variant="caption"
        color="textPrimary"
      >
        {userInfo && (
          <>
            {getFirstLetterFormName(userInfo.givenName)}. {userInfo.familyName}
          </>
        )}
      </OwcTypography>
      <OwcIconButton
        ref={menuRef}
        id="test"
        flat
        data-testid="app-bar-content-authenticated-box-user-btn"
        onClick={handleMenu}
      >
        <OwcIcon name="user" type="legacy" />
      </OwcIconButton>
      {menuRef.current &&
        createPortal(
          <OwcMenu
            data-testid="app-bar-content-authenticated-box-user-menu"
            anchor={menuRef.current}
            visible={Boolean(menuOpen)}
            disablePortal
            onClickOutside={() => {
              setMenuOpen(false);
            }}
            alignment="right"
          >
            <OwcListItem
              onClick={handleVersionsClick}
              data-testid="app-bar-content-authenticated-box-user-menu-item-versions"
              noBorder
              icon="monitor_settings"
              iconType="legacy"
            >
              Versions
            </OwcListItem>
            <OwcListItem
              onClick={handleLogout}
              data-testid="app-bar-content-authenticated-box-user-menu-item-logout"
              noBorder
              icon="log_off"
              iconType="legacy"
            >
              Log out
            </OwcListItem>
          </OwcMenu>,
          document.body
        )}
    </div>
  );
};
