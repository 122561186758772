import { DilutionDetails } from "../../../../../../models/coform-models/DilutionDetails";
import { Material } from "../../../../../../models/coform-models/Material";
import { MaterialInput } from "../../../../../../models/coform-models/MaterialInput";
import { PanelMember } from "../../../../../../models/coform-models/PanelMember";
import { PreDilutionInput } from "../../../../../../models/coform-models/PreDilutionInput";
import {
  CoFormDilutionInput,
  DilutionInput,
} from "../../../../../../models/DilutionInput";
import { toFixed } from "../../../../../../utils/helpers/toFixed";
import { CONCENTRATION_LIMIT_COMMA } from "../../../../constants";
import { HighLevelMaterialDilution } from "../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { getDiluentsArray } from "../../HighLevelMaterialInfoForm/utils/getDiluentsArray";
import { getPanelMemberInputConcentration } from "../../HighLevelMaterialInfoForm/utils/getPanelMemberInputConcentration";
import { getPreDilutionInputConcentration } from "../../HighLevelMaterialInfoForm/utils/getPreDilutionInputConcentration";

const getMappedPanelMembers = (
  dilutionDetails: DilutionDetails
): PanelMember[] => {
  return dilutionDetails.panelMembers.map((panelMember, panelIndex) => ({
    ...panelMember,
    targets:
      panelMember.targets?.map((target, targetIndex) => {
        const inputConcentration = getPanelMemberInputConcentration(
          panelIndex,
          targetIndex,
          dilutionDetails.preDilutions ?? [],
          dilutionDetails.panelMembers,
          dilutionDetails.panelMembers[panelIndex].targets![targetIndex]
            .material
        );
        const input = Number(inputConcentration.calculatedValue);
        const targetConcentration = Number(
          target.targetConcentration?.calculatedValue
        );
        const factorCalulated = input / targetConcentration;

        const factor =
          factorCalulated !== null
            ? {
                calculatedValue: toFixed(factorCalulated, { roundTo: null }),
                displayedValue: toFixed(factorCalulated, { roundTo: 2 }),
              }
            : null;
        return {
          ...target,
          inputConcentration,
          dilutionFactor: factor,
        };
      }) || null,
  }));
};

const alignPreDilutions = (dilutionDetails: DilutionDetails) => {
  if (dilutionDetails.preDilutions) {
    const { preDilutions } = dilutionDetails;

    preDilutions.forEach((preDilution) => {
      const { dilutionSteps } = preDilution;
      if (!dilutionSteps) {
        return;
      }
      preDilution.dilutionSteps = dilutionSteps.map((step, stepIndex) => {
        const inputConcentration = getPreDilutionInputConcentration(
          stepIndex,
          preDilution,
          dilutionSteps
        );
        const input = Number(inputConcentration.calculatedValue);
        const target = Number(step.targetConcentration!.calculatedValue);
        const factorCalulated = input / target;
        const factor =
          factorCalulated !== null
            ? {
                calculatedValue: toFixed(factorCalulated, { roundTo: null }),
                displayedValue: toFixed(factorCalulated, { roundTo: 2 }),
              }
            : null;
        return {
          ...step,
          inputConcentration,
          dilutionFactor: factor,
        };
      });
    });
  }
};

const materialToInput = ({
  neededVolume,
  highLevelInfo,
  ...material
}: Material): MaterialInput => ({
  ...material,
});

export const panelMemberDilutionToDilution = (
  values: HighLevelMaterialDilution
): DilutionInput => {
  const {
    id,
    dilutionDetails,
    deadVolume,
    panelMembers,
    replicatesPerPanelMember,
    volumeOfReplicate,
    ...dilution
  } = values;
  if (!dilutionDetails) {
    return {
      elnId: dilution.elnId,
      name: dilution.name,
      type: dilution.type,
      dilutionDetails: null,
      mastermixDetails: null,
      id: id ? id : null,
    };
  }

  alignPreDilutions(dilutionDetails);

  dilutionDetails.panelMembers = getMappedPanelMembers(dilutionDetails);

  const firstDiluent = dilutionDetails.diluents![0];
  const { neededVolume, ...diluent } = firstDiluent;

  const preDilutions: PreDilutionInput[] | null =
    dilutionDetails.preDilutions?.map((preDilution) => ({
      ...preDilution,
      inputMaterial: materialToInput(preDilution.inputMaterial),
      dilutionSteps:
        preDilution.dilutionSteps?.map((step) => {
          const { neededVolume: _, ...stepDiluent } = step.diluent;
          return {
            deadVolume: step.deadVolume,
            diluent: stepDiluent,
            dilutionFactor: step.dilutionFactor,
            inputConcentration: {
              calculatedValue: step.inputConcentration?.calculatedValue!,
              displayedValue: toFixed(
                Number(step.inputConcentration?.displayedValue!),
                {
                  roundTo: CONCENTRATION_LIMIT_COMMA,
                  exponential: step.inputConcentration?.displayedValue
                    .toLowerCase()
                    .includes("e"),
                }
              ),
            },
            stepName: step.stepName,
            stepNumber: step.stepNumber,
            targetConcentration: step.targetConcentration,
          };
        }) ?? null,
    })) ?? null;

  const diluentInput: Omit<CoFormDilutionInput, "id"> = {
    elnId: dilution.elnId,
    name: dilution.name,
    type: dilution.type,
    dilutionDetails: {
      hasNegativePanelMember: dilutionDetails.hasNegativePanelMember,
      diluents: getDiluentsArray(preDilutions, diluent),
      inputMaterials: dilutionDetails.inputMaterials.map(materialToInput),
      panelMembers: dilutionDetails.panelMembers.map((member) => ({
        customLeftOver: member.customLeftOver,
        stepNumber: member.stepNumber,
        stepName: member.stepName,
        isNegative: member.isNegative,
        diluent,
        numberOfReplicates: member.numberOfReplicates,
        replicateVolume: member.replicateVolume,
        deadVolume: member.deadVolume,
        targets:
          member.targets?.map((target) => ({
            ...target,
            material: materialToInput(target.material),
            inputConcentration: {
              calculatedValue: target.inputConcentration?.calculatedValue!,
              displayedValue: toFixed(
                Number(target.inputConcentration?.displayedValue!),
                {
                  roundTo: CONCENTRATION_LIMIT_COMMA,
                  exponential: target.inputConcentration?.displayedValue
                    .toLowerCase()
                    .includes("e"),
                }
              ),
            },
          })) ?? null,
      })),
      preDilutions,
      customLeftOver: dilutionDetails.customLeftOver,
    },
    mastermixDetails: null,
  };

  return { ...diluentInput, id: id ? id : null };
};
