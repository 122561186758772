import { Calculated } from "../../../../../models/Dilution";
import { toFixed } from "../../../../../utils/helpers/toFixed";

const ReactionVolumeRoundTo = 2;

export const getReactionVolume = (
  r1Volume: string,
  r2Volume: string,
  eluateVolume: string
): Calculated => {
  if (verifyNumbers(eluateVolume, r1Volume, r2Volume)) {
    return {
      calculatedValue: toFixed(
        Number(r1Volume) + Number(r2Volume) + Number(eluateVolume),
        {
          exponential:
            r1Volume.toLowerCase().includes("e") ||
            eluateVolume.toLowerCase().includes("e") ||
            r2Volume.toLowerCase().includes("e"),
          roundTo: null,
        }
      ),
      displayedValue: toFixed(
        Number(r1Volume) + Number(r2Volume) + Number(eluateVolume),
        {
          exponential:
            r1Volume.toLowerCase().includes("e") ||
            eluateVolume.toLowerCase().includes("e") ||
            r2Volume.toLowerCase().includes("e"),
          roundTo: ReactionVolumeRoundTo,
        }
      ),
    };
  }

  return {
    calculatedValue: "",
    displayedValue: "",
  };
};
function verifyNumbers(
  eluateVolume: string,
  r1Volume: string,
  r2Volume: string
) {
  return (
    eluateVolume !== "" &&
    r1Volume !== "" &&
    r2Volume !== "" &&
    !isNaN(Number(r1Volume)) &&
    !isNaN(Number(r2Volume)) &&
    !isNaN(Number(eluateVolume))
  );
}
