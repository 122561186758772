import { useContext } from "react";
import { QueryContext } from "./QueryContext";

export const useQueryContext = () => {
  const context = useContext(QueryContext);

  if (!context) {
    throw new Error("You cannot use useQueryContext outside QueryWrapper!");
  }

  return context;
};
