import { FormState } from "react-hook-form";
import { PreDilution } from "../../../../../../models/coform-models/PreDilution";
import { PreDilutionStep } from "../../../../../../models/coform-models/PreDilutionStep";
import { Diluent } from "../../../../../../models/Diluent";
import { Calculated } from "../../../../../../models/Dilution";
import { toFixed } from "../../../../../../utils/helpers/toFixed";
import { getPreDilutionStepName } from "../../../../utils/getPreDilutionStepName";
import { HighLevelMaterialDilution } from "../models/HighLevelMaterialDilution";
import { getDilutionStepDiluent } from "./getDilutionStepDiluent";
import { getPreDilutionInputConcentration } from "./getPreDilutionInputConcentration";
import { CONCENTRATION_LIMIT_COMMA } from "../../../../constants";

const getTargetConcentration = (
  existingStep: PreDilutionStep | undefined,
  dilution: HighLevelMaterialDilution,
  index: number,
  stepsCount: number,
  preDilution: PreDilution
): Calculated => {
  if (
    dilution.type === "CO_FORMULATED_DILUTION" &&
    index + 1 === stepsCount &&
    dilution.dilutionDetails?.panelMembers[0]
  ) {
    const target = dilution.dilutionDetails.panelMembers[0].targets?.find(
      ({ material }) => preDilution.inputMaterial.id === material.id
    );

    if (
      target &&
      target.targetConcentration?.calculatedValue !== "" &&
      !isNaN(Number(target.targetConcentration?.calculatedValue))
    ) {
      return {
        calculatedValue: toFixed(
          Number(target.targetConcentration?.calculatedValue) *
            dilution.dilutionDetails.inputMaterials.length,
          {
            exponential: target.targetConcentration?.calculatedValue
              .toLowerCase()
              .includes("e"),
            roundTo: null,
          }
        ),
        displayedValue: toFixed(
          Number(target.targetConcentration?.calculatedValue) *
            dilution.dilutionDetails.inputMaterials.length,
          {
            exponential: target.targetConcentration?.calculatedValue
              .toLowerCase()
              .includes("e"),
            roundTo: CONCENTRATION_LIMIT_COMMA,
          }
        ),
      };
    }
  }

  return (
    existingStep?.targetConcentration ?? {
      calculatedValue: "",
      displayedValue: "",
    }
  );
};

export const getStepName = (
  oldPreDilution: PreDilution | undefined,
  existingStep: PreDilutionStep | undefined,
  index: number,
  preDilution: PreDilution
) => {
  return oldPreDilution &&
    existingStep &&
    getPreDilutionStepName(index, oldPreDilution.inputMaterial) !==
      existingStep.stepName
    ? existingStep.stepName
    : getPreDilutionStepName(index, preDilution.inputMaterial);
};

const getNewDilutionStep = (
  preDilution: PreDilution,
  index: number,
  oldPreDilution: PreDilution | undefined,
  dilution: HighLevelMaterialDilution,
  stepsCount: number,
  dilutionSteps: PreDilutionStep[],
  oldDiluent?: FormState<Diluent>["defaultValues"]
) => {
  const existingStep = preDilution.dilutionSteps?.[index];

  const stepName = getStepName(
    oldPreDilution,
    existingStep,
    index,
    preDilution
  );

  const targetConcentration = getTargetConcentration(
    existingStep,
    dilution,
    index,
    stepsCount,
    preDilution
  );

  const newDilutionStep = {
    deadVolume: "",
    diluentVolume: null,
    dilutionFactor: {
      calculatedValue: "",
      displayedValue: "",
    },
    inputVolume: null,
    leftOver: null,
    inputName: "",
    stepNumber: index + 1,
    totalVolume: null,
    inputConcentration: getPreDilutionInputConcentration(
      index,
      preDilution,
      dilutionSteps
    ),
    ...(existingStep ?? {}),
    stepName,
    targetConcentration,
    diluent: getDilutionStepDiluent(dilution, existingStep, oldDiluent),
  };
  return newDilutionStep;
};

export const getPreDilutionStepArray = (
  dilution: HighLevelMaterialDilution,
  preDilution: PreDilution,
  oldPreDilution?: PreDilution,
  oldDiluent?: FormState<Diluent>["defaultValues"]
): PreDilutionStep[] | null => {
  const dilutionSteps: PreDilutionStep[] = [];
  const stepsCount =
    preDilution.inputMaterial.highLevelInfo?.numberOfPreDilutions ?? 0;
  for (let index = 0; index < stepsCount; index++) {
    const newDilutionStep = getNewDilutionStep(
      preDilution,
      index,
      oldPreDilution,
      dilution,
      stepsCount,
      dilutionSteps,
      oldDiluent
    );
    dilutionSteps.push(newDilutionStep);
  }

  return dilutionSteps.length ? dilutionSteps : null;
};
