import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { CoFormDilution } from "../../../../../../models/Dilution";
import { getNumberFieldInputProps } from "../../../../../../utils/helpers/getNumberFieldInputProps";
import { useValidationParams } from "../../ValidationParamsContext/useValidationParams";

const MaterialUnitFactorField = ({ index }: { index: number }) => {
  const { unitFactorMin, unitFactorMax } = useValidationParams();
  return (
    <Controller<
      CoFormDilution,
      `dilutionDetails.inputMaterials.${typeof index}.unitFactor`
    >
      name={`dilutionDetails.inputMaterials.${index}.unitFactor`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          label="*Unit factor"
          data-testid={`inputMaterials-unitFactor-${index}-input`}
          required
          type="number"
          min={unitFactorMin}
          max={unitFactorMax}
          {...getNumberFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`inputMaterials-unitFactor-${index}-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default MaterialUnitFactorField;
