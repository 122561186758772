import { Navigate } from "react-router-dom";
import Container from "../components/Container/Container";
import ManageMastermixForm from "../features/mastermix-dilution/manage-mastermix/ManageMastermixForm";
import { MastermixDilution } from "../models/Dilution";
import DilutionPageTemplate from "./DilutionPageTemplate";
import { getMastermixDilutionQueryGql } from "./mastermix-print-page/queries/getMastermixDilutionQueryGql";
import ValidationParamsWrapper from "../features/common-dilution/serial-and-co-formulated-dilution/forms/ValidationParamsContext/ValidationParamsWrapper";
import { useMastermixValidationParams } from "../features/hooks/useMastermixValidationParams";

const MastermixEditPage = ({ dilution }: { dilution: MastermixDilution }) => {
  const validationParamsValue = useMastermixValidationParams();
  if (dilution.status === "VERIFIED") {
    return <Navigate to="/" />;
  }

  return (
    <ValidationParamsWrapper value={validationParamsValue}>
      <Container>
        <ManageMastermixForm mastermixDilution={dilution} />
      </Container>
    </ValidationParamsWrapper>
  );
};

const MastermixEditPageView = () => {
  return (
    <DilutionPageTemplate
      component={MastermixEditPage}
      titleForError={`Loading edit page for dilution`}
      query={getMastermixDilutionQueryGql}
    />
  );
};

export default MastermixEditPageView;
