import { useFormContext } from "react-hook-form";
import { CoFormDilution } from "../../../../../../../models/Dilution";
import {
  DILUENT_FACTOR_MAX,
  DILUENT_FACTOR_MIN,
} from "../../../../../constants";

import { usePreDilutionInputConcentration } from "../hooks/usePreDilutionInputConcentration";
import { toFixed } from "../../../../../../../utils/helpers/toFixed";

export const usePreDilutionStepFactor = (
  preDilutionIndex: number,
  preDilutionStepIndex: number
) => {
  const { watch } = useFormContext<CoFormDilution>();
  const inputConcentration = usePreDilutionInputConcentration(
    preDilutionIndex,
    preDilutionStepIndex
  );
  const input = Number(inputConcentration.calculatedValue || "0");
  const target = Number(
    watch(
      `dilutionDetails.preDilutions.${preDilutionIndex}.dilutionSteps.${preDilutionStepIndex}.targetConcentration.calculatedValue`
    ) || "0"
  );
  const factor = target === 0 ? null : +toFixed(input / target, { roundTo: 2 });
  const waringFactorMore = (factor ?? 0) > DILUENT_FACTOR_MAX;
  const waringFactorLess = (factor ?? 1) < DILUENT_FACTOR_MIN;
  return { waringFactorMore, waringFactorLess, factor };
};
