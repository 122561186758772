import { OwcIcon, OwcTypography } from "@one/react";
import clsx from "clsx";
import { HTMLAttributes, ReactNode } from "react";
import styles from "./LabelWithArrow.module.scss";

interface LabelWithArrowProps extends HTMLAttributes<HTMLOwcTypographyElement> {
  children?: ReactNode;
}

const LabelWithArrow = ({ children, ...props }: LabelWithArrowProps) => {
  return (
    <OwcTypography
      {...props}
      className={clsx(styles.label, props.className)}
      variant="badge"
    >
      {children}
      <div className={styles.iconWrapper}>
        <OwcIcon name="arrow_rightwards" className={styles.icon}></OwcIcon>
      </div>
    </OwcTypography>
  );
};

export default LabelWithArrow;
