import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { CoFormDilution } from "../../../../../../../models/Dilution";
import { getStringFieldInputProps } from "../../../../../../../utils/helpers/getStringFieldInputProps";

const PanelMemberNameField = ({
  panelMemberIndex,
}: {
  panelMemberIndex: number;
}) => {
  return (
    <Controller<
      CoFormDilution,
      `dilutionDetails.panelMembers.${typeof panelMemberIndex}.stepName`
    >
      name={`dilutionDetails.panelMembers.${panelMemberIndex}.stepName`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`panel-member-dilutionDetails.panelMembers.${panelMemberIndex}.stepName-input`}
          label="*Panel member name"
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span
            slot="error-text"
            data-testid={`panel-member-dilutionDetails.panelMembers.${panelMemberIndex}.stepName-error`}
          >
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default PanelMemberNameField;
