import { OwcButton, OwcIcon, OwcTypography } from "@one/react";
import { useNavigate } from "react-router-dom";
import Container from "../../../components/Container/Container";
import { MastermixDilution } from "../../../models/Dilution";
import button from "../../../scss/button/Button.module.scss";
import { useGenerateJSON } from "../../hooks/useGenerateJSON";
import { useGenerateMastermixPdf } from "../../pdf-export/hooks/useGenerateMastermixPdf";
import mastermixSharedStyles from "./MastermixDetailsPage.module.scss";
import MastermixDetailsPageButtons from "./MastermixDetailsPageButtons";
import MastermixDetailsPageReagentsSection from "./reagents-section/MastermixDetailsPageReagentsSection";
import { DraftRedirectWrapper } from "../../common-dilution/serial-and-co-formulated-dilution/forms/PanelMemberForm/useHandleSaveMutationResult";
import MastermixDetailsPagePredilutionsSection from "./pre-dilutions-section/MastermixDetailsPagePredilutionsSection";
import {
  getDilutionDetails,
  useDetailsInfo,
} from "./reagents-section/getDilutionDetails";
import WarningInfoSection from "../../common-dilution/waring-section/WarningInfoSection";
import DilutionDetailsPageDiluentSection from "../../common-dilution/dilution-details-page/materials-section/DilutionDetailsPageDiluentSection";

export const getPredilutionsCount = (dilution: MastermixDilution) =>
  dilution?.dilutionDetails?.groups?.reduce(
    (allDilutions, group) =>
      allDilutions +
      (group?.reagents?.length
        ? group?.reagents?.reduce((acc, v) => acc + (v?.preDilution ? 1 : 0), 0)
        : 0),
    0
  ) ?? 0;

const MastermixDetailsPage = ({
  dilution,
}: {
  dilution: MastermixDilution;
}) => {
  const generateMastermixPdf = useGenerateMastermixPdf(dilution);
  const generateDilutionJSON = useGenerateJSON(dilution, dilution.name);
  const navigate = useNavigate();
  const preDilutionCount = getPredilutionsCount(dilution);
  const detailsInfo = useDetailsInfo();
  const { hasWarningValue } = getDilutionDetails(
    dilution.dilutionDetails?.diluent!
  );
  return (
    <DraftRedirectWrapper dilution={dilution}>
      <Container>
        <div className={mastermixSharedStyles.mainBox}>
          {hasWarningValue && <WarningInfoSection details={detailsInfo} />}
          <div
            data-testid="mastermix-dilution-print-title"
            className={mastermixSharedStyles.header}
          >
            <div className={mastermixSharedStyles.title}>
              <OwcTypography
                data-testid="mastermix-dilution-print-title-name"
                variant="title5"
              >
                {dilution.name}
              </OwcTypography>
              <OwcTypography
                data-testid="mastermix-dilution-print-title-elnid"
                className={mastermixSharedStyles.subtitle}
                variant="subtitle2"
              >
                ({dilution.elnId})
              </OwcTypography>
            </div>

            <MastermixDetailsPageButtons
              name={dilution.name}
              elnId={dilution.elnId}
              id={dilution.id}
              status={dilution.status!}
              createdBy={dilution.createdBy}
              generateMastermixPdf={generateMastermixPdf}
              generateDilutionJSON={generateDilutionJSON}
            />
          </div>
          <div className={mastermixSharedStyles.wrapper}>
            {preDilutionCount > 0 && (
              <div
                className={mastermixSharedStyles.section}
                data-testid="mastermix-dilution-print-pre-dilutions"
              >
                <OwcTypography
                  data-testid="mastermix-dilution-print-pre-dilutions-title"
                  variant="subtitle1"
                >
                  Pre-dilutions
                </OwcTypography>
                {dilution?.dilutionDetails?.preDiluents &&
                  dilution?.dilutionDetails?.preDiluents.length > 0 && (
                    <div className={mastermixSharedStyles.reagentsCard}>
                      <DilutionDetailsPageDiluentSection
                        diluents={dilution?.dilutionDetails?.preDiluents}
                        title="Additional diluents"
                        unit="μL"
                      />
                    </div>
                  )}
                <MastermixDetailsPagePredilutionsSection dilution={dilution} />
              </div>
            )}
            <div>
              <div
                className={mastermixSharedStyles.section}
                data-testid="mastermix-dilution-print-reagents"
              >
                <OwcTypography
                  data-testid="mastermix-dilution-print-reagents-title"
                  variant="subtitle1"
                >
                  Reagents
                </OwcTypography>
                <div className={mastermixSharedStyles.reagentsCard}>
                  <MastermixDetailsPageReagentsSection dilution={dilution} />
                </div>
              </div>
            </div>
          </div>
          <div
            data-testid="mastermix-dilution-print-footer"
            className={mastermixSharedStyles.footer}
          >
            <OwcButton
              onClick={() => navigate("/")}
              data-testid="mastermix-dilution-print-back-btn"
              className={button.large}
              flat
              variant="secondary"
            >
              <div className={button.iconWrapper}>
                <OwcIcon
                  data-testid="mastermix-dilution-print-back-btn-icon"
                  name="arrow_leftwards"
                  color="inherit"
                />
                Go to list of dilutions
              </div>
            </OwcButton>
            <MastermixDetailsPageButtons
              id={dilution.id}
              name={dilution.name}
              elnId={dilution.elnId}
              status={dilution.status!}
              createdBy={dilution.createdBy}
              generateMastermixPdf={generateMastermixPdf}
              generateDilutionJSON={generateDilutionJSON}
            />
          </div>
        </div>
      </Container>
    </DraftRedirectWrapper>
  );
};

export default MastermixDetailsPage;
