import { OwcButton, OwcIcon } from "@one/react";
import { ComponentProps } from "react";
import styles from "./ButtonToggle.module.scss";

type OwcIconProps = ComponentProps<typeof OwcIcon>;

interface ButtonToggleProps<L, R> {
  testId: string;
  leftTestId: string;
  rightTestId: string;
  onChange: (mode: L | R) => void;
  value: L | R;
  leftValue: L;
  rightValue: R;
  leftIconProps: OwcIconProps;
  rightIconProps: OwcIconProps;
}

const ButtonToggle = <L, R>({
  leftTestId,
  rightTestId,
  testId,
  leftValue,
  rightValue,
  value,
  onChange,
  leftIconProps,
  rightIconProps,
}: ButtonToggleProps<L, R>) => {
  return (
    <div className={styles.wrapper} data-testid={testId}>
      <OwcButton
        flat
        variant={value === leftValue ? "primary" : "secondary"}
        data-testid={leftTestId}
        onClick={() => onChange(leftValue)}
      >
        <OwcIcon {...leftIconProps} />
      </OwcButton>
      <OwcButton
        flat
        variant={value === rightValue ? "primary" : "secondary"}
        data-testid={rightTestId}
        onClick={() => onChange(rightValue)}
      >
        <OwcIcon {...rightIconProps} />
      </OwcButton>
    </div>
  );
};

export default ButtonToggle;
