import { UseFieldArrayReturn } from "react-hook-form";
import { MastermixDilution } from "../../../models/Dilution";
import MainInfoSection from "./MainInfoSection";
import styles from "./ManageMastermixFormContent.module.scss";
import ReagentGroupsContent from "./reagent-groups/ReagentGroupsContent";

interface ManageMastermixFormContentProps {
  fieldArray: UseFieldArrayReturn<MastermixDilution, `dilutionDetails.groups`>;
}

const ManageMastermixFormContent = ({
  fieldArray,
}: ManageMastermixFormContentProps) => {
  return (
    <div className={styles.wrapper}>
      <MainInfoSection />
      <ReagentGroupsContent fieldArray={fieldArray} />
    </div>
  );
};

export default ManageMastermixFormContent;
