import {
  OwcButton,
  OwcIcon,
  OwcIconButton,
  OwcListItem,
  OwcMenu,
} from "@one/react";
import { useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import ButtonLoading from "../../../components/button-loading/ButtonLoading";
import { useQueryContext } from "../../../components/query-context/useQueryContext";
import { StatusDilutionType } from "../../../models/Dilution";
import { DilutionType } from "../../../models/DilutionType";
import button from "../../../scss/button/Button.module.scss";
import { useDeleteDilution } from "../../delete-dilution/useDeleteDilution";
import { useNavigateToEditPage } from "../../hooks/useNavigateToEditPage";
import { useMarkAsVerifiedDilution } from "../../mark-as-verified-dilution/useMarkAsVerifiedDilution";

import styles from "./DilutionDetailsPage.module.scss";

const DilutionDetailsPageButtons = ({
  id,
  name,
  elnId,
  type,
  status,
  createdBy,
  customLeftOver,
  generateDilutionPdf,
  generateDilutionJSON,
  generateDilutionLabelsCSV,
}: {
  id: string;
  name: string;
  elnId: string | null;
  type: DilutionType;
  status: StatusDilutionType;
  createdBy?: string | null;
  customLeftOver: boolean;
  generateDilutionPdf: () => void;
  generateDilutionJSON: () => void;
  generateDilutionLabelsCSV: () => void;
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLOwcIconButtonElement | null>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuOpen((prev) => !prev);
  };
  const handleGoToEditPage = useNavigateToEditPage();
  const handlePrintPdf = () => generateDilutionPdf();
  const navigate = useNavigate();
  const { refetch } = useQueryContext();
  const { deleteDilution, loading: pendingDelete } = useDeleteDilution({
    id,
    name,
    elnId,
    createdBy,
  });
  const { markAsVerifiedDilution, loading: pendingVerify } =
    useMarkAsVerifiedDilution({
      id,
      name,
      elnId,
    });
  const pendingAction = pendingDelete || pendingVerify;
  return (
    <div className={styles.buttons}>
      <OwcButton
        className={button.large}
        flat
        variant="secondary"
        data-testid="serial-dilution-print-edit-btn"
        onClick={() => handleGoToEditPage(id, type)}
        disabled={pendingAction || status === "VERIFIED"}
      >
        {pendingAction && (
          <ButtonLoading testId="serial-dilution-print-edit-btn-loading" />
        )}
        Edit
      </OwcButton>
      <OwcButton
        className={button.large}
        flat
        variant="primary"
        data-testid="serial-dilution-print-verify-btn"
        onClick={async () => {
          await markAsVerifiedDilution();
          refetch();
        }}
        disabled={pendingAction || status === "VERIFIED"}
      >
        {pendingAction && (
          <ButtonLoading testId="serial-dilution-print-verify-btn-loading" />
        )}
        Verify
      </OwcButton>
      <OwcIconButton
        disableRipple
        data-testid="serial-dilution-print-more-menu-btn"
        onClick={handleMenu}
        ref={menuRef}
        flat
      >
        <OwcIcon
          data-testid="serial-dilution-print-more-menu-btn-icon"
          name="more_horizontal"
          type={"legacy"}
        />
      </OwcIconButton>
      {menuRef.current &&
        createPortal(
          <OwcMenu
            data-testid="serial-dilution-print-more-menu"
            anchor={menuRef.current}
            visible={Boolean(menuOpen)}
            disablePortal
            onClickOutside={() => {
              setMenuOpen(false);
            }}
            alignment="right"
          >
            <OwcListItem
              data-testid="serial-dilution-print-more-menu-print-btn"
              iconType="outlined"
              icon="upload"
              onClick={handlePrintPdf}
              noBorder
              disabled={pendingAction}
            >
              {pendingAction && (
                <ButtonLoading testId="serial-dilution-print-more-menu-print-btn-loading" />
              )}
              Export PDF
            </OwcListItem>
            <OwcListItem
              data-testid="serial-dilution-json-more-menu-json-btn"
              iconType="outlined"
              icon="upload"
              onClick={generateDilutionJSON}
              noBorder
              disabled={pendingAction}
            >
              {pendingAction && (
                <ButtonLoading testId="serial-dilution-json-more-menu-json-btn-loading" />
              )}
              Export JSON
            </OwcListItem>
            <OwcListItem
              data-testid="serial-dilution-print-labels-menu-print-btn"
              iconType="outlined"
              icon="upload"
              onClick={generateDilutionLabelsCSV}
              noBorder
              disabled={customLeftOver}
            >
              Print labels
            </OwcListItem>
            <OwcListItem
              data-testid="serial-dilution-print-more-menu-delete-btn"
              iconType="outlined"
              icon="delete"
              noBorder
              onClick={async () => {
                setMenuOpen(false);
                if (await deleteDilution()) {
                  navigate("/");
                }
                refetch();
              }}
              disabled={pendingAction}
            >
              {pendingAction && (
                <ButtonLoading testId="serial-dilution-print-more-menu-delete-btn-loading" />
              )}
              Delete
            </OwcListItem>
          </OwcMenu>,
          document.body
        )}
    </div>
  );
};

export default DilutionDetailsPageButtons;
