/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { limitComma } from "../../../../utils/helpers/limitComma";
import { numberKeepStringValidator } from "../../../../utils/helpers/numberKeepStringValidator";
import { calculatedSchema } from "../../../../utils/schema/calculatedSchema";
import {
  materialLotNumberNotEmpty,
  materialNameNotEmpty,
  materialNumberNotEmpty,
  materiaStockUnitNotEmpty,
  pcrConcentration,
  r2ConcentrationNotEmpty,
  stockConcentrationNotEmptyMessage,
} from "./mastermixSchemaNotEmpty";
import {
  pcrConcentrationMax,
  pcrConcentrationMin,
  r2ConcentrationMax,
  stockConcentrationMax,
  stockConcentrationMin,
} from "./maxtermixSchemaMinMaxValue";
import { preDilutionSchema } from "../../../common-dilution/serial-and-co-formulated-dilution/forms/PanelMemberForm/schema/preDilutionSchema";

export const stockConcentrationLimitComma = 3;
export const r2ConcentrationLimitComma = 3;
export const pcrConcentrationLimitComma = 3;
export const maxNumbersFormComment = 300;
export const mastermixGroupSchema = yup.object({
  reagents: yup.array(
    yup.object({
      comment: yup
        .string()
        .max(maxNumbersFormComment, "You can only provide 300 characters")
        .nullable(),
      r2Concentration: calculatedSchema(
        yup
          .string()
          .typeError(r2ConcentrationNotEmpty)
          .test(
            numberKeepStringValidator(
              yup
                .number()
                .required(r2ConcentrationNotEmpty)
                .typeError(r2ConcentrationNotEmpty)
                .min(
                  stockConcentrationMin,
                  "Conc. in R2 must be greater than or equal to ${min}"
                )
                .max(
                  r2ConcentrationMax,
                  "Conc. in R2 must be less than or equal to ${max}"
                )
            )
          )
          .test(
            limitComma(
              r2ConcentrationLimitComma,
              `Conc. in R2 must have at most ${r2ConcentrationLimitComma} digits after comma`
            )
          )
      ),
      pcrConcentration: calculatedSchema(
        yup
          .string()
          .typeError(pcrConcentration)
          .test(
            numberKeepStringValidator(
              yup
                .number()
                .required(pcrConcentration)
                .typeError(pcrConcentration)
                .min(
                  pcrConcentrationMin,
                  "Conc. in PCR must be greater than or equal to ${min}"
                )
                .max(
                  pcrConcentrationMax,
                  "Conc. in PCR must be less than or equal to ${max}"
                )
            )
          )
          .test(
            limitComma(
              pcrConcentrationLimitComma,
              `Conc. in PCR must have at most ${pcrConcentrationLimitComma} digits after comma`
            )
          )
      ),
      material: yup
        .object({
          stockName: yup
            .string()
            .required(materialNameNotEmpty)
            .typeError(materialNameNotEmpty),
          stockUnit: yup
            .string()
            .required(materiaStockUnitNotEmpty)
            .typeError(materiaStockUnitNotEmpty),
          lotNumber: yup
            .string()
            .required(materialLotNumberNotEmpty)
            .typeError(materialLotNumberNotEmpty),
          number: yup
            .string()
            .required(materialNumberNotEmpty)
            .typeError(materialNumberNotEmpty),
          stockConcentration: yup
            .string()
            .typeError(stockConcentrationNotEmptyMessage)
            .test(
              numberKeepStringValidator(
                yup
                  .number()
                  .required(stockConcentrationNotEmptyMessage)
                  .typeError(stockConcentrationNotEmptyMessage)
                  .min(
                    stockConcentrationMin,
                    "Stock concentration must be greater than or equal to ${min}"
                  )
                  .max(
                    stockConcentrationMax,
                    "Stock concentration must be less than or equal to ${max}"
                  )
              )
            )
            .test(
              limitComma(
                stockConcentrationLimitComma,
                `Stock concentration must have at most ${stockConcentrationLimitComma} digits after comma`
              )
            ),
        })
        .required(),
      preDilution: preDilutionSchema.nullable(),
    })
  ),
});
