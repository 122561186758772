import { OwcCard, OwcTypography } from "@one/react";

import Container from "../Container/Container";
import FullBlockProgress from "../FullBlockProgress/FullBlockProgress";
import Grid from "../Grid/Grid";
import AppVersionsRow from "./AppVersionsRow";
import { useAppVersions } from "./context/useAppVersions";

const frontendKeyMap: Record<string, string> = {
  version: "Web application",
  pipelineId: "Pipeline ID",
  commitHash: "Commit hash",
};

const backendKeyMap: Record<string, string> = {
  version: "Backend",
  pipelineId: "Pipeline ID",
  commitHash: "Commit hash",
};

const AppVersions = () => {
  const { backendVersion, frontendVersion } = useAppVersions();
  const frontendVersionMap = Object.fromEntries(
    Object.entries(frontendVersion).map(([key, value]) => [
      frontendKeyMap[key],
      value,
    ])
  );
  const backendVersionMap = Object.fromEntries(
    Object.entries(backendVersion).map(([key, value]) => [
      backendKeyMap[key],
      value,
    ])
  );

  const areVersionsAvailable =
    Object.keys(frontendVersionMap).length > 0 &&
    Object.keys(backendVersionMap).length > 0;

  if (!areVersionsAvailable) {
    return (
      <Container>
        <FullBlockProgress />
      </Container>
    );
  }

  return (
    <>
      <OwcTypography
        data-testid={`app-versions-card-title`}
        style={{
          marginLeft: "var(--one-spacer-16)",
          marginTop: "var(--one-spacer-32)",
          marginBottom: "var(--one-spacer-16)",
          color: "var(--one-color-blue-900)",
        }}
        variant="title4"
      >
        Versions
      </OwcTypography>
      <OwcCard
        data-testid={`app-frontend-version-card`}
        style={{ marginBottom: "var(--one-spacer-32)" }}
      >
        <Grid rowGap={16} container>
          {Object.entries(frontendVersionMap).map(([key, value], index) => (
            <AppVersionsRow
              key={key}
              title={key}
              value={value}
              index={index}
              testId={"app-frontend-version"}
            />
          ))}
        </Grid>
      </OwcCard>
      <OwcCard data-testid={`app-backend-version-card`}>
        <Grid rowGap={16} container>
          {Object.entries(backendVersionMap).map(([key, value], index) => (
            <AppVersionsRow
              key={key}
              title={key}
              value={value}
              index={index}
              testId={"app-backend-version"}
            />
          ))}
        </Grid>
      </OwcCard>
    </>
  );
};

export default AppVersions;
