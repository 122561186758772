import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";

export const getBooleanFieldInputProps = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>(
  field: ControllerRenderProps<TFieldValues, TName>
) => ({
  ...field,
  onInputChange: (event: CustomEvent<boolean>) => field.onChange(event.detail),
  value: field.value,
  checked: field.value,
});
