import { useState } from "react";
import HighLevelMaterialInfoForm from "../../common-dilution/serial-and-co-formulated-dilution/forms/HighLevelMaterialInfoForm/HighLevelMaterialInfoForm";
import { HighLevelMaterialDilution } from "../../common-dilution/serial-and-co-formulated-dilution/forms/HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import PanelMemberForm from "../../common-dilution/serial-and-co-formulated-dilution/forms/PanelMemberForm/PanelMemberForm";
import ValidationParamsWrapper from "../../common-dilution/serial-and-co-formulated-dilution/forms/ValidationParamsContext/ValidationParamsWrapper";
import { getEmptySerialAndCoFormulationDilution } from "../../common-dilution/serial-and-co-formulated-dilution/utils/getEmptySerialAndCoFormulationDilution";
import { fillHighLevelDataFromStep2 } from "../../common-dilution/utils/fillHighLevelDataFromStep2";

export type StepType = "HighLevelMaterialInfo" | "PanelMembers";

interface SerialDilutionWorkflowProps {
  initialDilution?: HighLevelMaterialDilution;
  initialStep?: StepType;
}

const emptyDilution = getEmptySerialAndCoFormulationDilution("SERIAL_DILUTION");

const SerialDilutionWorkflow = ({
  initialDilution = emptyDilution,
  initialStep = "HighLevelMaterialInfo",
}: SerialDilutionWorkflowProps) => {
  const [dilution, setDilution] = useState(initialDilution);
  const [step, setStep] = useState<StepType>(initialStep);

  const handleBack = (currentDilution: HighLevelMaterialDilution) => {
    setDilution(fillHighLevelDataFromStep2(currentDilution));
    setStep("HighLevelMaterialInfo");
  };

  return (
    <ValidationParamsWrapper>
      {step === "HighLevelMaterialInfo" && (
        <HighLevelMaterialInfoForm
          title="Create Serial Dilution"
          dilution={dilution}
          onDilutionChange={(f) => {
            setDilution(f);
            setStep("PanelMembers");
          }}
        />
      )}
      {step === "PanelMembers" && (
        <PanelMemberForm dilution={dilution} onBack={handleBack} />
      )}
    </ValidationParamsWrapper>
  );
};

export default SerialDilutionWorkflow;
