import { OwcCard, OwcTypography } from "@one/react";
import Collapse from "../../../../components/collapse/Collapse";
import { PreDilution } from "../../../../models/coform-models/PreDilution";
import styles from "./DilutionDetailsPagePreDilutionsSection.module.scss";
import DilutionDetailsPagePreDilutionStep from "./DilutionDetailsPagePreDilutionStep";

interface DilutionDetailsPagePreDilutionsSectionProps {
  preDilution: PreDilution;
  preDilutionIndex: number;
  unit?: string;
}

const DilutionDetailsPagePreDilutionsSection = ({
  preDilution,
  preDilutionIndex,
  unit = 'mL'
}: DilutionDetailsPagePreDilutionsSectionProps) => {
  return (
    <OwcCard
      className={styles.card}
      data-testid={`serial-dilution-print-page-pre-dilution-section-${preDilutionIndex}`}
    >
      <div className={styles.cardContent}>
        <Collapse
          testId={`serial-dilution-print-page-pre-dilution-section-${preDilutionIndex}-collapse`}
          titleSlot={
            <OwcTypography
              data-testid={`serial-dilution-print-page-pre-dilution-section-main-title-${preDilutionIndex}`}
              className={styles.mainTitle}
              variant="title5"
            >
              {preDilution.inputMaterial.shortName}
            </OwcTypography>
          }
        >
          {preDilution.dilutionSteps?.map((step, index) => (
            <DilutionDetailsPagePreDilutionStep
              preDilutionIndex={preDilutionIndex}
              input={
                index === 0
                  ? {
                      name: preDilution.inputMaterial.stockName!,
                      volume: preDilution.dilutionSteps?.[index].inputVolume!,
                      stockUnit: preDilution.inputMaterial.stockUnit!,
                      targetUnit: preDilution.inputMaterial.targetUnit!,
                    }
                  : {
                      name: preDilution.dilutionSteps?.[index - 1].stepName!,
                      volume: preDilution.dilutionSteps?.[index].inputVolume!,
                      stockUnit: preDilution.inputMaterial.stockUnit!,
                      targetUnit: preDilution.inputMaterial.targetUnit!,
                    }
              }
              key={`${step.stepName}${step.stepNumber}`}
              preDilutionStepIndex={index}
              preDilutionStep={step}
              unit={unit}
            />
          ))}
        </Collapse>
      </div>
    </OwcCard>
  );
};

export default DilutionDetailsPagePreDilutionsSection;
