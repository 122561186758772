import { Calculated } from "../../../../../models/Dilution";
import { toFixed } from "../../../../../utils/helpers/toFixed";
import { r2ConcentrationLimitComma } from "../../mastermixSchema/mastermixGroupSchema";

export const getReagentR2Concentration = (
  r2Volume: string,
  reactionVolume: Calculated,
  pcrConcentration: Calculated | null
): Calculated | null => {
  if (verifyNumbers(r2Volume, reactionVolume, pcrConcentration)) {
    const r2ConcentrationValue =
      (Number(reactionVolume.calculatedValue) *
        Number(pcrConcentration.calculatedValue)) /
      Number(r2Volume);
    return {
      calculatedValue: toFixed(r2ConcentrationValue, {
        exponential:
          reactionVolume.calculatedValue.toLowerCase().includes("e") ||
          pcrConcentration.calculatedValue.toLowerCase().includes("e") ||
          r2Volume.toLowerCase().includes("e"),
        roundTo: null,
      }),
      displayedValue: toFixed(r2ConcentrationValue, {
        exponential:
          reactionVolume.calculatedValue.toLowerCase().includes("e") ||
          pcrConcentration.calculatedValue.toLowerCase().includes("e") ||
          r2Volume.toLowerCase().includes("e"),
        roundTo: r2ConcentrationLimitComma,
      }),
    };
  }
  return {
    calculatedValue: "",
    displayedValue: "",
  };
};
function verifyNumbers(
  r2Volume: string,
  reactionVolume: Calculated,
  pcrConcentration: Calculated | null
): pcrConcentration is Calculated {
  return (
    (r2Volume !== "" &&
      !isNaN(Number(r2Volume)) &&
      reactionVolume.calculatedValue !== "" &&
      !isNaN(Number(reactionVolume.calculatedValue)) &&
      pcrConcentration &&
      pcrConcentration.calculatedValue !== "" &&
      !isNaN(Number(pcrConcentration.calculatedValue))) ??
    false
  );
}
