import { useFormContext } from "react-hook-form";
import { Calculated, MastermixDilution } from "../../../../../models/Dilution";
import { getPreDilutionInputConcentration } from "../../../../common-dilution/serial-and-co-formulated-dilution/forms/HighLevelMaterialInfoForm/utils/getPreDilutionInputConcentration";

export const usePreDilutionInputConcentration = ({
  groupIndex,
  reagentIndex,
  preDilutionStepIndex,
}: {
  groupIndex: number;
  reagentIndex: number;
  preDilutionStepIndex: number;
}): Calculated => {
  const { watch } = useFormContext<MastermixDilution>();
  const preDilution = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution`
  );
  if (!preDilution)
    return {
      calculatedValue: "",
      displayedValue: "",
    };
  return getPreDilutionInputConcentration(
    preDilutionStepIndex,
    preDilution,
    preDilution?.dilutionSteps ?? []
  );
};
