import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { HighLevelMaterialDilution } from "../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";

export const useTriggerErrorsOnEnter = (
  methods: UseFormReturn<HighLevelMaterialDilution>
) => {
  const { trigger, getValues } = methods;

  useEffect(() => {
    const panelMembers = getValues("dilutionDetails.panelMembers");

    panelMembers.forEach((panelMember, panelMemberIndex) => {
      panelMember.targets?.forEach((target, targetIndex) => {
        if (target.targetConcentration?.calculatedValue !== "") {
          trigger(
            `dilutionDetails.panelMembers.${panelMemberIndex}.targets.${targetIndex}`
          );
        }
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
