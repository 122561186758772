import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

export interface UserInfo {
  givenName: string;
  familyName: string;
  userId: string;
}

export const useUserInfo = () => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  useEffect(() => {
    const getInfo = async () => {
      const token = await Auth.currentSession();

      if (token) {
        const {
          family_name: familyName,
          given_name: givenName,
          "cognito:username": userId,
        } = token.getIdToken().payload;
        setUserInfo({
          familyName,
          givenName,
          userId: userId?.replace(/^roche_/i, ""),
        });
      }
    };

    getInfo();
  }, []);

  return userInfo;
};
