import { UseFieldArrayReturn } from "react-hook-form";
import { MastermixDilution } from "../../../../models/Dilution";
import ReagentGroupContent from "../reagentGroup/ReagentGroupContent";
import styles from "./ReagentGroupsContent.module.scss";
import { Fragment } from "react";
import ReagentsPredilution from "../reagents-predulition/ReagentsPredilution";

export interface ReagentGroupsContentProps {
  fieldArray: UseFieldArrayReturn<MastermixDilution, `dilutionDetails.groups`>;
}

const ReagentGroupsContent = ({ fieldArray }: ReagentGroupsContentProps) => {
  return (
    <>
      {fieldArray.fields.map((field, index) => {
        return (
          <Fragment key={field.id}>
            <div className={styles.wrapper}>
              <ReagentGroupContent groupIndex={index} />
            </div>
            <ReagentsPredilution groupIndex={index} />
          </Fragment>
        );
      })}
    </>
  );
};

export default ReagentGroupsContent;
