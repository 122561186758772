import { ReactNode, useState } from "react";
import {
  DilutionListModeContext,
  DilutionListModeContextType,
} from "./DilutionListModeContext";

interface DilutionListModeWrapperProps {
  children: ReactNode;
  defaultValue?: DilutionListModeContextType["mode"];
}

const DilutionListModeWrapper = ({
  children,
  defaultValue = "card",
}: DilutionListModeWrapperProps) => {
  const [mode, setMode] =
    useState<DilutionListModeContextType["mode"]>(defaultValue);

  return (
    <DilutionListModeContext.Provider value={{ mode, setMode }}>
      {children}
    </DilutionListModeContext.Provider>
  );
};

export default DilutionListModeWrapper;
