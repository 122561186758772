import gql from "graphql-tag";

export const getCoFormDilutionQueryGql = gql`
  query GetDilution($id: ID!) {
    getDilution(id: $id) {
      elnId
      id
      name
      type
      createdBy
      createdAt
      updatedBy
      updatedAt
      status
      appVersion
      dilutionDetails {
        ... on DilutionDetails {
          hasNegativePanelMember
          customLeftOver
          diluents {
            id
            lotNumber
            neededVolume {
              calculatedValue
              displayedValue
            }
            number
            shortName
            stockName
          }
          inputMaterials {
            id
            lotNumber
            availableVolume
            neededVolume {
              calculatedValue
              displayedValue
            }
            number
            shortName
            stockConcentration
            stockName
            stockUnit
            targetUnit
            unitFactor
          }
          panelMembers {
            deadVolume
            diluent {
              id
              lotNumber
              neededVolume {
                calculatedValue
                displayedValue
              }
              number
              stockName
              shortName
            }
            diluentVolume {
              calculatedValue
              displayedValue
            }
            inputs {
              inputReference
              inputType
              inputVolume {
                calculatedValue
                displayedValue
              }
              name
            }
            leftOver {
              calculatedValue
              displayedValue
            }
            customLeftOver
            numberOfReplicates
            replicateVolume
            stepName
            stepNumber
            isNegative
            targets {
              dilutionFactor {
                calculatedValue
                displayedValue
              }
              inputConcentration {
                calculatedValue
                displayedValue
              }
              material {
                id
                availableVolume
                lotNumber
                neededVolume {
                  calculatedValue
                  displayedValue
                }
                number
                shortName
                stockConcentration
                stockName
                stockUnit
                targetUnit
                unitFactor
              }
              targetConcentration {
                calculatedValue
                displayedValue
              }
            }
            totalVolume {
              calculatedValue
              displayedValue
            }
          }
          preDilutions {
            dilutionSteps {
              deadVolume
              inputName
              diluent {
                id
                lotNumber
                neededVolume {
                  calculatedValue
                  displayedValue
                }
                number
                stockName
                shortName
              }
              dilutionFactor {
                calculatedValue
                displayedValue
              }
              diluentVolume {
                calculatedValue
                displayedValue
              }
              inputConcentration {
                calculatedValue
                displayedValue
              }
              inputVolume {
                calculatedValue
                displayedValue
              }
              leftOver {
                calculatedValue
                displayedValue
              }
              stepNumber
              stepName
              targetConcentration {
                calculatedValue
                displayedValue
              }
              totalVolume {
                calculatedValue
                displayedValue
              }
            }
            inputMaterial {
              id
              availableVolume
              lotNumber
              number
              neededVolume {
                calculatedValue
                displayedValue
              }
              shortName
              stockConcentration
              stockName
              stockUnit
              unitFactor
              targetUnit
            }
            preDilutionNumber
          }
        }
      }
    }
  }
`;
