import { OwcIcon, OwcIconButton, OwcMenu, OwcTypography } from "@one/react";
import clsx from "clsx";
import { format } from "date-fns";
import { useRef, useState } from "react";
import { createPortal } from "react-dom";
import FullBlockProgress from "../../components/FullBlockProgress/FullBlockProgress";
import { useQueryContext } from "../../components/query-context/useQueryContext";
import TransparentLink from "../../components/TransparentLink/TransparentLink";
import { StatusDilutionTypeLabels } from "../../models/Dilution";
import { DilutionShortInfo } from "../../views/main-view/MainView";
import { useCopyDilution } from "../copy-dilution/useCopyDilution";
import { useDeleteDilution } from "../delete-dilution/useDeleteDilution";
import { useDetailsLink } from "../hooks/useDetailsLink";
import { useNavigateToEditPage } from "../hooks/useNavigateToEditPage";
import { useDilutionListFilter } from "./context/dilution-list-filter/useDilutionListFilter";
import DilutionShortInfoCardContent from "./DilutionShortInfoCardContent";

import styles from "./DilutionsList.module.scss";
import ShortCardMenuItems from "./ShortCardMenuItems";

export const DilutionShortInfoCard = ({
  index,
  dilutionShortInfo,
}: {
  index: number;
  dilutionShortInfo: DilutionShortInfo;
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLOwcIconButtonElement | null>(null);

  const detailsLink = useDetailsLink(
    dilutionShortInfo.type,
    dilutionShortInfo.id
  );

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setMenuOpen((prev) => !prev);
  };
  const { clearFilter } = useDilutionListFilter();

  const handleGoToEditPage = useNavigateToEditPage();
  const { deleteDilution, loading: pendingDelete } = useDeleteDilution({
    id: dilutionShortInfo.id,
    elnId: dilutionShortInfo.elnId,
    name: dilutionShortInfo.name,
    createdBy: dilutionShortInfo.createdBy,
  });
  const { copyDilution, loading: pendingCopy } = useCopyDilution({
    id: dilutionShortInfo.id,
    elnId: dilutionShortInfo.elnId,
    name: dilutionShortInfo.name,
  });
  const { refetch } = useQueryContext();

  const pendingAction = pendingDelete || pendingCopy;

  return (
    <TransparentLink to={detailsLink}>
      <button
        data-testid={`dilution-card-list-dilution-${index}-card-btn`}
        className={clsx(styles.cardBtn, styles.infoBtn)}
      >
        <div className={clsx(styles.card, styles.infoBtnContent)}>
          {pendingAction && (
            <div className={styles.loadingCard}>
              <FullBlockProgress />
            </div>
          )}
          <div
            data-testid={`dilution-card-list-dilution-${index}-card-btn-header`}
            className={styles.cardHeader}
          >
            <div className={styles.dateSection}>
              <OwcTypography
                variant="caption"
                data-testid={`dilution-card-list-dilution-${index}-card-btn-header-date`}
              >
                {format(new Date(dilutionShortInfo.updatedAt), "dd.MM.yyyy")}
              </OwcTypography>
              <OwcTypography
                data-testid={`dilution-card-list-dilution-${index}-card-btn-header-time`}
                className={styles.time}
                variant="caption"
              >
                {format(new Date(dilutionShortInfo.updatedAt), "HH:mm")}
              </OwcTypography>
            </div>
            <div>
              <OwcIconButton
                disableRipple
                data-testid={`dilutions-list-card-list-item-${index}-more-menu-btn`}
                onClick={handleMenu}
                ref={menuRef}
                flat
              >
                <OwcIcon
                  data-testid={`dilutions-list-card-list-item-${index}-more-menu-btn-icon`}
                  name="more_horizontal"
                  type={"legacy"}
                />
              </OwcIconButton>
              {menuRef.current &&
                createPortal(
                  <OwcMenu
                    data-testid={`dilutions-list-card-list-item-${index}-more-menu`}
                    anchor={menuRef.current}
                    visible={Boolean(menuOpen)}
                    disablePortal
                    onClickOutside={() => {
                      setMenuOpen(false);
                    }}
                    alignment="right"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ShortCardMenuItems
                      index={index}
                      handleGoToEditPage={handleGoToEditPage}
                      dilutionShortInfo={dilutionShortInfo}
                      pendingAction={pendingAction}
                      setMenuOpen={setMenuOpen}
                      copyDilution={copyDilution}
                      clearFilter={clearFilter}
                      refetch={refetch}
                      deleteDilution={deleteDilution}
                    />
                  </OwcMenu>,
                  document.body
                )}
            </div>
          </div>
          <DilutionShortInfoCardContent
            index={index}
            dilutionShortInfo={dilutionShortInfo}
          />
        </div>
        <div
          data-testid={`dilutions-list-card-list-item-${index}-footer`}
          className={clsx(styles.footer, {
            [styles.statusBlue]: dilutionShortInfo.status === "CALCULATED",
            [styles.statusGray]: dilutionShortInfo.status === "DRAFT",
            [styles.statusGreen]: dilutionShortInfo.status === "VERIFIED",
          })}
        >
          {dilutionShortInfo.status === "VERIFIED" && (
            <OwcIcon name="confirm" type="legacy" />
          )}
          <OwcTypography variant="caption">
            {StatusDilutionTypeLabels[dilutionShortInfo.status!]}
          </OwcTypography>
        </div>
      </button>
    </TransparentLink>
  );
};
