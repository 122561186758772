import { PanelMember } from "../../../../models/coform-models/PanelMember";
import { PanelMemberInput } from "../../../../models/coform-models/PanelMemberInput";
import { Target } from "../../../../models/coform-models/Target";
import { Diluent } from "../../../../models/Diluent";
import { Calculated } from "../../../../models/Dilution";
import resultsTable, {
  ResultsTableInput,
  ResultsTableRow,
} from "./results-table";

const getPredecessorsName = (
  inputs: (PanelMemberInput | null)[] | null
): string | string[] | null => {
  return inputs && inputs.length
    ? inputs.map((input) => input?.name ?? "—")
    : "—";
};

const getInputConcentrationName = (
  targets: Target[] | null
): string | string[] => {
  return (
    targets?.map((target) => target.inputConcentration?.displayedValue ?? "") ??
    "—"
  );
};

const getTargetConcentrationName = (
  targets: Target[] | null
): string | string[] | null => {
  return (
    targets?.map(
      (target) => target.targetConcentration?.displayedValue ?? ""
    ) ?? "—"
  );
};

const getDilutionFactorName = (
  targets: Target[] | null
): string | string[] | null => {
  return targets && targets.length
    ? targets[0]!.dilutionFactor?.displayedValue ?? ""
    : "—";
};

const getDiluentVolumeName = (
  diluentVolume: Calculated | null,
  diluentIndex: number
): string | string[] | null => {
  return [
    diluentVolume?.displayedValue ?? "",
    diluentIndex > -1 ? `(${diluentIndex + 1})` : "",
  ];
};

const getInputVolumesName = (
  inputs: (PanelMemberInput | null)[] | null
): string | string[] | null => {
  return inputs && inputs.length
    ? inputs.map((input) => input?.inputVolume?.displayedValue ?? "—")
    : "—";
};

const getNumberOfReplicates = (numberOfReplicates: number | null) =>
  `${numberOfReplicates ?? "-"}`;

const getPanelMembersMapFn = (
  diluents: Diluent[],
  isCustomLeftOver: boolean
) => {
  return (panelMember: PanelMember) => {
    const {
      stepName,
      diluentVolume,
      totalVolume,
      inputs,
      targets,
      diluent,
      numberOfReplicates,
      leftOver,
      customLeftOver,
    } = panelMember;

    const diluentIndex = diluents.findIndex((d) => d.id === diluent?.id);
    const additionalField = isCustomLeftOver
      ? {
          leftOver: { value: leftOver?.displayedValue ?? "-" },
          customLeftOver: { value: customLeftOver ?? "-" },
        }
      : {
          numberOfReplicates: {
            value: getNumberOfReplicates(numberOfReplicates),
          },
        };
    return {
      stepName: { value: stepName },
      predecessorName: {
        value: getPredecessorsName(inputs),
      },
      inputConcentration: {
        value: getInputConcentrationName(targets),
      },
      inputVolume: {
        value: getInputVolumesName(inputs),
      },
      diluentVolume: {
        value: getDiluentVolumeName(diluentVolume, diluentIndex),
        noBorder: true,
        styles: [undefined, ["bold"]],
      },
      dilutionFactor: {
        value: getDilutionFactorName(targets),
      },
      targetConcentration: {
        value: getTargetConcentrationName(targets),
      },
      totalVolume: { value: totalVolume?.displayedValue },
      ...additionalField,
    } as ResultsTableRow;
  };
};

const dilutionsTable = (
  panelMembers: PanelMember[],
  diluents: Diluent[],
  customLeftOver?: string | null
) => {
  const rows: ResultsTableRow[] = panelMembers.map(
    getPanelMembersMapFn(diluents, !!customLeftOver)
  );

  const hasTargets =
    panelMembers &&
    panelMembers.length &&
    panelMembers[0].targets &&
    panelMembers[0].targets.length;

  const resultsTableInput: ResultsTableInput = {
    rows,
    targetUnit: hasTargets
      ? panelMembers[0].targets![0].material.targetUnit!
      : "—",
  };

  return [
    {
      style: "tableTitle",
      text: "Dilutions",
    },
    resultsTable(
      resultsTableInput,
      customLeftOver
        ? [
            "Total Volume\n [mL]****",
            "Leftover\n [mL]******",
            "Panel Volume needed",
          ]
        : ["Total Volume\n [mL]****", "Number of replicates"]
    ),
  ];
};

export default dilutionsTable;
