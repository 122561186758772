import { OwcCard } from "@one/react";
import Grid from "../../../../../components/Grid/Grid";
import DeadVolumeField from "./fields/DeadVolumeField";
import DiluentLotNumberField from "./fields/DiluentLotNumberField";
import DiluentNameField from "./fields/DiluentNameField";
import DiluentNumberField from "./fields/DiluentNumberField";
import DilutionElnIdField from "./fields/DilutionElnIdField";
import DilutionNameField from "./fields/DilutionNameField";
import NegativePanelMemberField from "./fields/NegativePanelMemberField";
import PanelMembersField from "./fields/PanelMembersField";
import ReplicatesPerPanelMemberField from "./fields/ReplicatesPerPanelMemberField";
import VolumeOfReplicateField from "./fields/VolumeOfReplicateField";
import styles from "./HighLevelInfoFormContent.module.scss";
import DiluentPanelVolumeField from "./fields/DiluentPanelVolumeField";
import { HighLevelMaterialDilution } from "./models/HighLevelMaterialDilution";
import { useFormContext } from "react-hook-form";

const ReplicatesPerPanelAndVolumeOfReplicateFields = () => {
  const { watch } = useFormContext<HighLevelMaterialDilution>();
  const customLeftOver = watch(`dilutionDetails.customLeftOver`);
  return customLeftOver ? null : (
    <>
      <Grid item columnSpan={6}>
        <ReplicatesPerPanelMemberField />
      </Grid>
      <Grid item columnSpan={6}>
        <VolumeOfReplicateField />
      </Grid>
    </>
  );
};

const HighLevelInfoFormContent = () => {
  return (
    <OwcCard data-testid="high-level-material-info-form-content">
      <div className={styles.cardContent}>
        <Grid container columnGap={24} rowGap={16} columns={12}>
          <Grid item columnSpan={6}>
            <DilutionNameField />
          </Grid>
          <Grid item columnSpan={6}>
            <DilutionElnIdField />
          </Grid>
          <ReplicatesPerPanelAndVolumeOfReplicateFields />
          <Grid item columnSpan={12}>
            <DiluentPanelVolumeField />
          </Grid>
          <Grid item columnSpan={6}>
            <PanelMembersField />
          </Grid>
          <Grid item columnSpan={6}>
            <DeadVolumeField />
          </Grid>
          <Grid item columnSpan={6}>
            <DiluentNameField />
          </Grid>
          <Grid item columnSpan={6} className={styles.diluentMaterial}>
            <DiluentNumberField />
            <DiluentLotNumberField />
          </Grid>
          <Grid item columnSpan={6}>
            <NegativePanelMemberField />
          </Grid>
        </Grid>
      </div>
    </OwcCard>
  );
};

export default HighLevelInfoFormContent;
