import { OwcTypography } from "@one/react";
import clsx from "clsx";
import Grid from "../../../../../components/Grid/Grid";
import { Reagent } from "../../../../../models/mastermix-models/Reagent";
import mastermixSharedStyles from "../../MastermixDetailsPage.module.scss";
import styles from "./MastermixDetailsPageGroup.module.scss";
import {
  getLastPredilutionStep,
  getPredilutionLastStepName,
  getReagentName,
} from "../../../manage-mastermix/utils/getLastPredilutionStep";

const MastermixDetailsPageGroupRow = ({
  reagent,
  groupIndex,
  index,
}: {
  reagent: Reagent;
  groupIndex: number;
  index: number;
}) => {
  const lastPredilutionStep = getLastPredilutionStep(reagent);
  return (
    <div className={styles.groupRow}>
      <div
        className={clsx(
          mastermixSharedStyles.grid,
          mastermixSharedStyles.gridBlue
        )}
      >
        <Grid columnGap={16} rowGap={4} columns={16} container>
          <Grid item columnSpan={7}>
            <OwcTypography
              data-testid={`mastermix-details-page-group-${groupIndex}-material-name-${index}`}
              variant="subtitle1"
            >
              {lastPredilutionStep
                ? getPredilutionLastStepName(reagent)
                : getReagentName(reagent)}
            </OwcTypography>
          </Grid>
          <Grid item columnSpan={3}>
            <OwcTypography
              data-testid={`mastermix-details-page-group-${groupIndex}-material-lot-number-${index}`}
              variant="subtitle1"
            >
              {reagent.material.lotNumber}
            </OwcTypography>
          </Grid>
          <Grid item columnSpan={3}>
            <OwcTypography
              data-testid={`mastermix-details-page-group-${groupIndex}-material-internal-lot-number-${index}`}
              variant="subtitle1"
            >
              {!reagent.material.internalLotNumber ||
              reagent.material.internalLotNumber === ""
                ? "N/A"
                : reagent.material.internalLotNumber}
            </OwcTypography>
          </Grid>
          <Grid item columnSpan={3}>
            <OwcTypography
              className={clsx(
                mastermixSharedStyles.volume,
                mastermixSharedStyles.toRight
              )}
              data-testid={`mastermix-details-page-group-${groupIndex}-volume-${index}`}
              variant="subtitle1"
            >
              {reagent.material.neededVolume?.displayedValue} µL
            </OwcTypography>
          </Grid>
        </Grid>
      </div>
      <div className={mastermixSharedStyles.grid}>
        <Grid columnGap={16} rowGap={4} columns={16} container>
          <Grid item columnSpan={7}>
            <OwcTypography
              data-testid={`mastermix-details-page-group-${groupIndex}-material-stock-concentration-${index}`}
              variant="caption"
            >
              Stock conc:
              <span className={styles.subInfo}>
                {lastPredilutionStep?.targetConcentration?.calculatedValue ??
                  reagent.material.stockConcentration}{" "}
                {reagent.material.targetUnit}
              </span>
            </OwcTypography>
          </Grid>
          <Grid item columnSpan={3}>
            <OwcTypography
              data-testid={`mastermix-details-page-group-${groupIndex}-r2-concentration-${index}`}
              variant="caption"
            >
              Conc. in R2:
              <span className={styles.subInfo}>
                {reagent.r2Concentration?.displayedValue}{" "}
                {reagent.material.targetUnit}
              </span>
            </OwcTypography>
          </Grid>
          <Grid item columnSpan={6}>
            <OwcTypography
              className={mastermixSharedStyles.toRight}
              data-testid={`mastermix-details-page-group-${groupIndex}-final-concentration-${index}`}
              variant="caption"
            >
              Conc. in PCR:
              <span className={styles.subInfo}>
                {reagent.pcrConcentration?.displayedValue}{" "}
                {reagent.material.targetUnit}
              </span>
            </OwcTypography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item>
            <OwcTypography
              data-testid={`mastermix-details-page-group-${groupIndex}-comment-${index}`}
              variant="caption"
              className={styles.comment}
            >
              Comment:
              <div className={styles.commentContent}>
                {!reagent.comment || reagent.comment === ""
                  ? "N/A"
                  : reagent.comment}
              </div>
            </OwcTypography>
          </Grid>
        </Grid>
        {lastPredilutionStep && (
          <Grid container>
            <Grid item>
              <OwcTypography
                data-testid={`mastermix-details-page-group-${groupIndex}-predilution-${index}`}
                variant="caption"
                className={styles.comment}
              >
                <div className={styles.commentContent}>
                  Material was pre-diluted
                </div>
              </OwcTypography>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default MastermixDetailsPageGroupRow;
