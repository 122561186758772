import Container from "../../components/Container/Container";
import FullBlockProgress from "../../components/FullBlockProgress/FullBlockProgress";
import QueryWrapper from "../../components/query-context/QueryWrapper";
import QueryError from "../../components/QueryError";
import DilutionListFilterWrapper from "../../features/dilutions-list/context/dilution-list-filter/DilutionListFilterWrapper";
import { useDilutionListFilter } from "../../features/dilutions-list/context/dilution-list-filter/useDilutionListFilter";
import DilutionListModeWrapper from "../../features/dilutions-list/context/dilution-list-mode/DilutionListModeWrapper";
import DilutionsList from "../../features/dilutions-list/DilutionsList";
import { DilutionsListHeader } from "../../features/dilutions-list/DilutionsListHeader";
import { StatusDilutionType } from "../../models/Dilution";
import { DilutionType } from "../../models/DilutionType";
import { useDillutionData } from "./useDillutionData";
import WelcomeView from "./WelcomeView";

export interface DilutionListQueryType {
  listSortedDilutions: {
    items: DilutionShortInfo[];
    nextToken: string | null;
  } | null;
}
export interface DilutionShortInfo {
  id: string;
  name: string;
  elnId: string | null;
  updatedAt: string;
  createdAt: string;
  type: DilutionType;
  updatedBy: string;
  createdBy: string;
  status: StatusDilutionType | null;
}

const MainViewContent = () => {
  const { hasFilter } = useDilutionListFilter();
  const {
    loading,
    fetching,
    items: data,
    error,
    refetch,
    loadMore,
    hasMoreData,
  } = useDillutionData();

  if (error) {
    return (
      <Container>
        <QueryError error={error} refetch={refetch} />
      </Container>
    );
  }
  if (loading) {
    return (
      <Container fullWidth>
        <DilutionsListHeader />
        <FullBlockProgress />
      </Container>
    );
  }

  if (!data.length && !hasFilter) {
    return <WelcomeView />;
  }

  return (
    <Container fullWidth>
      <QueryWrapper {...{ refetch }}>
        <DilutionsListHeader />
        <DilutionsList
          hasMore={hasMoreData}
          loadingMore={fetching}
          loadMore={loadMore}
          dilutionsShortInfo={data}
        />
      </QueryWrapper>
    </Container>
  );
};

const MainView = () => (
  <DilutionListFilterWrapper>
    <DilutionListModeWrapper>
      <MainViewContent />
    </DilutionListModeWrapper>
  </DilutionListFilterWrapper>
);

export default MainView;
