import { OwcCard } from "@one/react";
import Grid from "../../../components/Grid/Grid";
import DiluentLotNumber from "./main-fields/DiluentLotNumber";
import DiluentNumber from "./main-fields/DiluentNumber";
import DiluentStockName from "./main-fields/DiluentStockName";
import ElnId from "./main-fields/ElnId";
import Name from "./main-fields/Name";
import NumberOfReactions from "./main-fields/NumberOfReactions";
import ReactionVolumeInputs from "./main-fields/reaction-volume-inputs/ReactionVolumeInputs";
import ReactionVolume from "./main-fields/ReactionVolume";
import TotalVolume from "./main-fields/TotalVolume";
import styles from "./ManageMastermixFormContent.module.scss";

const MainInfoSection = () => {
  return (
    <OwcCard data-testid={`mastermix-main-info`}>
      <div className={styles.cardContent}>
        <Grid container columns={2} columnGap={24} rowGap={16}>
          <Grid item columnSpan={1}>
            <Name />
          </Grid>
          <Grid item columnSpan={1}>
            <ElnId />
          </Grid>
          <Grid item columnSpan={1}>
            <Grid container columns={1} rowGap={16}>
              <Grid item columnSpan={1}>
                <ReactionVolumeInputs />
              </Grid>
              <Grid item columnSpan={1}>
                <ReactionVolume />
              </Grid>
            </Grid>
          </Grid>
          <Grid item columnSpan={1}>
            <Grid container columns={1} rowGap={16}>
              <Grid item columnSpan={1}>
                <NumberOfReactions />
              </Grid>

              <Grid item columnSpan={1}>
                <TotalVolume />
              </Grid>
            </Grid>
          </Grid>
          <Grid item columnSpan={1}>
            <DiluentStockName />
          </Grid>
          <Grid item className={styles.columnInside} columnSpan={1}>
            <DiluentNumber />
            <DiluentLotNumber />
          </Grid>
        </Grid>
      </div>
    </OwcCard>
  );
};

export default MainInfoSection;
