/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { Target } from "../../../../../../models/coform-models/Target";
import { numberKeepStringValidator } from "../../../../../../utils/helpers/numberKeepStringValidator";
import { toFixed } from "../../../../../../utils/helpers/toFixed";
import {
  calculatedSchema,
  isCalculatedSchemaContextType,
} from "../../../../../../utils/schema/calculatedSchema";
import {
  isPanelMemberFormContextType,
  PanelMemberFormContext,
} from "../PanelMemberFormContext";
import { getTargetConcentrationErrorMessage } from "./getTargetConcentrationErrorMessage";
import {
  getFactorDetails,
  getPanelMemberDetails,
} from "./panelMemberSchemaHelpers";

const targetConcentrationMessage =
  "Target concentration must be a valid number";
export const PANEL_MEMBER_DILUTION_FACTOR_ERROR =
  "PANEL_MEMBER_DILUTION_FACTOR_ERROR";

const hasAllFactorsDefined = (
  factors: (number | null)[]
): factors is number[] => factors.every((v) => v !== null);

type TargetConcentrationContext = PanelMemberFormContext & {
  parent: unknown;
};

type TargetConcentrationTestContext =
  yup.TestContext<TargetConcentrationContext>;

function isProperYupContextType(
  yupContext: yup.TestContext
): yupContext is TargetConcentrationTestContext & {
  options: TargetConcentrationTestContext["options"] & {
    context: Required<TargetConcentrationContext>;
  };
} {
  return Boolean(
    yupContext.options.context &&
      isPanelMemberFormContextType(yupContext) &&
      isCalculatedSchemaContextType(yupContext)
  );
}

export const targetSchema = yup.object({
  targetConcentration: calculatedSchema(
    yup
      .string()
      .typeError(targetConcentrationMessage)
      .test(
        numberKeepStringValidator(
          yup
            .number()
            .required(targetConcentrationMessage)
            .typeError(targetConcentrationMessage)
            .min(
              yup.ref("$validation.panelMemberTargetConcentrationMin"),
              "Target concentration must greater or equal ${min}"
            )
            .max(
              yup.ref("$validation.panelMemberTargetConcentrationMax"),
              "Target concentration must lesser or equal ${max}"
            )
        )
      )
      .test({
        test: (_: string | undefined, yupContext: yup.TestContext) => {
          if (!isProperYupContextType(yupContext)) {
            return true;
          }

          const { context } = yupContext.options;

          if (context.getValues("type") !== "CO_FORMULATED_DILUTION") {
            return true;
          }

          const currentTarget = context.parent as Target;
          const result = getPanelMemberDetails(currentTarget, context);
          if (!result || result.panelMemberIndex !== 0) {
            return true;
          }

          const current = getFactorDetails(currentTarget, result, context);

          /* istanbul ignore next */
          if (current === null) {
            return true;
          }

          const { factor, input } = current;

          const targetsLength = result.targets?.length ?? 0;
          if (targetsLength && toFixed(factor) !== toFixed(targetsLength)) {
            const message = getTargetConcentrationErrorMessage(
              context,
              currentTarget,
              factor,
              targetsLength,
              input
            );
            return yupContext.createError({
              type: PANEL_MEMBER_DILUTION_FACTOR_ERROR,
              message,
            });
          }

          return true;
        },
      })
      .test({
        test: (_: string | undefined, yupContext: yup.TestContext) => {
          if (!isProperYupContextType(yupContext)) {
            return true;
          }
          const { context } = yupContext.options;

          if (context.getValues("type") !== "CO_FORMULATED_DILUTION") {
            return true;
          }

          const currentTarget = context.parent as Target;
          const result = getPanelMemberDetails(currentTarget, context);
          /* istanbul ignore next */
          if (!result || result.panelMemberIndex === 0) {
            return true;
          }

          const currentTargetIndex =
            result.targets?.indexOf(currentTarget) ?? -1;
          const factors =
            result.targets
              ?.map((target) => getFactorDetails(target, result, context))
              .map((value) => value?.factor ?? null) ?? [];

          if (!hasAllFactorsDefined(factors)) {
            return true;
          }

          if (
            factors.reduce(
              (acc: number | false, value) => (acc === value ? acc : false),
              factors[0]
            ) === false
          ) {
            const currentFactor = factors[currentTargetIndex];
            const factorMessage = `this target has a factor of ${toFixed(
              currentFactor,
              {
                roundTo: 2,
              }
            )}`;
            return yupContext.createError({
              type: PANEL_MEMBER_DILUTION_FACTOR_ERROR,
              message: `Dilution factors for this panel member are not the same (${factorMessage})`,
            });
          }

          return true;
        },
      })
  ),
});
