import { Diluent } from "../../../models/Diluent";
import { breakableString } from "../utils/breakable-string";

export function diluentTable(diluent: Diluent, index: number) {
  return {
    table: {
      body: [
        [
          "Diluent number:",
          {
            text: `${index}`,
            style: ["marginBottomNormal", "bold"],
          },
        ],
        ["Diluent Name:", breakableString(diluent.stockName || "—")],
        ["Diluent Material Number:", breakableString(diluent.number || "—")],
        [
          "Diluent Material Lot Number:",
          breakableString(diluent.lotNumber || "—"),
        ],
      ],
    },
    layout: "noBorders",
  };
}
