const DWMLogo = () => {
  return (
    <svg
      data-testid="dwm-logo"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 13C19.4477 13 19 13.4477 19 14C19 14.5523 19.4477 15 20 15H22V13H20ZM24 14C24 13.4477 24.4477 13 25 13H27V15H25C24.4477 15 24 14.5523 24 14ZM29 14C29 13.4477 29.4477 13 30 13H32V15H30C29.4477 15 29 14.5523 29 14ZM5 36C5 35.4477 5.44772 35 6 35H38V15L34 15L34 13H39C39.5523 13 40 13.4477 40 14V35L42 35C42.5523 35 43 35.4477 43 36C43 36.5523 42.5523 37 42 37L6 37C5.44772 37 5 36.5523 5 36Z"
        fill="#0B41CD"
      />
      <path
        d="M17.9988 22.1V14H15.5985V22.75L20.399 31.5H8.39779L13.1983 22.75V14H10.798V22.0875L6.32157 30.25C6.11101 30.6299 6.00011 31.0607 6 31.4994C5.99989 31.938 6.11058 32.3689 6.32096 32.7489C6.53133 33.1289 6.83398 33.4445 7.19853 33.6641C7.56307 33.8837 7.97666 33.9995 8.39779 34H20.399C20.8201 33.9995 21.2337 33.8837 21.5983 33.6641C21.9628 33.4445 22.2655 33.1289 22.4758 32.7489C22.6862 32.3689 22.7969 31.938 22.7968 31.4994C22.7967 31.0607 22.6858 30.6299 22.4752 30.25L17.9988 22.1Z"
        fill="#1482FA"
      />
      <path
        d="M21.5991 9V21.3875C21.5991 21.8848 21.7888 22.3617 22.1264 22.7133C22.464 23.065 22.9219 23.2625 23.3993 23.2625C23.8768 23.2625 24.3346 23.065 24.6722 22.7133C25.0098 22.3617 25.1995 21.8848 25.1995 21.3875V9H21.5991ZM23.9994 11.5V21.3875C23.9994 21.5533 23.9362 21.7122 23.8236 21.8294C23.7111 21.9467 23.5585 22.0125 23.3993 22.0125C23.2402 22.0125 23.0875 21.9467 22.975 21.8294C22.8625 21.7122 22.7993 21.5533 22.7993 21.3875V11.5H23.9994Z"
        fill="#1482FA"
      />
      <path
        d="M26.3996 9V21.3875C26.3996 21.8848 26.5893 22.3617 26.9269 22.7133C27.2645 23.065 27.7224 23.2625 28.1998 23.2625C28.6773 23.2625 29.1351 23.065 29.4727 22.7133C29.8103 22.3617 30 21.8848 30 21.3875V9H26.3996ZM28.7999 21.3875C28.7999 21.5533 28.7367 21.7122 28.6241 21.8294C28.5116 21.9467 28.359 22.0125 28.1998 22.0125C28.0407 22.0125 27.888 21.9467 27.7755 21.8294C27.663 21.7122 27.5998 21.5533 27.5998 21.3875V11.5H28.7999V21.3875Z"
        fill="#1482FA"
      />
      <path
        d="M31 9V21.3875C31 21.8848 31.1897 22.3617 31.5273 22.7133C31.8649 23.065 32.3227 23.2625 32.8002 23.2625C33.2776 23.2625 33.7355 23.065 34.0731 22.7133C34.4107 22.3617 34.6004 21.8848 34.6004 21.3875V9H31ZM33.4002 21.3875C33.4002 21.5533 33.337 21.7122 33.2245 21.8294C33.112 21.9467 32.9593 22.0125 32.8002 22.0125C32.641 22.0125 32.4884 21.9467 32.3759 21.8294C32.2633 21.7122 32.2001 21.5533 32.2001 21.3875V11.5H33.4002V21.3875Z"
        fill="#1482FA"
      />
    </svg>
  );
};

export default DWMLogo;
