import { OwcIcon, OwcTypography } from "@one/react";
import clsx from "clsx";
import TransparentLink from "../../components/TransparentLink/TransparentLink";
import { DilutionShortInfo } from "../../views/main-view/MainView";
import { DilutionShortInfoCard } from "./DilutionShortInfoCard";
import styles from "./DilutionsList.module.scss";

export const CardListView = ({
  dilutionsShortInfo,
}: {
  dilutionsShortInfo: DilutionShortInfo[];
}) => {
  return (
    <div className={styles.list}>
      <TransparentLink to={"/experiment-type"}>
        <button
          data-testid="dilution-card-list-add-btn"
          className={clsx(styles.cardBtn, styles.addBtn)}
        >
          <div className={styles.card}>
            <OwcIcon
              data-testid="dilution-card-list-add-btn-icon"
              variant="primary"
              name="sign_plus"
            />
            <OwcTypography
              data-testid="dilution-card-list-add-btn-text"
              className={styles.title}
              variant="subtitle1"
            >
              Add new dilution
            </OwcTypography>
          </div>
        </button>
      </TransparentLink>

      {dilutionsShortInfo.map((dilutionShortInfo, index) => (
        <DilutionShortInfoCard
          key={dilutionShortInfo.id}
          index={index}
          dilutionShortInfo={dilutionShortInfo}
        />
      ))}
    </div>
  );
};
