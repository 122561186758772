import { Target } from "../../../../../../models/coform-models/Target";
import { Calculated } from "../../../../../../models/Dilution";
import { toFixed } from "../../../../../../utils/helpers/toFixed";
import { CONCENTRATION_LIMIT_COMMA } from "../../../../constants";
import { PanelMemberFormContext } from "../PanelMemberFormContext";

export const getTargetConcentrationErrorMessage = (
  context: PanelMemberFormContext,
  currentTarget: Target,
  factor: number,
  targetsLength: number,
  input: Calculated
) => {
  return context
    .getValues("dilutionDetails.preDilutions")
    ?.some(
      (preDilution) =>
        preDilution.inputMaterial.id === currentTarget.material.id
    )
    ? `Provided target concentration leads to dilution factor ${factor}` +
        `, but that factor must be equal to number of materials - ` +
        `${targetsLength} (hint: target concentration should be ${toFixed(
          Number(input.displayedValue) / targetsLength,
          {
            exponential: input.displayedValue.toLowerCase().includes("e"),
            roundTo: CONCENTRATION_LIMIT_COMMA,
          }
        )})`
    : `Target concentration differs from stock material concentration. Add pre-dilution step to make this value possible, or change this value to ${toFixed(
        Number(input.displayedValue) / targetsLength,
        {
          exponential: input.displayedValue.toLowerCase().includes("e"),
          roundTo: CONCENTRATION_LIMIT_COMMA,
        }
      )}.`;
};
