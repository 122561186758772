import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { getNumberFieldInputProps } from "../../../../../../utils/helpers/getNumberFieldInputProps";
import { useValidationParams } from "../../ValidationParamsContext/useValidationParams";
import { HighLevelMaterialDilution } from "../models/HighLevelMaterialDilution";

const PanelMembersField = () => {
  const { numberOfPanelMembersMin, numberOfPanelMembersMax } =
    useValidationParams();
  return (
    <Controller<HighLevelMaterialDilution, "panelMembers">
      name="panelMembers"
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid="dilution-panelMembers-input"
          label="*No. of panel members"
          required
          type="number"
          min={numberOfPanelMembersMin}
          max={numberOfPanelMembersMax}
          {...getNumberFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span slot="error-text" data-testid="dilution-panelMembers-error">
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default PanelMembersField;
