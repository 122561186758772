import { useFormContext } from "react-hook-form";

import { usePreDilutionInputConcentration } from "../hooks/usePreDilutionInputConcentration";
import { MastermixDilution } from "../../../../../models/Dilution";
import { LabelDetailRow } from "../../../../common-dilution/serial-and-co-formulated-dilution/forms/PanelMemberForm/common/LabelDetailRow";

export const StockMaterialConcentrationRow = ({
  groupIndex,
  reagentIndex,
  index,
}: {
  groupIndex: number;
  reagentIndex: number;
  index: number;
}) => {
  const { watch } = useFormContext<MastermixDilution>();
  const preDilution = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.preDilution`
  );
  const inputConcentration = usePreDilutionInputConcentration({
    groupIndex,
    reagentIndex,
    preDilutionStepIndex: index,
  });
  return (
    <LabelDetailRow
      label={
        index === 0 ? "Stock material concentration" : "Input concentration"
      }
      data-testid={`groups.${groupIndex}.reagents.${reagentIndex}.preDilution.dilutionSteps.preDilution-step-${index}.stock-or-input-concentration`}
    >
      {inputConcentration && inputConcentration.displayedValue
        ? `${inputConcentration.displayedValue} ${preDilution?.inputMaterial.targetUnit}`
        : "unknown yet"}
    </LabelDetailRow>
  );
};
