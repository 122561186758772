import { PanelMember } from "../../../models/coform-models/PanelMember";

export type PrintLabelType = {
  panelName: string;
  labelName: string;
  numberOfReplicates: number;
};

export const getDefaultValues = (data: PanelMember[]) =>
  data?.reduce(
    (acc, v) =>
      v.stepName
        ? [
            ...acc,
            {
              panelName: v?.stepName,
              labelName: v?.stepName,
              numberOfReplicates: v.numberOfReplicates,
            } as PrintLabelType,
          ]
        : acc,
    [] as PrintLabelType[]
  );

export const generateBarcodes = (
  values: PrintLabelType[],
  headerName = "Barcodes"
) =>
  values
    .map((v) => [
      ...Array(v.numberOfReplicates)
        .fill(null)
        .map((_, l) => {
          const idReplication = `${l + 1}`.padStart(
            `${v.numberOfReplicates}`.length,
            "0"
          );
          return {
            [headerName]: `${v.labelName}_${idReplication}`,
          };
        }),
      { [headerName]: "" },
    ])
    .flatMap((x) => x)
    .slice(0, -1);
