import { OwcTypography } from "@one/react";
import clsx from "clsx";
import Grid from "../../../../../components/Grid/Grid";
import { ReagentGroup } from "../../../../../models/mastermix-models/ReagentGroup";
import mastermixSharedStyles from "../../MastermixDetailsPage.module.scss";

const MastermixDetailsPageGroupSummary = ({
  group,
  index,
}: {
  group: ReagentGroup;
  index: number;
}) => {
  return (
    <div
      className={clsx(
        mastermixSharedStyles.grid,
        mastermixSharedStyles.gridBlue
      )}
    >
      <Grid columnGap={16} rowGap={4} columns={16} container>
        <Grid item columnSpan={8}>
          <OwcTypography
            data-testid={`mastermix-details-page-group-summary-title-${index}`}
            variant="subtitle1"
          >
            Sum of volume for {group.name}
          </OwcTypography>
        </Grid>
        <Grid item columnSpan={8}>
          <OwcTypography
            className={clsx(
              mastermixSharedStyles.volume,
              mastermixSharedStyles.toRight
            )}
            data-testid={`mastermix-details-page-group-summary-value-${index}`}
            variant="subtitle1"
          >
            {group.totalVolume?.displayedValue} µL
          </OwcTypography>
        </Grid>
      </Grid>
    </div>
  );
};

export default MastermixDetailsPageGroupSummary;
