import { OwcCard, OwcTypography } from "@one/react";
import Collapse from "../../../../../components/collapse/Collapse";
import { ReagentGroup } from "../../../../../models/mastermix-models/ReagentGroup";
import mastermixSharedStyles from "../../MastermixDetailsPage.module.scss";
import styles from "./MastermixDetailsPageGroup.module.scss";
import MastermixDetailsPageGroupHeader from "./MastermixDetailsPageGroupHeader";
import MastermixDetailsPageGroupRow from "./MastermixDetailsPageGroupRow";
import MastermixDetailsPageGroupSummary from "./MastermixDetailsPageGroupSummary";

const MastermixDetailsPageGroup = ({
  group,
  index: groupIndex,
}: {
  group: ReagentGroup;
  index: number;
}) => {
  return (
    <OwcCard
      className={mastermixSharedStyles.card}
      data-testid={`mastermix-details-page-group-${groupIndex}`}
    >
      <div className={mastermixSharedStyles.cardContent}>
        <MastermixDetailsPageGroupHeader index={groupIndex} />

        <Collapse
          className={styles.groupCollapse}
          testId={`mastermix-details-page-group-collapse-${groupIndex}`}
          titleSlot={
            <OwcTypography
              className={mastermixSharedStyles.cardTitle}
              data-testid={`mastermix-details-page-group-title-${groupIndex}`}
              variant="title5"
            >
              {group.name}
            </OwcTypography>
          }
        >
          <div className={styles.reagent}>
            {group.reagents &&
              group.reagents.map((reagent, index) => (
                <MastermixDetailsPageGroupRow
                  key={reagent.material.id}
                  reagent={reagent}
                  groupIndex={groupIndex}
                  index={index}
                />
              ))}
          </div>
          <MastermixDetailsPageGroupSummary group={group} index={groupIndex} />
        </Collapse>
      </div>
    </OwcCard>
  );
};

export default MastermixDetailsPageGroup;
