import { useCallback } from "react";

const FILE_EXTENSION = ".json";
export const DEFAULT_FILENAME = "data";

export const useGenerateJSON = (
  data?: object,
  filename: string = DEFAULT_FILENAME
) =>
  useCallback(
    (jsonData: object = {}, jsonFilename: string = "") => {
      const dataStr = JSON.stringify(data || jsonData);
      const dataUri =
        "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);
      const linkElement = document.createElement("a");
      linkElement.setAttribute("href", dataUri);
      const exportFileName =
        jsonFilename ||
        `${filename} - ${new Date().toLocaleString("en-US")}` ||
        DEFAULT_FILENAME;
      linkElement.setAttribute(
        "download",
        exportFileName.toLowerCase().endsWith(FILE_EXTENSION)
          ? exportFileName
          : `${exportFileName}${FILE_EXTENSION}`
      );
      linkElement.click();
    },
    [filename, data]
  );
