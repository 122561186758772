import { OwcSlideToggle } from "@one/react";
import { Controller } from "react-hook-form";
import { getBooleanFieldInputProps } from "../../../../../../utils/helpers/getBooleanFieldInputProps";
import { HighLevelMaterialDilution } from "../models/HighLevelMaterialDilution";

const NegativePanelMemberField = () => {
  return (
    <Controller<
      HighLevelMaterialDilution,
      "dilutionDetails.hasNegativePanelMember"
    >
      name="dilutionDetails.hasNegativePanelMember"
      render={({ field }) => (
        <OwcSlideToggle
          disableRipple
          data-testid="dilution-hasNegativePanelMember-toggle"
          {...getBooleanFieldInputProps(field)}
        >
          Add negative panel member
        </OwcSlideToggle>
      )}
    />
  );
};

export default NegativePanelMemberField;
