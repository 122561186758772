import { OwcInput } from "@one/react";
import { useFormContext } from "react-hook-form";
import OwcInputSuffix from "../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { MastermixDilution } from "../../../../models/Dilution";

const TotalVolume = () => {
  const { watch, getFieldState, formState } =
    useFormContext<MastermixDilution>();
  const value = watch(`dilutionDetails.totalVolume.displayedValue`);
  const { error } = getFieldState("dilutionDetails.totalVolume", formState);
  return (
    <OwcInput
      data-testid={`dilutionDetails.totalVolume-input`}
      label="*Amount for total MMx R2"
      value={value}
      type="number"
      required
      disabled
    >
      <span slot="error-text" data-testid={`dilutionDetails.totalVolume-error`}>
        {error?.message}
      </span>
      <OwcInputSuffix>mL</OwcInputSuffix>
    </OwcInput>
  );
};

export default TotalVolume;
