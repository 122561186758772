import { snackbarService } from "@one/web-components";
import { ReactNode, useCallback } from "react";
import { SnackbarMessage } from "./models/SnackbarMessage";
import { SnackbarContext } from "./SnackbarContext";

interface SnackbarWrapperProps {
  children: ReactNode;
}

const DEFAULT_DURATION = 5000;
const DEFAULT_SEVERITY = "info";

const SnackbarWrapper = ({ children }: SnackbarWrapperProps) => {
  const addSnackbar = useCallback((snack: SnackbarMessage) => {
    snackbarService.show({
      message: snack.message,
      duration: snack.duration ?? DEFAULT_DURATION,
      type: snack.severity ?? DEFAULT_SEVERITY,
    });
  }, []);

  return (
    <SnackbarContext.Provider
      value={{
        addSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarWrapper;
