import styles from "./AppBar.module.scss";
import { AppBarContent } from "./AppBarContent";
import { AuthenticatedContent } from "./AuthenticatedContent";

interface AppBarProps {
  isAuthenticated: boolean | null;
}
const AppBar = ({ isAuthenticated }: AppBarProps) => {
  return (
    <>
      <header data-testid="app-bar" className={styles.header}>
        <div data-testid="tool-bar" className={styles.toolbar}>
          <AppBarContent>
            {isAuthenticated && <AuthenticatedContent />}
          </AppBarContent>
        </div>
      </header>
    </>
  );
};

export default AppBar;
