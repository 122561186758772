import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { federated } from "../aws-exports";
import Container from "../components/Container/Container";
import RocheLogoBig from "../components/pic/RocheLogoBig";
import Hero from "../components/shared/Hero/Hero";

export const signIn = () => {
  if (
    process.env.REACT_APP_COGNITO_LOGIN_ENABLED?.trim()?.toUpperCase() !==
    "TRUE"
  ) {
    Auth.federatedSignIn({
      customProvider: federated.googleClientId,
    });
  } else {
    Auth.federatedSignIn();
  }
};

const LoginPage = () => {
  const versionsLink = (
    <Link to={"/versions"} style={{ marginTop: "var(--one-spacer-24)" }}>
      Show versions
    </Link>
  );

  return (
    <Container>
      <Hero
        testId="loginPage"
        actionName="Log in"
        action={() => signIn()}
        pic={<RocheLogoBig />}
        title="Dilution Workflow Manager"
        children={versionsLink}
      />
    </Container>
  );
};

export default LoginPage;
