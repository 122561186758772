import { MastermixDetails } from "../../../../models/mastermix-models/MastermixDetails";
import { breakableString } from "../../utils/breakable-string";

const infoTable = (details: MastermixDetails) => {
  const unit = "μL";

  return {
    style: ["table", "marginBottomLarge"],
    table: {
      body: [
        [
          "Number of Reactions:",
          breakableString(`${details.numberOfReactions}`),
        ],
        [
          "Amount of total MMx R2:",
          breakableString(`${details.totalVolume?.displayedValue} mL`),
        ],
        [
          { text: "PCR Reaction Volume:", style: ["fontExtraSmall"] },
          {
            text: breakableString(
              `${details.reactionVolume?.displayedValue} ${unit}`
            ),
            style: ["fontExtraSmall"],
          },
        ],
        [
          { text: "Volume R1:", style: ["fontExtraSmall"] },
          {
            text: breakableString(`${details.r1Volume} ${unit}`),
            style: ["fontExtraSmall"],
          },
        ],
        [
          { text: "Volume R2:", style: ["fontExtraSmall"] },
          {
            text: breakableString(`${details.r2Volume} ${unit}`),
            style: ["fontExtraSmall"],
          },
        ],
        [
          { text: "Volume Eluate:", style: ["fontExtraSmall"] },
          {
            text: breakableString(`${details.eluateVolume} ${unit}`),
            style: ["fontExtraSmall"],
          },
        ],
      ],
    },
    layout: "noBorders",
  };
};

export default infoTable;
