import { useFormContext } from "react-hook-form";
import Grid from "../../../../../components/Grid/Grid";
import WarningInfoSection from "../../../../common-dilution/waring-section/WarningInfoSection";
import EluateVolume from "./fields/EluateVolume";
import R1Volume from "./fields/R1Volume";
import R2Volume from "./fields/R2Volume";
import { ReactionVolumeInputsForm } from "./ReactionVolumeInputsForm";

const ReactionVolumeInputsDialogContent = () => {
  const {
    formState: { isDirty },
  } = useFormContext<ReactionVolumeInputsForm>();
  return (
    <Grid container columns={3} columnGap={24} rowGap={16}>
      <Grid item columnSpan={1}>
        <R1Volume />
      </Grid>
      <Grid item columnSpan={1}>
        <R2Volume />
      </Grid>
      <Grid item columnSpan={1}>
        <EluateVolume />
      </Grid>
      {isDirty && (
        <Grid item columnSpan={3}>
          <WarningInfoSection
            details={[
              {
                label: "",
                detailsInfo: `Changing those values will result in clearing the "Conc. in R2" and "Conc. in PCR" for all provided materials`,
              },
            ]}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ReactionVolumeInputsDialogContent;
