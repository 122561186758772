import { v4 } from "uuid";
import { ReagentGroup } from "../../../../models/mastermix-models/ReagentGroup";
import { getEmptyReagent } from "./getEmptyReagent";

export const getEmptyReagentGroup = (
  name: string,
  groupType: ReagentGroup["groupType"],
  defaultCountReagents = 1
): ReagentGroup => {
  return {
    groupType,
    id: v4(),
    name,
    totalVolume: null,
    reagents: Array.from({ length: defaultCountReagents }, () =>
      getEmptyReagent()
    ),
  };
};
