import { OwcInput } from "@one/react";
import { Controller, useFormContext } from "react-hook-form";
import OwcInputSuffix from "../../../../../components/shared/OwcInputSuffix/OwcInputSuffix";
import { MastermixDilution } from "../../../../../models/Dilution";
import { getCalculatedOnChangeFieldInput } from "../../../../../utils/helpers/getCalculatedOnChangeFieldInput";
import { getCalculatedValueFieldInput } from "../../../../../utils/helpers/getCalculatedValueFieldInput";
import { getNumberFieldInputProps } from "../../../../../utils/helpers/getNumberFieldInputProps";
import {
  pcrConcentrationMax,
  pcrConcentrationMin,
} from "../../mastermixSchema/maxtermixSchemaMinMaxValue";
import { useUpdateReagentR2Concentration } from "../hooks/useUpdateReagentR2Concentration";

interface ReagentPcrConcentrationProps {
  groupIndex: number;
  reagentIndex: number;
}

const ReagentPcrConcentration = ({
  groupIndex,
  reagentIndex,
}: ReagentPcrConcentrationProps) => {
  const { getValues, watch } = useFormContext<MastermixDilution>();
  const targetUnit = watch(
    `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.material.targetUnit`
  );
  const updateReagentR2Concentration = useUpdateReagentR2Concentration();
  return (
    <Controller<
      MastermixDilution,
      `dilutionDetails.groups.${typeof groupIndex}.reagents.${typeof reagentIndex}.pcrConcentration`
    >
      name={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.pcrConcentration`}
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.pcrConcentration-input`}
          label="*Conc. in PCR"
          {...getNumberFieldInputProps(field)}
          type="number"
          min={pcrConcentrationMin}
          max={pcrConcentrationMax}
          value={getCalculatedValueFieldInput(field.value)}
          error={Boolean(error)}
          onInputChange={(event) => {
            const theValue = getValues(
              `dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.pcrConcentration.displayedValue`
            );
            /* istanbul ignore next */
            if (theValue === event.detail) {
              // It's already set to that value; skip on change
              return;
            }
            getCalculatedOnChangeFieldInput(field.onChange)(event);
            updateReagentR2Concentration(groupIndex, reagentIndex);
          }}
        >
          <span
            slot="error-text"
            data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.pcrConcentration-error`}
          >
            {error?.message}
          </span>
          <OwcInputSuffix
            data-testid={`dilutionDetails.groups.${groupIndex}.reagents.${reagentIndex}.pcrConcentration-suffix`}
          >
            {targetUnit}
          </OwcInputSuffix>
        </OwcInput>
      )}
    />
  );
};

export default ReagentPcrConcentration;
