export const nameNotEmpty = "Name cannot be empty";
export const r1VolumeNotEmpty = "R1 must be a valid number";
export const r2VolumeNotEmpty = "R2 must be a valid number";
export const eluateVolumeNotEmpty = "Eluate must be a valid number";
export const numberOfReactionsVolumeNotEmpty =
  "Number of reactions must be a valid number";
export const diluentStockNameVolumeNotEmpty =
  "Diluent name must be a valid number";
export const diluentNumberVolumeNotEmpty =
  "Diluent number must be a valid number";
export const diluentLotNumberVolumeNotEmpty =
  "Diluent lot number must be a valid number";
export const materialNumberNotEmpty = "Material number must be a valid number";
export const materialNameNotEmpty = "Material name cannot be empty";
export const materialLotNumberNotEmpty =
  "Material lot number must be a valid number";
export const materiaStockUnitNotEmpty = "Stock unit cannot be empty";
export const r2ConcentrationNotEmpty =
  "Conc. in R2 must be a valid number, please fill this field or fill Conc. in PCR - one of them is required";
export const pcrConcentration =
  "Conc. in PCR must be a valid number, please fill this field or fill Conc. in R2 - one of them is required";
export const stockConcentrationNotEmptyMessage =
  "Stock concentration must be a valid number";
export const reactionVolumeNotEmpty =
  "PCR reaction volume must be a valid number, please fill R1, R2 and Eluate";
export const totalVolumeNotEmpty =
  "Amount for total MMx R2 must be a valid number, please fill R1, R2, Eluate (Reaction volume inputs) or Number of reactions";
