import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { getStringFieldInputProps } from "../../../../../../utils/helpers/getStringFieldInputProps";
import { HighLevelMaterialDilution } from "../models/HighLevelMaterialDilution";

const DilutionNameField = () => {
  return (
    <Controller<HighLevelMaterialDilution, "name">
      name="name"
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid="dilution-name-input"
          label="*Name of dilution"
          required
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span slot="error-text" data-testid="dilution-name-error">
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default DilutionNameField;
