import { OwcTypography } from "@one/react";
import Grid from "../Grid/Grid";

const AppVersionsRow = ({
  title,
  value,
  index,
  testId,
}: {
  title: string;
  value: string | null;
  index: number;
  testId: string;
}) => {
  return (
    <Grid item container columns={2} columnGap={12}>
      <Grid item>
        <OwcTypography
          data-testid={`${testId}-title-${index}`}
          variant="title6"
        >
          {title}:
        </OwcTypography>
      </Grid>
      <Grid item>
        <OwcTypography
          data-testid={`${testId}-value-${index}`}
          style={{
            color: "var(--one-color-gray-500)",
            paddingLeft: "var(--one-spacer-8)",
          }}
          variant="title6"
        >
          {value || "N/A"}
        </OwcTypography>
      </Grid>
    </Grid>
  );
};

export default AppVersionsRow;
