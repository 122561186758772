import { OwcButton, OwcCard, OwcIcon } from "@one/react";
import clsx from "clsx";
import { UseFieldArrayReturn } from "react-hook-form";
import { useConfirm } from "../../../../components/confirm-context/useConfirm";
import Grid from "../../../../components/Grid/Grid";
import { MastermixDilution } from "../../../../models/Dilution";
import button from "../../../../scss/button/Button.module.scss";
import MaterialInternalLotNumber from "./fields/material/MaterialInternalLotNumber";
import MaterialLotNumber from "./fields/material/MaterialLotNumber";
import MaterialNumber from "./fields/material/MaterialNumber";
import MaterialShortName from "./fields/material/MaterialShortName";
import MaterialStockConcentration from "./fields/material/MaterialStockConcentration";
import MaterialStockName from "./fields/material/MaterialStockName";
import MaterialStockUnit from "./fields/material/MaterialStockUnit";
import ReagentComment from "./fields/ReagentComment";
import ReagentPcrConcentration from "./fields/ReagentPcrConcentration";
import ReagentR2Concentration from "./fields/ReagentR2Concentration";
import styles from "./ReagentContent.module.scss";
import { useAddPredilutionToReagent } from "./hooks/useAddPredilutionToReagent";
export interface ReagentContentProps {
  groupIndex: number;
  reagentIndex: number;
  fieldArray: UseFieldArrayReturn<
    MastermixDilution,
    `dilutionDetails.groups.${number}.reagents`
  >;
}

const ReagentContent = ({
  groupIndex,
  reagentIndex,
  fieldArray,
}: ReagentContentProps) => {
  const confirm = useConfirm();
  const { addPredilution, reagent } = useAddPredilutionToReagent({
    groupIndex,
    reagentIndex,
  });
  const removeReagentFromGroup = async (index: number) => {
    if (
      await confirm({
        description: `Do you really want to delete reagent ${reagent.material.stockName}?`,
      })
    ) {
      fieldArray.remove(index);
    }
  };
  return (
    <OwcCard data-testid={`reagent-${groupIndex}-${reagentIndex}`}>
      <div className={styles.cardContent}>
        <Grid container columns={2} columnGap={24} rowGap={16}>
          <Grid item columnSpan={1}>
            <Grid container columns={2} columnGap={16}>
              <Grid item columnSpan={1}>
                <MaterialStockName
                  groupIndex={groupIndex}
                  reagentIndex={reagentIndex}
                />
              </Grid>
              <Grid item columnSpan={1}>
                <MaterialShortName
                  groupIndex={groupIndex}
                  reagentIndex={reagentIndex}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item columnSpan={1}>
            <Grid container columns={6} columnGap={8}>
              <Grid item columnSpan={4}>
                <MaterialInternalLotNumber
                  groupIndex={groupIndex}
                  reagentIndex={reagentIndex}
                />
              </Grid>
              <Grid item className={styles.buttons} columnSpan={2}>
                <OwcButton
                  onClick={() => removeReagentFromGroup(reagentIndex)}
                  className={clsx(
                    button.large,
                    button.colorError,
                    button.fullWidth
                  )}
                  flat
                  variant="secondary"
                  data-testid={`reagent-${groupIndex}-${reagentIndex}-delete-btn`}
                >
                  Delete
                </OwcButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item columnSpan={1}>
            <Grid container columns={2} columnGap={16}>
              <Grid item columnSpan={1}>
                <MaterialNumber
                  groupIndex={groupIndex}
                  reagentIndex={reagentIndex}
                />
              </Grid>
              <Grid item columnSpan={1}>
                <MaterialLotNumber
                  groupIndex={groupIndex}
                  reagentIndex={reagentIndex}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item columnSpan={1}>
            <Grid container columns={6} columnGap={8}>
              <Grid item columnSpan={4}>
                <MaterialStockConcentration
                  groupIndex={groupIndex}
                  reagentIndex={reagentIndex}
                />
              </Grid>
              <Grid item columnSpan={2}>
                <MaterialStockUnit
                  groupIndex={groupIndex}
                  reagentIndex={reagentIndex}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item columnSpan={1}>
            <ReagentR2Concentration
              groupIndex={groupIndex}
              reagentIndex={reagentIndex}
            />
          </Grid>
          <Grid item columnSpan={1}>
            <ReagentPcrConcentration
              groupIndex={groupIndex}
              reagentIndex={reagentIndex}
            />
          </Grid>
          <Grid item columnSpan={2}>
            <ReagentComment
              groupIndex={groupIndex}
              reagentIndex={reagentIndex}
            />
          </Grid>
          {!reagent?.preDilution && (
            <Grid item columnSpan={2}>
              <div className={styles.addPredilutionButton}>
                <OwcButton
                  onClick={() => addPredilution()}
                  flat
                  variant="secondary"
                  data-testid={`mastermix-add-new-reagent-in-group-btn-${groupIndex}`}
                >
                  <div className={button.iconWrapper}>
                    <OwcIcon name="sign_plus" color="inherit" />
                    Add predilution
                  </div>
                </OwcButton>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </OwcCard>
  );
};

export default ReagentContent;
