import { ContentTable } from "pdfmake/interfaces";
import { EXPORT_PDF_CONFIG } from "../../constants";
import { MASTERMIX_PDF_CONFIG } from "../../mastermix-pdf/mastermix-pdf.constants";
import { calculateRowHeight } from "../../utils/calculate-heights";
import {
  CellData,
  generateSingleValueCellTable,
  generateTableCellContent,
} from "../../utils/generate-cell-table";
import { parseToBreakableStrings } from "../../utils/parse-to-breakable-strings";
import { applyVerticalAlignment } from "../../utils/vertical-aligment";
import { DILUTION_PDF_CONFIG } from "../dilution-pdf.constants";

export interface ResultsTableRow {
  stepName: CellData;
  predecessorName: CellData;
  inputConcentration: CellData;
  inputVolume: CellData;
  diluentVolume: CellData;
  dilutionFactor: CellData;
  targetConcentration: CellData;
  totalVolume?: CellData;
  deadVolume?: CellData;
  numberOfReplicates?: CellData;
  leftOver?: CellData;
}

export interface ResultsTableInput {
  rows: ResultsTableRow[];
  targetUnit: string;
}

const CELL_WIDTH_ROUND_PRECISION = 3;
export const calculateCellWidth = (colNumbers: number) =>
  parseFloat(
    (DILUTION_PDF_CONFIG.COLUMNS_WIDTH / colNumbers).toFixed(
      CELL_WIDTH_ROUND_PRECISION
    )
  );

const resultsTable = (
  resultsData: ResultsTableInput,
  additionalHeaders: string[] = []
) => {
  const tableHeaderNames = [
    "Panel Members",
    "Name of Predecessor",
    `Concentration Predecessor\n [${resultsData.targetUnit}]`,
    "Volume of Predecessor\n [mL]*",
    "Volume of Diluent\n [mL]**",
    "Dilution Factor***",
    `Final Concentration\n [${resultsData.targetUnit}]`,
    ...additionalHeaders,
  ];

  const columnsAlignedToLeft = [0, 1];
  const headers = tableHeaderNames.map((tableHeaderName, index) => {
    const style = ["bold"];

    if (columnsAlignedToLeft.includes(index)) {
      style.push("alignedToLeft");
    }

    return generateSingleValueCellTable({ value: tableHeaderName, style });
  });

  const rows = resultsData.rows.map((row: ResultsTableRow, rowIndex: number) =>
    Object.values(row).map(({ value, noBorder, styles }, index) => {
      const style = [];
      let valueToAssign = parseToBreakableStrings(value);

      // Align first two columns to the left
      if (columnsAlignedToLeft.includes(index)) {
        style.push("alignedToLeft");
      }

      // Display one element inner table as regular value
      if (Array.isArray(valueToAssign) && valueToAssign.length === 1) {
        valueToAssign = valueToAssign[0];
      }

      return generateTableCellContent(rowIndex, {
        value: valueToAssign,
        style,
        noBorder,
        styles,
      });
    })
  );
  const columnWidth = calculateCellWidth(tableHeaderNames.length);
  return {
    style: ["table", "alignedToCenter", "marginBottomLarge"],
    table: {
      headerRows: 1,
      widths: [...Array(tableHeaderNames.length)].map(() => columnWidth),
      dontBreakRows: true,
      body: [headers, ...rows],
    },
    layout: {
      paddingTop: (rowIndex: number, node: ContentTable) => {
        const rowHeight = calculateRowHeight(
          rowIndex,
          headers,
          rows,
          columnWidth
        );
        applyVerticalAlignment(
          node,
          rowIndex,
          rowHeight,
          MASTERMIX_PDF_CONFIG.COLUMN_WIDTH
        );

        return EXPORT_PDF_CONFIG.OUTSIDE_CELL_PADDING;
      },
      paddingBottom: () => EXPORT_PDF_CONFIG.OUTSIDE_CELL_PADDING,
      paddingLeft: () => EXPORT_PDF_CONFIG.OUTSIDE_CELL_PADDING,
      paddingRight: () => EXPORT_PDF_CONFIG.OUTSIDE_CELL_PADDING,
    },
  };
};

export default resultsTable;
