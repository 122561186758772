import { OwcCard, OwcTypography } from "@one/react";
import clsx from "clsx";
import Grid from "../../../../components/Grid/Grid";
import { MastermixDetails } from "../../../../models/mastermix-models/MastermixDetails";
import { getTotalVolumeDetails } from "../../utils/getTotalVolumeDetails";
import mastermixSharedStyles from "../MastermixDetailsPage.module.scss";

const MastermixDetailsPageReagentsSummary = ({
  details,
}: {
  details: MastermixDetails | null;
}) => {
  if (!details) {
    return null;
  }

  const { totalVolumeWithoutMMx, totalVolumeInMicroUnit } =
    getTotalVolumeDetails(details);

  return (
    <OwcCard
      className={mastermixSharedStyles.card}
      data-testid={`mastermix-details-page-summary-section`}
    >
      <div className={mastermixSharedStyles.cardContent}>
        <OwcTypography
          className={mastermixSharedStyles.cardTitle}
          data-testid="mastermix-details-page-summary-title"
          variant="title5"
        >
          Summary
        </OwcTypography>

        <div
          className={clsx(
            mastermixSharedStyles.grid,
            mastermixSharedStyles.gridBlue
          )}
        >
          <Grid columnGap={16} rowGap={4} columns={16} container>
            <Grid item columnSpan={7}>
              <OwcTypography
                data-testid={`mastermix-details-page-total-volume-without-mmx-title`}
                variant="caption"
              >
                Total volume MMx without diluent
              </OwcTypography>
            </Grid>
            <Grid item columnSpan={3}>
              <OwcTypography
                data-testid={`mastermix-details-page-total-amount-of-mmx-title`}
                variant="caption"
              >
                Total amount of MMx
              </OwcTypography>
            </Grid>
            <Grid item columnSpan={7}>
              <OwcTypography
                data-testid={`mastermix-details-page-total-volume-without-mmx-value`}
                variant="subtitle1"
              >
                {totalVolumeWithoutMMx} µL
              </OwcTypography>
            </Grid>
            <Grid item columnSpan={3}>
              <OwcTypography
                data-testid={`mastermix-details-page-total-amount-of-mmx-value`}
                variant="subtitle1"
              >
                {totalVolumeInMicroUnit} µL
              </OwcTypography>
            </Grid>
          </Grid>
        </div>
      </div>
    </OwcCard>
  );
};

export default MastermixDetailsPageReagentsSummary;
