import { OwcCard, OwcDivider } from "@one/react";
import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { CoFormDilution } from "../../../../../../models/Dilution";

import PanelMemberContent from "../PanelMemberContent/PanelMemberContent";
import styles from "./PanelMembersContent.module.scss";

const PanelMembersContent = () => {
  const { watch } = useFormContext<CoFormDilution>();
  const panelMembers = watch(`dilutionDetails.panelMembers`);
  return (
    <OwcCard className={styles.card}>
      <div className={styles.cardContent}>
        {panelMembers?.map((panelMember, index, arr) => {
          return (
            <Fragment key={`${index}_${panelMember.stepNumber}`}>
              <PanelMemberContent panelMemberIndex={index} />
              {index + 1 !== arr.length && <OwcDivider />}
            </Fragment>
          );
        })}
      </div>
    </OwcCard>
  );
};

export default PanelMembersContent;
