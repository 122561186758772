import { useEffect } from "react";
import { UseFieldArrayReturn, useFormContext } from "react-hook-form";
import { HighLevelMaterialDilution } from "../../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";

export const usePreDilutionAutoRemove = (
  index: number,
  fieldArray: UseFieldArrayReturn<
    HighLevelMaterialDilution,
    `dilutionDetails.preDilutions`,
    "id"
  >
) => {
  const { watch } = useFormContext<HighLevelMaterialDilution>();
  const { remove } = fieldArray;

  const dilutionSteps = watch(
    `dilutionDetails.preDilutions.${index}.dilutionSteps`
  );

  useEffect(() => {
    if (!dilutionSteps || dilutionSteps.length === 0) {
      remove(index);
    }
  }, [dilutionSteps, remove, index]);
};
