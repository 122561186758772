import { OwcIcon, OwcTypography } from "@one/react";
import clsx from "clsx";
import { Fragment } from "react";
import Grid from "../../../../components/Grid/Grid";
import { PanelMemberInput } from "../../../../models/coform-models/PanelMemberInput";
import { Target } from "../../../../models/coform-models/Target";
import styles from "./DilutionDetailsPagePanelMembersSection.module.scss";

export const PanelMemberInputsAndTargesInfoSection = ({
  index,
  panelMemberIndex,
  input,
  target,
  warningDiluentFactorMore,
  warningDiluentFactorLess,
  warningVolumeInput,
}: {
  input:
    | Pick<Partial<PanelMemberInput>, "name" | "inputVolume">
    | null
    | undefined;
  target: Target | null | undefined;
  warningDiluentFactorMore: boolean;
  warningDiluentFactorLess: boolean;
  warningVolumeInput: boolean;
  index: number;
  panelMemberIndex: number;
}) => {
  return (
    <Fragment>
      <Grid columnSpan={1}>
        {input && (
          <div
            className={clsx(
              styles.flexLine,
              styles.wrapTypography,
              styles.multipleInColumn,
              styles.keepAlign
            )}
          >
            <OwcTypography
              data-testid={`panel-member-section-${panelMemberIndex}-input-${index}-name`}
              variant="body1"
            >
              {input.name}
            </OwcTypography>
            <OwcTypography
              className={clsx({ [styles.warningAccent]: warningVolumeInput })}
              data-testid={`panel-member-section-${panelMemberIndex}-input-${index}-volume`}
              variant="title6"
            >
              {input.inputVolume?.displayedValue} mL
            </OwcTypography>
          </div>
        )}
      </Grid>
      <Grid columnSpan={1}>
        {target && (
          <div
            className={clsx(
              styles.flexLine,
              styles.wrapTypography,
              styles.multipleInColumn
            )}
          >
            <div className={styles.insideFlexBaseline}>
              <OwcTypography
                className={clsx({
                  [styles.warningAccent]:
                    warningDiluentFactorMore || warningDiluentFactorLess,
                })}
                data-testid={`panel-member-section-${panelMemberIndex}-target-${index}-dilution-factor`}
                variant="subtitle1"
              >
                {target.dilutionFactor?.displayedValue}
              </OwcTypography>
              <OwcTypography
                data-testid={`panel-member-section-${panelMemberIndex}-target-${index}-material-shortName`}
                variant="body1"
              >
                {target.material.shortName}
              </OwcTypography>
            </div>
            <div
              className={styles.insideFlexLine0}
              data-testid={`panel-member-section-${panelMemberIndex}-target-${index}-concentrations`}
            >
              <OwcTypography
                data-testid={`panel-member-section-${panelMemberIndex}-target-${index}-input-concentration`}
                variant="badge"
              >
                {target.inputConcentration?.displayedValue}
              </OwcTypography>
              <OwcIcon name="caret_right" type="outlined" />
              <OwcTypography
                data-testid={`panel-member-section-${panelMemberIndex}-target-${index}-target-concentration`}
                variant="title6"
              >
                {target.targetConcentration?.displayedValue}{" "}
                {target.material.targetUnit}
              </OwcTypography>
            </div>
          </div>
        )}
      </Grid>
    </Fragment>
  );
};
