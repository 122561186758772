import { MastermixDilution } from "../../../../models/Dilution";
import MastermixDetailsPageGroup from "./group/MastermixDetailsPageGroup";
import MastermixDetailsPageReagentsDiluent from "./MastermixDetailsPageReagentsDiluent";
import MastermixDetailsPageReagentsSummary from "./MastermixDetailsPageReagentsSummary";

const MastermixDetailsPageReagentsSection = ({
  dilution,
}: {
  dilution: MastermixDilution;
}) => {
  return (
    <>
      {dilution.dilutionDetails?.groups!.map((group, index) => (
        <MastermixDetailsPageGroup key={group.id} group={group} index={index} />
      ))}
      <MastermixDetailsPageReagentsDiluent
        diluent={dilution.dilutionDetails?.diluent!}
      />
      <MastermixDetailsPageReagentsSummary details={dilution.dilutionDetails} />
    </>
  );
};

export default MastermixDetailsPageReagentsSection;
