import { OwcInput } from "@one/react";
import { Controller } from "react-hook-form";
import { getStringFieldInputProps } from "../../../../../../utils/helpers/getStringFieldInputProps";
import { HighLevelMaterialDilution } from "../models/HighLevelMaterialDilution";

const DilutionElnIdField = () => {
  return (
    <Controller<HighLevelMaterialDilution, "elnId">
      name="elnId"
      render={({ field, fieldState: { error } }) => (
        <OwcInput
          data-testid="dilution-elnId-input"
          label="eLN id"
          {...getStringFieldInputProps(field)}
          error={Boolean(error)}
        >
          <span slot="error-text" data-testid="dilution-elnId-error">
            {error?.message}
          </span>
        </OwcInput>
      )}
    />
  );
};

export default DilutionElnIdField;
