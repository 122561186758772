/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { limitComma } from "../../../../../../utils/helpers/limitComma";
import { nullableNumber } from "../../../../../../utils/helpers/nullableNumber";
import { numberKeepStringValidator } from "../../../../../../utils/helpers/numberKeepStringValidator";

const unitFactorMessage = "Unit factor must be a valid number";
const numberOfPreDilutionsMessage =
  "Number of pre-dilutions must be a valid number";

const UNIT_FACTOR_LIMIT_COMMA = 3;
const AVAILABLE_VOLUME_LIMIT_COMMA = 3;

const stockConcentrationMessage =
  "Stock material concentration must be a valid number";
export const materialSchema = yup.object({
  highLevelInfo: yup.object({
    numberOfPreDilutions: yup
      .number()
      .required(numberOfPreDilutionsMessage)
      .typeError(numberOfPreDilutionsMessage)
      .min(
        yup.ref("$validation.numberOfPreDilutionsMin"),
        "Number of pre-dilutions must be greater than or equal to ${min}"
      )
      .max(
        yup.ref("$validation.numberOfPreDilutionsMax"),
        "Number of pre-dilutions must be less than or equal to ${max}"
      )
      .integer("Number of pre-dilutions must be an integer"),
  }),
  stockName: yup
    .string()
    .required("Stock material name cannot be empty")
    .typeError("Stock material name cannot be empty"),
  lotNumber: yup
    .string()
    .required("Stock lot number cannot be empty")
    .typeError("Stock lot number cannot be empty"),
  number: yup
    .string()
    .required("Stock material number cannot be empty")
    .typeError("Stock material number cannot be empty"),
  stockConcentration: yup
    .string()
    .typeError(stockConcentrationMessage)
    .test(
      numberKeepStringValidator(
        yup
          .number()
          .required(stockConcentrationMessage)
          .typeError(stockConcentrationMessage)
          .when(
            "$validation.stockConcentrationMin",
            (min: number | undefined, schema: yup.NumberSchema) => {
              if (typeof min === "number") {
                return schema.min(
                  min,
                  "Stock concentration must be greater than or equal to ${min}"
                );
              }

              return schema;
            }
          )
          .when(
            "$validation.stockConcentrationMax",
            (max: number | undefined, schema: yup.NumberSchema) => {
              if (typeof max === "number") {
                return schema.max(
                  max,
                  "Stock concentration must be less than or equal to ${max}"
                );
              }

              return schema;
            }
          )
      )
    ),
  stockUnit: yup
    .string()
    .required("Stock unit cannot be empty")
    .typeError("Stock unit cannot be empty"),
  unitFactor: yup
    .string()
    .typeError(unitFactorMessage)
    .test(
      numberKeepStringValidator(
        yup
          .number()
          .required(unitFactorMessage)
          .typeError(unitFactorMessage)
          .min(
            yup.ref("$validation.unitFactorMin"),
            "Unit factor must be greater than or equal to ${min}"
          )
          .max(
            yup.ref("$validation.unitFactorMax"),
            "Unit factor must be less than or equal to ${max}"
          )
      )
    )
    .test(
      limitComma(
        UNIT_FACTOR_LIMIT_COMMA,
        "Unit factor must have at most 3 digits after comma"
      )
    ),
  availableVolume: yup
    .string()
    .typeError("Stock material available must be a valid number")
    .test(
      numberKeepStringValidator(
        yup
          .number()
          .typeError("Stock material available must be a valid number")
          .min(
            yup.ref("$validation.availableVolumeMin"),
            "Stock material available volume must be greater than or equal to ${min}"
          )
          .max(
            yup.ref("$validation.availableVolumeMax"),
            "Stock material available volume must be less than or equal to ${max}"
          )
          .nullable()
          .transform(nullableNumber)
      )
    )
    .test(
      limitComma(
        AVAILABLE_VOLUME_LIMIT_COMMA,
        "Available volume must have at most 3 digits after comma"
      )
    )
    .nullable(),
  targetUnit: yup
    .string()
    .required("Target unit cannot be empty")
    .typeError("Target unit cannot be empty")
    .test({
      message:
        "Unit factor is equal to 1, so stock and target unit must be identical",
      test: (targetUnit, context) => {
        const { unitFactor, stockUnit } = context.parent;
        if (Number(unitFactor) === 1) {
          return stockUnit === targetUnit;
        }
        return true;
      },
    }),
});
