import { useFormContext } from "react-hook-form";
import { Calculated } from "../../../../../../../models/Dilution";
import { toFixed } from "../../../../../../../utils/helpers/toFixed";
import { HighLevelMaterialDilution } from "../../../HighLevelMaterialInfoForm/models/HighLevelMaterialDilution";
import { usePanelMemberInputConcentration } from "../hooks/usePanelMemberInputConcentration";
import { useUpdateTargetsInPanelMember } from "./useUpdateTargetsInPanelMember";
import { CONCENTRATION_LIMIT_COMMA } from "../../../../../constants";

export const useUpdateNotFirstPanelMemberTargets = (
  panelMemberIndex: number,
  targetIndex: number
) => {
  const getInputConcentration =
    usePanelMemberInputConcentration(panelMemberIndex);

  const updateTargetsInPanelMember = useUpdateTargetsInPanelMember(targetIndex);
  const { watch } = useFormContext<HighLevelMaterialDilution>();
  const targets = watch(
    `dilutionDetails.panelMembers.${panelMemberIndex}.targets`
  );
  return (targetConcentration: Calculated | null) => {
    if (!targetConcentration || targetConcentration.calculatedValue === "") {
      return;
    }
    const targetNumber = Number(targetConcentration.calculatedValue);

    if (isNaN(targetNumber) || targetNumber === 0) {
      return;
    }

    const inputConcentration =
      getInputConcentration(targetIndex).calculatedValue;
    const inputNumber = Number(inputConcentration);

    if (
      !inputConcentration ||
      isNaN(inputNumber) ||
      inputConcentration === ""
    ) {
      return;
    }

    const dilutionFactor: Calculated = {
      calculatedValue: toFixed(inputNumber / targetNumber, {
        roundTo: null,
      }),
      displayedValue: toFixed(inputNumber / targetNumber, {
        roundTo: CONCENTRATION_LIMIT_COMMA,
      }),
    };

    updateTargetsInPanelMember(targets ?? [], dilutionFactor, panelMemberIndex);
  };
};
