import { OwcButton } from "@one/react";
import { FetchResult, Mutation } from "react-apollo";
import ButtonLoading from "../../../components/button-loading/ButtonLoading";
import button from "../../../scss//button/Button.module.scss";
import { saveDraftGql } from "../../mutations/saveDraftGql";
import { DilutionInput } from "../../../models/DilutionInput";
import { useRunGraphQlWithSnackbars } from "../../../utils/hooks/useRunGraphQlWithSnackbars";
import { mastermixDilutionToDilutionInput } from "./utils/mastermixDilutionToDilutionInput";
import { useFormContext } from "react-hook-form";
import { MastermixDilution } from "../../../models/Dilution";
import { useHandleSaveMutationResult } from "../../common-dilution/serial-and-co-formulated-dilution/forms/PanelMemberForm/useHandleSaveMutationResult";

const getId = (
  result: FetchResult<{ saveDraft: { id: string } | null }>
): string => result?.data?.saveDraft?.id ?? "";

export const SaveMastermixDraft = () => {
  const handleSaveMutationResult = useHandleSaveMutationResult({
    navigatePrefix: "/",
    getId,
  });
  const runGraphQlWithSnackbars = useRunGraphQlWithSnackbars({
    errorMessage: "Saving draft dilution went wrong!",
    successMessage: "Successfully saved draft dilution!",
  });
  const { getValues, trigger } = useFormContext<MastermixDilution>();
  return (
    <Mutation<{ saveDraft: { id: string } | null }, { dilution: DilutionInput }>
      mutation={saveDraftGql}
    >
      {(saveDraft, { loading }) => (
        <OwcButton
          className={button.large}
          flat
          type="button"
          data-testid="mastermix-save-draft-btn"
          disabled={loading}
          onClick={async () => {
            const validation = await trigger("name", { shouldFocus: true });
            if (validation) {
              await runGraphQlWithSnackbars(async () => {
                const values = getValues();
                const dilutionInput = mastermixDilutionToDilutionInput(values);
                const result = await saveDraft({
                  variables: { dilution: dilutionInput },
                });
                handleSaveMutationResult(result);
              });
            }
          }}
        >
          {loading && (
            <ButtonLoading testId="mastermix-save-draft-btn-loading" />
          )}
          Save
        </OwcButton>
      )}
    </Mutation>
  );
};
