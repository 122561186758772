import { TDocumentDefinitions } from "pdfmake/interfaces";
import { CoFormDilution } from "../../../models/Dilution";
import { UserInfo } from "../../../utils/hooks/useUserInfo";

import idTable from "../shared/id-table";
import metaDataTable from "../shared/meta-data-table";
import { generateTemplate } from "../utils/generate-template";
import dilutionsTable from "./tables/dilutions-table";
import inputMaterialsTables from "./tables/input-materials-tables";
import summaryTables from "./tables/summary-table";

import preDilutionsTables from "./tables/pre-dilutions-tables";

export const generateDilutionPdf = (
  dilution: CoFormDilution,
  userInfo: UserInfo | null
): TDocumentDefinitions => {
  const { updatedBy, elnId, status: dilutionStatus, name } = dilution;
  const {
    inputMaterials,
    diluents,
    preDilutions,
    panelMembers,
    customLeftOver,
  } = dilution.dilutionDetails!;

  return {
    ...generateTemplate({ dilutionName: name }),
    content: [
      idTable({ elnId, status: dilutionStatus, updatedBy }),
      summaryTables(panelMembers, customLeftOver),
      inputMaterialsTables(inputMaterials, diluents ?? []),
      preDilutions && preDilutions.length
        ? preDilutionsTables(preDilutions, diluents ?? [])
        : [],
      dilutionsTable(panelMembers, diluents ?? [], customLeftOver),
      metaDataTable({
        printedBy: userInfo?.userId,
        appVersion: dilution?.appVersion,
        additionalRows: [
          ["*", "Total Volume / Dilution Factor"],
          ["**", "Total Volume - Volume of Predecessor"],
          ["***", "Source Concentration / Target Concentration"],
          [
            "****",
            customLeftOver
              ? "leftover + dead volume + amount of volume needed for next Panel Member"
              : "number of replicates * volume of replicate + dead volume + amount of volume needed for next Panel Member",
          ],
          [
            "*****",
            "dead volume + amount of volume needed for next Panel Member or Pre-Dilution",
          ],
          customLeftOver
            ? ["******", "Panel Volume needed + Dead Volume"]
            : ["", ""],
          ["Note: ", "All calculated values are rounded values."],
        ],
      }),
    ],
  };
};
