import { Material } from "../../../../models/coform-models/Material";
import { PreDilutionStep } from "../../../../models/coform-models/PreDilutionStep";
import { Reagent } from "../../../../models/mastermix-models/Reagent";
import { ReagentGroup } from "../../../../models/mastermix-models/ReagentGroup";
import inputMaterialsTables from "./input-materials-tables";
import predilutionStepsTable, {
  PreDilutionTableRow,
} from "./pre-dilution-tables";

const reagentsWithPredilutionTables = (reagents: Reagent[]) => {
  const sections = reagents.map((reagent, idx) => {
    const materials = inputMaterialsTables(
      reagent.material as Material,
      `Dilution ${idx + 1} - ${reagent.material.shortName ?? ""} ${
        reagent.material.stockName ? `(${reagent.material.stockName})` : ""
      } `
    );
    const rows: PreDilutionTableRow[] =
      reagent?.preDilution?.dilutionSteps!.map(
        (dilutionStep: PreDilutionStep, id) => {
          const {
            stepName,
            inputName,
            inputConcentration,
            dilutionFactor,
            targetConcentration,
            totalVolume,
            deadVolume,
          } = dilutionStep;
          return {
            stepName: { value: stepName },
            predecessorName: { value: inputName },
            concentrationPredecessor: {
              value: inputConcentration?.displayedValue ?? "",
              unit: reagent.preDilution?.inputMaterial.stockUnit ?? undefined,
            },
            volumeOfPredecessor: {
              value: dilutionStep.inputVolume?.displayedValue ?? "",
            },
            volumeOfDiluent: {
              value: dilutionStep.diluentVolume?.displayedValue ?? "",
            },
            dilutionFactor: { value: dilutionFactor?.displayedValue ?? "" },
            finalConcentration: {
              value: targetConcentration?.displayedValue ?? "",
              unit: reagent.preDilution?.inputMaterial.targetUnit ?? undefined,
            },
            totalVolume: { value: totalVolume?.displayedValue ?? "" },
            deadVolume: { value: deadVolume },
          } as PreDilutionTableRow;
        }
      ) ?? [];
    return [materials, predilutionStepsTable({ rows })];
  });
  return sections;
};

export const getReagentsWithPredilution = (groups: ReagentGroup[]) =>
  groups
    ?.map((group) =>
      (group?.reagents ?? [])?.filter((x) => x?.preDilution)?.filter((x) => x)
    )
    .flatMap((x) => x) ?? [];

export default reagentsWithPredilutionTables;
