import { createContext } from "react";
import { DilutionType } from "../../../../models/DilutionType";

export interface DilutionListFilterContextType {
  searchText: string;
  setSearchText: (text: string) => void;
  type: "ALL" | DilutionType;
  setType: (type: "ALL" | DilutionType) => void;
  hasFilter: boolean;
  clearFilter: () => void;
}

export const DilutionListFilterContext =
  createContext<DilutionListFilterContextType | null>(null);
