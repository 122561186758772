import { Amplify } from "aws-amplify";
import { Rehydrated } from "aws-appsync-react";
import React from "react";
import { ApolloProvider } from "react-apollo";
import { createRoot } from "react-dom/client";
import { appSyncClient } from "./appSyncClient";
import awsconfig from "./aws-exports";
import { ApolloClientWrapper } from "./components/apollo-client-context/ApolloClientWrapper";
import "./index.css";
import Main from "./Main";
import reportWebVitals from "./reportWebVitals";

declare module "csstype" {
  interface Properties {
    [index: `--${string}`]: any;
  }
}

Amplify.configure(awsconfig);

const TypeSafeRehydrated = Rehydrated as any;
const TypeSafeApolloProvider = ApolloProvider as any;

const root = createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <TypeSafeApolloProvider client={appSyncClient}>
      <TypeSafeRehydrated>
        <ApolloClientWrapper>
          <Main />
        </ApolloClientWrapper>
      </TypeSafeRehydrated>
    </TypeSafeApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
