import { FormState } from "react-hook-form";
import { PanelMember } from "../../../../../../models/coform-models/PanelMember";
import { PreDilution } from "../../../../../../models/coform-models/PreDilution";

import { HighLevelMaterialDilution } from "../models/HighLevelMaterialDilution";
import { getPanelMembersArray } from "./getPanelMembersArray";
import { getPreDilutionStepArray } from "./getPreDilutionStepArray";

export const highLevelMaterialDilutionToDilution = (
  values: HighLevelMaterialDilution,
  defaultValues?: FormState<HighLevelMaterialDilution>["defaultValues"]
): HighLevelMaterialDilution => {
  if (!values.dilutionDetails) {
    return values;
  }
  const { dilutionDetails } = values;
  let preDilutions: PreDilution[] = dilutionDetails.inputMaterials?.map(
    (material, materialIndex) => {
      const existingPreDilution = dilutionDetails.preDilutions?.find(
        ({ inputMaterial }) => inputMaterial.id === material.id
      );
      if (existingPreDilution) {
        return {
          ...existingPreDilution,
          inputMaterial: material,
        };
      } else {
        return {
          preDilutionNumber: materialIndex + 1,
          inputMaterial: material,
          dilutionSteps: null,
        };
      }
    }
  );
  dilutionDetails.inputMaterials?.forEach((material, materialIndex) => {
    if (!Number.isNaN(material.highLevelInfo?.numberOfPreDilutions)) {
      const preDilution = preDilutions[materialIndex];
      preDilution.dilutionSteps = getPreDilutionStepArray(
        values,
        preDilution,
        dilutionDetails.preDilutions?.find(
          ({ inputMaterial }) => inputMaterial.id === material.id
        ),
        defaultValues?.dilutionDetails?.diluents![0]
      );
    }
  });

  preDilutions = preDilutions?.filter(
    ({ dilutionSteps }) => dilutionSteps !== null && dilutionSteps.length > 0
  );

  const panelMembers: PanelMember[] = getPanelMembersArray(
    values,
    preDilutions
  );

  return {
    ...values,
    dilutionDetails: {
      ...values.dilutionDetails,
      panelMembers,
      preDilutions,
    },
  };
};
