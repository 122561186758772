const WelcomePic = () => {
  return (
    <svg
      data-testid="welcome-pic"
      width="441"
      height="200"
      viewBox="0 0 441 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M423.01 26.2281L406.17 43.0681L401.01 37.8981L398.18 35.0881L415.02 18.2381C415.178 18.0854 415.39 18 415.61 18C415.83 18 416.041 18.0854 416.2 18.2381L423.01 25.0481C423.163 25.2065 423.248 25.418 423.248 25.6381C423.248 25.8582 423.163 26.0697 423.01 26.2281Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M423.01 26.228L406.17 43.068L401.01 37.898L418.73 20.718L423.06 25.048C423.206 25.2128 423.283 25.428 423.273 25.6481C423.264 25.8683 423.17 26.0762 423.01 26.228Z"
        fill="#BFD9F2"
      />
      <path
        d="M44.3399 116.638C46.3175 122.519 50.5202 127.392 56.0473 130.213C61.5745 133.033 67.9864 133.576 73.9092 131.725C79.8319 129.875 84.7942 125.778 87.7328 120.313C90.6713 114.848 91.3524 108.449 89.6299 102.488C89.2572 101.288 88.7856 100.12 88.2199 98.998C86.7 95.914 84.5272 93.1976 81.8524 91.0374C79.1775 88.8772 76.0647 87.3248 72.7299 86.488V53.288H73.0399C73.5704 53.288 74.0791 53.0773 74.4541 52.7022C74.8292 52.3271 75.0399 51.8184 75.0399 51.288V49.668C75.0399 49.1375 74.8292 48.6288 74.4541 48.2538C74.0791 47.8787 73.5704 47.668 73.0399 47.668H59.1399C58.6095 47.668 58.1008 47.8787 57.7257 48.2538C57.3506 48.6288 57.1399 49.1375 57.1399 49.668V51.298C57.1399 51.8284 57.3506 52.3371 57.7257 52.7122C58.1008 53.0873 58.6095 53.298 59.1399 53.298H59.5299V87.058C54.7726 88.642 50.6409 91.6953 47.7299 95.778C45.586 98.754 44.1637 102.188 43.5753 105.808C42.9869 109.429 43.2487 113.136 44.3399 116.638Z"
        fill="white"
      />
      <path
        d="M44.34 116.638C46.3176 122.519 50.5202 127.392 56.0474 130.213C61.5746 133.033 67.9865 133.576 73.9093 131.725C79.832 129.875 84.7943 125.778 87.7329 120.313C90.6714 114.848 91.3525 108.449 89.63 102.488C89.2573 101.288 88.7857 100.12 88.22 98.998C88.22 98.998 80.14 105.538 61.67 98.248C61.67 98.248 53.12 93.3179 47.67 95.8179C45.5424 98.7943 44.1343 102.224 43.5565 105.836C42.9787 109.449 43.2468 113.146 44.34 116.638Z"
        fill="#BFD9F2"
      />
      <path
        d="M88.22 98.958C88.22 98.958 85.32 90.418 76.64 93.798C76.64 93.798 66.53 97.878 61.64 98.208C61.67 98.208 77.96 105.588 88.22 98.958Z"
        fill="#80B3E6"
      />
      <path d="M89.46 67.2981H17.5V73.6081H89.46V67.2981Z" fill="#CDE0F5" />
      <path
        d="M36.3999 177.468L36.3999 47.5281H30.0899L30.0899 177.468H36.3999Z"
        fill="#CDE0F5"
      />
      <path
        d="M33.71 77.878C37.3441 77.878 40.29 74.932 40.29 71.298C40.29 67.664 37.3441 64.718 33.71 64.718C30.076 64.718 27.13 67.664 27.13 71.298C27.13 74.932 30.076 77.878 33.71 77.878Z"
        fill="#E0ECF9"
      />
      <path
        d="M33.71 73.608C35.0023 73.608 36.05 72.5603 36.05 71.268C36.05 69.9756 35.0023 68.928 33.71 68.928C32.4176 68.928 31.37 69.9756 31.37 71.268C31.37 72.5603 32.4176 73.608 33.71 73.608Z"
        fill="white"
      />
      <path d="M106.72 170H22.72V181H106.72V170Z" fill="#CDE0F5" />
      <path d="M106.28 172.898H22.87V174.998H106.28V172.898Z" fill="#BFD9F2" />
      <path
        d="M83.39 169.868H48.11V165.078L52.26 160.288H79.23L83.39 165.078V169.868Z"
        fill="#80B3E6"
      />
      <path
        d="M79.2299 154.138H51.9099V160.288H79.2299V154.138Z"
        fill="#BFD9F2"
      />
      <path
        d="M65.73 154.138C65.0214 154.081 64.3337 153.87 63.7149 153.52C63.096 153.171 62.561 152.69 62.1469 152.112C61.7328 151.534 61.4496 150.873 61.3172 150.175C61.1847 149.476 61.2062 148.757 61.38 148.068C61.5908 146.872 62.0434 145.733 62.71 144.718C63.71 143.058 65.55 139.838 65.71 137.218C66.6576 139.642 67.8048 141.984 69.14 144.218C69.8379 145.14 70.3568 146.185 70.67 147.298C71.42 150.038 71.52 154.138 65.73 154.138Z"
        fill="white"
      />
      <path d="M250.56 97.3682H158.43V101.728H250.56V97.3682Z" fill="#CDE0F5" />
      <path d="M158.43 86.3682H153.29V172.898H158.43V86.3682Z" fill="#E0ECF9" />
      <path d="M253.13 86.3682H247.99V172.898H253.13V86.3682Z" fill="#E0ECF9" />
      <path
        d="M186.35 90.3382C186.35 89.6274 186.068 88.9457 185.565 88.4431C185.062 87.9405 184.381 87.6582 183.67 87.6582H170.03C169.347 87.6742 168.695 87.9515 168.211 88.4332C167.726 88.9149 167.444 89.5642 167.423 90.2473C167.403 90.9305 167.645 91.5955 168.1 92.1054C168.555 92.6153 169.189 92.9313 169.87 92.9882V155.168C169.87 157.025 170.607 158.805 171.92 160.118C173.233 161.431 175.013 162.168 176.87 162.168C178.726 162.168 180.507 161.431 181.82 160.118C183.132 158.805 183.87 157.025 183.87 155.168V92.9882C184.542 92.9426 185.172 92.6437 185.632 92.1519C186.092 91.66 186.349 91.0118 186.35 90.3382Z"
        fill="white"
      />
      <path
        d="M183.85 117.118V155.168C183.85 157.025 183.112 158.805 181.8 160.118C180.487 161.431 178.706 162.168 176.85 162.168C174.993 162.168 173.213 161.431 171.9 160.118C170.587 158.805 169.85 157.025 169.85 155.168V114.168C172.342 114.324 174.752 115.116 176.85 116.468C179.03 117.516 181.514 117.747 183.85 117.118Z"
        fill="#80B3E6"
      />
      <path
        d="M212.71 90.3382C212.71 89.6274 212.428 88.9457 211.925 88.4431C211.422 87.9405 210.741 87.6582 210.03 87.6582H196.39C195.707 87.6742 195.056 87.9515 194.571 88.4332C194.086 88.9149 193.804 89.5642 193.784 90.2473C193.763 90.9305 194.005 91.5955 194.46 92.1054C194.916 92.6153 195.549 92.9313 196.23 92.9882V155.168C196.148 156.134 196.268 157.106 196.581 158.023C196.895 158.94 197.395 159.782 198.051 160.495C198.706 161.209 199.503 161.779 200.39 162.168C201.277 162.558 202.236 162.759 203.205 162.759C204.174 162.759 205.133 162.558 206.02 162.168C206.907 161.779 207.704 161.209 208.36 160.495C209.015 159.782 209.516 158.94 209.829 158.023C210.142 157.106 210.262 156.134 210.18 155.168V92.9882C210.861 92.9549 211.503 92.6613 211.974 92.1682C212.445 91.6751 212.708 91.02 212.71 90.3382Z"
        fill="white"
      />
      <path
        d="M210.18 117.118V155.168C210.262 156.134 210.142 157.106 209.829 158.023C209.515 158.94 209.015 159.782 208.359 160.495C207.704 161.209 206.907 161.779 206.02 162.168C205.133 162.558 204.174 162.759 203.205 162.759C202.236 162.759 201.277 162.558 200.39 162.168C199.503 161.779 198.706 161.209 198.05 160.495C197.395 159.782 196.894 158.94 196.581 158.023C196.268 157.106 196.148 156.134 196.23 155.168V114.168C198.722 114.324 201.132 115.116 203.23 116.468C205.396 117.502 207.859 117.733 210.18 117.118Z"
        fill="#BFD9F2"
      />
      <path
        d="M239.4 90.3382C239.4 89.6291 239.119 88.949 238.619 88.4467C238.118 87.9443 237.439 87.6608 236.73 87.6582H223.08C222.397 87.6761 221.747 87.955 221.264 88.4375C220.78 88.92 220.5 89.5694 220.481 90.2522C220.462 90.9349 220.705 91.599 221.161 92.108C221.616 92.6169 222.249 92.9319 222.93 92.9882V155.168C222.84 156.138 222.953 157.116 223.262 158.039C223.572 158.962 224.07 159.811 224.726 160.531C225.382 161.251 226.181 161.826 227.072 162.219C227.963 162.612 228.926 162.816 229.9 162.816C230.874 162.816 231.837 162.612 232.728 162.219C233.619 161.826 234.418 161.251 235.074 160.531C235.73 159.811 236.228 158.962 236.537 158.039C236.847 157.116 236.96 156.138 236.87 155.168V92.9882C237.551 92.9549 238.193 92.6613 238.664 92.1682C239.135 91.6751 239.398 91.02 239.4 90.3382Z"
        fill="white"
      />
      <path
        d="M236.85 117.118V155.168C236.94 156.138 236.827 157.116 236.517 158.039C236.208 158.962 235.71 159.811 235.054 160.531C234.398 161.251 233.599 161.826 232.708 162.219C231.817 162.612 230.854 162.816 229.88 162.816C228.906 162.816 227.943 162.612 227.052 162.219C226.161 161.826 225.362 161.251 224.706 160.531C224.05 159.811 223.552 158.962 223.243 158.039C222.933 157.116 222.82 156.138 222.91 155.168V114.168C225.401 114.325 227.811 115.117 229.91 116.468C232.072 117.503 234.533 117.734 236.85 117.118Z"
        fill="#80B3E6"
      />
      <path d="M257.41 169.868H150.72V180.148H257.41V169.868Z" fill="#CDE0F5" />
      <path d="M242.63 95.3782H163.79V103.708H242.63V95.3782Z" fill="#E0ECF9" />
      <path
        d="M424.87 180.138H307.67C307.666 177.777 308.13 175.438 309.034 173.257C309.939 171.075 311.266 169.094 312.94 167.428C313.467 166.899 314.028 166.404 314.62 165.948C317.76 163.491 321.632 162.153 325.62 162.148H406.84C411.614 162.148 416.192 164.045 419.568 167.421C422.944 170.796 424.84 175.375 424.84 180.148L424.87 180.138Z"
        fill="#E0ECF9"
      />
      <path
        opacity="0.2"
        d="M417.92 165.948H314.62C317.76 163.49 321.632 162.153 325.62 162.148H406.84C410.855 162.133 414.759 163.472 417.92 165.948Z"
        fill="#80B3E6"
      />
      <path
        d="M350.893 85.8511L357.172 92.1302L350.356 98.9467C350.243 99.0592 350.091 99.1224 349.931 99.1224C349.772 99.1224 349.62 99.0592 349.507 98.9467L344.077 93.5161C344.021 93.4604 343.977 93.3943 343.947 93.3215C343.916 93.2487 343.901 93.1707 343.901 93.0919C343.901 93.0131 343.916 92.9351 343.947 92.8623C343.977 92.7895 344.021 92.7233 344.077 92.6676L350.893 85.8511Z"
        fill="white"
      />
      <path
        d="M319.39 133.368V136.738C319.388 138.071 319.888 139.356 320.79 140.338L340.94 162.148H390.2C390.2 162.148 435.69 124.808 393.28 63.5781L411.36 46.1381L396.01 30.0581L344.36 82.0581L358.73 97.3681L378.91 77.6081C378.91 77.6081 390.27 88.6081 391.1 110.278C391.7 122.584 387.967 134.711 380.55 144.548C374.55 152.348 365.94 158.668 356.67 150.128L338.2 133.368H319.39Z"
        fill="#E0ECF9"
      />
      <path
        opacity="0.2"
        d="M333.41 133.368L359.41 157.998C359.41 157.998 371.04 166.888 382.67 155.598C382.67 155.598 420.98 118.658 386.09 71.1082L411.36 46.1082L358.36 84.7581L346.73 79.6482L344.34 82.0582L358.73 97.3682L378.91 77.6082C378.91 77.6082 392.97 90.4882 391.25 116.488C390.738 124.891 388.229 133.05 383.93 140.288C378.23 149.798 368.34 160.748 356.67 150.168L338.2 133.408L333.41 133.368Z"
        fill="#80B3E6"
      />
      <path d="M349.49 131.308H301.94V135.408H349.49V131.308Z" fill="white" />
      <path d="M22.5 181L424.5 181" stroke="#BFD9F2" strokeWidth="2" />
    </svg>
  );
};

export default WelcomePic;
