import { FormState } from "react-hook-form";
import { Diluent } from "../../../../../../models/Diluent";

export const compareFormDiluents = (
  a: Exclude<FormState<Diluent>["defaultValues"], undefined>,
  b: Exclude<FormState<Diluent>["defaultValues"], undefined>
) =>
  a.lotNumber === b.lotNumber &&
  a.stockName === b.stockName &&
  a.number === b.number;
